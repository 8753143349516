import React from 'react'
import './Esfuerzos.css'
import otros from '../../../assets/images/desastres/IMG_salon.png'
import extr1 from '../../../assets/images/desastres/extr1.png'
import extr2 from '../../../assets/images/desastres/extr2.png'

export default function Esfuerzos({english=false}) {
    const textos = {
        en: {
            t1:`EARTHQUAKES IN SYRIA AND TURKEY`,
            t2:`OUR ACTIONS:`,
            t3:`Thanks to the alliance with <span class='bold'>Save the Children</span> through <span class='bold'>Gol por Mexico</span>, the <span class='bold'>first international support</span> was possible.`,
            t4:`<span class='bold'>20,000 children and young people affected by the earthquakes</span> were benefited with <span class='bold'>prepared food and blankets</span>.`
        },
        es: {
          t1:`Sismos en Siria y Turquía`,
          t2:`Nuestras acciones:`,
          t3:`Gracias a la alianza con <span class='bold'>Save the Children </span> a través de <span class='bold'>Gol por México </span>se pudo realizar el <span class='bold'>primer apoyo internacional.</span>`,
          t4:`<span class='bold'>20,000 niños, niñas y jóvenes afectados por los sismos, </span>fueron beneficiados con <span class='bold'>alimentos preparados y cobijas.</span>`
        },
      }
    
    const txt_v = english ? textos.en : textos.es;
  return (
    <div>
        <div className="container-fluid py-4 bg-default">
            <div className="row justify-content-center">
                <div className="col-lg-9">
                    <h2 className="cdn-title">{txt_v.t1}</h2>
                    <h3 className="cdn-subtitle pb-4 pb-lg-5">{txt_v.t2}</h3>
                </div>
            </div>
            <div className="row justify-content-evenly">
                <div className="col-lg-4 col-10">
                    <img src={extr1} alt="info" className="extr-img img-fluid" />
                    <p className="extr-descr" dangerouslySetInnerHTML={{ __html: txt_v.t3 }}></p>
                </div>
                <div className="col-lg-4 col-10 pt-4 pt-lg-0">
                    <img src={extr2} alt="info" className="extr-img img-fluid" />
                    <p className="extr-descr" dangerouslySetInnerHTML={{ __html: txt_v.t4 }}></p>
                </div>
            </div>
            {/* <div className="row bg-cint-az text-center py-5">
                <a href="https://tuayudasillega.org"><h3 className="tys-link">tuayudasillega.org</h3></a>
            </div> */}
        </div>
    </div>
  )
}
