import React from 'react'
import './Reconocimientos.css'
import img from '../../../../assets/images/programas/tecnolochicas/icon-tch.png'
export default function Reconocimientos({english=false}) {

    const textos = {
        en: {
            t1:`ACKNOWLEDGEMENTS`,
            t2:`El <b>Instituto Mexicano para la Competitividad, A.C</b>  (IMCO) referred to <b>Tecnolochicas</b> as one of the most important public-private <b>collaborations to close the gender gap in technology</b>.`,
            t3:`The <b>National Academic of Sciences</b> report refers to <b>Tecnolochicas</b> as one of the most committed organizations with actions to increase the number of <b>STEM women</b>.`,
        },
        es: {
            t1:`RECONOCIMIENTOS`,
            t2:`El <b>Instituto Mexicano para la Competitividad, A.C</b> (IMCO) se refirió a <b>Tecnolochicas</b> como una de las colaboraciones públicas- privadas <b>más importantes para cerrar la brecha de género</b> en Tecnología.`,
            t3:`El reporte de <b>National Academic of Sciences</b> hace referencia a <b>Tecnolochicas</b> como una de las organizaciones más comprometidas con acciones para incrementar el número de <b>mujeres STEM</b>.`,
        }
    }
    
    const txt_v = english ? textos.en : textos.es;
  return (
    <div className='rec-tch technochicas'>
        <div className="container-fluid">
            <div className="row pb-5">
                <h2 className="rc-t-tch my-5" dangerouslySetInnerHTML={{ __html: txt_v.t1 }}></h2>
                <div className="row justify-content-around m-auto">
                    <div className="col-lg-5 text-center card-rec">
                            <img src={img} alt="" className="my-4" /> <br />
                        <p className="rc-p-tch px-3" dangerouslySetInnerHTML={{ __html: txt_v.t2 }}></p>
                    </div>
                    <div className="col-lg-5 text-center card-rec">
                            <img src={img} alt="" className="my-4" /> <br />
                        <p className="rc-p-tch px-3" dangerouslySetInnerHTML={{ __html: txt_v.t3 }}></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
