import React, { useState } from 'react'
import './Impactamos.css'
// import rectangle1 from '../../../assets/images/comunicacion/rectangle-impactamos1.png'
// import rectangle2 from '../../../assets/images/comunicacion/rectangle-impactamos2.png'
// import rectangleMobile from '../../../assets/images/comunicacion/rectangle-impactamos1-mobile.png'
import CountUp from 'react-countup';
import cuantrixYucatan from '../../../assets/images/comunicacion/comunicacion.jpg'
import VideoPlayer from '../../VideoPlayer/VideoPlayer';

export default function Impactamos({english=false}) {
  const [showVideo, setShowVideo] = useState(false);
  const textos = {
    en: {
      t1:`In 2023, our messages reached`,
      t2:`<b> million people</b> through <b>television</b> and our <b>digital channels</b>.`,
      t3:`Our <b>online community</b> has <b>more than 2.1 million people</b>, who receive valuable content daily.`
    },
    es: {
      t1:`En 2023, nuestros mensajes alcanzaron`,
      t2:`<b> millones de personas</b> por medio de la <b>televisión</b> y nuestros <b>canales digitales.</b>`,
      t3:`Nuestra <b>comunidad en línea</b> cuenta con <b>más de 2.1 millones de personas,</b> que día con día reciben contenidos de valor.`
    },
  }

  const txt_v = english ? textos.en : textos.es;

  return (
    <div className='Impactamos'>
      <div className='row m-0 px-0 py-4 py-md-0'>
        <div className='col-12'>
          <div className='d-flex justify-content-center align-items-center h-100 px-0 py-0 px-lg-5 py-lg-5'>
            <div className='title px-4 py-0 py-md-4 py-lg-5 px-lg-5'>
              {txt_v.t1} <b><CountUp
                enableScrollSpy={true}
                start={'60'}
                end={61.5}
                duration={3}
                decimals={1}
                decimal="."
              /></b>
              <span dangerouslySetInnerHTML={{ __html: txt_v.t2 }}></span>
            </div>
          </div>
        </div>
      </div>

      <div className='row m-0 p-0'>
        <div className='col-12 col-lg-6 m-0 p-0'>
          {
            !showVideo &&
            <div className="img-vid">
            <img src={cuantrixYucatan} alt="Cuantrix Yucatán" className="ctx-img object-fit-cover" onClick={() => setShowVideo(true)} style={{ width: '100%' }}  />
            {/*<img src={play} alt=">" className="overlay-icon" />*/}
            </div>
          }
          {
            showVideo &&
            <div className='p-4'>
            <VideoPlayer
                src="https://www.youtube.com/watch?v=DwJo8gcmYdg"
                className="w-100"
                playing={true}
            />
            </div>
          }
        </div>
        <div className='col-12 col-lg-6 m-0 p-0 px-lg-5 bg1'>
          <div className='d-flex justify-content-center align-items-center h-100'>
            <div className='subtitle px-2 py-4 px-md-5 py-md-5 px-lg-0 py-lg-0' dangerouslySetInnerHTML={{ __html: txt_v.t3 }}></div>
          </div>
        </div>
      </div>

    </div>
      
  )
}
