import React from 'react'
import'./Testimonios.css'
import Carousel from '../../../Carousel/Carousel'
import iconoTest from '../../../../assets/images/programas/tecnolochicas/testimonios.svg'
import thumbnailTecnolochicas from '../../../../assets/images/home/testimonios/thumbnails/tecnolochicas.jpg'
import thumbnailTecnolochicas2 from '../../../../assets/images/programas/tecnolochicas/thumbnails/tecnolochicas2.jpg'
import thumbnailTecnolochicas3 from '../../../../assets/images/programas/tecnolochicas/thumbnails/tecnolochicas3.png'

const items = [
    {
        type: 'video',
        image: thumbnailTecnolochicas,
        videoUrl: 'https://www.youtube.com/watch?v=t8lg4Hs9nXk&feature=youtu.be'
    },
    {
        type: 'video',
        image: thumbnailTecnolochicas2,
        videoUrl: 'https://www.youtube.com/watch?v=td3d7N50CvQ&feature=youtu.be'
    },
    {
        type: 'video',
        image: thumbnailTecnolochicas3,
        videoUrl: 'https://www.youtube.com/watch?v=C37JEfLvXnU&feature=youtu.be'
    }
];
export default function Testimonios({english=false}) {
    const textos = {
        en: {
            t1:`READ THEIR TESTIMONIALS`
        },
        es:{
            t1:`CONOCE SUS TESTIMONIOS`
        }
      }
    
    const txt_v = english ? textos.en : textos.es;

  return (
    <div className='testim-bg'>
        <div className="container  pb-5">
            <div className="row text-center">
                <img src={iconoTest} alt="" className="img-logo" />
                <h2 className="testimonios-title">
                    {txt_v.t1}
                </h2>
            </div>
            <div className="row">
                <Carousel 
                items={items}/>
            </div>
        </div>
    </div>
  )
}
