import React from 'react'
import './Cuantrix.css'
import Escolar from './Escolar/Escolar'
import Iniciativas from './Iniciativas/Iniciativas'
import Impacto from './Impacto/Impacto'
import Testimonios from './Testimonios/Testimonios'
import Comunidades from './Comunidades/Comunidades'
import Progredes from '../Progredes/Progredes'
import fb from '../../../assets/images/iconos/fb.svg'
import ig from '../../../assets/images/iconos/ig.svg'
import tiktok from '../../../assets/images/iconos/tiktok.svg'
import yt from '../../../assets/images/iconos/yt.svg'
// import Footer from '../../Footer/Footer'
import FooterProgramas from '../FooterProgramas/FooterProgramas'
import arrowsValores from '../../../assets/images/programas/footer/arrows-valores.png'


const social= [
  {
      link:'https://www.facebook.com/cuantrixmx',
      icon:fb,
      nombre:'cuantrixmx'
  },
  {
      link:'https://www.instagram.com/cuantrix',
      icon:ig,
      nombre:'cuantrix'
  },
  {
    link:'https://www.youtube.com/@cuantrix7343 ',
    icon:yt,
    nombre:'cuantriX'
  },
  {
    link:'https://www.tiktok.com/@cuantrix?lang=es',
    icon:tiktok,
    nombre:'cuantrix'
  }
]

export default function Cuantrix(
  {handleSectionSelected,englishp=false}
) {
  return (
    <div className='cuantrix'>
        <Escolar english={englishp}/>
        <Iniciativas english={englishp}/>
        <Impacto english={englishp}/>
        <Testimonios english={englishp}/>
        <Comunidades english={englishp}/>
        <Progredes 
          sitio = 'https://golpormexico.org/'
          redes = {social}
          english={englishp}
        />

        {/* <Footer /> */}
        <FooterProgramas 
          background='bg-programa-valores'
          arrows={arrowsValores}
          title='VALORES'
          color='#216C95'
          bgImage='bg-program-image-valores'
          handleSectionSelected={handleSectionSelected}
          programSection='valores'
          english={englishp}
        />
    </div>
  )
}
