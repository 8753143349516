import React from 'react'
import './Desastres.css'
// import Headerdn from '../../componentes/Desastres/Headerdn/Headerdn'
import Sedena from '../../componentes/Desastres/Sedena/Sedena'
import Programas from '../../componentes/Desastres/Programas/Programas'
import Esfuerzos from '../../componentes/Desastres/Esfuerzos/Esfuerzos'
import Footer from '../../componentes/Footer/Footer'
import HeaderSection from '../../componentes/HeaderSection/HeaderSection'
import hero from '../../assets/images/desastres/dn-r-mv.png'

import item1 from '../../assets/images/items-footer/nuestros-aliados.png'
import item2 from '../../assets/images/items-footer/reconocimientos.png'
import item3 from '../../assets/images/items-footer/nuestros-programas.png'
import mobile from '../../assets/images/items-footer/nuestros-aliados-mobile.png'

const itemsDesastres = [
  {
    image: item1,
    title: {
      en:'OUR ALLIES',
      es:'Nuestros Aliados',
    },
    link: '/nuestros-aliados'
  },
  {
    image: item2,
    title: {
      en:'ACKNOWLEDGEMENTS',
      es:'RECONOCIMIENTOS',
    },
    link: '/reconocimientos'
  },
  {
    image: item3,
    title: {
      en:'OUR PROGRAMS',
      es:'NUESTROS PROGRAMAS',
    },
    link: '/programas'
  }
];

const itemDesastresMobile = [
  {
    image: mobile,
    title: {
      en:'OUR ALLIES',
      es:'Nuestros Aliados',
    },
    link: '/nuestros-aliados'
  }
]

export default function Desastres({version=false}) {
  const textos = {
    en: {
      t1:'SUPPORT IN NATURAL DISASTERS '
    },
    es: {
      t1:'APOYO EN DESASTRES NATURALES'
    }
  }
  const txt_v = version ? textos.en : textos.es;
  return (
    <div className='dn'>
        {/* <Headerdn /> */}

        <HeaderSection
          image={hero}
          title={txt_v.t1}
          english={version}
        />

        <Sedena english={version}/>
        <Programas english={version}/>
        <Esfuerzos english={version}/>
        <Footer 
          showLogo={false}
          items={itemsDesastres}
          itemMobile={itemDesastresMobile}
          english={version}
        />
    </div>
  )
}
