import React from 'react'
import './Dnatural.css'
import viveres from '../../../assets/images/home/dnatural/viveres.png'
import viveresMobile from '../../../assets/images/home/dnatural/viveres.jpg'
import iconodn from '../../../assets/images/home/dnatural/desastres.svg'
import CountUp from 'react-countup'
import { Button } from '../../Button/Button'
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { LazyLoadImage } from 'react-lazy-load-image-component'

export function Dnatural({english=false}) {

  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const variants = {
    hidden: { translateY: '20vh' },
    visible: { translateY: '0vh', transition: { duration: 1 } },
  };

  const textos = {
    en: {
      termi:'/en',
      links:{
        l1:'desastres-naturales'
      },
      mov:{
        t1:`NATURAL DISASTER SUPPORT`,
        t2:`In <b>response</b> to <b>Hurricane Otis</b>, we mobilized resources, together with our allies, allocating <b>60 million pesos</b> in relief actions for <b>Guerrero, Mexico</b>. We promoted <b>donations to various organizations</b> to deal with the emergency.`
      },
      desk:{
        t1:`NATURAL DISASTER SUPPORT`,
        t2:`In <b>response</b> to <b>Hurricane Otis</b>, we mobilized resources, together with our allies, allocating <b>60 million pesos</b> in relief actions for <b>Guerrero, Mexico</b>. We promoted <b>donations to various organizations</b> to deal with the emergency.`
      }
    },
    es: {
      termi:'',
      links:{
        l1:'desastres-naturales'
      },
      mov:{
        t1:`APOYO EN DESASTRES NATURALES`,
        t2:`En <b>respuesta al Huracán Otis</b> movilizamos recursos junto con nuestros aliados, destinando <b>60 millones de pesos</b> en acciones de ayuda para <b>Guerrero, México.</b> Promovimos <b>donativos a diversas organizaciones</b> para enfrentar la emergencia.`
      },
      desk:{
        t1:`APOYO EN DESASTRES NATURALES`,
        t2:`En <b>respuesta al Huracán Otis</b> movilizamos recursos junto con nuestros aliados, destinando <b>60 millones de pesos</b> en acciones de ayuda para <b>Guerrero, México.</b> Promovimos <b>donativos a diversas organizaciones</b> para enfrentar la emergencia.`
      }
    }
  }

  const txt_v = english ? textos.en : textos.es;

    return (
        <div className='Dnatural'>
          <div className="container-fluid m-0 p-0">

            {/* mobile */}
            <div className='mobile-device m-0 p-0'>
              <div className=''>
                <div className='d-flex justify-content-center pt-md-5 pt-4'>
                  <img src={iconodn} alt="" className="icon-width" />
                </div>
                <div className='d-flex justify-content-center'>
                  <div className="dn-title px-4 py-2">{txt_v.mov.t1}</div>
                </div>
                <div className='d-flex justify-content-center'>
                  <div className="dn-par px-5 py-md-4 py-lg-5 py-2" dangerouslySetInnerHTML={{ __html: txt_v.mov.t2}}></div>
                </div>
                {/*<div className='mt-md-2 mt-0'>&nbsp;</div>*/}
              </div>

              <div className='m-0 p-0'>
                <section>
                  {/* <motion.div
                    // whileInView ={{ translateY: '0vh' }} // Start offscreen at the bottom
                    // animate={{ translateY: '20vh' }} // Animate to the top
                    // transition={{ duration: 0.5 }} // Set animation duration
                    variants={variants}
                    initial="hidden"
                    animate={inViewMobile ? 'visible' : 'hidden'}
                  > */}
                    <LazyLoadImage
                      src={viveresMobile}
                      alt="Image Alt"
                      className='img-fluid'
                    />
                    {/* <img src={viveres} alt="" className="img-fluid" /> */}
                  {/* </motion.div> */}
                </section>
                
              </div>

              <div className='mt-4 mb-4 mx-2 mx-md-0'>
                <Button link={txt_v.links.l1} english={english}/>
                <div className='pt-0 p-md-2'>&nbsp;</div>
              </div>
              
            </div>

            {/* web */}
            <div className='web-device'>
              <div className="row m-0 p-0">
                
                <div className="col-lg-6 m-0 p-0">
                  <div className='m-0 p-5  h-100'>
                    <div className='m-0 px-5 h-100 text-start m-auto'>
                      <img src={iconodn} alt="" className="my-md-4" style={{'paddingTop': '2rem'}} />
                      <div className="dn-title pb-md-2">
                        {txt_v.desk.t1}
                      </div>
                      <p className="dn-par" dangerouslySetInnerHTML={{ __html: txt_v.desk.t2}}></p>
                      <div className='mt-4'>
                        <Button link={txt_v.links.l1} english={english}/>
                      </div>
                    </div>
                  </div>
                  
                </div>

                <div className="col-lg-6 m-0 py-5" ref={ref}>
                  <motion.div
                    variants={variants}
                    initial="hidden"
                    animate={inView ? 'visible' : 'hidden'}
                  >
                    <img src={viveres} alt="" className="w-100" />
                  </motion.div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      )
}
