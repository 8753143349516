import React, { useState } from 'react'
import './HeaderFotograficaMx.css'
import heroTablet from '../../../../../assets/images/programas/subsections/fotograficamx/hero-tablet.png'
import CountUp from 'react-countup';
import VideoPlayer from '../../../../VideoPlayer/VideoPlayer';

export default function HeaderFotograficaMx({english=false}) {

  const [showVideoMobile, setShowVideoMobile] = useState(false);
  const [showVideo, setShowVideo] = useState(false);

  const textos = {
    en: {
        t1:`FOTOGRÁFICAMX`,
        t2:`We promote audiovisual and photographic culture.`,
        t3:`In 2023, we held`,
        t4:`<b>exhibitions and film shows</b> in Mexico and abroad, with an attendance of more than`,
        t5:`<b>people.</b>`,
        t6:`Through the Dirección de Artes Visuales, we promoted <b>heritage conservation, cultural, artistic and documentary appreciation,</b> as well as the <b>preservation and dissemination</b> of audiovisual culture.`,
        t7:`<b>people</b> were reached through our <b>digital communities</b>.`
    },
    es:{
        t1:`FOTOGRÁFICAMX`,
        t2:`Promovemos la cultura audiovisual y fotográfica.`,
        t3:`En 2023, realizamos`,
        t4:`<b>exposiciones y muestras de películas</b> en México y en el extranjero, con una asistencia de más de`,
        t5:`<b>personas.</b>`,
        t6:`A través de la Dirección de Artes Visuales, fomentamos la <b>conservación patrimonial, la valoración cultural, artística y documental,</b> así como la <b>preservación y difusión</b> de la cultura audiovisual.`,
        t7:`<b>de personas</b> fueron alcanzadas a través de nuestras <b>comunidades digitales</b>.`
    }
}

const txt_v = english ? textos.en : textos.es;

  return (
    <div className='HeaderFotograficaMx'>
      {/* mobile */}
      <div className='mobile-device'>
        <div className='m-0 p-0'>
          {
            !showVideoMobile &&
            <img src={heroTablet} alt='hero' className='w-100' onClick={() => setShowVideoMobile(true)} />
          }
          {
            showVideoMobile &&
            <div className='p-4'>
              <VideoPlayer
                src="https://www.youtube.com/watch?v=C-KGk3veQRs"
                className="w-100"
                playing={true}
              />
            </div>
          }
          
        </div>
        <div className='row m-0 p-0 bg-gradient-fotomx'>
          <div className='col-12 m-0 p-0 '>
            <div className='d-flex justify-content-center mt-4'>
              <div className='main-title-programas'>
                {txt_v.t1}
              </div>
            </div>
            <div className='d-flex justify-content-center'>
              <div className='main-subtitle-programas'>
                {txt_v.t2}
              </div>
            </div>
            <div className='row mt-4 px-5 pb-4'>
              <div className='col-12'>
                <div className='d-flex justify-content-center'>
                <p className='main-paragraph-programas pt-4'>
                  <span dangerouslySetInnerHTML={{ __html: txt_v.t3 }}></span> <b><CountUp
                    enableScrollSpy={true}
                    start={0}
                    end={7}
                    duration={2}
                  /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t4 }}></span> <b><CountUp
                    enableScrollSpy={true}
                    start={'228900'}
                    end={229000}
                    duration={3}
                  /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t5 }}></span>
                </p>
                </div>
                <div className='d-flex justify-content-center'>
                <p className='main-paragraph-programas pt-4' dangerouslySetInnerHTML={{ __html: txt_v.t6 }}></p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='row m-0 p-0 bg-purple px-5 py-4 px-md-5 py-md-4'>
          <div className='d-flex justify-content-center'>
            <div className='paragraph-hf-2'>
              <b><CountUp
                  enableScrollSpy={true}
                  start={'5084081'}
                  end={5084881}
                  duration={4}
                /></b><span dangerouslySetInnerHTML={{ __html: txt_v.t7 }}></span>
            </div>
          </div>
        </div>
        
      </div>

      {/* web */}
      <div className='web-device'>
        <div className='d-flex m-0 p-0'>
          <div className='w-50 bg-gradient-fotomx'>
            <div className='p-5'>
              <div className=''>
                <div className='main-title-programas'>
                  {txt_v.t1}
                </div>
                <div className='main-subtitle-programas pt-2'>
                  {txt_v.t2}
                </div>
                <p className='main-paragraph-programas pt-4'>
                  <span dangerouslySetInnerHTML={{ __html: txt_v.t3 }}></span> <b><CountUp
                    enableScrollSpy={true}
                    start={0}
                    end={7}
                    duration={2}
                  /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t4 }}></span> <b><CountUp
                    enableScrollSpy={true}
                    start={'228900'}
                    end={229000}
                    duration={3}
                  /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t5 }}></span>
                </p>
                <p className='main-paragraph-programas pt-4' dangerouslySetInnerHTML={{ __html: txt_v.t6 }}></p>
              </div>
            </div>
          </div>
          <div className='w-50'>
            {
              !showVideo &&
              <div className='bg-hero' onClick={() => setShowVideo(true)}>&nbsp;</div>
            }
            {
              showVideo &&
              <div className='p-4'>
                <VideoPlayer
                  src="https://www.youtube.com/watch?v=C-KGk3veQRs"
                  className="w-100"
                  playing={true}
                />
              </div>
            }
          </div>
          
        </div>
        <div className='row  bg-purple m-0 p-0 justify-content-center'>
          <div className='col-lg-8 p-5 py-md-5 px-md-4'>
            <div className='d-flex justify-content-center'>
              <div className='paragraph-hf-2 px-5'>
                <b><CountUp
                    enableScrollSpy={true}
                    start={'5084681'}
                    end={5084881}
                    duration={4}
                  /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t7 }}></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
