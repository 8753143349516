import React, {useEffect, useState} from 'react'
import './VideoPlayer.css'
import ReactPlayer from 'react-player';

export default function VideoPlayer(
  { src, playing = false }
) {

  const [width, setWidth] = useState('100%');
  const [height, setHeight] = useState(500);

  useEffect(() => {
    console.log('*** window.innerWidth: ', window.innerWidth);
    if (window.innerWidth < 600) {
      setWidth('100%');
      setHeight(200);
    } else {
      if (window.innerWidth < 768) {
        setWidth('100%');
        setHeight(320);
      } else {
        if (window.innerWidth < 1024) {
          setWidth('100%');
          setHeight(350);
        } else {
        if (window.innerWidth < 1280) {
          setWidth('100%');
          setHeight(380);
        } else {
          if (window.innerWidth < 1366) {
            setWidth('100%');
            setHeight(500);
          } else 
            if (window.innerWidth < 1440) {
              setWidth('100%');
              setHeight(500);
            } else{
            if (window.innerWidth < 1600) {
              setWidth('100%');
              setHeight(500);
            }
          }
        }
      }
      }
    }
  }, [playing, src]);


  return (
    <div className='VideoPlayer'>
      <div className="player-wrapper d-flex justify-content-center">
        <ReactPlayer
          url={src}
          controls
          width={width}
          height={height}
          playing={playing}
          config={{
            youtube: {
              playerVars: { showinfo: 0, rel: 0 }
            },
          }}

  />
      </div>
    </div>
  )
}
