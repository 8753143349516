import React from 'react'
import './Programs.css'
import CardProgram from './CardProgram/CardProgram'
import becalos from '../../../assets/images/igualdad/programs/becalos.png'
import cuantrix from '../../../assets/images/igualdad/programs/cuantrix.png'
import empiezaTemprano from '../../../assets/images/igualdad/programs/empieza_temprano.jpg'
import golPorMexico from '../../../assets/images/igualdad/programs/gol_por_mexico.png'
import posible from '../../../assets/images/igualdad/programs/posible.png'
import valores from '../../../assets/images/igualdad/programs/valores.jpg'
import tecnolochicas from '../../../assets/images/igualdad/programs/tecnolochicas.png'
import fotograficamx from '../../../assets/images/igualdad/programs/fotograficamx.png'

export default function Programs() {
  return (
    <div className='Programs'>
      <div className='program-container'>
        <div className='row p-0 m-0 '>
          <div className='col-12 col-lg-6 card-container pt-4 pt-lg-0'>
            <CardProgram
              image={becalos}
              title='Bécalos'
              description={<div><b>3,217 mujeres jóvenes</b> fueron impulsadas a través de <b>Bécalas</b> en áreas de <b>ingeniería y tecnología</b>. Otorgamos <b>150 becas Bécalos Tech Challenge</b> a <b>mujeres de 18 a 30 años</b> para formarse en <b>Programación y Ciencia de Datos</b>.</div>}
              url ='https://www.youtube.com/watch?utm_source=informe2023&utm_medium=juditharias&utm_id=becalas&v=U5kaf0NLO4k&feature=youtu.be'
              type='video'
            />
          </div>
          <div className='col-12 col-lg-6 mt-4 mt-md-5 mt-lg-0 card-container'>
            <CardProgram
              image={cuantrix}
              title='CUANTRIX'
              description={<div>El <b>49%</b> de las usuarias de <b>Cuantrix</b> son niñas que descubren sus habilidades digitales y buscan soluciones para destacar como <b>tecnólogas</b>.</div>}
            />
          </div>
          <div className='mt-0 mt-lg-4'>&nbsp;</div>
          <div className='col-12 col-lg-6 mt-4 mt-md-5 mt-lg-0 card-container'>
            <CardProgram
              image={empiezaTemprano}
              title='EMPIEZA TEMPRANO'
              description={<div><b>35 personas</b> fueron capacitadas para atender a <b>19 centros de readaptación social de mujeres</b> en Veracruz y Ciudad de México, con el objetivo de que las mujeres pudieran aprovechar cada momento con sus hijos de manera <b>más positiva</b>.</div>}
            />
          </div>
          <div className='col-12 col-lg-6 mt-4 mt-lg-0 card-container'>
            <CardProgram
              image={golPorMexico}
              title='Gol por méxico'
              description={<div>En <b>2023, 8,042 mil mujeres</b> transformaron sus vidas gracias al programa <b>Gol por México Femenil</b> con <b>mastografías, ultrasonidos, cursos y talleres</b> de economía del hogar y de la comunidad, prevención de la violencia de género, entre otros.</div>}
            />
          </div>
          <div className='mt-0 mt-lg-4'>&nbsp;</div>
          <div className='col-12 col-lg-6 mt-4 mt-lg-0 card-container'>
            <CardProgram
              image={posible}
              title='Posible'
              description={<div>Más de <b>2,198 mujeres</b> capacitadas e impulsadas a emprender con <b>Mujer POSIBLE</b> en <b>Chiapas y se incorporó al Foro Mujer Emprendedora en el Estado de México</b>. <br/><br/>En 2023 se logró la <b>participación de mujeres con 41%</b> en la <b>Convocatoria Nacional</b> de <b>POSIBLE, 45%</b> en el <b>Campamento Nacional 2023 y 40%</b> en <b>Posible COOP</b> y <b>Reto Posible</b>.</div>}
            />
          </div>
          <div className='col-12 col-lg-6 mt-4 mt-lg-0 card-container'>
            <CardProgram
              image={valores}
              title='Valores'
              description={<div>En 2023 nuestra campaña anual estuvo enfocada al valor del <b>Respeto</b>, que incluye el <b>respeto a las mujeres</b>. El spot fue transmitido por <b>Facebook</b> alcanzando  más de <b>65 mil reproducciones</b>.</div>}
            />
          </div>
          <div className='mt-0 mt-lg-4'>&nbsp;</div>
          <div className='col-12 col-lg-6 mt-4 mt-lg-0 card-container'>
            <CardProgram
              image={tecnolochicas}
              title='Tecnolochicas'
              description={<div>Es el programa de Fundación Televisa enfocado <b>exclusivamente a niñas y jóvenes para inspirar y crear oportunidades para prepararse dentro de las carreras STEM</b>. <br/><br/><b>Tuvimos 16,157 graduadas</b> en 2023 y <b>31,980 cursos impartidos</b>.</div>}
            />
          </div>
          <div className='mobile-device'>
            <div className='bg-rectangle-programs'>&nbsp;</div>
          </div>
          <div className='col-12 col-lg-6 mt-4 mt-lg-0 card-container'>
            <CardProgram
              image={fotograficamx}
              title='FOTOGRÁFICAMX'
              description={<div>En el contexto del <b>Día Internacional de la Mujer</b>, el programa <b>Fotográfica</b> realizó la exposición <b>Luminarias del cine mexicano</b>, en la plataforma de <b>Google Arts and Culture</b>, mostrando <b>la contribución que las mujeres han hecho a la cinematografía mexicana</b>.<br/><br/><b>12,353 vistas</b> generaron los carruseles sobre <b>la vida y obra de destacadas mujeres</b> como <b>Stella Inda</b> y <b>Silvia Pinal</b>, a través de los <b>medios digitales</b> de <b>FotográficaMx</b>.</div>}
              url='https://www.youtube.com/watch?v=si2-azAD5S8'
              type='video'
            />
          </div>
        </div>
        <div className='mt-4'>&nbsp;</div>
      </div>
    </div>
  )
}
