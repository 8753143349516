import React from 'react'
import './Aliados.css'
import ninosMobile from '../../../assets/images/home/alianza/Valores_4_WEB-mobile.jpg'
import impacto from '../../../assets/images/home/alianza/alianza-icon.svg'
import femaleMobile from '../../../assets/images/home/alianza/DSC_0034-trans-mobile.png'
import { Button } from '../../Button/Button'
import CountUp from 'react-countup'
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { LazyLoadImage } from 'react-lazy-load-image-component'

export function Aliados({english=false}){

  const [ref2, inView2] = useInView({
    triggerOnce: true,
  });

  const variants3 = {
    hidden: { translateY: '30vw' },
    visible: { translateY: '0vh', transition: { duration: 1 } },
  };

  const [ref3, inView3] = useInView({
    triggerOnce: true,
  });

  const variants2 = {
    hidden: { translateY: '30vw' },
    visible: { translateY: '0vh', transition: { duration: 1 } },
  };

  const textos = {
    en: {
      t1:`IN 2023`,
      t2:`their stories tell ours.`,
    },
    es: {
      t1:`En este 2023,`,
      t2:`sus historias cuentan la nuestra.`,
    }
  }


  const txt_v = english ? textos.en : textos.es;

  return (
    <div>
      {/* mobile */}
      <div className='mobile-device'>
        {/* <div className='al-bg-gr'>
          <div className='d-flex justify-content-center pb-2 pt-5'>
            <img src={impacto} alt="icono" className='icon-width' />
          </div>
          <div className='d-flex justify-content-center'>
            <h2 className="al-title">IMPACTAMOS A TRAVÉS DE NUESTRAS ALIANZAS</h2>
          </div>
          <div className='d-flex justify-content-center'>
            <h2 className="al-num">
              <CountUp
                enableScrollSpy={true}
                start={100}
                end={261}
                duration={4}
              /> aliados
            </h2>
          </div>
          <div className="al-par px-5">
            fueron la catapulta que impulsó la vida de más de <b>785 mil</b> niñas, niños, jóvenes y adultos mexicanos. Sin este esfuerzo nuestras iniciativas no hubieran sido posibles.
          </div>
          <div className='mt-2'>&nbsp;</div>
        </div>

        <div className="p-0 m-0" >
          <section>
              <LazyLoadImage
                src={ninosMobile}
                alt="Image Alt"
                className='img-fluid'
              />
          </section>
          
        </div>

        <div className='p-4'>
          <Button link='nuestros-aliados'/>
        </div> */}

        <div className="container-fluid al-hist-bg m-0 p-0" >
          <section>
              <div className="row">
                  <div className="col-12">
                      <div className="row">
                          <div className="col-12 text-center">
                              <h2 className="al-hist-title mt-5">{txt_v.t1}</h2>
                              <h2 className="al-hist-title2">{txt_v.t2}</h2>
                          </div>
                          <div className="col-12 mt-2">
                              <img src={femaleMobile} alt="" className="al-hist-img-move-up" />
                          </div>
                      </div>
                  </div>
              </div>  
          </section>
        </div>
      </div>

      {/* web */}
      <div className='web-device'>
        {/* <div className="container-fluid bg-ninios-web">
          <div className="row">
            
            <div className="col-md-6 text-start p-5" ref={ref2}>
              <motion.div
                variants={variants2}
                initial="hidden"
                animate={inView2 ? 'visible' : 'hidden'}
              >
                <div className="al-cont ps-5">
                  <img src={impacto} alt="icono" style={{'paddingTop': '2rem'}} />
                  <h2 className="al-title mt-4">IMPACTAMOS <br/> A TRAVÉS DE <br/> NUESTRAS ALIANZAS</h2>
                  <h2 className="al-num">
                    <CountUp
                      enableScrollSpy={true}
                      start={200}
                      end={261}
                      duration={4}
                    /> aliados
                  </h2>
                  <p className="al-par">
                    fueron la catapulta que impulsó la vida de más de <b>785 mil</b> niñas, niños, jóvenes y adultos mexicanos. Sin este esfuerzo nuestras iniciativas no hubieran sido posibles.
                  </p>
                  <div className='mt-4'>
                    <Button link='nuestros-aliados'/>
                  </div>
                </div>
              </motion.div>
            </div>

            <div className="col-md-6 p-0">
            </div>
          
          </div>
        </div> */}

        <div className="container-fluid al-hist-bg position-relative z-2" ref={ref3}>
          <motion.div
            // whileInView ={{ translateY: '0vh' }} // Start offscreen at the bottom
            // animate={{ translateY: '50vh' }} // Animate to the top
            // transition={{ duration: 1 }} // Set animation duration
            variants={variants3}
            initial="hidden"
            animate={inView3 ? 'visible' : 'hidden'}
          >
            <div className="d-flex justify-content-end">
              <div className="col-6 padding-text">
                  <h2 className="al-hist-title">{txt_v.t1}</h2>
                  <h2 className="al-hist-title2">{txt_v.t2}</h2>
              </div>
            </div>  
          </motion.div>
          
        </div>
      </div>
      
    </div>
  )
}
