import React from 'react'
import './Acciones.css'
import becalos from '../../../assets/images/acciones/becalos.jpg'
import empiezaTemprano from '../../../assets/images/acciones/empieza-temprano.jpg'
import tecnolochicas from '../../../assets/images/acciones/tecnolochicas.jpg'
import posible from '../../../assets/images/acciones/posible.jpg'
import valores from '../../../assets/images/acciones/valores.jpg'
import golPorMexico from '../../../assets/images/acciones/gol_por_mexico.jpg'
import fotograficamx from '../../../assets/images/acciones/fotograficamx.jpg'
import atteTuAmiga from '../../../assets/images/acciones/atte_tu_amiga.png'
import cuantrix from '../../../assets/images/acciones/cuantrix.jpg'
import CardAcciones from './CardAcciones/CardAcciones'


export default function Acciones() {
  return (
    <div className='Acciones'>
      <div className='d-flex justify-content-center'>
        <div className='title'>
          Acciones con impacto
        </div>
      </div>
      <div className='cards-gallery'>
        <div className='row m-0 p-0 justify-content-evenly'>
          <div className='col-12 col-lg-6'>
            <CardAcciones
              title='Bécalos'
              image={becalos}
              description={<div><span className='bold'>Más de 1.1 millones</span> de personas  fueron alcanzados gracias a los contenidos y testimonios de jóvenes becados a través de <span className='bold'>Movilidad Canadá</span> transmitido en redes sociales.</div>}
              url='https://www.facebook.com/becalos/posts/pfbid04DQsZprNnhBCXoCC8ovYpPkMZQYRAtHnW2z4WN5zdWz3uRRxTFhZaPf9TLJUDWWtl '
              isTitlePurple={false}
              type='link'
            />
            
          </div>
          <div className='col-12 col-lg-6 mt-4 mt-md-5 mt-lg-0'>
            <CardAcciones
              title='Empieza Temprano'
              image={empiezaTemprano}
              description={<div>La campaña <span className='bold'>“Cada momento cuenta”</span> realizada durante la <span className='bold'>Semana de la Primera Infancia</span>, alcanzó una audiencia promedio diaria de <span className='bold'>2.2M</span>, con <span className='bold'>cinco capítulos inéditos</span> en el programa <span className='bold'>Como dice el dicho.</span> </div>}
              url='https://youtu.be/hRC20SQMKe0 '
              isTitlePurple={false}
              type='video'
            />
          </div>

          <div className='mt-0 mt-lg-5'>&nbsp;</div>

          <div className='col-12 col-lg-6 mt-4 mt-md-5 mt-lg-0'>
            <CardAcciones
              title='Tecnolochicas'
              image={tecnolochicas}
              description={<div>Para continuar con el interés de nuestra audiencia, realizamos una <span className='bold'>actualización en el contenido</span> del curso enfocado a <span className='bold'>Inteligencia Artificial</span> y en colaboración con <span className='bold'>BEDU</span> abrimos el curso de <span className='bold'>Data Science</span>, donde participaron <span className='bold'>300 mujeres universitarias</span>.</div>}
              url='https://youtu.be/wD8prh2LX5A'
              isTitlePurple={false}
              type='video'
            />
          </div>
          <div className='col-12 col-lg-6 mt-4 mt-md-5 mt-lg-0'>
            <CardAcciones
              title='POSIBLE'
              image={posible}
              description={<div>En <span className='bold'>Tiktok</span> nuestros contenidos se han vuelto virales, alcanzando más de <span className='bold'>8.3 millones de vistas</span>, generando <span className='bold'>más de 390 mil “Me Gusta”</span>.</div>}
              url='https://youtu.be/R_Q6rkubTSw'
              isTitlePurple={false}
              type='video'
            />
          </div>

          <div className='mt-0 mt-lg-5'>&nbsp;</div>

          <div className='col-12 col-lg-6 mt-4 mt-md-5 mt-lg-0'>
            <CardAcciones
              title='VALORES'
              image={valores}
              description={<div><span className='bold'>Más de 460K reproducciones</span> en conjunto de nuestros eventos de <span className='bold'>Facebook Live del Día del niño y Día del maestro</span>, por medio de cápsulas educativas para acercar a los niños a los valores y habilidades socioemocionales, a través de los maestros y padres de familia.</div>}
              isTitlePurple={false}
              url="https://www.facebook.com/valores.fundaciontelevisa/videos/791351622707357/"
              type='link'
            />
          </div>
          <div className='col-12 col-lg-6 mt-4 mt-md-5 mt-lg-0'>
            <CardAcciones
              title='GOL POR MÉXICO'
              image={golPorMexico}
              description={<div>Gol por México Femenil supera las <span className='bold'>30 mil beneficiadas</span> (desde el inicio de sus apoyos 2018) y lo comunica con el apoyo de los participantes de <span className='bold'>“la Casa de los famosos”</span>, lo que hizo llegar el mensaje de las <span className='bold'>12 cápsulas</span> a <span className='bold'>más de 5 millones de usuarios</span> en <span className='bold'>redes sociales</span>.</div>}
              isTitlePurple={false}
              url="https://www.youtube.com/watch?v=pIY0PbVoALk"
              type='video'
            />
          </div>

          <div className='mt-0 mt-lg-5'>&nbsp;</div>

          <div className='col-12 col-lg-6 mt-4 mt-md-5 mt-lg-0'>
            <CardAcciones
              title='FOTOGRÁFICAMX'
              image={fotograficamx}
              description={<div>Nuestros <span className='bold'>contenidos</span> alcanzaron a <span className='bold'>más de 5,084,881 personas</span> en nuestros <span className='bold'>canales digitales</span>. Los contenidos de la exposición <span className='bold'>Mexichrome. Fotografía y color</span>, se vieron fortalecidos gracias a la creación del sitio <span className='bold'>mexichrome.mx</span> que ha generado hasta ahora <span className='bold'>13,352 visitas</span> y más de <span className='bold'>1.3M de reproducciones</span> en redes sociales.</div>}
              isTitlePurple={false}
              url="https://www.youtube.com/watch?v=bOENgaGuOtQ&feature=youtu.be"
              type='video'
            />
          </div>
          <div className='col-12 col-lg-6 mt-4 mt-md-5 mt-lg-0'>
            <CardAcciones
              title='ATTE.TUAMIGA'
              image={atteTuAmiga}
              description={<div><span className='bold'>Más de 986K reproducciones</span> de fragmentos del <span className='bold'>podcast</span> con tips y recomendaciones de especialistas generando un círculo de confianza para fomentar una vida más plena y libre de violencia.</div>}
              isTitlePurple={false}
              url="https://youtu.be/OldZjMdJixc"
              type='video'
            />
          </div>

          <div className='mt-0 mt-lg-5'>&nbsp;</div>

          <div className='col-12 col-lg-6 mt-4 mt-md-5 mt-lg-0'>
            <CardAcciones
              title='cuantrix'
              image={cuantrix}
              description={<div><span className='bold'>6,112 registros</span> en Compu Cuantrix gracias a nuestros contenidos en <span className='bold'>Facebook y TikTok</span>.</div>}
              isTitlePurple={false}
              url="https://www.youtube.com/watch?v=Q9jy6rWuIWM&feature=youtu.be"
              type='video'
            />
          </div>
          <div className='col-12 col-lg-6 mt-4 mt-md-5 mt-lg-0'></div>
          <div>
            &nbsp;
          </div>

        </div>
      </div>
    </div>
  )
}
