import React from 'react'
import Progredes from '../Progredes/Progredes'
import fb from '../../../assets/images/iconos/fb.svg'
import ig from '../../../assets/images/iconos/ig.svg'
import tiktok from '../../../assets/images/iconos/tiktok.svg'
import x from '../../../assets/images/iconos/x.svg'
import Compromiso from './Compromiso/Compromiso'
import Iniciativas from './Iniciativas/Iniciativas'
import Testimonios from './Testimonios/Testimonios'
import Comunidad from './Comunidad/Comunidad'
// import Footer from '../../Footer/Footer'
import FooterProgramas from '../FooterProgramas/FooterProgramas'
import arrowsEmpiezaTemprano from '../../../assets/images/programas/footer/arrows-empieza-temprano.png'

export default function Becalos(
    {handleSectionSelected, englishp=false}
) {
   const social= [
        {
            link:'https://www.facebook.com/becalos',
            icon:fb,
            nombre:'becalos    '
        },
        {
            link:'https://www.instagram.com/becalos_mx',
            icon:ig,
            nombre:'becalos_mx'
        },
        {
            link:'https://twitter.com/becalosmx',
            icon:x,
            nombre:'becalosmx'
        },
        {
            link:'https://www.tiktok.com/@becalos_mx?lang=es',
            icon:tiktok,
            nombre:'@becalos_mx'
        },
    ]

  return (
    <div className='becalos'>
        <Compromiso english={englishp}/>
        <Iniciativas english={englishp}/>
        
        <Testimonios english={englishp}/>
        <Comunidad english={englishp}/>
        <Progredes 
            sitio = 'https://becalos.mx/'
            redes = {social}
            english={englishp}
        />

        {/* <Footer /> */}
        <FooterProgramas 
          background='bg-programa-empieza-temprano'
          arrows={arrowsEmpiezaTemprano}
          title='EMPIEZA TEMPRANO'
          color='#D4569B'
          bgImage='bg-program-image-empieza-temprano'
          handleSectionSelected={handleSectionSelected}
          programSection='empieza-temprano'
          english={englishp}
        />
    </div>
  )
}
