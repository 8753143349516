import React from 'react'
import './Tecnolochicas.css'
import Impulsando from './Impulsando/Impulsando'
import Iniciativas from './Iniciativas/Iniciativas'
import Impacto from './Impacto/Impacto'
import Testimonios from './Testimonios/Testimonios'
import Comunidades from './Comunidades/Comunidades'
import Progredes from '../Progredes/Progredes'

import fb from '../../../assets/images/iconos/fb.svg'
import ig from '../../../assets/images/iconos/ig.svg'
import tiktok from '../../../assets/images/iconos/tiktok.svg'
import yt from '../../../assets/images/iconos/yt.svg'
import Reconocimientos from './Reconocimientos/Reconocimientos'
// import Footer from '../../Footer/Footer'
import FooterProgramas from '../FooterProgramas/FooterProgramas'
import arrowsBecalos from '../../../assets/images/programas/footer/arrows-becalos.png'

const social= [
  {
      link:'https://www.facebook.com/tecnolochicasmx',
      icon:fb,
      nombre:'tecnolochicasmx'
  },
  {
      link:'https://www.instagram.com/tecnolochicasmx',
      icon:ig,
      nombre:'tecnolochicasmx'
  },
  {
    link:'https://www.youtube.com/@tecnolochicasmexico5144',
    icon:yt,
    nombre:'Tecnolochicas Mexico'
  },
  {
    link:'https://www.tiktok.com/@tecnolochicasmx',
    icon:tiktok,
    nombre:'tecnolochicasmx'
  },
]
export default function Tecnolochicas(
  {handleSectionSelected,englishp=false}
) {
  return (
    <div className='tecnochicas'>
        <Impulsando english={englishp}/>
        <Iniciativas english={englishp}/>
        <Impacto english={englishp}/>
        <Reconocimientos english={englishp}/>
        <Testimonios english={englishp}/>
        <Comunidades english={englishp}/>
        <Progredes 
          sitio = 'https://tecnolochicas.mx/'
          redes = {social}
          english={englishp}
        />

        {/* <Footer /> */}
        <FooterProgramas 
          background='bg-programa-becalos'
          arrows={arrowsBecalos}
          title='BECALOS'
          color='#00B9F1'
          bgImage='bg-program-image-becalos'
          handleSectionSelected={handleSectionSelected}
          programSection='becalos'
          english={englishp}
        />
    </div>
  )
}
