import React from 'react'
import './Iniciativas.css'
import inibanner from '../../../../assets/images/programas/temprano/Grabacion_UPN_CENDI_5_1.png'
import inibannermv from '../../../../assets/images/programas/temprano/ninios-mobile.png'
import CountUp from 'react-countup'

export default function Iniciativas({english=false}) {

    const textos = {
        en: {
            t1:`INITIATIVES THAT <br/> GENERATE IMPACT:`,
            t2:`<b>children</b> benefited thanks to the in-person training of <b>1,488 agents</b> in <b>Nuevo León, Chihuahua, Veracruz and Mexico City.</b>`,
            t3:`Thanks to the Bezos Family Foundation's <b>multi-year alliance with Vroom</b>, we were able to articulate alliances in 10 <b>Mexican states</b>.`,
            t4:`<b>18 thousand children</b><br /> benefited in alliance with Un Kilo de Ayuda.`,
        },
        es:{
            t1:`Iniciativas que <br/> generan impacto:`,
            t2:`<b>infantes</b> beneficiados gracias a la formación presencial de <b>1,658 agentes</b> en <b>Nuevo León, Chihuahua, Veracruz y Ciudad de México.</b>`,
            t3:`Gracias a la <b>alianza multianual con Vroom</b> de la Fundación de la Familia Bezos, logramos articular alianzas en <b>12 estados</b> de la República Mexicana.`,
            t4:`<b>18 mil niñas y niños</b><br /> beneficiados con Un Kilo de Ayuda.`,
        }
    }
    
    const txt_v = english ? textos.en : textos.es;
  return (
    <div>
        <div className="container-fluid">
            <div className="row justify content-center">
                <div className="col-lg-8 col-md-10 m-auto pb-2 pt-4 pt-md-5 pt-lg-5 pb-lg-0">
                    <h2 className="et-in-title" dangerouslySetInnerHTML={{ __html: txt_v.t1 }}></h2>
                </div>
                <div className="col-lg-7 m-auto px-4 py-2 px-md-0 py-md-4">
                    <p className="ini-desc">
                        <b><CountUp
                            enableScrollSpy={true}
                            start={20000}
                            end={23967}
                            duration={3}/></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t2 }}></span>
                    {/* <span className="bold"><CountUp
                        enableScrollSpy={true}
                        start={20000}
                        end={25095}
                        duration={3}/> infantes </span>beneficiados gracias a la atención de 
                    <span className="bold"> 1,488 agentes </span> que acudieron a la formación presencial de 20 horas en Nuevo León, Chihuahua, Veracruz y Ciudad de México. */}
                    </p>
                </div>
            </div>
            <div className="row">
                <div className='d-flex justify-content-center position-absolute peques-description2'>
                    <div className='bg-peques-gradient p-md-4 p-2' dangerouslySetInnerHTML={{ __html: txt_v.t3 }}></div>
                </div>
                <div className='d-flex justify-content-center position-absolute peques-description'>
                    <div className='bg-peques-gradient p-md-4 p-2' dangerouslySetInnerHTML={{ __html: txt_v.t4 }}></div>
                </div>
                
                <img src={inibanner} alt="peques" className="img-banner p-0 desk" />
                <img src={inibannermv} alt="peques" className="img-banner p-0 mov" />
            </div>
            
        </div>
    </div>
  )
}
