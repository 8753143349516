import React from 'react'
import './FooterProgramas.css'

export default function FooterProgramas(
  {background = '', arrows, title = '', color = '#000', bgImage, handleSectionSelected, programSection, english=false}
) {
  const textos = {
    en: {
        t1:`Next`,
    },
    es: {
        t1:`Siguiente`,
    }
  }

const txt_v = english ? textos.en : textos.es;
  return (
    <div className='FooterProgramas'>
      <div className='row m-0 p-0 h-100'>
        <div className={`col-12 col-lg-6 ${background} `}>
          <div className='px-md-5 d-flex justify-content-center justify-content-lg-start align-items-center h-100'>
            <div className='w-100'>
              <div
                className='d-flex justify-content-center justify-content-lg-start cursor-pointer'
                onClick={() => handleSectionSelected(programSection)}
              >
                <div className='next-text'>
                  {txt_v.t1}
                </div>
                <div className='arrows-next-program ps-2'>
                  <img src={arrows} alt='next' className='next-arrows-width' />
                </div>
              </div>
              <div className='next-title text-center text-lg-start' style={{'color': color}}>
                {title}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`col-12 col-lg-6 ${bgImage} d-none d-lg-block`}
        >
          &nbsp;
        </div>
      </div>
    </div>
  )
}
