import React from 'react'
import './Iniciativas.css'
import CountUp from 'react-countup'
import img1 from '../../../../assets/images/programas/golxmex/IMG_5548png 1.png'

export default function Iniciativas({english=false}) {
    const textos = {
        en: {
            t1:`In 2023, `,
            t2:`<span class='bold'>people</span> benefited from the`,
            t3:`<span class='bold'>goals</span> scored in the <span class='bold'>Mexican League for both men and women,</span> including the <span class='bold'>20,000 children </span> affected by the <span class='bold'> earthquakes in Syria and Turkey,</span> as part of our <span class='bold'>first international effort with Save The Children.</span>`,
            t4:`INITIATIVES THAT <br /> GENERATE IMPACT:`,
            t5:`benefited with food pantries in <br class="d-lg-block d-none" /> Guerrero in response to <span class="highlight">Natural Disasters.</span>`,
            t6:`beneficiaries <br class="d-lg-block d-none" />in <span class="highlight">Nutrition and Development.</span>`,
        },
        es:{
            t1:`En 2023, `,
            t2:`<span class='bold'>mil personas</span> se beneficiaron por los más de`,
            t3:`<span class='bold'>goles</span> de la <span class='bold'>liga mexicana varonil y femenil,</span> incluyendo a las <span class='bold'>20 mil niñas y niños </span> afectados por los <span class='bold'> sismos en Siria y Turquía</span> como parte de <span class='bold'>nuestro primer esfuerzo internacional con Save The Children.</span>`,
            t4:`Iniciativas que <br /> generan impacto:`,
            t5:`beneficiados en <br class="d-lg-block d-none" /> Guerrero como respuesta a <span class="highlight">Desastres Naturales.</span>`,
            t6:`beneficiados <br class="d-lg-block d-none" />en temas de <span class="highlight">Nutrición y Desarrollo.</span>`,
        }
    }
    
    const txt_v = english ? textos.en : textos.es;

  return (
    <div>
        <div className="container-fluid ini-bg">
            <div className="row justify content-center">
                <div className="col-lg-8 m-auto">
                    <p className="gxm-in-par mt-md-5 mt-3">
                    <span dangerouslySetInnerHTML={{ __html: txt_v.t1 }}></span>
                    <span className='bold'><CountUp
          enableScrollSpy={true}
          start={'69106'}
          end={69306}
          duration={4}/></span> <span dangerouslySetInnerHTML={{ __html: txt_v.t2 }}></span> <span className='bold'><CountUp
                                    enableScrollSpy={true}
                                    start={'800'}
                                    end={867}
                                    duration={3}
                                /></span> <span dangerouslySetInnerHTML={{ __html: txt_v.t3 }}></span>
                    </p>
                </div>
            </div>
            <div className="row justify content-center">
                <div className="col-lg-6 m-auto py-4">
                    <h2 className="gxm-in-title" dangerouslySetInnerHTML={{ __html: txt_v.t4 }}></h2>
                </div>
            </div>
            <div className="row justify-content-around px-0 pb-desk">
                <div className="col-lg-4 col-md-8">
                    <div className="text-box-numbs px-0 px-md-4 px-lg-0">
                        <p className="numbers">
                        <span className="highlight"><CountUp
          enableScrollSpy={true}
          start={'18809'}
          end={19000}
          duration={4}/></span> <span dangerouslySetInnerHTML={{ __html: txt_v.t5 }}></span>
                        </p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-8">
                    <div className="text-box-numbs px-0 px-md-4 px-lg-0">
                        <p className="numbers">
                        <span className="highlight"><CountUp
          enableScrollSpy={true}
          start={'32605'}
          end={32745}
          duration={4}/></span> <span dangerouslySetInnerHTML={{ __html: txt_v.t6 }}></span>
                        </p>
                    </div>
                </div>
                <img src={img1} alt="" className="gol-mex-img-ini p-0" />
            </div>
        </div>
    </div>
  )
}
