import React from 'react'
import '../Aliados.css'
import logo from '../../../assets/images/aliados/logos/Becalos.png'
import CountUp from 'react-countup'


export default function Becalos({english=false}) {

  const textos = {
    en: {
      t1:`Thanks to the`,
      t2:`Thanks to the Association of Mexican Banks, 30 banks, 43 companies and organizations, 11 educational institutions and thousands of people who donate through ATMs.`,
      t3:`Our allies in the Bécalos program in 2023 were:`,
      t4:`Banks`,
      t5:`Association of Mexican Banks, HSBC, Santander, Banorte, BBVA, Citibanamex, Compartamos, Scotiabank, Monex, Visa, BanCoppel, Multiva, Actinver, Credit Suisse, Interbanco / Intercam, MUFG Bank- before Bank of Tokio, Barclays, S3 Caceis, KEB Hana México, Banco Bx+, Mifel, Mexican Stock Exchange, Banco Invex, Banco Inmobiliario Mexicano, Afirme, Banco Autofin, Banco Base, Bankaool, Banjercito, Banbajío, Inbursa.`,
      t6:`Companies and Organizations`,
      t7:`Casa Hogar para Varones, Club de Niños y Niñas de Nuevo León, The Mexican Business Council, Educación Solidaria en las Californias, Epura, GNP Seguros, Diageo, Arcelor Mittal, DM Foundation, Tigres, HEB, Nacional Monte de Piedad, Peña Colorada, Alsea Foundation, Chevron, Rise / Schmidt Foundation, L´Oréal, Mexican Football Federation, Grupo Posadas, Krispy Kreme, Grupo Bimbo, Miranda Sánchez y Asoc., Cisco Net Academy, Inter American Development Bank Group Innovation Laboratory, Instituto DIA, La Vaca Independiente, Centro Comunitario Santa Fe, Centro de Apoyo al Niño de la Calle de Oaxaca, A.C, Centro Integral de Atención A las Mujeres CIAM Cancún, Colectivo Pro Derechos de la niñez A.C., MESE Vasco de Quiroga, I.A.P., Niños y Adolescentes en Armonía A.C., CONALEP, International Youth Foundation, Las Hormigas, Fundación Alberto Baillères, Pro Mazahua Foundation, Fundación Comunitaria Oaxaca, Baisae A.C., Universidad Veracruzana Foundation, Universidad Autónoma de Nuevo León Foundation, Tarahumara Foundation, Questro Foundation, Fundación Mexicana para la Educación, la Tecnología y la Ciencia A.C., NEMI Foundation, Banorte Foundation, BBVA Foundation, Fundación UNAM, Fomento Social Banamex, BanCoppel Foundation, PROVAY.`,
      t8:`Educational Institutions`,
      t9:`Instituto Tecnológico de Estudios Superiores de Monterrey, Instituto Tecnológico de Colima, Instituto Tecnológico de Lázaro Cárdenas, Universidad Autónoma Benito Juárez de Oaxaca, Universidad Nacional Autónoma de México, Universidad Autónoma de Nuevo León, Universidad Politécnica de Lázaro Cárdenas, Universidad Tecnológica de Manzanillo, Universidad de Colima, Universidad Tecnológica de Querétaro, Universidad Tecnológica de León.`,
    },
    es: {
      t1:`Gracias a la`,
      t2:`Asociación de Bancos de México, 30 bancos, 43 empresas y organizaciones, 11 instituciones educativas y a miles de personas que donan a través de los cajeros automáticos.`,
      t3:`Nuestros aliados del programa Bécalos en 2023 fueron:`,
      t4:`Bancos`,
      t5:`Asociación de Bancos de México, HSBC, Santander, Banorte, BBVA, Citibanamex, Compartamos, Scotiabank, Monex, Visa, BanCoppel, Multiva, Actinver, Credit Suisse, Interbanco / Intercam, MUFG Bank- antes Bank of Tokio, Barclays, S3 Caceis, KEB Hana México, Banco Bx+, Mifel, Bolsa Mexicana de Valores, Banco Invex, Banco Inmobiliario Mexicano, Afirme, Banco Autofin, Banco Base, Bankaool, Banjercito, Banbajío, Inbursa.`,
      t6:`Empresas y organizaciones`,
      t7:`Casa Hogar para Varones, Club de Niños y Niñas de Nuevo León, Consejo Mexicano de Negocios, Educación Solidaria en las Californias, Epura, GNP Seguros, Diageo, Arcelor Mittal, Fundación DM, Tigres, HEB, Nacional Monte de Piedad, Peña Colorada, Fundación Alsea, Chevron, Rise / Schmidt Foundation, L´Oréal, Federación Mexicana de Futbol, Grupo Posadas, Krispy Kreme, Grupo Bimbo, Miranda Sánchez y Asoc., Cisco Net Academy, Laboratorio de Innovación del Grupo Banco Interamericano deDesarrollo, Instituto DIA, La Vaca Independiente, Centro Comunitario Santa Fe, Centro de Apoyo al Niño de la Calle de Oaxaca A.C, Centro Integral de Atención A las Mujeres CIAM Cancún, Colectivo Pro Derechos de la niñez A.C., MESE Vasco de Quiroga I.A.P., Niños y Adolescentes en Armonía A.C., CONALEP, International Youth Foundation, Las Hormigas, Fundación Alberto Baillères, Fundación de la Universidad Autónoma de Nuevo León,  Fundación Pro Mazahua, Fundación Comunitaria Oaxaca, Baisae A.C., Fundación de la Universidad Veracruzana, Fundación Tarahumara, Fundación Questro, Fundación Mexicana para la Educación, la Tecnología y la Ciencia A.C., Fundación NEMI, Fundación Banorte, Fundación BBVA, Fundación UNAM, Fomento Social Banamex, Fundación BanCoppel, PROVAY.`,
      t8:`Instituciones educativas`,
      t9:`Instituto Tecnológico de Estudios Superiores de Monterrey, Instituto Tecnológico de Colima, Instituto Tecnológico de Lázaro Cárdenas, Universidad Autónoma Benito Juárez de Oaxaca, Universidad Nacional Autónoma de México, Universidad Autónoma de Nuevo León, Universidad Politécnica de Lázaro Cárdenas, Universidad Tecnológica de Manzanillo, Universidad de Colima, Universidad Tecnológica de Querétaro, Universidad Tecnológica de León.`,
    },
  }

  const txt_v = english ? textos.en : textos.es;

  return (
    <div className='BecalosAliados'>
        <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6 m-auto mh-mov text-center">
            <img src={logo} alt="Becalos" className="img-fluid w-75" />
          </div>
          <div className="col-lg-6 bg-color text-start">
            <h3 className="h-prog">{txt_v.t1}</h3>
            <h2 className="par">{txt_v.t2}</h2>
          </div>
        </div>
        <div className="row bg-aliados gradient justify-content-center py-5">
            <h2 className="sub-title">{txt_v.t3}</h2>
            <div className="col-lg-9 col-11 text-start pt-0 pt-lg-4">
              <h3 className="lbl-aliados">{txt_v.t4}</h3>
              <p className="par-aliados">{txt_v.t5}</p>
              <h3 className="lbl-aliados">{txt_v.t6}</h3>
              <p className="par-aliados">{txt_v.t7}</p>
              <h3 className="lbl-aliados">{txt_v.t8}</h3>
                <p className="par-aliados">{txt_v.t9}</p>
            </div>
        </div>
      </div>
    </div>
  )
}