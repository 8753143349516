import React from 'react'
import './Content.css'
import CountUp from 'react-countup'

export default function Content({english=false}) {
  const textos = {
    en: {
      t1:`<b>Nuestras Alas</b> is <b>Club América's social responsibility program</b> that helps those most in need in the areas of <b>education, health, inclusion, the environment, and natural disasters</b>.`,
      t2:`Together with the <b>Club América</b> soccer team, we promoted support efforts benefiting`,
      t3:`<b>people.</b>`
    },
    es: {
      t1:`<b>Nuestras Alas</b> es el programa de responsabilidad social del <b>Club América</b> que ayuda a quien más lo necesita en: <b>educación, salud, inclusión, medioambiente y desastres naturales.</b>`,
      t2:`Junto con el equipo de futbol <b>Club América</b>, promovimos esfuerzos de apoyo logrando beneficiar a`,
      t3:`<b>personas en 2023.</b>`
    },
  }

  const txt_v = english ? textos.en : textos.es;

  return (
    <div className='ContentNuestrasAlas'>
      {/* <div className='d-flex justify-content-center'>
        <div className='subtitle px-4 py-5'>
          <b>Nuestras Alas</b> es el programa de responsabilidad social del <b>Club América</b> que ayuda a quien más lo necesita en: <b>educación, salud, inclusión, medioambiente y desastres naturales.</b>
        </div>
      </div> */}
      <div className='row m-0 px-0 py-4 py-md-0'>
        <div className='col-12'>
          <div className='d-flex justify-content-center align-items-center h-100 px-0 py-0 px-lg-5 py-lg-5'>
            <div className='subtitle px-4 py-0 py-md-4 py-lg-5 px-lg-5' dangerouslySetInnerHTML={{ __html: txt_v.t1 }}></div>
          </div>
        </div>
      </div>

      {/* mobile */}
      <div className='mobile-device'>
        <div className='bg-beneficiada'>&nbsp;</div>
        <div className='px-1 py-4 p-md-5 text-center bg-custom d-flex justify-content-center'>
          <div className='px-0'>
            {/* <div className='second-title'>
              <CountUp
                enableScrollSpy={true}
                start={0}
                end={44855}
                duration={3}
              /> personas beneficiadas en 2023
            </div>
            <div className='paragraph mt-4 mt-md-5'>
              <b>El Club América</b> busca <b>generar un impacto permanente en la sociedad y en las próximas generaciones</b> a través de acciones de <b>responsabilidad social y desarrollo humano</b>.
              <br /> <br />
              El objetivo es <b>posicionarse como la institución deportiva número uno</b> en llevar a cabo a <b>acciones de impacto social</b>. 
            </div> */}
            <div className='paragraph'>
              <span dangerouslySetInnerHTML={{ __html: txt_v.t2 }}></span> <b><CountUp
                enableScrollSpy={true}
                start={'44155'}
                end={45246}
                duration={3}
              /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t3 }}></span>
            </div>
          </div>
        </div>
      </div>

      {/* web */}
      <div className='web-device'>
        <div className='row m-0 p-0'>
          <div className='col-6 m-0 p-0'>
            <div className='bg-beneficiada'>&nbsp;</div>
          </div>
          <div className='col-6 m-0 p-0'>
            <div className='bg-custom h-100 d-flex align-items-center justify-content-center'>
              <div className='paragraph'>
                <span dangerouslySetInnerHTML={{ __html: txt_v.t2 }}></span> <b><CountUp
                  enableScrollSpy={true}
                  start={'44155'}
                  end={45246}
                  duration={3}
                /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t3 }}></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
