import React from 'react'
import CardGral from '../../Cardgral/CardGral'
import './Iniciativas.css'
import img1 from '../../../../assets/images/programas/becalos/iniciativas/LANZAMIENTO INGLES (1) 1.png'
import img2 from '../../../../assets/images/programas/becalos/iniciativas/CELEBRACION 2023 (7) 2.png'
import img3 from '../../../../assets/images/programas/becalos/iniciativas/DSC_0162 2.png'
import img4 from '../../../../assets/images/programas/becalos/iniciativas/MOVILIDAD CANADA (13) 1.png'
import img5 from '../../../../assets/images/programas/becalos/iniciativas/LANZAMINETO BE TECH CHALLENGE (3) 1.png'
import img6 from '../../../../assets/images/programas/becalos/iniciativas/Graduación Bécalos_6 1.png'
import img7 from '../../../../assets/images/programas/becalos/iniciativas/Grabación Video Sueños; 6 de mayo; Becarios y exbecarios destacados; Estudio de Televisa Santa Fe-remini-enhanced 1.png'
import img8 from '../../../../assets/images/programas/becalos/iniciativas/_DSC4169 1.png'
export default function Iniciativas({english=false}) {
    const textos = {
        en: {
            t1:`INITIATIVES THAT <br />GENERATE IMPACT:`,
            t2:`English Challenge`,
            t3:`<p><b>17,650 young people and teachers</b> learned English in this <b>eighth edition</b>.</p>`,
            t4:`EXCELLENCE SCHOLARSHIPS`,
            t5:`<p><b>6,363 scholarships awarded</b> to the best students, prioritizing support in the areas of <b>science, technology, engineering, and mathematics</b>.</p>`,
            t6:`DEVELOPMENT OF SKILLS FOR EMPLOYABILITY`,
            t7:`<p><b>7,855 young people</b> acquired skills that allowed them greater opportunities for employability in the <b>Information Technology and Financial sectors</b>.</p>`,
            t8:`MOBILITY AND TALENT`,
            t9:`<p><b>330 scholarships</b> awarded for young people to have a <b>learning experience abroad</b> with the <b>Mobility program</b> and to <b>strengthen socioemotional and leadership skills with the Talent</b> program.</p>`,
            t10:`BÉCALAS`,
            t11:`<p><b>3,217 young women</b> benefited thanks to <b>BécALAS</b>, the division of the Bécalos program with a gender perspective that seeks to develop them academically and professionally in <b>STEM areas</b>.</p>`,
            t12:`BÉCALOS CHALLENGE`,
            t13:`<p><b>1,250 scholarships</b> awarded to <b>young people and teachers</b> who acquired <b>socioemotional skills</b> for their academic, personal, and professional development.</p>`,
            t14:`TEACHER TRAINING`,
            t15:`<p>1,912 teachers trained, who strengthened their skills to provide <b>high quality education to their students</b>.</p>`,
        },
        es: {
            t1:`Iniciativas que <br /> generan impacto:`,
            t2:`English Challenge`,
            t3:`<p><b>17,650 jóvenes y docentes</b> aprendieron inglés en esta <b>octava edición</b>.</p>`,
            t4:`Becas de excelencia`,
            t5:`<p><b>6,363 becas otorgadas</b> a los mejores estudiantes, priorizando el apoyo en las áreas de <b>ciencia, tecnología, ingeniería y matemáticas.</b> </p>`,
            t6:`Desarrollo de Competencias para la Empleabilidad`,
            t7:`<p><b>7,855 jóvenes</b> adquirieron habilidades que les permitieron mayores oportunidades de empleabilidad en los <b>sectores de Tecnología de la Información y Financiero.</b></p>`,
            t8:`Movilidad y talento`,
            t9:`<p><b>330 becas</b> otorgadas para que los jóvenes tuvieran una experiencia de <b>aprendizaje en el extranjero </b>con el programa de <b>Movilidad </b>y <b>fortalecieran habilidades socioemocionales y de liderazgo</b> con el programa <b>Talento.</b></p>`,
            t10:`BÉCALAS`,
            t11:`<p><b>3,217 mujeres</b> jóvenes fueron beneficiadas gracias a <b>BécALAS</b>, la división del programa Bécalos con perspectiva de género que busca se desarrollen académica y profesionalmente en <b> áreas STEM.</b></p>`,
            t12:`Desafío Bécalos`,
            t13:`<p><b>1,250 becas</b>otorgadas a <b>jóvenes y docentes,</b> quienes adquirieron <b>habilidades socioemocionales </b>para su formación académica, personal y profesional.</p>`,
            t14:`Capacitación docentes`,
            t15:`<p><b>1,912 docentes</b> capacitados, quienes fortalecieron sus habilidades para proporcionar una <b> educación de alta calidad a sus estudiantes.</b></p>`,
        }
    }

    const txt_v = english ? textos.en : textos.es;
  return (
    <div className='Iniciativas'>
        <div className="container-fluid">
            <div className="row py-lg-5 pb-4">
                <h1 className="ini-title" dangerouslySetInnerHTML={{ __html: txt_v.t1 }}></h1>
            </div>
        </div>
        <div className="row row-cols-lg-2 g-lg-5 row-cols-1 g-4 px-lg-5 justify-content-around text-start">
            <div className="col-lg-5">
                <CardGral
                title={txt_v.t2}
                image={img1}
                description={txt_v.t3}
                url='https://www.youtube.com/watch?utm_source=informe2023&utm_medium=videominutocorto&utm_id=becalosenglishchallenge&v=eteVBgaLwoo&feature=youtu.be'
                type='video'
                />
            </div>
            <div className="col-lg-5">
                <CardGral
                title={txt_v.t4}
                image={img2}
                description={txt_v.t5}
                />
            </div>
            <div className="col-lg-5">
                <CardGral
                title={txt_v.t6}
                image={img5}
                description={txt_v.t7}
                url='https://www.youtube.com/watch?v=ni9jcPkgfko&t=15s+&utm_source=informe2023&utm_medium=videominuto&utm_id=becalostechchallenge&utm_content=carmenmunoz'
                type='video'
                />
            </div>
            <div className="col-lg-5">
                <CardGral
                title={txt_v.t8}
                image={img4}
                description={txt_v.t9}
            />
            </div>
            <div className="col-lg-5">
                <CardGral
                title={txt_v.t10}
                image={img6}
                description={txt_v.t11}
                />
            </div>
            <div className="col-lg-5">
                <CardGral
                title={txt_v.t12}
                image={img7}
                description={txt_v.t13}
                />
            </div>
            <div className="col-lg-5">
                <CardGral
                title={txt_v.t14}
                image={img8}
                description={txt_v.t15}
                />
            </div>
            <div className="col-lg-5"></div>
        </div>

    </div>
  )
}
