import React from 'react'
import './Tecnolochicas.css'
import '../Iniciativas/Iniciativas.css'
import svg1 from '../../../assets/images/foundation/S-WEB-Goal-01 4.png'
import svg2 from '../../../assets/images/foundation/S-WEB-Goal-01 5.png'
import svg3 from '../../../assets/images/foundation/S-WEB-Goal-01 6.png'
import Pboy from '../../../assets/images/foundation/DSC_0024.png'

export default function Tecnolochicas({english=false}) {
    const textos = {
        en: {
            t1:`Our commitment to <span class='text-mint'>education and culture</span> has brought changes to the <span class='text-mint'>Hispanic community in the United States and in some European Union countries</span>.`,
            t2:`INITIATIVES THAT GENERATE IMPACT:`,
            t3:`TECHNOLOCHICAS`,
            t4:`<strong>75 university students</strong> who played a vital role as <strong>middle and high school</strong> instructors to teach and inspire the next generation of <strong>female technologists</strong> were trained.`,
            t5:`<strong>1,500 girls</strong> benefited in 5 <strong>U.S. cities</strong>. We collaborated with two key partners: in Houston, Los Angeles, and Chicago with <strong>Google</strong> and in Phoenix and Providence, with <strong>Infosys Foundation USA</strong>.`,
            t6:`We thank: <strong>University of Houston, Maricopa Community Colleges</strong> in Phoenix, and <strong>Loyola University</strong> in Chicago. Their support is essential to the success of our program.`,
            t7:`CULTURE`,
            t8:`We restored the film <span class='bold font-italic'>Macario</span> (Roberto Gavaldón, 1959) which premiered at the <span class='bold'>II Cinema Ritrovato Festival</span> in Bologna, Italy.`,
            t9:`In collaboration with the <span class='bold'>Filmoteca de Catalunya</span> in Barcelona, Spain, the exhibition <span class='bold font-italic'>El sueño de la liebre. The cinema of Luis Buñuel and Gabriel Figueroa</span> took place.`,
            t10:`UN Sustainable Development Goals`,
            t11:`Televisa Foundation is aligned with the 2030 Agenda in favor of education, gender equality, and alliances to achieve the goals.`,
            t12:`Learn about the impact of Televisa Foundation in 2023`
        },
        es: {
            t1:`Nuestro compromiso con la <span class='text-mint'> educación y la cultura</span> ha generado cambios para la <span class='text-mint'> comunidad hispana en Estados Unidos y en algunos países de la Unión Europea.</span>`,
            t2:`Iniciativas que generan impacto:`,
            t3:`TECHNOLOCHICAS`,
            t4:`Se capacitó a <strong>75 universitarias</strong> que desempeñaron un rol vital como instructoras de <strong>secundaria y preparatoria</strong> para enseñar e inspirar a la próxima generación de <strong>tecnólogas.</strong>`,
            t5:`<strong>1,500 niñas</strong> beneficiadas en <strong>5 ciudades estadounidenses.</strong> Colaboramos con dos aliados clave, en Houston, Los Ángeles y Chicago con <strong>Google</strong> y en Phoenix y Providence, con <strong>Infosys Foundation USA.</strong>`,
            t6:`Agradecemos a: <strong>University of Houston, Maricopa Community Colleges</strong> en Phoenix y <strong>Loyola University</strong> en Chicago. Su apoyo es fundamental para el éxito de nuestro programa.`,
            t7:`CULTURA`,
            t8:`Restauramos la película <span class='bold font-italic'>Macario</span> (Roberto Gavaldón, 1959) cuya su premier fue en el <span class='bold'>Festival II Cinema Ritrovato,</span> en Bolonia, Italia.`,
            t9:`En colaboración con la <span class='bold'>Filmoteca de Catalunya</span> en Barcelona, España, se realizó la exposición <span class='bold font-italic'>El sueño de la liebre. El cine de Luis Buñuel y Gabriel Figueroa</span>`,
            t10:`Objetivos de Desarrollo Sostenible de la ONU`,
            t11:`Televisa Foundation está alineada con la Agenda 2030 a favor de la educación, la igualdad de género y alianzas para lograr los objetivos.`,
            t12:`Conoce el impacto de Televisa Foundation en 2023`
        }
      }
      const txt_v = english ? textos.en : textos.es;

  return (
    <div className='tf-tch'>
        <div className="container-fluid ">
            <div className="row py-lg-5 py-md-3 bg-tf-compromiso justify-content-center">
                <div className='col-md-10 py-md-4'>
                <h3 className='tf-compromiso' dangerouslySetInnerHTML={{ __html: txt_v.t1 }}></h3>
                </div>
            </div>
            <div className="row py-lg-0 py-md-3">
            <h2 className="ini-title">{txt_v.t2}</h2>
            <h2 className="tch-title py-lg-5 py-md-3">{txt_v.t3}</h2>
            </div>
            <div className="row text-start">
                <div className="col-lg-4 square sky-blue">
                    <p className="tch-par" dangerouslySetInnerHTML={{ __html: txt_v.t4 }}></p>
                </div>
                <div className="col-lg-4 square green">
                    <p className="tch-par" dangerouslySetInnerHTML={{ __html: txt_v.t5 }}></p>
                </div>
                <div className="col-lg-4 square mint">
                    <p className="tch-par" dangerouslySetInnerHTML={{ __html: txt_v.t6 }}></p>
                </div>
            </div>
            <div className="row bg-tch text-center py-md-4 py-3" >
                <a href="https://youtu.be/q8QAHz0aE4A" target='_blank' rel='noreferrer'><h3 className="tch-link">{txt_v.t12}</h3></a>
            </div>
            <div className="row bg-blue">
                <div className="col-lg-6 p-0">
                    <img src={Pboy} alt="" className="img-fluid b-boy" />
                </div>
                <div className="col-lg-6 text-start m-auto contain-text">
                    <h2 className="cult-head pb-sm-3">{txt_v.t7}</h2>
                    <p className="cult-para" dangerouslySetInnerHTML={{ __html: txt_v.t8 }}></p>
                    <br className="d-lg-block d-none" />
                    <p className="cult-para" dangerouslySetInnerHTML={{ __html: txt_v.t9 }}></p>
                </div>
            </div>
            <div className="row justify-content-evenly mint text-start p-lg-4 p-md-5 p-3">
                <div className="col-lg-8">
                    <h3 className="onu-title pt-0 pt-lg-4">{txt_v.t10}</h3>
                    <p className="onu-par pt-2">{txt_v.t11}</p>
                </div>
                <div className="col-lg-4 d-inline-block text-lg-end text-start pt-0 pt-md-4 pt-lg-0 ">
                    <img src={svg1} alt="" className="img-thumbnial px-lg-2" />
                    <img src={svg2} alt="" className="img-thumbnial px-lg-2" />
                    <img src={svg3} alt="" className="img-thumbnial px-lg-2" />
                </div>
            </div>
        </div>
    </div>
  )
}
