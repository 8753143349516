import React from 'react'
import './Iniciativas.css'
import CountUp from 'react-countup'

export default function Iniciativas({english=false}) {
    const textos = {
        en: {
            t1:`INITIATIVES THAT <br/> GENERATE IMPACT:`,
            t2:`<span class="highlight">lessons</span> <br /> delivered in 2023.`,
            t3:`<span class="highlight">registrations</span> <br class="d-md-none d-block" />in Compu Cuantrix through Facebook and Tik Tok.`,
        },
        es: {
            t1:`Iniciativas que <br /> generan impacto:`,
            t2:`<span class="highlight">lecciones </span> <br /> impartidas en 2023.`,
            t3:`<span class="highlight">registros </span> <br class="d-md-none d-block" />en Compu Cuantrix a través de Facebook y TikTok.`,
        }
    }

    const txt_v = english ? textos.en : textos.es;

  return (
    <div>
        <div className="container-fluid ini-bg">
            <div className="row justify content-center">
                <div className="col-lg-6 m-auto py-4">
                    <h2 className="ctx-in-title pt-4 pt-lg-2" dangerouslySetInnerHTML={{ __html: txt_v.t1 }}></h2>
                </div>
            </div>
            <div className="row justify-content-around position-relative">
                <div className="col-md-8 col-lg-4 align-self-center">
                    <div className="text-box-numbs-1 ini ">
                        <p className="bg-number numbers">
                        <span className="highlight">+<CountUp
          enableScrollSpy={true}
          start={'15599'}
          end={16000}
          duration={3}/></span> <span dangerouslySetInnerHTML={{ __html: txt_v.t2 }}></span>
                        </p>
                    </div>
                </div>
                <div className="col-md-8 col-lg-4 align-self-center">
                    <div className="text-box-numbs-2 ini ">
                        <p className="bg-number numbers">
                        <span className="highlight"> <CountUp
          enableScrollSpy={true}
          start={'6012'}
          end={6112}
          duration={3}/></span> <span dangerouslySetInnerHTML={{ __html: txt_v.t3 }}></span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
