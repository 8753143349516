import React, {useState} from 'react'
import CountUp from 'react-countup'
import './Sedena.css'
import '../Desastres.css'
import cruzroja from '../../../assets/images/desastres/IMG_9706-01.png'
import VideoPlayer from '../../VideoPlayer/VideoPlayer'

export default function Sedena({english=false}) {

    const [showVideo, setShowVideo] = useState(false);

    const textos = {
        en: {
            t1:`WE ACTED IN THE EVENT OF EMERGENCIES:`,
            t2:`Hurricane Otis, Earthquakes in Syria and Turkey.`,
            t3:`The estimated contribution of our efforts together with our allies was`,
            t4:`<span class='bold'>million pesos</span> to face the emergency in <span class='bold'>Guerrero</span> after <span class='bold'>Hurricane Otis</span>.`,
            t5:`In <span class='bold'>Syria and Turkey</span>, we reached out to <span class='bold'>children and young people</span> affected by the earthquakes, providing them with <span class='bold'>food and blankets</span>.`,
            t6:`We were the first to deliver food pantries in Guerrero through SEDENA in 2023. We have had this alliance for more than 10 years.`,
            t7:`HURRICANE OTIS, GUERRERO`,
            t8:`OUR ACTIONS`,
            t9:`We reacted immediately to the emergency. Together with the BBVA Foundation, through SEDENA and the Cruz Roja, we delivered`,
            t10:`<span class="hyper">food pantries benefiting</span>`,
            t11:`<span class="hyper">people.</span>`
        },
        es: {
            t1:`nos activamos ante la emergencia:`,
            t2:`Huracán Otis, sismos Siria y Turquía.`,
            t3:`La aportación estimada de nuestros esfuerzos junto con nuestros aliados fue de`,
            t4:`<span class='bold'>millones de pesos </span> para hacer frente a la emergencia en <span class='bold'>Guerrero</span> tras el <span class='bold'>Huracán Otis.</span>`,
            t5:`En <span class='bold'>Siria y Turquía</span> nos acercamos a los <span class='bold'>niños y jóvenes </span> afectados por los sismos proporcionándoles <span class='bold'>alimentos y cobertores.</span>`,
            t6:`Fuimos los primeros en entregar despensas en Guerrero a través de la SEDENA en el 2023. Llevamos más de 10 años con esta alianza.`,
            t7:`HURACÁN OTIS, GUERRERO`,
            t8:`Nuestras acciones`,
            t9:`Ante la emergencia reaccionamos de inmediato. Junto con Fundación BBVA a través de la SEDENA y la Cruz Roja, entregamos`,
            t10:`<span class="hyper">despensas beneficiando a</span>`,
            t11:`<span class="hyper">personas.</span>`
        }
    }
    
    const txt_v = english ? textos.en : textos.es;

  return (
    <div>
        <div className="container-fluid my-4 py-lg-5">
            <div className="row justify-content-center">
                <div className="col-xl-8 col-lg-9">
                    <h2 className="cdn-title">{txt_v.t1}</h2>
                </div>
                <div className="col-lg-8">
                    <h3 className='cdn-desc '>{txt_v.t2}</h3>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-lg-9">
                    <p className="cdn-par">
                    <span dangerouslySetInnerHTML={{ __html: txt_v.t3 }}></span> <span className='bold'> <CountUp
                        enableScrollSpy={true}
                        start={0}
                        end={60}
                        duration={2}/></span> <span dangerouslySetInnerHTML={{ __html: txt_v.t4 }}></span>
                    </p>
                    <p className="cdn-par" dangerouslySetInnerHTML={{ __html: txt_v.t5 }}></p>
                </div>
            </div>
            </div>
            <div className="container-fluid cdn-bg-blue">
                <div className="row py-lg-5 justify-content-center">
                    <div className="col-lg-10 ">
                        <h2 className="cdn-quote py-4 py-lg-2 ">
                            {txt_v.t6}
                        </h2>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row py-lg-5 justify-content-center">
                <div className="col-lg-8">
                    <h2 className="cdn-title">{txt_v.t7}</h2>
                    <h3 className="cdn-subtitle">{txt_v.t8}</h3>
                </div>
                </div>
            </div>
        <div className="container-fluid">
            <div className="row cdn-bg-blue">
                <div className="col-lg-6 p-0">
                    {
                        !showVideo &&
                        <img src={cruzroja} alt="cruz roja" className="img-fluid" onClick={() => setShowVideo(true)} />
                    }
                    {
                        showVideo &&
                        <VideoPlayer
                            src="https://www.youtube.com/watch?v=KOasx7XBa_o"
                            className="w-100"
                            playing={true}
                        />
                    }
                    
                </div>
                <div className="col-lg-6 m-auto cr-text-box">
                    <h3 className="cr-title">
                    <span dangerouslySetInnerHTML={{ __html: txt_v.t9 }}></span>
                        <span className="hyper"> <CountUp
                        enableScrollSpy={true}
                        start={23700}
                        end={23785}
                        duration={5}/></span> <span dangerouslySetInnerHTML={{ __html: txt_v.t10 }}></span>
                        
                        <span className="hyper"> <CountUp
                        enableScrollSpy={true}
                        start={118800}
                        end={118823}
                        duration={4}/></span> <span dangerouslySetInnerHTML={{ __html: txt_v.t11 }}></span>
                    </h3>
                </div>
            </div>
        </div>
    </div>
  )
}
