import React, {useState} from 'react'
import './VistaAguila.css'
import vistaAguila from '../../../assets/images/nuestrasAlas/vista_de_aguila.png'
import vistaAguilaen from '../../../assets/images/nuestrasAlas/nuestras-alas-en.jpg'
import VideoPlayer from '../../../componentes/VideoPlayer/VideoPlayer'

export default function VistaAguila({english=false}) {

  const [showVideo, setShowVideo] = useState(false);

  return (
    <div className='VistaAguila'>
      <div className='px-0 py-5 px-lg-5 py-lg-4'>
        <div className='m-0 px-0 py-2 px-lg-4 py-lg-5 d-flex justify-content-center position-relative z-3'>
          {
            english &&
            <div className='w-75'>
            {
              !showVideo &&
              <img src={vistaAguilaen} alt='vista águila' className='w-100' onClick={() => setShowVideo(true)} />
            }
            { showVideo &&
              <div className='p-2'>
                <VideoPlayer
                  src="https://www.youtube.com/watch?v=OHztq6eHIyw"
                  className="w-100"
                  playing={true}
                />
              </div>
            }
          </div>
          }
          {
            !english &&
          <div className='w-75'>
            {
              !showVideo &&
              <img src={vistaAguila} alt='vista águila' className='w-100' onClick={() => setShowVideo(true)} />
            }
            { showVideo &&
              <div className='p-2'>
                <VideoPlayer
                  src="https://www.youtube.com/watch?v=OHztq6eHIyw"
                  className="w-100"
                  playing={true}
                />
              </div>
            }
          </div>
          }
        </div>
      </div>
    </div>
  )
}
