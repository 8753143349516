import React from 'react'
import './Cifras.css'

export default function Cifras() {
  return (
    <div className='Cifras'>
      <div className='text-content'>
        <div className='paragraph'>
          <b>5,562,917 de personas</b><br/> realizaron alguna acción en las <b>plataformas digitales, obtuvieron más información o participaron</b> en nuestros programas.
        </div>
      </div>
    </div>
  )
}
