import React from 'react'
import './Comunidades.css'
import img1 from '../../../../assets/images/programas/temprano/logros/S_SDG_PRINT-01.png'
import img2 from '../../../../assets/images/programas/temprano/logros/S_SDG_PRINT-03.png'
import img3 from '../../../../assets/images/programas/temprano/logros/S-WEB-Goal-01 4.png'
import img4 from '../../../../assets/images/programas/temprano/logros/S-WEB-Goal-01 5.png'
import img5 from '../../../../assets/images/programas/temprano/logros/S_SDG_PRINT-10 1.png'
import img6 from '../../../../assets/images/programas/temprano/logros/S_SDG_PRINT-16 1.png'
import img7 from '../../../../assets/images/programas/temprano/logros/S-WEB-Goal-01 7.png'
import CountUp from 'react-countup'


export default function Comunidades({english=false}) {

    const textos = {
        en: {
            t1:`DIGITAL <br />COMMUNITIES:`,
            t2:`<span class="highlight">people</span> make up our <span class="highlight">digital community</span>, where we share informative and educational content.`,
            t3:`UN Sustainable Development Goals`,
            t4:`<span class='bold'> Empieza Temprano </span>is aligned with the 2030 agenda, contributing directly to Sustainable Development Goals focused on education, decent work and economic growth, and the reduction of inequalities.`,
        },
        es:{
            t1:`comunidades <br />digitales:`,
            t2:`<span class="highlight">personas</span> conforman nuestra <span class="highlight">comunidad digital,</span> donde compartimos contenidos informativos y didácticos.`,
            t3:`Objetivos de Desarrollo Sostenible de la ONU`,
            t4:`<span class='bold'> Empieza Temprano </span>está alineado con la agenda 2030, contribuyendo directamente a Objetivos de Desarrollo Sostenible enfocados a la educación, el trabajo decente y crecimiento económico, y la reducción de las desigualdades.`,
        }
    }

    const txt_v = english ? textos.en : textos.es;

  return (
    <div>
        <div className="container-fluid">
            <div className="row ">
                <div className="col-lg-6 m-auto px-md-5 px-3 py-4 py-md-0">
                    <h2 className="com-dig" dangerouslySetInnerHTML={{ __html: txt_v.t1 }}></h2>
                </div>
                <div className="col-lg-6 p-0 bg-cd-cl m-auto">
                    <div className="row com-h align-items-center">
                        <div className="col pt-3 px-4 py-md-5 px-lg-4">
                            <div className='d-flex w-100 '>
                            <ul className='comunidades mb-0'>
                                <li className=''>
                                <div className='m-0 p-0'>
                                    <p className="par-comunidad-digital px-2 pe-3">
                                        <span className="highlight"><CountUp
                                            enableScrollSpy={true}
                                            start={'212009'}
                                            end={212391}
                                            duration={4}/></span> <span dangerouslySetInnerHTML={{ __html: txt_v.t2 }}></span>
                                    </p>
                                </div> 
                                </li>
                            </ul>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row bg-nar pb-3 pb-lg-0">
                <div className="col-lg-7 m-auto px-5 py-4 p-lg-5 pe-lg-3">
                <h2 className="title-onu ">{txt_v.t3}</h2>
                <p className="paragraph-onu mt-4" dangerouslySetInnerHTML={{ __html: txt_v.t4 }}></p>
                </div>
                <div className="col-lg-5 text-md-center p-md-5 pt-lg-5 pb-lg-4 px-lg-2 pb-3 ">
                            <div className='row m-0 p-0'>
                            <div className='d-none d-lg-block col-lg-1'>&nbsp;</div>
                                <div className='col-6 col-lg-2'>
                                    <img src={img1} alt="" className="img-objetivos" />
                                </div>
                                <div className='col-6 col-lg-2'>
                                    <img src={img2} alt="" className="img-objetivos" />
                                </div>
                                <div className='col-6 col-lg-2'>
                                    <img src={img3} alt="" className="img-objetivos" />
                                </div>
                                <div className='col-6 col-lg-2'>
                                    <img src={img4} alt="" className="img-objetivos" />
                                </div>
                                <div className='col-6 col-lg-2'>
                                    <img src={img5} alt="" className="img-objetivos" />
                                </div>
                                <div className='d-none d-lg-block col-lg-2'>&nbsp;</div>
                                <div className='col-6 col-lg-2 offset-0 offset-lg-5'>
                                    <img src={img6} alt="" className="img-objetivos" />
                                </div>
                                <div className='col-6 col-lg-2'>
                                    <img src={img7} alt="" className="img-objetivos" />
                                </div>
                            </div>
                    
                    {/* <img src={img2} alt="" className="img-objetivos" />
                    <img src={img3} alt="" className="img-objetivos" />
                    <img src={img4} alt="" className="img-objetivos" />
                    <img src={img5} alt="" className="img-objetivos" />
                    <img src={img6} alt="" className="img-objetivos" />
                    <img src={img7} alt="" className="img-objetivos" /> */}
                </div>
            </div>
        </div>
    </div>
  )
}
