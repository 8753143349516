import React, { useState } from 'react'
import './CardGral.css'
import play from '../../../assets/images/home/video/play.png'
import VideoPlayer from '../../VideoPlayer/VideoPlayer';

export default function CardGral(
  {image, title, description,url, type = 'image', videoUrl = ''}
) {

  const [showVideo, setShowVideo] = useState(false);

  return (
    <div className='CardGral px-lg-4'>
      <div className="position-relative">
        {
          type === 'image' &&
          <img src={image} alt={title} className='image'/>
        }
        {
          type === 'video' && !showVideo &&
          <img src={image} alt={title} className='image' onClick={() => setShowVideo(true)}/>
        }
        {
          type === 'video' && showVideo &&
          <VideoPlayer
            src={url}
            className="w-100"
            playing={true}
          />
        }
        {
          url !== undefined && !showVideo &&
          <img src={play} alt="video" className='play-overlay' onClick={() => setShowVideo(true)}/>
        }
      </div>
      <div className='title-card'>
        {title}
      </div>
      <div className='description' dangerouslySetInnerHTML={{ __html: description }}></div>
    </div>
  )
}
