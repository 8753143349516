import React, { useState } from 'react'
import './HeaderProgramasPosible.css'
import CountUp from 'react-countup';
import VideoPlayer from '../../../../VideoPlayer/VideoPlayer';

export default function HeaderProgramasPosible({english=false}) {

  const [showVideo, setShowVideo] = useState(false);
  const [showVideoMobile, setShowVideoMobile] = useState(false);

  const textos = {
    en: {
        t1:`Posible`,
        t2:`The most far-reaching entrepreneurship program in Mexico.`,
        t3:`<b>entrepreneur registrations</b> in 2023, thanks to our National Call for Proposals.`,
        t4:`Through the program, entrepreneurs find <b>tools, contacts, and resources</b> to take their projects to the next level, actively collaborating on the progress of Mexico.`,
        t5:`More than <b>718 thousand people</b> have registered, and more than <b>125 thousand business models</b> have been completed throughout the program's history.`
    },
    es: {
        t1:`Posible`,
        t2:`El programa de emprendimiento de mayor alcance en México.`,
        t3:`<b>registros de emprendedores</b> en 2023, gracias a nuestra Convocatoria Nacional.`,
        t4:`A través del programa, los emprendedores encuentran <b>herramientas, contactos y recursos</b> para llevar sus proyectos al siguiente nivel, colaborando activamente en el progreso de México.`,
        t5:`Más de <b>718 mil personas</b> se han registrado y más de <b>125 mil modelos de negocio completados</b> a lo largo de la historia del programa.`
    }
}

const txt_v = english ? textos.en : textos.es;

  return (
    <div className='HeaderProgramasPosible'>
      {/* mobile */}
      <div className='mobile-device'>
        <div className='m-0 p-0'>
          {
            !showVideoMobile &&
            <div className='m-0 p-0 bg-header-img' onClick={() => setShowVideoMobile(true)}>
              <div className='play-icon'>
                
              </div>
            </div>
          }
          {
            showVideoMobile &&
            <div className='p-4'>
              <VideoPlayer
                src="https://www.youtube.com/watch?v=vs0kjueHiyc"
                className="w-100"
                playing={true}
              />
            </div>
          }
        </div>

        <div className='m-0 p-0 bg-rectangle'>
          <div className='d-flex justify-content-center'>
            <div className='text-container pb-5'>
              <div className='d-flex justify-content-center mt-5'>
                <div className='main-title-programas'>
                  {txt_v.t1}
                </div>
              </div>
              <div className='d-flex justify-content-center mt-2 px-md-4'>
                <div className='main-subtitle-programas'>
                  {txt_v.t2}
                </div>
              </div>
              <div className='d-flex justify-content-center mt-4 px-md-4 px-lg-0'>
                <div className='main-paragraph-programas'>
                  <b><CountUp
                    enableScrollSpy={true}
                    start={'37000'}
                    end={37335}
                    duration={3}
                  /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t3 }}></span>
                  <div className='pt-4' dangerouslySetInnerHTML={{ __html: txt_v.t4 }}></div>
                  {/* <b>Más de <CountUp
                    enableScrollSpy={true}
                    start={'36100'}
                    end={37000}
                    duration={3}
                  /> registros de emprendedores en 2023</b>, gracias a nuestra Convocatoria Nacional.
                   */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className='bg-gray-posible'>
          <div className='d-flex justify-content-center p-5'>
            <div className='paragraph-header-two'>
              Más de <b><CountUp
                    enableScrollSpy={true}
                    start={0}
                    end={718}
                    duration={2}
                  /> mil personas</b> se han registrado y más de <b><CountUp
                    enableScrollSpy={true}
                    start={0}
                    end={125}
                    duration={2}
                  /> mil modelos de negocio completados</b> a lo largo de la historia del programa.
            </div>
          </div>
        </div>

        <div className='bg-gold-posible'>
          <div className='d-flex justify-content-center p-5'>
            <div className='paragraph-header-three'>
              Confiamos en lo que tu mente <br/> crea, te ayudamos a lograrlo.
            </div>
          </div>
        </div> */}

      </div>

      {/* web */}
      <div className='web-device'>
        <div className='row m-0 p-0'>
          <div className='col-6 m-0 p-0'>
            <div className='bg-rectangle'>
              <div className='p-3 p-lg-5'>
                <div className='main-title-programas pt-md-3'>
                {txt_v.t1}
                </div>
                <div className='main-subtitle-programas pt-2'>
                {txt_v.t2}
                </div>
                <div className='main-paragraph-programas pt-4'>
                  <b><CountUp
                    enableScrollSpy={true}
                    start={'37000'}
                    end={37335}
                    duration={3}
                  /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t3 }}></span>
                  <div className='pt-4' dangerouslySetInnerHTML={{ __html: txt_v.t4 }}></div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-6 m-0 p-0'>
            <div>
              {
                !showVideo &&
                <div className='bg-header-img' onClick={() => setShowVideo(true)}>
                  {/*<div className='play-icon'>
                    <img src={playIcon} alt='play' />
                  </div>*/}
                </div>
              }
              {
                showVideo &&
                <div className='p-4'>
                  <VideoPlayer
                    src="https://www.youtube.com/watch?v=ChDwR_dDCn0"
                    className="w-100"
                    playing={true}
                  />
                </div>
              }
            </div>
          </div>
        </div>

        {/* <div className='row m-0 p-0'>
          <div className='w-50 m-0 p-0'>
            <div className='bg-gray-posible'>
              <div className='d-flex justify-content-center p-5'>
                <div className='paragraph-header-two'>
                  Más de <b><CountUp
                    enableScrollSpy={true}
                    start={0}
                    end={718}
                    duration={2}
                  /> mil personas</b> se han registrado y más de <b><CountUp
                    enableScrollSpy={true}
                    start={0}
                    end={125}
                    duration={2}
                  /> mil modelos de negocio completados</b> a lo largo de la historia del programa.
                </div>
              </div>
            </div>
          </div>
          <div className='w-50 m-0 p-0 bg-gold-posible'>
            <div className='bg-gold-posible'>
              <div className='d-flex justify-content-center p-5'>
                <div className='paragraph-header-three'>
                  Confiamos en lo que tu mente <br/> crea, te ayudamos a lograrlo.
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>

      <div className='d-flex justify-content-center bg-gold-posible'>
        <div className='w-75'>
          <div className='paragraph-header p-md-5 py-4' dangerouslySetInnerHTML={{ __html: txt_v.t5 }}></div>
        </div>
      </div>

    </div>
  )
}
