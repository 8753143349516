import React from 'react'
import './Header.css'
import Nav from '../Nav/Nav'
import CountUp from 'react-countup';
import { motion } from 'framer-motion';
import subrayado from '../../assets/images/nuestrasAlas/subrayado.png'


export default function Header({english=false}) {

  const textos = {
    en: {
      mov:{
        t1:`THEIR SUCCESS`,
        t2:`IS OUR BEST RESULT.`,
        t3:`<b>IN 2023</b>, we helped transform the lives of: <br class='d-block d-md-none'/>`,
        t4:`children, young people, and adults in our country.`
      },
      tab:{
        t1:`THEIR SUCCESS`,
        t2:`IS OUR BEST RESULT.`,
        t3:`<b>IN 2023</b>, we helped transform the lives of:`,
        t4:`children, young people, and adults in our country.`
      },
      desk:{
        t1:`THEIR SUCCESS`,
        t2:`IS OUR BEST RESULT.`,
        t3:`IN 2023,`,
        t4:`we helped transform the lives of:`,
        t5:`children, young people, and adults in our country.`
      }
    },
    es:{
      mov:{
        t1:`SUS TRIUNFOS`,
        t2:`SON NUESTRO MEJOR RESULTADO.`,
        t3:`<b>EN 2023</b>, ayudamos a transformar la vida de: <br classs='d-block d-md-none'/>`,
        t4:`niños, jóvenes y adultos  en nuestro país.`
      },
      tab:{
        t1:`SUS TRIUNFOS`,
        t2:`SON NUESTRO MEJOR RESULTADO.`,
        t3:`<b>EN 2023</b>, ayudamos a transformar la vida de:`,
        t4:`niños, jóvenes y adultos en nuestro país.`
      },
      desk:{
        t1:`SUS TRIUNFOS`,
        t2:`SON NUESTRO MEJOR RESULTADO.`,
        t3:`EN 2023,`,
        t4:`ayudamos a transformar la vida de:`,
        t5:`niños, jóvenes y adultos en nuestro país.`
      }
    }
  };

  // Selecciona el texto basado en la prop 'version'
  const txt_v = english ? textos.en : textos.es;

  return (
    <div className='Header'>
      <Nav ver={english}/>

      <div className='mobile-device'>

        {/* <motion.div
          initial={{ translateY: '100vh' }} // Start offscreen at the bottom
          animate={{ translateY: '0vh' }} // Animate to the top
          transition={{ duration: 0.5 }} // Set animation duration
        > */}
          <div className='mobile-container'>
            <div className='sus-triunfos-mobile'>
              <div className='title pt-2'>
                {txt_v.mov.t1}
              </div>
              <div className='subtitle'>
                {txt_v.mov.t2} 
              </div>
              <div className='mt-4'>&nbsp;</div>
            </div>

            <div className='en-2023-mobile'>
              <div className='d-flex justify-content-center'>
                <div className='title w-75'>
                  <span dangerouslySetInnerHTML={{ __html: txt_v.mov.t3 }}></span>
                  <b>
                    <CountUp enableScrollSpy={true} start={0} end={785583} duration={3}/>&nbsp;
                  </b>
                  <span dangerouslySetInnerHTML={{ __html: txt_v.mov.t4 }}></span>
                </div>
              </div>
            </div>
          </div>
        {/* </motion.div> */}

      </div>

      <div className='tablet-device'>

        {/* <motion.div
          initial={{ translateY: '100vh' }} // Start offscreen at the bottom
          animate={{ translateY: '0vh' }} // Animate to the top
          transition={{ duration: 1 }} // Set animation duration
        > */}

          <div className='sus-triunfos'>
            {/* <div className='rectangle'>
              <img src={susTriunfos} alt='rectangle1' className='rectangle1' />
            </div> */}
            <div className='title'>
              {txt_v.tab.t1}
              <div className='subtitle'>
                {txt_v.tab.t2}
              </div>
            </div>
          </div>

          <div className='en-2023'>
            {/* <div className='rectangle'>
              <img src={en2023} alt='rectangle2' className='rectangle2' />
            </div> */}
            <div className='title' dangerouslySetInnerHTML={{ __html: txt_v.tab.t3 }}></div>
          </div>

          <div className='setecientos-mil'>
            {/* <div className='rectangle'>
              <img src={setescientos} alt='rectangle3' className='rectangle3' />
            </div> */}
            <div className='title'>
              <CountUp
                enableScrollSpy={true}
                start={0}
                end={785583}
                duration={5}
              />
              <div className='subtitle'>
                {txt_v.tab.t4}
                {/* <div className='subrayado d-flex justify-content-center'>
                  <img src={subrayado} alt='subrayado' />
                </div> */}
              </div>
            </div>
          </div>

        {/* </motion.div> */}

      </div>


      
     <div className='web-device'>
        <motion.div
          initial={{ translateY: '100vh' }} // Start offscreen at the bottom
          animate={{ translateY: '-20vh' }} // Animate to the top
          transition={{ duration: 1 }} // Set animation duration
        >
          <div className='row m-0 p-0'>
            <div className='col-12'>
              <div className='sus-triunfos'>
              {/* <div className='rectangle'>
                <img src={susTriunfos} alt='rectangle1' className='rectangle1' />
              </div> */}
                <div className='title'>
                  {txt_v.desk.t1}
                  <div className='subtitle'>
                  {txt_v.desk.t2}
                  </div>
                </div>
              </div>

            <div className='en-2023'>
              {/* <div className='rectangle'>
                <img src={en2023} alt='rectangle2' className='rectangle2' />
              </div> */}
              <div className='title'>
                <span className='strong-num'>{txt_v.desk.t3}</span> 
                <div className='subtitle'>
                  {txt_v.desk.t4}
                </div>
              </div>
            </div>

            <div className='setecientos-mil'>
              {/* <div className='rectangle'>
                <img src={setescientos} alt='rectangle3' className='rectangle3' />
              </div> */}
              <div className='title'>
                <CountUp
                  enableScrollSpy={true}
                  start={0}
                  end={785583}
                  duration={5}
                />
                <div className='subtitle'>
                  {txt_v.desk.t5}
                  {/* <div className='subrayado d-flex justify-content-center'>
                    <img src={subrayado} alt='subrayado' />
                  </div> */}
                </div>
              </div>
            </div>
            </div>
          </div>
        </motion.div>

        {/* <div className='rectangle-bottom'>
          <img src={rectangle} alt='rectangle3' className='rectangle4' />
        </div> */}
     </div>
      
    </div>
  )
}
