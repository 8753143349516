import React from 'react'
import './Impacto.css'
import CountUp from 'react-countup'

import img1 from '../../../../assets/images/programas/golxmex/IMG_1353-01 (1) 1.png'
import img2 from '../../../../assets/images/programas/golxmex/image 2.png'
export default function Impacto({english=false}) {
    const textos = {
        en: {
            t1:`beneficiaries <br className="d-lg-block d-none" />in <span className='highlight'>Health.</span>`,
            t2:`beneficiaries <br className="d-lg-block d-none" />in <span className='highlight text-light'>Education.</span>`,
            t3:`beneficiaries <br className="d-block" /> with <span className='highlight text-light'>Housing.</span>`,
            t4:`trees were our <br class="d-block" /><span class='highlight'>reforestation</span> effort.`,
            t5:`Among the beneficiaries in 2023, <b>8,042 women</b> transformed their lives thanks to <b>Gol por México Femenil</b> with <b>mastographies, ultrasounds, courses, and workshops on home economics and the prevention of gender violence.</b>`,
        },
        es:{
            t1:`beneficiados <br class="d-lg-block d-none" /> en temas de <span class='highlight'>Salud.</span>`,
            t2:`beneficiados <br class="d-lg-block d-none" />en temas  de <span class='highlight text-light'>Educación.</span>`,
            t3:`beneficiados <br class="d-block" /> con <span class='highlight text-light'>Vivienda.</span>`,
            t4:`árboles fueron <br class="d-block" /> sembrados gracias a nuestro esfuerzo en <span class='highlight'>reforestación.</span>`,
            t5:`De los beneficiarios del 2023, un total de <b>8,042 mujeres</b> transformaron sus vidas gracias a <b>Gol por México Femenil </b> con <b>mastografías, ultrasonidos, cursos y talleres sobre economía del hogar y comunitaria, y prevención de la violencia de género.</b>`,
        }
    }
    
    const txt_v = english ? textos.en : textos.es;
  return (
    <div>
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-6 p-0 order-lg-1 order-2">
                    <img src={img1} alt="" className="w-100" />
                </div>
                <div className="col-lg-5 offset-lg-1 p-l-0 p-md-5 m-auto order-lg-2 order-1">
                    <p className="imp-par-2 w-100 m-auto"><span className='highlight'><CountUp
          enableScrollSpy={true}
          start={'6750'}
          end={6850}
          duration={4}/></span> <span dangerouslySetInnerHTML={{ __html: txt_v.t1 }}></span></p>
                </div>
                <div className="col-12 p-0 order-3">
                    <div className="row bg-team justify-content-around pb-4">
                        <div className="col-lg-5 col-8 align-self-lg-end align-self-center mb-md-5">
                            <div className="rect-gr ">
                                <p className="imp-par text-light"><span className='highlight text-light'><CountUp
          enableScrollSpy={true}
          start={'9861'}
          end={9961}
          duration={4}/></span> <span dangerouslySetInnerHTML={{ __html: txt_v.t2 }}></span></p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-8 align-self-lg-end align-self-center">
                            <div className="rect-gr ">
                                <p className="imp-par  text-light"><span className='highlight text-light'><CountUp
          enableScrollSpy={true}
          start={700}
          end={750}
          duration={4}/></span> <span dangerouslySetInnerHTML={{ __html: txt_v.t3 }}></span></p>
                            </div>                            
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 p-0 m-auto order-4 p-md-5">
                    <p className="imp-par-2 w-75 m-auto"><span className='highlight'><CountUp
          enableScrollSpy={true}
          start={'7350'}
          end={7550}
          duration={6}/></span> <span dangerouslySetInnerHTML={{ __html: txt_v.t4 }}></span></p>
                </div>
                <div className="col-lg-6 p-0 order-5">
                    <img src={img2} alt="" className="w-100" />
                </div>
            </div>
        </div>

        <div className='row m-0 p-0'>
            <div className='col-12 m-0 p-0'>
                <div className='bg-golxmexfem d-flex justify-content-center'>
                    <div className='text-content d-flex justify-content-center'>
                        <div className='golxmexfem-description bg-paragraph-golxmexfem p-2' dangerouslySetInnerHTML={{ __html: txt_v.t5 }}></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
