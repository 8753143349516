import React, { useState } from 'react'
import './Camp.css'
import imgvideo from '../../../assets/images/home/video/collage_nuestras-camp.jpg'
import imgvideoMobile from '../../../assets/images/home/video/collage_nuestras-camp_en.jpg'
import overlap from '../../../assets/images/home/video/video-overlap.png'
import play from '../../../assets/images/home/video/play.png'
import { motion } from 'framer-motion';
import VideoPlayer from '../../../componentes/VideoPlayer/VideoPlayer';
import { useInView } from 'react-intersection-observer';

export function Camp({english=false}){
console.log(english);
  const imgm = english ? imgvideoMobile: imgvideo;

  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const variants = {
    hidden: { translateY: '20vh' },
    visible: { translateY: '0vh', transition: { duration: 1 } },
  };

  const [showVideoMobile, setShowVideoMobile] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  return (
    <div>
      <div className='container-fluid camp-bg p-0 m-0'>
        {/* mobile */}
        <div className='mobile-device'>
          <div className='row position-relative' >
            {/* <img src={overlap} alt="overlap" className='camp-overlap'/> */}
            <section ref={ref}>
              {/* <motion.div
                // whileInView ={{ translateX: '0vw' }} // Set to the right
                // animate={{ translateX: '20vw' }} // Start to the left
                // transition={{ duration: 0.5 }} // Set animation duration
                variants={variants}
                initial="hidden"
                animate={inView ? 'visible' : 'hidden'}
              > */}
                <div className='col-12 m-0 p-0'>
                  {
                    !showVideoMobile &&
                    <>
                      <img src={imgm} alt="video background" className='img-fluid' onClick={() => {setShowVideoMobile(true)}}/>
                      {/* <img src={play} alt="video" className='camp-play-overlay' onClick={() => {setShowVideoMobile(true)}}/> */}
                    </>
                  }
                  {
                    showVideoMobile &&
                    <div className='w-100 h-auto'>
                      <VideoPlayer 
                        src="https://www.youtube.com/watch?v=-wh5JrN-UUo"
                        playing={true}
                      />
                    </div>
                  }
                  
                  {/* <div className='camp-textos'>
                    <h2 className='camp-conoce m-0 p-0'>Conoce</h2>
                    <h2 className='camp-camp text-uppercase m-0 p-0'>nuestras campañas</h2>
                  </div> */}
                </div>
              {/* </motion.div> */}
            </section>
          </div>
        </div>

        {/* web */}
        <div className='web-device'>
          <div className='row m-0 p-0 position-relative' ref={ref}>
            {/* <img src={overlap} alt="overlap" className='camp-overlap'/> */}
            <motion.div
              // whileInView ={{ translateX: '0vw' }} // Set to the right
              // animate={{ translateX: '-50vw' }} // Start to the left
              // transition={{ duration: 1 }} // Set animation duration
              variants={variants}
              initial="hidden"
              animate={inView ? 'visible' : 'hidden'}
              className='m-0 p-0'
            >
              <div className='col-12 m-0 p-0'>
                {
                  !showVideo &&
                  <>
                    <img src={imgm} alt="video background" className='w-100 m-0 p-0' onClick={() => {setShowVideo(true)}}/>
                    {/* <img src={play} alt="video" className='camp-play-overlay' onClick={() => {setShowVideo(true)}}/> */}
                  </>
                }
                {
                  showVideo &&
                  <div className='w-100 h-auto'>
                    <VideoPlayer
                      src="https://www.youtube.com/watch?v=-wh5JrN-UUo"
                      playing={true}
                    />
                  </div>
                }
                
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  )
}
