import React from 'react'
import './Programas.css'
// import HeaderProgramas from '../../componentes/Programas/HeaderProgramas/HeaderProgramas'
import NuestrosProgramas from '../../componentes/Programas/NuestrosProgramas/NuestrosProgramas'
import HeaderSection from '../../componentes/HeaderSection/HeaderSection'
import hero from '../../assets/images/programas/hero.png'


export default function Programas({version=false}) {

  const textos = {
    en: {
      t1:'OUR PROGRAMS'
    },
    es: {
      t1:'Nuestros Programas'
    }
  }
  const txt_v = version ? textos.en : textos.es;

  return (
    <div className='Programas'>
      {/* <HeaderProgramas /> */}
      <HeaderSection
        image={hero}
        title={txt_v.t1}
        english={version}
      />

      <NuestrosProgramas english={version}/>

    </div>
  )
}
