import React, { useState } from 'react'
import './Compromiso.css'
import celeb from '../../../../assets/images/programas/becalos/becalos_celebracion.jpg'
import grad from '../../../../assets/images/programas/becalos/Graduacion_Becalos_7.png'
import CountUp from 'react-countup';
import VideoPlayer from '../../../VideoPlayer/VideoPlayer';


export default function Compromiso({english=false}) {
    const [showVideo, setShowVideo] = useState(false);
    const [showVideoP, setShowVideoP] = useState(false);
    const textos = {
        en: {
            t1:`BÉCALOS`,
            t2:`A permanent commitment to education in Mexico.`,
            t3:`<b>scholarships</b> awarded over <b>18 years</b>.`,
            t4:`At <b>Bécalos</b>, we generate <b>opportunities</b> for <b>quality education</b> to reach <b>socially disadvantaged young Mexicans</b> so they can develop <b>skills</b> for their <b>future</b>.`,
            t5:`In 2023, we awarded`,
            t6:`<span class='bold'>scholarships</span> to continue promoting the Mexican youth.`,
            t7:`Bécalos is present in <span class='bold'>98.9 percent</span> of the country and has invested <span class='bold'>$2,870,145,235 pesos</span> since 2006, with <span class='bold'>$166,581,235 belonging to 2023</span>.`
        },
        es: {
            t1:`BÉCALOS`,
            t2:`Un compromiso permanente con la educación de calidad en México.`,
            t3:`<b>becas</b> otorgadas a lo largo de <b>18 años.</b>`,
            t4:`En <b>Bécalos</b> generamos <b>oportunidades</b> para que la <b>educación de calidad</b> llegue a los <b>jóvenes mexicanos en desventaja social,</b> y así puedan desarrollar <b>competencias</b> para su <b>futuro.</b>`,
            t5:`En 2023, otorgamos`,
            t6:`<span class='bold'>becas</span> para seguir impulsando a la juventud mexicana.`,
            t7:`Bécalos tiene presencia en el <span class='bold'> 98.9 por ciento</span> del país y se han invertido <span class='bold'> $2,870,145,235 pesos</span> desde el 2006, siendo <span class='bold'> $166,581,235 pertenecientes al 2023.</span>`
        }
    }

    const txt_v = english ? textos.en : textos.es;

  return (
    <div>
        <div className="container-fluid">
            <div className="row bg-grad">
                <div className="col-lg-6 p-0 m-0">
                    <div className="p-md-5 pb-4">
                        <h2 className="main-title-programas">{txt_v.t1}</h2>
                        <h4 className="main-subtitle-programas">{txt_v.t2}</h4>
                        <h5 className="main-paragraph-programas pt-4 mx-3 mx-md-0">
                        <b><CountUp
                            enableScrollSpy={true}
                            start={0}
                            end={550696}
                            duration={3}
                        /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t3 }}></span>
                        </h5>
                        <p className="main-paragraph-programas mx-3 mx-md-0" dangerouslySetInnerHTML={{ __html: txt_v.t4 }}></p>
                    </div>
                </div>
                <div className="col-lg-6 p-0">
                    {
                        !showVideoP &&
                        <img src={celeb} alt="fut" className="et-img object-fit-cover" onClick={() => setShowVideoP(true)} />
                    }
                    {
                        showVideoP &&
                        <div className='p-4'>
                            <VideoPlayer
                                src="https://www.youtube.com/watch?v=1zbdow8-Ro0"
                                className="w-100"
                                playing={true}
                            />
                        </div>
                    }
                </div>
            </div>
            <div className="row bg-azbec justify-content-center">
                <div className="col-lg-10 p-0 ">
                    <div className="row mh-250 align-items-center">
                        <div className="col">
                            <h3 className="title-quote">
                            <span dangerouslySetInnerHTML={{ __html: txt_v.t5 }}></span> <span className='bold'><CountUp
                                enableScrollSpy={true}
                                start={0}
                                end={38577}
                                duration={3}
                            /></span> <span dangerouslySetInnerHTML={{ __html: txt_v.t6 }}></span>
                            </h3>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6 p-0 text-end">
                  {
                    !showVideo &&
                    <div className="img-vid">
                        <img src={grad} alt="celebracion" className="w-100" onClick={() => setShowVideo(true)} />
                        
                    </div>
                  }
                  {
                    showVideo &&
                    <VideoPlayer
                      src="https://www.youtube.com/watch?si=BYF_BS7CgGaQJAuD+&utm_source=informe2023&utm_medium=videominuto&utm_id=celebracion2023&utm_content=carmenmunoz&v=GKXcHBFtyAg&feature=youtu.be"
                      className="w-100"
                      playing={true}
                    />
                  }
                </div>
                <div className="col-lg-6 p-0 m-auto">
                    <p className="bec-pres-par" dangerouslySetInnerHTML={{ __html: txt_v.t7 }}></p>
                </div>
            </div>
        </div>
    </div>
  )
}
