import React from 'react'
import './ProgramasPosible.css'
import HeaderProgramasPosible from '../../../componentes/Programas/subsections/ProgramasPosible/HeaderProgramasPosible/HeaderProgramasPosible'
import IniciativasPosible from '../../../componentes/Programas/subsections/ProgramasPosible/IniciativasPosible/IniciativasPosible'
import TestimoniosPosible from '../../../componentes/Programas/subsections/ProgramasPosible/TestimoniosPosible/TestimoniosPosible'
import ProgramasComunidadesDigitales from '../../../componentes/Programas/subsections/ProgramasPosible/ProgramasComunidadesDigitales/ProgramasComunidadesDigitales'
import ProgramasObjetivosOnu from '../../../componentes/Programas/subsections/ProgramasPosible/ProgramasObjetivosOnu/ProgramasObjetivosOnu'
// import Footer from '../../../componentes/Footer/Footer'
import FooterProgramas from '../../../componentes/Programas/FooterProgramas/FooterProgramas'
import arrowsGolMex from '../../../assets/images/programas/footer/arrows-golmex.png'

export default function Posible(
  {handleSectionSelected,englishp=false}
) {
  return (
    <div className='ProgramasPosible'>
      <HeaderProgramasPosible english={englishp}/>

      <IniciativasPosible english={englishp}/>

      <TestimoniosPosible english={englishp}/>

      <ProgramasComunidadesDigitales english={englishp}/>

      <ProgramasObjetivosOnu english={englishp}/>

      {/* <Footer /> */}
      <FooterProgramas 
        background='bg-programa-golmex'
        arrows={arrowsGolMex}
        title='GOL POR MÉXICO'
        color='#FF8434'
        bgImage='bg-program-image-golmex'
        handleSectionSelected={handleSectionSelected}
        programSection='golmex'
        english={englishp}
      />
    </div>
  )
}
