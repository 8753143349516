import React from 'react'
import './Impacto.css'
import img1 from '../../../../assets/images/programas/tecnolochicas/WhatsApp Image 2023-08-29 at 18.24 1.png'
import img2 from '../../../../assets/images/programas/tecnolochicas/309A5010 1.png'
import CountUp from 'react-countup'

export default function Impacto({english=false}) {
    const textos = {
        en: {
            t1:`<span class='highlight'>mil women</span> graduates thanks to the alliance with the <span class='highlight'>Instituto de Educación de Aguascalientes.</span>`,
            t2:`<span class='highlight'>female students</span> benefited through <br class="d-none d-md-block d-lg-none" /><span class='highlight'>TC PRO</span>, an extracurricular educational initiative, where we seek to provide teenage girls with <span class='highlight'>programming and employability skills</span>.`,
            t3:`Alliance with the <span class='highlight text-light'>Instituto de Innovación y Transferencia Tecnológica</span>, impacting more than`,
            t4:`<span class='highlight text-light'>students </span><br class="d-md-none d-block" />at the Universidad Autónoma de Nuevo León.`,
            t5:`Launch of Technolochicas in <span class='highlight text-light'>5 cities in the United States,</span> benefiting`,
            t6:`<span class='highlight text-light'>girls</span>.`,
            t7:`We developed a <b>Mentor with Artificial Intelligence</b> through <b>Chat GPT</b> to accompany the girls who participate in our courses, and we <b>updated</b> the <b>course</b> content focusing on <b>AI</b>.`
        },
        es: {
            t1:`<span class='highlight'>mil mujeres</span> graduadas gracias a la alianza con el <span class='highlight'>Instituto de Educación de Aguascalientes.</span>`,
            t2:`<span class='highlight'>alumnas </span> realizaron <span class='highlight'>80 horas</span> prácticas y teóricas a través de <br class="d-none d-md-block d-lg-none" /><span class='highlight'>TC PRO,</span> iniciativa educativa extracurricular, donde buscamos dotar a las adolescentes con <span class='highlight'>habilidades digitales y de empleabilidad,</span> a través de la enseñanza de programación.`,
            t3:`Alianza con el <span class='highlight text-light'> Instituto de Innovación y Transferencia Tecnológica </span>  impactando más de`,
            t4:`<span class='highlight text-light'>estudiantes </span> <br class="d-md-none d-block" /> de la Universidad Autónoma de Nuevo León.`,
            t5:`Lanzamiento de Technolochicas en <span class='highlight text-light'>5 nuevas ciudades de Estados Unidos,</span> beneficiando a`,
            t6:`<span class='highlight text-light'>niñas</span>.`,
            t7:`Desarrollamos una <b>Mentora con Inteligencia Artificial</b> mediante <b>Chat GPT</b> para dar acompañamiento a las niñas que participan en nuestros cursos y <b>actualizamos</b> el contenido del <b>curso</b> enfocándolo en <b>IA</b>.`
        }
    }
    
    const txt_v = english ? textos.en : textos.es;

  return (
    <div>
        <div className="container-fluid m-0 p-0">
            <div className="row">
                <div className="col-lg-6 p-0 order-lg-1 order-2">
                    <img src={img1} alt="" className="w-100" />
                </div>
                <div className="col-lg-5 offset-lg-1 p-0 m-auto order-lg-2 order-1">
                    <p className="imp-par w-75 m-auto"><span className='highlight'><CountUp
                            enableScrollSpy={true}
                            start={0}
                            end={2}
                            duration={3}/></span> <span dangerouslySetInnerHTML={{ __html: txt_v.t1 }}></span></p>
                </div>
                <div className="col-lg-5 p-0 m-auto order-lg-2 order-3">
                    <p className="imp-par w-75 m-auto"><span className='highlight'><CountUp
                            enableScrollSpy={true}
                            start={'2700'}
                            end={2786}
                            duration={3}/></span> <span dangerouslySetInnerHTML={{ __html: txt_v.t2 }}></span></p>
                </div>
                <div className="col-lg-6 p-0 order-lg-4 order-4">
                    <img src={img2} alt="" className="w-100" />
                </div>
                <div className="col-12 p-0  order-lg-5 order-5">
                    <div className="row bg-team justify-content-around py-md-4 py-0">
                        <div className="col-md-6 align-self-end align-self-md-end order-last order-lg-first">
                            <div className="rect-gr">
                                <p className="imp-par-2 text-light">
                                <span dangerouslySetInnerHTML={{ __html: txt_v.t3 }}></span><span className='highlight text-light'> <CountUp
                            enableScrollSpy={true}
                            start={'1200'}
                            end={1700}
                            duration={3}/></span> <span dangerouslySetInnerHTML={{ __html: txt_v.t4 }}></span>
                                </p>
                            </div>
                        </div>
                        <div className="col-md-5 align-self-start align-self-md-start mt-md-5 order-first order-md-last">
                            <div className="rect-gr ">
                                <p className="imp-par-2  text-light">
                                    <span dangerouslySetInnerHTML={{ __html: txt_v.t5 }}></span> 
                                      <span className='highlight text-light'> <CountUp
                            enableScrollSpy={true}
                            start={'1000'}
                            end={1500}
                            duration={3}/></span> <span dangerouslySetInnerHTML={{ __html: txt_v.t6 }}></span></p>
                            </div>                            
                        </div>
                    </div>
                </div>
 
            </div>
        </div>
    </div>
  )
}
