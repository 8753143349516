import React from 'react'
import './Foundation.css'
import Footer from '../../componentes/Footer/Footer'
// import Headerf from '../../componentes/Foundation/Headerft/Headerf'
import Iniciativas from '../../componentes/Foundation/Iniciativas/Iniciativas'
import Tecnolochicas from '../../componentes/Foundation/Tecnolochicas/Tecnolochicas'
import HeaderSection from '../../componentes/HeaderSection/HeaderSection'
import hero from '../../assets/images/foundation/tchbckgrnd.png'

import item1 from '../../assets/images/items-footer/desastres-naturales.png'
import item2 from '../../assets/images/items-footer/nuestros-aliados.png'
import item3 from '../../assets/images/items-footer/reconocimientos.png'
import mobile from '../../assets/images/items-footer/desastres-naturales-mobile.png'

const itemsFoundation = [
  {
    image: item1,
    title: {
      en:'SUPPORT IN NATURAL DISASTERS',
      es:'Apoyo en DesastreS NATURALES',
    },
    link: '/desastres-naturales'
  },
  {
    image: item2,
    title: {
      en:'OUR ALLIES',
      es:'Nuestros Aliados',
    },
    link: '/nuestros-aliados'
  },
  {
    image: item3,
    title: {
      en:'ACKNOWLEDGEMENTS',
      es:'RECONOCIMIENTOS',
    },
    link: '/reconocimientos'
  }
];

const itemFoundationMobile = [
  {
    image: mobile,
    title: {
      en:'SUPPORT IN NATURAL DISASTERS',
      es:'Apoyo en DesastreS NATURALES',
    },
    link: '/desastres-naturales'
  }
]

export default function Foundation({version=false}) {
  const textos = {
    en: {
      t1:`TELEVISA FOUNDATION`
    },
    es: {
      t1:`TELEVISA FOUNDATION`
    }
  }
  const txt_v = version ? textos.en : textos.es;

  return (
    <div className='tf'>
        {/* <Headerf /> */}
        <HeaderSection
          image={hero}
          title={txt_v.t1}
          ajustemov='mov-40'
          english={version}
        />

        <Tecnolochicas english={version}/>
        <Footer 
          showLogo={false}
          items={itemsFoundation}
          itemMobile={itemFoundationMobile}
          english={version}
        />
    </div>
  )
}
