import React, {useState} from 'react'
import './NuestrasAcciones.css'
import chatBot from '../../../assets/images/igualdad/chatbot-web.png'
import chatBotMobile from '../../../assets/images/igualdad/chatbot-tablet.png'
import VideoPlayer from '../../../componentes/VideoPlayer/VideoPlayer'

export default function NuestrasAcciones() {

  const [showVideo, setShowVideo] = useState(false);
  const [showVideoMobile, setShowVideoMobile] = useState(false);

  return (
    <div className='NuestrasAcciones'>
      {/* mobile */}
      <div className='mobile-device'>
        <div className='bg-purple'>
          <div className='title'>
            NUESTRAS ACCIONES A FAVOR DE LA PREVENCIÓN DE LA VIOLENCIA DE GÉNERO
          </div>
          <div className='paragraph1'>
            <b>6,132 spots</b> en televisión impactaron a la audiencia a favor de las mujeres.
          </div>
          <div className='paragraph2'>
            <b>11,348 usuarios interactuaron con el chatbot de Hola Violetta</b> durante la transmisión de la telenovela <b>“Eternamente Amándonos”</b> y por la <b>campaña en televisión</b>, impactando <b>de manera positiva a los televidentes</b> que interactuaron con el <b>chatbot</b> obteniendo herramientas y consejos para crear relaciones sanas.
          </div>
          <div className='mt-3'>&nbsp;</div>
        </div>
        <div className='d-flex justify-content-center align-items-center px-5 pb-4 bg-rectangle'>
          {
            !showVideoMobile &&
            <img src={chatBotMobile} alt='chatbot' className='chatbot-img' onClick={() => setShowVideoMobile(true)}/>
          }
          {
            showVideoMobile &&
            <div className='w-100'>
              <VideoPlayer
                  src="https://www.youtube.com/watch?v=im3gsFcGgUY"
                  className="w-100"
                  playing={true}
              />
            </div>
          }
          
        </div>
      </div>

      {/* web */}
      <div className='web-device'>
        {/* <div className='row p-0 m-0'>
          <div className='col p-0 m-0'>
           <img src={chatBot} alt='chatbot' className='chatbot-img'/>
          </div>
          <div className='col m-0 pl-3 pr-3 bg-purple'>
            <div className='title'>
              NUESTRAS ACCIONES A FAVOR DE LA PREVENCIÓN DE LA VIOLENCIA DE GÉNERO
            </div>
            <div className='paragraph1'>
              <b>6,132 spots</b> en televisión impactaron a la audiencia a favor de las mujeres.
            </div>
            <div className='paragraph2'>
              <b>11,348 usuarios interactuaron con el chatbot de Hola Violetta</b> durante la transmisión de la telenovela <b>“Eternamente Amándonos”</b> y por la <b>campaña en televisión</b>, impactando <b>de manera positiva a los televidentes</b> que interactuaron con el <b>chatbot</b> obteniendo herramientas y consejos para crear relaciones sanas.
            </div>
            <div className='mt-3 mt-lg-2'>&nbsp;</div>
          </div>
        </div> */}
        <div className='bg-rectangle'>
          <div className='d-flex justify-content-center pt-4'>
            <div className='title'>
              Nuestras acciones a favor de la <br/> prevención de la violencia de género.
            </div>
          </div>
          <div className='row mt-5'>
            <div className='col-1'>&nbsp;</div>
            <div className='col-5 d-flex justify-content-center align-items-center'>
              <div className='px-4'>
                {
                  !showVideo &&
                  <img src={chatBot} alt='chatbot' className='chatbot-img w-100' onClick={() => setShowVideo(true)}/>
                }
                {
                  showVideo &&
                  <div className='chatbot-img border-0'>
                    <VideoPlayer
                        src="https://www.youtube.com/watch?v=im3gsFcGgUY"
                        className="w-100"
                        playing={true}
                    />
                  </div>
                }
                
              </div>
            </div>
            <div className='col-5 p-0 m-0'>
              <div className='paragraph1'>
                <b>6,132 spots</b> en televisión impactaron a la audiencia a favor de las mujeres.
                <br/><br/>
                <b>11,348 usuarios interactuaron con el chatbot de Hola Violetta</b> durante la transmisión de la telenovela <b>“Eternamente Amándonos”</b> y por la <b>campaña en televisión</b>, impactando <b>de manera positiva a los televidentes</b> que interactuaron con el <b>chatbot</b> obteniendo herramientas y consejos para crear relaciones sanas.
              </div>
            </div>
            <div className='col-1'>&nbsp;</div>
          </div>
          <div className='mt-5'>&nbsp;</div>
        </div>
      </div>
    </div>
  )
}
