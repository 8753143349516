import React from 'react'
import './Comunidad.css'
import img1 from '../../../../assets/images/programas/becalos/obj/S_SDG_PRINT-01.png'
import img2 from '../../../../assets/images/programas/becalos/obj/S-WEB-Goal-01 4.png'
import img3 from '../../../../assets/images/programas/becalos/obj/S-WEB-Goal-01 8.png'
import img4 from '../../../../assets/images/programas/becalos/obj/S-WEB-Goal-01 9.png'
import img5 from '../../../../assets/images/programas/becalos/obj/S_SDG_PRINT-10 1.png'
import img6 from '../../../../assets/images/programas/becalos/obj/S-WEB-Goal-01 7.png'
import CountUp from 'react-countup';


export default function Comunidad({english=false}) {
    const textos = {
        en: {
            t1:`DIGITAL <br /> COMMUNITIES:`,
            t2:``,
            t3:`<span class="highlight">people</span> make up our <span class="highlight">digital communities.</span>`,
            t4:`More than `,
            t5:`<span class="highlight">website entries</span> represent a community with greater access to education.`,
            t6:`UN Sustainable Development Goals`,
            t7:`<span class='bold'>Bécalos</span> is aligned with the 2030 Agenda to continue contributing to a fair access to education in our country. `
        },
        es:{
            t1:`comunidades <br /> digitales:`,
            t2:`Más de `,
            t3:`<span class="highlight">personas</span> conforman nuestras <span class="highlight">comunidades digitales.</span>`,
            t4:`Más de `,
            t5:`<span class="highlight">mil entradas al sitio web</span> representan una comunidad con mayor acceso a la educación.`,
            t6:`Objetivos de Desarrollo Sostenible de la ONU`,
            t7:`<span class='bold'>Bécalos</span> está alineado con la Agenda 2030 para seguir contribuyendo en nuestro país, un justo acceso a la educación.`
        }
    }

    const txt_v = english ? textos.en : textos.es;

  return (
    <div className='Comunidad'>
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-6 m-auto">
                    <h2 className="com-tit" dangerouslySetInnerHTML={{ __html: txt_v.t1 }}></h2>
                </div>
                <div className="col-lg-6 bg-com-l px-md-5">
                <ul className='comunidades'>
                <li className=''>
                    <p className="par-comunidad-digital">
                        <span dangerouslySetInnerHTML={{ __html: txt_v.t2 }}></span>
                        <span className="highlight"><CountUp
                        enableScrollSpy={true}
                        start={190000}
                        end={196287}
                        duration={2}
                    /></span> <span dangerouslySetInnerHTML={{ __html: txt_v.t3 }}></span></p>
                    </li>
                  <li className=''>
                    <p className="par-comunidad-digital ">
                        <span dangerouslySetInnerHTML={{ __html: txt_v.t4 }}></span>
                        <span className="highlight"><CountUp
                        enableScrollSpy={true}
                        start={0}
                        end={160}
                        duration={2}
                    /></span> <span dangerouslySetInnerHTML={{ __html: txt_v.t5 }}></span></p>
                    </li>
                    </ul>
                </div>
            </div>   
            <div className="row bg-objetivos">
                <div className='col-12 col-lg-6 m-0 p-0'>
                    <div className='d-flex justify-content-center'>
                        <div className='p-md-5 pt-5 pb-3 px-5'>
                            <h2 className="title-onu">{txt_v.t6}</h2>
                            <p className="paragraph-onu mt-4 mt-md-3" dangerouslySetInnerHTML={{ __html: txt_v.t7 }}></p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 py-xl-5 pb-3 text-center text-md-end">
                    <img src={img1} alt="" className="img-objetivos" />
                    <img src={img2} alt="" className="img-objetivos" />
                    <img src={img3} alt="" className="img-objetivos" />
                    <br className='d-xl-block d-none'/>
                    <img src={img4} alt="" className="img-objetivos" />
                    <img src={img5} alt="" className="img-objetivos" />
                    <img src={img6} alt="" className="img-objetivos" />
                </div>
            </div>
        </div>
    </div>
  )
}
