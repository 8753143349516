import React from 'react'
import './HeaderContenidos.css'
import Nav from '../../Nav/Nav'

export default function HeaderContenidos() {
  return (
    <div className="cntnds container-fluid ct-bg mshadow">
      <div className="row ">
        <Nav />
      </div>
    <div className="row justify-content-center ct-header">
        <div className="col-md-9 text-center align-self-end pb-5">
            <h2 className="ct-title">
                NUESTROS CONTENIDOS
            </h2>
        </div>
    </div>
  </div>
  )
}
