import React from 'react'
import'./Testimonios.css'
import Carousel from '../../../Carousel/Carousel'
import iconoTest from '../../../../assets/images/programas/golxmex/testimonios.svg'
import thumbnailGXMex1 from '../../../../assets/images/programas/golxmex/thumbnails/gxmex1.jpg'
import thumbnailGXMex2 from '../../../../assets/images/home/testimonios/thumbnails/gxmex.jpg'
import thumbnailGXMex3 from '../../../../assets/images/programas/golxmex/thumbnails/gxmex3.jpg'

const items = [
    {
        type: 'video',
        image: thumbnailGXMex1,
        videoUrl: 'https://www.youtube.com/watch?si=ZeAGI1gJAHO9S26u&v=6-ajuGmigDE&feature=youtu.be'
    },
    {
        type: 'video',
        image: thumbnailGXMex2,
        videoUrl: 'https://www.youtube.com/watch?v=Ad3Qz-kB194&feature=youtu.be'
    },
    {
        type: 'video',
        image: thumbnailGXMex3,
        videoUrl: 'https://www.youtube.com/watch?v=hQLEt99Vt9w'
    }
];
export default function Testimonios({english=false}) {

    const textos = {
        en: {
            t1:`READ THEIR TESTIMONIALS`,
        },
        es:{
            t1:`CONOCE SUS TESTIMONIOS`,
        }
    }

    const txt_v = english ? textos.en : textos.es;

  return (
    <div className='test-bg'>
        <div className="container  pb-5">
            <div className="row text-center">
                <img src={iconoTest} alt="" className="img-logo" />
                <h2 className="testimonios-title">
                {txt_v.t1}
                </h2>
            </div>
            <div className="row">
                <Carousel 
                items={items}/>
            </div>
        </div>
    </div>
  )
}
