import React from 'react'
import './Igualdad.css'
import HeaderIgualdad from '../../componentes/Igualdad/HeaderIgualdad/HeaderIgualdad'
import EnAccion from '../../componentes/Igualdad/EnAccion/EnAccion'
import Programs from '../../componentes/Igualdad/Programs/Programs'
import Footer from '../../componentes/Footer/Footer'
import NuestrasAcciones from '../../componentes/Igualdad/NuestrasAcciones/NuestrasAcciones'

export default function Igualdad() {
  return (
    <div className='Igualdad'>
      <HeaderIgualdad />

      <EnAccion />

      <Programs />

      <NuestrasAcciones />

      <div className='mt-4'>
        <Footer />
      </div>
    </div>
  )
}
