import React, { useState } from 'react'
import './Impacto.css'
import img1 from '../../../../assets/images/programas/temprano/Mamá Andrea Díaz con su hija María Stella 1.png'
import img2 from '../../../../assets/images/programas/temprano/Grabacion_UPN_CENDI_1_1.png'
import VideoPlayer from '../../../VideoPlayer/VideoPlayer';
import CountUp from 'react-countup';

export default function Impacto({english=false}) {

    const [showVideo, setShowVideo] = useState(false);

    const textos = {
        en: {
            t1:`<b><span class='highlight'>agents </span></b> trained in person thanks to the alliance with IMSS-Bienestar. <br /><br />We were able to consolidate <b><span class='highlight'>12 alliances</span></b> with civil organizations and federal and state authorities. <br /><br />We maintained our collaboration with <b><span class='highlight'>UNICEF</span></b> in the media and for the first time with <b><span class='highlight'>in-person training.</span>`,
            t2:`We trained <span class='highlight'>154 people</span> from <span class='highlight'>19 women's social readaptation centers</span> in Veracruz and Mexico City to ensure that they could enjoy every moment with their children in a <span class='highlight'>more positive and productive way.</span>`,
        },
        es:{
            t1:`<b><span class='highlight'>agentes</span></b> formados de manera presencial, gracias a la alianza con IMSS-Bienestar. <br /><br />Logramos consolidar <b><span class='highlight'>12 alianzas</span></b> con organizaciones civiles, autoridades federales y estatales. <br /><br />Mantuvimos nuestra colaboración con <b><span class='highlight'>UNICEF</span></b> en medios de comunicación y por primera vez con <b><span class='highlight'>formación presencial.</span>`,
            t2:`Capacitamos a <span class='highlight'>154 personas</span> de <span class='highlight'>19 centros de readaptación social de mujeres</span> en Veracruz y Ciudad de México, para asegurar que pudieran disfrutar cada momento con sus hijos de manera <span class='highlight'>más positiva y productiva.</span>`,
        }
    }
    
    const txt_v = english ? textos.en : textos.es;

  return (
    <div>
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-6 p-0 order-lg-first order-last">
                    <img src={img1} alt="" className="w-100" />
                </div>
                <div className="col-lg-6 p-0 m-auto">
                    <p className="imp-par w-75 m-auto pb-3 pt-md-5 ">
                        <b><span className='highlight'><CountUp
                            enableScrollSpy={true}
                            start={500}
                            end={543}
                            duration={4}/></span></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t1 }}></span>
                    
                        {/* <span className='highlight'><CountUp
                            enableScrollSpy={true}
                            start={1000}
                            end={1500}
                            duration={4}/> agentes</span> formados de manera presencial, gracias a la alianza con IMSS- Bienestar. </p>
                        <p className="imp-par w-75 m-auto">Logramos consolidar <span className='highlight'> <CountUp
                            enableScrollSpy={true}
                            start={0}
                            end={12}
                            duration={2}/> alianzas</span> con organizaciones civiles, autoridades federales y estatales, como parte de la estrategia multianual para favorecer cambios de forma sistémica. </p>
                        <p className="imp-par w-75 m-auto">Refrendamos nuestra colaboración con <span className='highlight'>UNICEF</span> en medios de comunicación y por primera vez con <span className='highlight'>formación presencial,</span> lo que beneficia <span className='highlight'>a la primera infancia en situación de vulnerabilidad.</span>  */}
                    </p>
                </div>
                </div>
                <div className="row">
                <div className="col-lg-6 pb-3 pb-md-0 pt-md-4 m-auto">
                    <p className='imp-par w-75 m-auto' dangerouslySetInnerHTML={{ __html: txt_v.t2 }}></p>
                    
                    {/* <p className="imp-par w-75 m-auto">Formamos a una dupla de personal especializado de
                    <span className='highlight'> <CountUp
                        enableScrollSpy={true}
                        start={10}
                        end={19}
                        duration={5}/> centros penitenciarios </span>   del estado de Veracruz y Ciudad de México con PRONIÑEZ A.C., equipo enfocado a mujeres privadas de su libertad que son madres de niñas o niños de 0 a 6 años. </p>
                    <p className="imp-par w-75 m-auto">Gracias a la <span className='highlight'>alianza multianual con Vroom</span>, de la Fundación de la Familia Bezos, Empieza Temprano ha logrado articular alianzas en 
                    <span className='highlight'> 10 estados</span> de la República Mexicana para impulsar el desarrollo de la primera infancia en el sector salud, educativo y social. </p> */}
                </div>
                <div className="col-lg-6 p-0">
                    {
                        !showVideo &&
                        <img src={img2} alt="" className="w-100 et-custom-rec" onClick={() => setShowVideo(true)}/>
                    }
                    {
                        showVideo &&
                        <VideoPlayer
                            src="https://www.youtube.com/watch?v=fMprlKGuMMA&feature=youtu.be"
                            className="w-100"
                            playing={true}
                        />
                    }
                </div>
            </div>
        </div>
    </div>
  )
}
