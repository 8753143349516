import React, { useCallback, useEffect, useRef, useState,useLayoutEffect } from 'react'
import './NuestrosProgramas.css'
import '../../../App.css';

import empiezaTempranoLogo from '../../../assets/images/programas/subsections/empieza-temprano.png'
import cuantrixLogo from '../../../assets/images/programas/subsections/cuantrix.png'
import valoresLogo from '../../../assets/images/aliados/logos/logo_valores 1.png'
import tecnochicasLogo from '../../../assets/images/aliados/logos/logo_tecnochicas 1.png'
import becalosLogo from '../../../assets/images/programas/subsections/becalos.png'

import posibleLogo from '../../../assets/images/aliados/logos/logo_posible 1.png'

import golxmexLogo from '../../../assets/images/aliados/logos/golxmex.png'

import fotograficaMxLogo from '../../../assets/images/aliados/logos/logo_fotografica 2.png'
import ProgramasValores from '../../../pages/Programas/ProgramasValores/ProgramasValores';
import Temprano from '../Temprano/Temprano';
import Tecnolochicas from '../Tecnolochicas/Tecnolochicas';
import Golxmex from '../Golxmex/Golxmex';
import FotograficaMx from '../../../pages/Programas/FotograficaMx/FotograficaMx';
import Cuantrix from '../Cuantrix/Cuantrix';
import Becalos from '../Becalos/Becalos';
import ProgramasPosible from '../../../pages/Programas/ProgramasPosible/ProgramasPosible'

import backButton from '../../../assets/images/programas/back-button.png'
import { Button } from '../../../componentes/Button/Button'
import Footer from '../../Footer/Footer';

import item1 from '../../../assets/images/items-footer/comunicacion-con-impacto.png'
import item2 from '../../../assets/images/items-footer/nuestras-alas-del-club-america.png'
import item3 from '../../../assets/images/items-footer/televisa-foundation.png'
import mobile from '../../../assets/images/items-footer/comunicacion-con-impacto-mobile.png'

const programasEducacion = [
  {
    logo: empiezaTempranoLogo,
    text:{
      en:`We work to promote the <b>integral development of children</b> from early childhood. `,
      es:`Trabajamos en promover el <b>desarrollo integral de los niños</b> desde la primera infancia.`
    },
    sectionName: 'empieza-temprano',
    className: 'cursor-pointer program-button-conoce-mas-blue'
  },
  {
    logo: cuantrixLogo,
    text:{
      en:`We generate <b>equal opportunities</b> through Computer Science for children and young people to learn to <b>program</b>. `,
      es:`Generamos <b>equidad de oportunidades</b> a través de las Ciencias de la Computación para que niños y jóvenes aprendan a <b>programar</b>.`
    },
    sectionName: 'cuantrix',
    className: 'cursor-pointer program-button-conoce-mas-blue'
  },
  {
    logo: valoresLogo,
    text:{
      en:`We promote values as a foundation for a <b>better coexistence</b>.`,
      es:`Promovemos los valores como cimiento para una <b>mejor convivencia.</b>`
    },
    sectionName: 'valores',
    className: 'cursor-pointer program-button-conoce-mas-blue'
  },
  {
    logo: tecnochicasLogo,
    text:{
      en:`We encourage <b>girls and young women</b> to study a <b>STEM</b>-related career.`,
      es:`Impulsamos a <b>niñas y mujeres jóvenes</b> para estudiar una carrera relacionada con <b>STEM.</b>`
    },
    sectionName: 'tecnolochicas',
    className: 'cursor-pointer program-button-conoce-mas-blue'
  },
  {
    logo: becalosLogo,
    text:{
      en:`We work so that the greatest number of children, young people and teachers in Mexico have <b>quality education</b>.`,
      es:`Trabajamos para que el mayor número de niños, jóvenes y docentes en México cuenten con <b>educación de calidad</b>.`
    },
    sectionName: 'becalos',
    className: 'cursor-pointer program-button-conoce-mas-blue'
  }
];

const programasEmprendimiento = [
  {
    logo: posibleLogo,
    text:{
      en:`<b>The most far-reaching entrepreneurship</b> program in Mexico.`,
      es:`El programa de <b>emprendimiento de mayor alcance</b> en México.`
    },
    sectionName: 'posible',
    className: 'cursor-pointer program-button-conoce-mas-yellow'
  }
];

const programasSalud = [
  {
    logo: golxmexLogo,
    text:{
      en:`We transform the passion for soccer into passion for <b>education, equality, nutrition, health, housing, and the environment</b>.`,
      es:`Transformamos la pasión por el futbol en pasión a favor de <b>la educación, la igualdad, la nutrición, la salud, la vivienda y el medioambiente.</b>`
    },
    sectionName: 'golmex',
    className: 'cursor-pointer program-button-conoce-mas-orange'
  }
];

const programasCultura = [
  {
    logo: fotograficaMxLogo,
    text:{
      en:`<b>We promote and disseminate</b> the visual richness of our country through our audiovisual and photographic archive`,
      es:`<b>Promovemos y difundimos</b> la riqueza visual de nuestro país, a través de nuestro acervo audiovisual y fotográfico.`
    },
    sectionName: 'fotograficamx',
    className: 'cursor-pointer program-button-conoce-mas-purple'
  }
];

const itemsProgramas = [
  {
    image: item1,
    title: {
      en:'COMMUNICATION EFFORTS WITH IMPACT',
      es:'ESFUERZOS DE COMUNICACIÓN CON IMPACTO',
    },
    link: '/comunicacion'
  },
  {
    image: item2,
    title: {
      en:'NUESTRAS ALAS DEL CLUB AMÉRICA',
      es:'NUESTRAS ALAS DEL CLUB AMÉRICA',
    },
    link: '/nuestras-alas'
  },
  {
    image: item3,
    title: {
      en:'TELEVISA FOUNDATION',
      es:'TELEVISA FOUNDATION',
    },
    link: '/televisa-foundation'
  }
];

const itemProgramasMobile = [
  {
    image: mobile,
    title: {
      en:'COMMUNICATION EFFORTS WITH IMPACT',
      es:'ESFUERZOS DE COMUNICACIÓN CON IMPACTO',
    },
    link: '/comunicacion'
  }
]

export default function NuestrosProgramas(
  {onSectionSelected, english=false}
) {

  const [programSelected, setProgramSelected] = useState('educacion');
  const [programsList, setProgramsList] = useState([]);
  const [first, setFirst] = useState(true);
  const [showProgramDetails, setShowProgramDetails] = useState(false);
  const [sectionSelected, setSectionSelected] = useState('');

  const theTop = useRef(null)


  const handleProgramSelected = useCallback((value) => {
    setShowProgramDetails(false);
    setProgramSelected(value);
    if (value === 'educacion') {
      setProgramsList(programasEducacion);
    }
    if (value === 'emprendimiento') {
      setProgramsList(programasEmprendimiento);
    }
    if (value === 'salud') {
      setProgramsList(programasSalud);
    }
    if (value === 'cultura') {
      setProgramsList(programasCultura);
    }
  }, []);

  const handleSectionSelected = useCallback((section) => {
    if (section === 'golmex') {
      handleProgramSelected('salud');
    }
    if (section === 'fotograficamx') {
      handleProgramSelected('cultura');
    }
    if (section === 'posible') {
      handleProgramSelected('emprendimiento');
    }
    setShowProgramDetails(true);
    setSectionSelected(section);
    scrollToTop();
  }, [handleProgramSelected]);

  const scrollToTop = () => {
    window.scrollTo({
      top: theTop.current.offsetTop,
      behavior: "smooth",
     });
    };

  const handleCloseProgram = useCallback(() => {
    setShowProgramDetails(false);
    scrollToTop();
  }, []);
    
 
  useEffect(() => {
    // Get the current URL
    const url = window.location.href;

    // Extract the value of the 'group' parameter
    const params = new URLSearchParams(url.split('?')[1]);
    const groupParam = params.get('group');

    // Set the value of 'group' state variable
    if (groupParam) {
      handleProgramSelected(groupParam);
      scrollToTop();
    } 
    if (first) {
      setFirst(false);
      handleProgramSelected('educacion');
    }
  }, [first, handleProgramSelected]);

  const stickymenu = useRef();
  useLayoutEffect(() => {
    console.log(stickymenu);
    const divAnimate = stickymenu.current.getBoundingClientRect().top;
    console.log(divAnimate);
    const onScroll = () => {
      if (divAnimate < window.scrollY && isDesktop()) {

        stickymenu.current.style.position = "fixed";
        stickymenu.current.style.top = 0;
        stickymenu.current.style.left = 0;
      } else {
        stickymenu.current.style.position = "relative";
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  const isDesktop = () => {
    return window.matchMedia("(min-width: 1280px)").matches;
  };

  const textos = {
    en: {
      t1:`WE FOCUS ON FOUR MAIN <br/>LINES OF ACTION:`,
      categoria:{
        edu:{
          titulo:`Education`,
          desc:`We firmly believe that the key to promoting equitable opportunities lies in investing in quality education for all, which is why we have created programs ranging from early childhood to continuing education initiatives for young people and teachers. `
        },
        empr:{
          titulo:`Entrepreneurship`,
          desc:`We recognize the importance of fostering entrepreneurship for the country's growth and are dedicated to boosting the potential of entrepreneurs.`
        },
        nutri:{
          titulo:`Nutrition, health, and housing`,
          desc:`We work to improve the quality of life of the most vulnerable people and enhance the development of each of them.`
        },
        cult:{
          titulo:`Photographic and audiovisual culture`,
          desc:`Through Fundación Televisa's historical archive, we seek to preserve the historical memory of our country.`
        }
      }
    },
    es: {
      t1:`Nos enfocamos en cuatro <br /> ejes de acción:`,
      categoria:{
        edu:{
          titulo:`Educación`,
          desc:`Creemos firmemente que la clave para fomentar <b>oportunidades equitativas</b> radica en la inversión de educación de calidad para todos, por lo que hemos creado programas que van <b>desde la primera infancia hasta iniciativas de formación continua para jóvenes y docentes.</b>`
        },
        empr:{
          titulo:`Emprendimiento`,
          desc:`Reconocemos la importancia de <b>fomentar el emprendimiento</b> para el crecimiento del país y nos dedicamos a <b>impulsar el potencial</b> de los emprendedores.`
        },
        nutri:{
          titulo:`Nutrición, salud y vivienda`,
          desc:`Trabajamos para mejorar la <b>calidad de vida de las personas más vulnerables y potenciar el desarrollo</b> de cada una de ellas.`
        },
        cult:{
          titulo:`Cultura fotográfica y audiovisual`,
          desc:`A través de la Colección y Archivo histórico de Fundación Televisa, <b>buscamos preservar la memoria histórica</b> de nuestro país.`
        }
      }
    }
  }

  const txt_v = english ? textos.en : textos.es;

  return (
    <div className='NuestrosProgramas'>

      <div className='d-flex justify-content-center align-items-center h-100' ref={theTop}>
          {/* <div className='subtitle-programas py-5'>
            En <b>Fundación Televisa</b>, durante <b>23 años</b>, a través de nuestros <b>programas e iniciativas</b>, hemos apoyado la vida de <b>más de 15 millones de personas</b>. Siempre de la mano de <b>importantes aliados</b>, estamos transformando vidas para construir un México mejor con oportunidades para todos.
          </div> */}
          <div className='title-programas w-75 px-md-4 py-5' dangerouslySetInnerHTML={{ __html: txt_v.t1 }}></div>
        </div>

<div  
  className=' position-relative'  
  // ref={theTop}
>
  <div  ref={stickymenu} className='w-100 z-10'>
        <div className='row m-0 p-0 '  >
          <div 
            className='col-12 col-md-6 col-lg-3 m-0 p-0 bg-programa1 cursor-pointer p-4 p-md-0' 
            onClick={() => handleProgramSelected('educacion')}
          >
            <div className='d-flex flex-column justify-content-center align-items-center h-100 p-md-5 p-3 p-lg-4'>
              <div className={programSelected === 'educacion' ? 'programa-tab-name programa-tab-name-active' : 'programa-tab-name'}>
                {txt_v.categoria.edu.titulo}
              </div>
              {
                programSelected === 'educacion' &&
                <div className='programa-active'>&nbsp;</div>
              }
            </div>
          </div>
          <div 
            className='col-12 col-md-6 col-lg-3 m-0 p-0 bg-programa2 cursor-pointer p-4 p-md-0' 
            onClick={() => handleProgramSelected('emprendimiento')}
          >
            <div className='d-flex flex-column justify-content-center align-items-center h-100 p-md-5 p-3 p-lg-4'>
              <div className={programSelected === 'emprendimiento' ? 'programa-tab-name2 programa-tab-name-active' : 'programa-tab-name2'}>
              {txt_v.categoria.empr.titulo}
              </div>
              {
                programSelected === 'emprendimiento' &&
                <div className='programa-active'>&nbsp;</div>
              }
            </div>
          </div>
          <div 
            className='col-12 col-md-6 col-lg-3 m-0 p-0 bg-programa3 cursor-pointer p-4 p-md-0' 
            onClick={() => handleProgramSelected('salud')}
          >
            <div className='d-flex flex-column justify-content-center align-items-center h-100 p-md-5 p-3 p-lg-4'>
              <div className={programSelected === 'salud' ? 'programa-tab-name3 programa-tab-name-active' : 'programa-tab-name3'} >
              {txt_v.categoria.nutri.titulo}
              </div>
              {
                programSelected === 'salud' &&
                <div className='programa-active'>&nbsp;</div>
              }
            </div>
          </div>
          <div
            className='col-12 col-md-6 col-lg-3 m-0 p-0 bg-programa4 cursor-pointer p-4 p-md-0' 
            onClick={() => handleProgramSelected('cultura')}
          >
            <div className='d-flex flex-column justify-content-center align-items-center h-100 p-md-5 p-3 p-lg-4'>
              <div className={programSelected === 'cultura' ? 'programa-tab-name4 programa-tab-name-active' : 'programa-tab-name4'} >
              {txt_v.categoria.cult.titulo}
              </div>
              {
                programSelected === 'cultura' &&
                <div className='programa-active'>&nbsp;</div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
        {
          programSelected === 'educacion' &&
          <div className='bg-programa1 d-flex justify-content-center'>
            <div className='w-75 text-center'>
              <div className='pt-5 tab-programa-title'>
                {txt_v.categoria.edu.titulo}
              </div>
              <div className='pt-4 pb-5 tab-programa-description' dangerouslySetInnerHTML={{ __html: txt_v.categoria.edu.desc }}></div>
            </div>
          </div>
        }

        {
          programSelected === 'emprendimiento' &&
          <div className='bg-programa2 d-flex justify-content-center'>
            <div className='w-75 text-center'>
              <div className='pt-5 tab-programa-title'>
              {txt_v.categoria.empr.titulo}
              </div>
              <div className='pt-4 pb-5 tab-programa-description' dangerouslySetInnerHTML={{ __html: txt_v.categoria.empr.desc }}></div>
            </div>
          </div>
        }

        {
          programSelected === 'salud' &&
          <div className='bg-programa3 d-flex justify-content-center'>
            <div className='w-75 text-center'>
              <div className='pt-5 tab-programa-title'>
                {txt_v.categoria.nutri.titulo}
              </div>
              <div className='pt-4 pb-5 tab-programa-description' dangerouslySetInnerHTML={{ __html: txt_v.categoria.nutri.desc }}></div>
            </div>
          </div>
        }

        {
          programSelected === 'cultura' &&
          <div className='bg-programa4 d-flex justify-content-center'>
            <div className='w-75 text-center'>
              <div className='pt-5 tab-programa-title'>
                {txt_v.categoria.cult.titulo}
              </div>
              <div className='pt-4 pb-5 tab-programa-description' dangerouslySetInnerHTML={{ __html: txt_v.categoria.cult.desc }}></div>
            </div>
          </div>
        }

        {/* <div className='m-0 p-0' >&nbsp;</div> */}

        {
          !showProgramDetails &&
          <div className='tab-program-details'>

            {
              programSelected !== '' &&
              <div className='bg-programs-list'>
                {
                  programsList.map((program, index) => (
                    <div className='row m-0 p-0 pt-4 pt-md-0' key={index}>
                      <div className='col-12 col-lg-6 pt-md-5 py-lg-5 pb-4 pt-0 d-flex justify-content-center align-items-center'>
                        <img src={program.logo} alt={program.sectionName} id={program.sectionName}  className='programa-logo'/>
                      </div>
                      <div className='col-12 col-lg-6 px-0 pb-md-4 px-md-5 py-0 px-lg-0 py-lg-5 d-flex justify-content-start align-items-center'>
                        <div>
                          <div className='program-text-description px-2 py-0 px-md-5 py-md-0 px-lg-4 py-lg-0' dangerouslySetInnerHTML={{ __html: english ? program.text.en : program.text.es }}></div>
                          <div className='pt-0 pt-lg-2'>&nbsp;</div>
                          <div className='d-flex justify-content-center justify-content-lg-start'>
                            {/* <div 
                              className={program.className}
                              onClick={() => handleSectionSelected(program.sectionName)}
                            >
                              CONOCE MÁS
                            </div> */}
                            {
                              programSelected === 'educacion' &&
                              <Button
                                link='#'
                                english={english}
                                btnClass='conoce-mas-blue'
                                onClick={() => handleSectionSelected(program.sectionName)}
                              />
                            }
                            {
                              programSelected === 'emprendimiento' &&
                              <Button
                                link='#'
                                english={english}
                                btnClass='conoce-mas-brown'
                                onClick={() => handleSectionSelected(program.sectionName)}
                              />
                            }
                            {
                              programSelected === 'salud' &&
                              <Button
                                link='#'
                                english={english}
                                btnClass='conoce-mas-orange'
                                onClick={() => handleSectionSelected(program.sectionName)}
                              />
                            }
                            {
                              programSelected === 'cultura' &&
                              <Button
                                link='#'
                                english={english}
                                btnClass='conoce-mas-purple-programa'
                                onClick={() => handleSectionSelected(program.sectionName)}
                              />
                            }
                          </div>
                        </div>
                      </div>
                      <div className='pt-5 pt-lg-0'>&nbsp;</div>
                    </div>
                  ) )
                }
              </div>
            }
          </div>
        }


        {
          showProgramDetails &&
          <div className='ProgramasGlobal'>
            <div id="program-details" className='container-float-button'>
              <div className='floating-button m-0 p-0'>
                <img src={backButton} alt='back' className='back-button' onClick={handleCloseProgram}/>
              </div>
              {
                sectionSelected === 'becalos' &&
                <Becalos 
                  handleSectionSelected={handleSectionSelected}
                  englishp={english}
                />
              }

              {
                sectionSelected === 'cuantrix' &&
                <Cuantrix 
                  handleSectionSelected={handleSectionSelected}
                  englishp={english}
                />
              }

              {
                sectionSelected === 'fotograficamx' &&
                <FotograficaMx 
                  handleSectionSelected={handleSectionSelected}
                  englishp={english}
                />
              }

              {
                sectionSelected === 'posible' &&
                <ProgramasPosible 
                  handleSectionSelected={handleSectionSelected}
                  englishp={english}
                />
              }

              {
                sectionSelected === 'golmex' &&
                <Golxmex 
                  handleSectionSelected={handleSectionSelected}
                  englishp={english}
                />
              }

              {
                sectionSelected === 'tecnolochicas' &&
                <Tecnolochicas 
                  handleSectionSelected={handleSectionSelected}
                  englishp={english}
                />
              }
              {
                sectionSelected === 'empieza-temprano' &&
                <Temprano 
                  handleSectionSelected={handleSectionSelected}
                  englishp={english}
                />
              }
              {
                sectionSelected === 'valores' &&
                <ProgramasValores 
                  handleSectionSelected={handleSectionSelected}
                  englishp={english}
                />
              }

            </div>
          </div>
        }

        {
          sectionSelected === '' &&
          <Footer
            showLogo={false}
            items={itemsProgramas}
            itemMobile={itemProgramasMobile}
            english={english}
          />
        }

    </div>
  )
}
