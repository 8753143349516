import React from 'react'
import '../Aliados.css'
import logo from '../../../assets/images/aliados/logos/logo_valores 1.png'
import CountUp from 'react-countup'
export default function Valores({english=false}) {
    const textos = {
        en: {
          t1:`Thanks to`,
          t2:`Grupo Bimbo's social responsibility area and its team.`,
          t3:`Our allies in the Valores program in 2023 were:`,
          t4:`Grupo Bimbo, Fundación en Movimiento, Educación Millennial, Generation México, Papá Alfa, Aserrín Aserrán.`
        },
        es: {
          t1:`Gracias al`,
          t2:`área de responsabilidad social de Grupo Bimbo y su equipo.`,
          t3:`Nuestros aliados del programa de Valores en 2023 fueron:`,
          t4:`Grupo Bimbo, Fundación en Movimiento, Educación Millennial, Generation México, Papá Alfa, Aserrín Aserrán.`,
        },
      }
    const txt_v = english ? textos.en : textos.es;
  return (
    <div>
        <div className="container-fluid">
            <div className="row">
                <div className="col-6 m-auto pt-5 pt-lg-0 pb-md-3 pb-0 text-center">
                    <img src={logo} alt="Becalos" className="img-fluid  w-100 px-lg-5 " />
                </div>
                <div className="col-lg-6 bg-color text-start order-lg-first pt-lg-5 pt-md-0 pt-4">
                    <h2 className="h-prog">{txt_v.t1}</h2> 
                    <p className="par">{txt_v.t2}</p>
                </div>
            </div>
            <div className="row bg-aliados justify-content-center pb-5">
                <h2 className="sub-title mt-5">{txt_v.t3}</h2>
                <div className="col-lg-10 col-11 text-center">
                    <p className="par-aliados">{txt_v.t4}</p>
                </div>
            </div>
        </div>
    </div>
  )
}
