import React from 'react'
import '../Aliados.css'
import logo from '../../../assets/images/aliados/logos/logo_tecnochicas 1.png'
import CountUp from 'react-countup'
export default function Tecnolochicas({english=false}) {
    const textos = {
        en: {
          t1:`We would like to thank the`,
          t2:`33 companies, organizations, educational institutions, and government allies in the program.`,
          t3:`Our allies in the Tecnolochicas program in 2023 were:`,
          t4:`Microsoft, izzi, Sura, Accenture, IFOSYS, Santander,  Instituto de Educación de Aguascalientes, Universidad Autónoma del Estado de México, ICATI, Autoridad Educativa Federal de la Ciudad de México, Colegio Santa Fe, ITAM, Universidad Iberoamericana Ciudad de México, Bosh, 3M, Oracle, Centro, Google con I am Remarkable, I2T2, Centros Comunitarios, CONALEP, Nueva Ruta, Universidad Autónoma de Nuevo León, Ministry of Education in Nuevo Leon, Universidad Autónoma de Campeche, Ministry of Education in Campeche, Ministry of Education in Sonora, Universidad Tecnológica Metropolitana de Yucatán, Ministry of Economy in Chiapas, ANAHUAC Oaxaca, Instituto Queretano de la Mujer, Universidad Tecnológica del Estado del Sur de México, Tecnológico de estudios superiores de Ecatepec, UNAM Facultad de Química e Ingeniería.`,
        },
        es: {
          t1:`Agradecemos a las`,
          t2:`33 empresas, organizaciones, instituciones educativas y de gobierno.`,
          t3:`Nuestros aliados del programa Tecnolochicas en 2023 fueron:`,
          t4:`Microsoft, izzi, Sura, Accenture, IFOSYS, Santander, Instituto de Educación de Aguascalientes, Universidad Autónoma del Estado de México, ICATI, Autoridad Educativa Federal de la Ciudad de México, Colegio Santa Fe, ITAM, Universidad Iberoamericana Ciudad de México, Bosh, 3M, Oracle, Centro, Google con I am Remakable, I2T2, Centros Comunitarios, CONALEP, Nueva Ruta, Universidad Autónoma de Nuevo León, Secretaría de Educación de Nuevo León, Universidad Autónoma de Campeche, Secretaría de Educación de Campeche, Secretaría de Educación de Sonora, Universidad Tecnológica Metropolitana de Yucatán, Secretaría de Economía de Chiapas, ANAHUAC Oaxaca, Instituto Queretano de la Mujer, Universidad Tecnológica del Estado del Sur de México, Tecnológico de estudios superiores de Ecatepec, UNAM Facultad de Química e Ingeniería.`,
        },
      }
    const txt_v = english ? textos.en : textos.es;

  return (
    <div>
        <div className="container-fluid">
            <div className="row">
                    <div className="col-lg-6 col-7 m-auto py-4 text-center">
                        <img src={logo} alt="Becalos" className="img-fluid w-75 px-5 px-md-0" />
                    </div>
                    <div className="bg-color col-lg-6 text-start ">
                        <h3 className="h-prog">{txt_v.t1}</h3>
                        <p className="par">{txt_v.t2}</p>
                    </div>
                </div>
                <div className="row bg-aliados justify-content-center py-5">
                    <h2 className="sub-title my-lg-5  mb-md-3">{txt_v.t3}</h2>
                    <div className="col-lg-9 text-start col-11">
                    <p className="par-aliados pe-xl-3">{txt_v.t4}</p>
                </div>
            </div>
        </div>
    </div>
  )
}
