import React, { useState } from 'react'
import './Nav.css'
import logo from '../../assets/images/logo.png'
import menuIcon from '../../assets/images/menu_icon.png'
import { Link } from 'react-router-dom';

export default function Nav({ver=false}) {

  const [toggleMenu, setToggleMenu] = useState(false);

  const textos = {
    en: {
      url:`/en`,
      cerrar:'CLOSE',
      home:'HOME',
      programa:'OUR PROGRAMS',
      edu:'Education',
      emp:'Entrepreneurship',
      nut:'Nutrition, health, and housing',
      phot:'Photographic and audiovisual culture',
      comun:'COMMUNICATION EFFORTS WITH IMPACT',
      alas:'NUESTRAS ALAS DEL CLUB AMERICA',
      telf:'TELEVISA FOUNDATION',
      sup:'SUPPORT IN NATURAL DISASTERS',
      allies:'OUR ALLIES',
      know:'ACKNOWLEDGEMENTS'
    },
    es:{
      url:``,
      cerrar:'CERRAR',
      home:'Inicio',
      programa:'Nuestros Programas',
      edu:'Educación',
      emp:'Emprendimiento',
      nut:'Nutrición, salud y vivienda',
      phot:'Cultura fotográfica y audiovisual',
      comun:'Esfuerzos de Comunicación con Impacto',
      alas:'Nuestras Alas del Club América',
      telf:'Televisa Foundation',
      sup:'Apoyo en Desastres Naturales',
      allies:'Nuestros Aliados',
      know:'Reconocimientos'
    }
  };

  const txt_v = ver ? textos.en : textos.es;
  return (
    <div className='Nav'>
      {/* mobile  */}
      <div className='mobile-device'>
        <div className='d-flex align-items-center h-100'>
          <div className='logo-container'>
            <a href={process.env.PUBLIC_URL+txt_v.url} alt="inicio">
              <img src={logo} alt='Logo Fundación Televisa' className='logo' />
            </a>
          </div>
          <div className='menu-icon-container'>
            <img
              src={menuIcon}
              alt='Menu icon'
              className='menu-icon'
              onClick={() => setToggleMenu(true)}
            />
            <div className='lang-text'>
              <Link to="/en" className='ver'>ENG</Link>
              <div>&nbsp;|&nbsp;</div>
              <Link to="/" className='ver'>ESP</Link>
            </div>
          </div>
        </div>
      </div>

      {/* web */}

      <div className='web-device'>
        <div className='d-flex justify-content-between align-items-center h-100'>
          <div>
            <a href={process.env.PUBLIC_URL+txt_v.url} alt="Inicio">
              <img src={logo} alt='Logo Fundación Televisa' className='logo' />
            </a>
          </div>
          <div className='d-flex align-items-center h-100'>
            {
              !toggleMenu &&
              <div>
                <img
                  src={menuIcon}
                  alt='Menu icon'
                  className='menu-icon'
                  onClick={() => setToggleMenu(true)}
                />
                <div className='lang-text'>
                  <Link to="/en" className='ver'>ENG</Link>
                  <div>&nbsp;|&nbsp;</div>
                  <Link to="/" className='ver'>ESP</Link>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
      
      {
        toggleMenu &&
          <div className='menu-container w-100'>
            <div className='d-flex justify-content-end pt-5 pe-4 pe-lg-0'>
              <div
                className='close-icon px-2 py-2 px-lg-5 py-lg-4'
                onClick={() => setToggleMenu(false)}
              >
                <div className='mobile-device'>
                  <div className='close-icon-text'>
                    X
                  </div>
                </div>
                <div className='web-device'>
                  <div className='close-icon-text'>
                    {txt_v.cerrar}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <ul className="nav flex-column gap-custom">
                <li className="nav-item">
                  <a className="nav-link active d-flex align-items-center h-100" href={process.env.PUBLIC_URL+txt_v.url}>
                    <div className='bullet'>&nbsp;</div>
                    <div className='ps-2'>
                      {txt_v.home}
                    </div>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link d-flex align-items-center h-100" href={process.env.PUBLIC_URL+txt_v.url + "/programas"}>
                    <div className='bullet'>&nbsp;</div>
                    <div className='ps-2'>
                      {txt_v.programa}
                    </div>
                  </a>
                  <ul className='ps-5 list-group list-unstyled'>
                    <li className="nav-item">
                      <a className="nav-link d-flex align-items-center h-100" href={process.env.PUBLIC_URL+txt_v.url + "/programas?group=educacion"}>
                        <div className='bullet-inner'>&nbsp;</div>
                        <div className='ps-2 text-normal'>
                          {txt_v.edu}
                        </div>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link d-flex align-items-center h-100" href={process.env.PUBLIC_URL+txt_v.url + "/programas?group=emprendimiento"}>
                        <div className='bullet-inner'>&nbsp;</div>
                        <div className='ps-2 text-normal'>
                          {txt_v.emp}
                        </div>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link d-flex align-items-center h-100" href={process.env.PUBLIC_URL+txt_v.url + "/programas?group=salud"}>
                        <div className='bullet-inner'>&nbsp;</div>
                        <div className='ps-2 text-normal'>
                          {txt_v.nut}
                        </div>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link d-flex align-items-center h-100" href={process.env.PUBLIC_URL+txt_v.url + "/programas?group=cultura"}>
                        <div className='bullet-inner'>&nbsp;</div>
                        <div className='ps-2 text-normal'>
                          {txt_v.phot}
                        </div>
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <a className="nav-link d-flex align-items-center h-100" href={process.env.PUBLIC_URL+txt_v.url + "/comunicacion"}>
                    <div className='bullet'>&nbsp;</div>
                    <div className='ps-2'>
                      {txt_v.comun}
                    </div>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link d-flex align-items-center h-100" href={process.env.PUBLIC_URL+txt_v.url + "/nuestras-alas"}>
                    <div className='bullet'>&nbsp;</div>
                    <div className='ps-2'>
                      {txt_v.alas}
                    </div>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link d-flex align-items-center h-100" href={process.env.PUBLIC_URL+txt_v.url + "/televisa-foundation"}>
                    <div className='bullet'>&nbsp;</div>
                    <div className='ps-2'>
                      {txt_v.telf}
                    </div>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link d-flex align-items-center h-100" href={process.env.PUBLIC_URL+txt_v.url + "/desastres-naturales"}>
                    <div className='bullet'>&nbsp;</div>
                    <div className='ps-2'>
                      {txt_v.sup}
                    </div>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link d-flex align-items-center h-100" href={process.env.PUBLIC_URL+txt_v.url + "/nuestros-aliados"}>
                    <div className='bullet'>&nbsp;</div>
                    <div className='ps-2'>
                      {txt_v.allies}
                    </div>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link d-flex align-items-center h-100" href={process.env.PUBLIC_URL+txt_v.url + "/reconocimientos"}>
                    <div className='bullet'>&nbsp;</div>
                    <div className='ps-2'>
                      {txt_v.know}
                    </div>
                  </a>
                </li>
              </ul>
            </div>

          </div>
      }
    </div>
  )
}
