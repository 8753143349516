import React from 'react'
import './golxmex.css'
import Futbol from './Futbol/Futbol'
import Iniciativas from './Iniciativas/Iniciativas'
import Impacto from './Impacto/Impacto'
import Comunidades from './Comunidades/Comunidades'
import Progredes from '../Progredes/Progredes'
import fb from '../../../assets/images/iconos/fb.svg'
import ig from '../../../assets/images/iconos/ig.svg'
import Testimonios from './Testimonios/Testimonios'
// import Footer from '../../Footer/Footer'
import FooterProgramas from '../FooterProgramas/FooterProgramas'
import arrowsFotograficamx from '../../../assets/images/programas/footer/arrows-fotograficamx.png'

const social= [
  {
      link:'https://www.facebook.com/golpormexico',
      icon:fb,
      nombre:'golpormexico'
  },
  {
      link:'https://www.instagram.com/golpormexico',
      icon:ig,
      nombre:'golpormexico'
  }
]
export default function Golxmex(
  {handleSectionSelected,englishp=false}
) {
  return (
    <div className='golxmex'>
        <Futbol english={englishp}/>
        <Iniciativas english={englishp}/>
        <Impacto english={englishp}/>
        <Testimonios english={englishp}/>
        <Comunidades english={englishp}/>
        <Progredes 
          sitio = 'https://golpormexico.org/'
          redes = {social}
          english={englishp}
        />

        {/* <Footer /> */}
        <FooterProgramas 
          background='bg-programa-fotograficamx'
          arrows={arrowsFotograficamx}
          title='FOTOGRÁFICAMX'
          color='#AD6DC9'
          bgImage='bg-program-image-fotograficamx'
          handleSectionSelected={handleSectionSelected}
          programSection='fotograficamx'
          english={englishp}
        />
    </div>
  )
}
