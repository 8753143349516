import React from 'react'
import './Impacto.css'
import img1 from '../../../../assets/images/programas/cuantrix/TestimonialesCuantrix_1 (1) 1.png'
import img2 from '../../../../assets/images/programas/cuantrix/PresentacionCuantrix.png'
import CountUp from 'react-countup'

export default function Impacto({english=false}) {
    const textos = {
        en: {
            t1:`<span class='highlight'>teachers <br class="d-md-none d-block" /></span> trained from <span class='highlight'> 835 schools</span> in <span class='highlight'>6 Mexican states.</span>`,
            t2:`<span class='highlight text-light'>users </span>certified by <span class='highlight text-light'> Compu Cuantrix.</span>`,
            t3:`<span class='highlight text-light'>downloads </span> generated in <span class='highlight text-light'>Compu Cuantrix.</span>`,
            t4:`students <span class='highlight'>benefited</span> with <span class='highlight'>800 computers</span> in <span class='highlight'>220 elementary schools</span> in Mexico City and Orizaba, Veracruz.`
        },
        es: {
            t1:`<span class='highlight'>docentes <br class="d-md-none d-block" /></span> capacitados pertenecientes a <span class='highlight'> 835 escuelas</span> en <span class='highlight'>6 estados</span> de la República Mexicana.`,
            t2:`<span class='highlight text-light'>usuarios </span>certificados por <span class='highlight text-light'> Compu Cuantrix.</span>`,
            t3:`<span class='highlight text-light'>descargas </span> generadas en <span class='highlight text-light'>Compu Cuantrix.</span>`,
            t4:`alumnos <span class='highlight'>beneficiados</span> con <span class='highlight'>800 computadoras</span> en <span class='highlight'>220 primarias</span> públicas en Ciudad de México y Orizaba, Veracruz.`
        }
    }

    const txt_v = english ? textos.en : textos.es;
  return (
    <div>
        <div className="container-fluid">
            <div className="row bg-gray-custom">
                <div className="col-lg-6 p-0 order-lg-1 order-2">
                    <img src={img1} alt="" className="w-100" />
                </div>
                <div className="col-lg-5 offset-lg-1 p-0 m-auto order-lg-2 order-1">
                    <p className="imp-par w-75 m-auto">
                    <span className='highlight'><CountUp
          enableScrollSpy={true}
          start={'5800'}
          end={5877}
          duration={5}/></span> <span dangerouslySetInnerHTML={{ __html: txt_v.t1 }}></span>
                    </p>
                </div>
                <div className="col-12 p-0  order-3">
                    <div className="row bg-team justify-content-around">
                        <div className="col-10 col-md-8 col-10 align-self-center mb-md-5">
                            <div className="rect-gr text-box-numbs-1">
                                <p className="imp-par text-light"> 
                                    <span className='highlight text-light'><CountUp
          enableScrollSpy={true}
          start={'7600'}
          end={7681}
          duration={4}/></span> <span dangerouslySetInnerHTML={{ __html: txt_v.t2 }}></span></p>
                            </div>
                        </div>
                        <div className="col-10 col-md-8 col-10 align-self-center">
                            <div className="rect-gr text-box-numbs-2">
                                <p className="imp-par  text-light">  
                                    <span className='highlight text-light'><CountUp
          enableScrollSpy={true}
          start={'33024'}
          end={33224}
          duration={4}/></span> <span dangerouslySetInnerHTML={{ __html: txt_v.t3 }}></span></p>
                            </div>                            
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 p-0 m-auto order-4">
                    <p className="imp-par w-75 m-auto">       
                    <CountUp
                        enableScrollSpy={true}
                        start={'9899'}
                        end={10000}
                        duration={3}/> <span dangerouslySetInnerHTML={{ __html: txt_v.t4 }}></span>
                        {/* <span className='highlight'> <CountUp
          enableScrollSpy={true}
          start={'9599'}
          end={10000}
          duration={4}/> alumnos </span> <br className="d-block" /> beneficiados con la donación de<br className="d-md-block d-none" /> 
                        <span className='highlight'> <CountUp
          enableScrollSpy={true}
          start={'710'}
          end={800}
          duration={4}/> computadoras</span><br className="d-block" /> en 2023 a escuelas públicas para <br className="d-md-block d-none" />
                        <span className='highlight'><CountUp
          enableScrollSpy={true}
          start={'150'}
          end={200}
          duration={4}/> primarias </span> en Ciudad <br className="d-block" /> de México y
                        <span className='highlight'> 20 </span> en Orizaba, Veracruz. */}
                        </p>
                </div>
                <div className="col-lg-6 p-0 order-5">
                    <img src={img2} alt="" className="w-100" />
                </div>
            </div>
        </div>
    </div>
  )
}
