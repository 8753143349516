import React from 'react'
import './Contenidos.css'
import HeaderContenidos from '../../componentes/Contenidos/Header/HeaderContenidos'
import NuestrosContenidos from '../../componentes/Contenidos/NuestrosContenidos/NuestrosContenidos'
import Acciones from '../../componentes/Contenidos/Acciones/Acciones'
import Footer from '../../componentes/Footer/Footer'
import Cifras from '../../componentes/Contenidos/Cifras/Cifras'

export default function Contenidos() {
  return (
    <div className='Contenidos'>
      <HeaderContenidos />

      <NuestrosContenidos />

      <Acciones />

      <Cifras />

      <Footer />
    </div>
  )
}
