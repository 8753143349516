import React from 'react'
import './ProgramasObjetivosOnu.css'
import educacionCalidad from '../../../../../assets/images/nuestrasAlas/objetivosOnu/educacion-calidad.png'
import igualdadGenero from '../../../../../assets/images/nuestrasAlas/objetivosOnu/igualdad-genero.png'
import trabajoCrecimiento from '../../../../../assets/images/nuestrasAlas/objetivosOnu/trabajo-crecimiento.png'
import reduccionDesigualdades from '../../../../../assets/images/nuestrasAlas/objetivosOnu/reduccion-desigualdades.png'
import alianzasObjetivos from '../../../../../assets/images/nuestrasAlas/objetivosOnu/alianzas-objetivos.png'
import Progredes from '../../../Progredes/Progredes'
import fb from '../../../../../assets/images/iconos/fb.svg'
import ig from '../../../../../assets/images/iconos/ig.svg'
import x from '../../../../../assets/images/iconos/x.svg'
import tiktok from '../../../../../assets/images/iconos/tiktok.svg'

export default function ProgramasObjetivosOnu({english=false}) {

  const social= [
    {
        link:'https://www.facebook.com/PosibleMex',
        icon: fb,
        nombre:'PosibleMex'
    },
    {
        link:'https://www.instagram.com/posiblemex ',
        icon: ig,
        nombre:'posiblemex'
    },
    {
      link:'https://twitter.com/PosibleMex',
      icon: x,
      nombre:'PosibleMex'
    },
    {
      link:'https://www.tiktok.com/@posiblemx',
      icon: tiktok,
      nombre:'posiblemx'
    }
  ]

  const textos = {
    en: {
      t1:`UN Sustainable Development Goals`,
      t2:`<b>Posible</b> is aligned with the 2030 agenda, contributing directly to Decent Work and economic growth, through programs to boost entrepreneurship, in addition to safeguarding gender equality.`,
    },
    es:{
        t1:`Objetivos de Desarrollo Sostenible de la ONU`,
        t2:`<b>Posible</b> está alineado con la agenda 2030, contribuyendo directamente al Trabajo decente y crecimiento económico, a través de los programas de impulso al emprendimiento, además de salvaguardar la igualdad de género.`,
    }
  }

  const txt_v = english ? textos.en : textos.es;

  return (
    <div className='ProgramasObjetivosOnu'>
      {/* mobile*/}
      <div className='mobile-device'>
        <div className='text-container-objetivos'>
          <div className='d-flex justify-content-center'>
            <div className='title-onu' dangerouslySetInnerHTML={{ __html: txt_v.t1 }}></div>
          </div>

          <div className='d-flex justify-content-center mt-4'>
            <div className='paragraph-onu w-75' dangerouslySetInnerHTML={{ __html: txt_v.t2 }}></div>
          </div>

          <div className='d-flex justify-content-center'>
            <div className='programs-cards-container'>
              <div className='row px-md-4 mt-4'>
                <div className='col-6'>
                  <div className='d-flex justify-content-start p-1'>
                    <img src={educacionCalidad} alt='Educación de calidad' className='img-objetivos'/>
                  </div>
                </div>
                <div className='col-6'>
                  <div className='d-flex justify-content-start p-1'>
                    <img src={igualdadGenero} alt='Igualdad de género' className='img-objetivos'/>
                  </div>
                </div>
              </div>
              <div className='row px-md-4 mt-4'>
                <div className='col-6'>
                    <div className='d-flex justify-content-start p-1'>
                      <img src={reduccionDesigualdades} alt='Educación de calidad' className='img-objetivos'/>
                    </div>
                </div>
                <div className='col-6'>
                  <div className='d-flex justify-content-start p-1'>
                    <img src={trabajoCrecimiento} alt='Igualdad de género' className='img-objetivos'/>
                  </div>
                </div>
              </div>
              <div className='row px-md-4 mt-4'>
                <div className='col-6'>
                  <div className='d-flex justify-content-start p-1'>
                    <img src={alianzasObjetivos} alt='Igualdad de género' className='img-objetivos'/>
                  </div>
                </div>
                <div className='col-6'>
                  
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      {/* web */}
      <div className='web-device'>
        <div className='row m-0 py-5 px-0'>
          <div className='col-6'>
            <div className='d-flex justify-content-center align-items-center h-100'>
              <div className='text-container-objetivos'>
                <div className='title-onu' dangerouslySetInnerHTML={{ __html: txt_v.t1 }}></div>
                <div className='paragraph-onu pt-2' dangerouslySetInnerHTML={{ __html: txt_v.t2 }}></div>
              </div>
            </div>
          </div>
          <div className='col-6'>
            <div className='d-flex justify-content-center'>
              <div className='w-50'>
                <div className='row'>
                  <div className='col-4 d-flex justify-content-start p-1'>
                    <img src={educacionCalidad} alt='Educación de calidad' className='img-objetivos'/>
                  </div>
                  <div className='col-4 d-flex justify-content-start p-1'>
                    <img src={igualdadGenero} alt='Igualdad de género' className='img-objetivos'/>
                  </div>
                  <div className='col-4 d-flex justify-content-start p-1'>
                    <img src={trabajoCrecimiento} alt='Igualdad de género' className='img-objetivos'/>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-4'>&nbsp;</div>
                  <div className='col-4 d-flex justify-content-start p-1'>
                    <img src={reduccionDesigualdades} alt='Educación de calidad' className='img-objetivos'/>
                  </div>
                  <div className='col-4 d-flex justify-content-start p-1'>
                    <img src={alianzasObjetivos} alt='Igualdad de género' className='img-objetivos'/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row m-0 p-0'>
        <Progredes
          sitio = 'https://posible.org.mx/'
          redes = {social}
          english={english}
        />
      </div>
    </div>
  )
}
