import React from 'react'
import './HeaderIgualdad.css'
import Nav from '../../Nav/Nav'

export default function HeaderIgualdad() {
  return (
    <div className='HeaderIgualdad'>
      <Nav />
      {/* <div className='hero-gradient'>&nbsp;</div> */}
      <div className='hero-title px-5 px-lg-0'>
        IGUALDAD DE GÉNERO
      </div>
    </div>
  )
}
