import React from 'react'
import './Comunicacion.css'
import imgcom from '../../../assets/images/home/comunicacion/comunic.png'
import imgcomMobile from '../../../assets/images/home/comunicacion/comunic-mobile.png'
import megafono from '../../../assets/images/home/comunicacion/megafono.svg'
import { Button } from '../../Button/Button'
import CountUp from 'react-countup';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { LazyLoadImage } from 'react-lazy-load-image-component'

export function Comunicacion({english=false}) {

  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const variants = {
    hidden: { translateY: '20vh' },
    visible: { translateY: '0vh', transition: { duration: 1 } },
  };

  const textos = {
    en: {
        termi:'/en',
        links:{
            l1:'comunicacion'
        },
        mov:{
            t1:`Televisa Foundation:`,
            t2:`COMMUNICATION <br class="d-md-block d-none"/> WITH IMPACT`,
            t3:`We used <b>Televisa Univision's media and our digital platforms</b> to spread <b>messages</b> that positively influence the lives of millions of people.`,
            t4:`MILLION PEOPLE RECEIVED OUR MESSAGES`,
            t5:`through our digital platforms and television in 2023.`,
        },
        desk:{
            t1:`Televisa Foundation:`,
            t2:`COMMUNICATION <br class='d-md-block d-none'/> WITH IMPACT`,
            t3:`We used <b>Televisa Univision's media and our digital platforms</b> to spread <b>messages</b> that positively influence the lives of millions of people.`,
            t4:`MILLION PEOPLE RECEIVED OUR MESSAGES`,
            t5:`through our digital platforms and television in 2023.`,
        },
        generico:`We generated <b>efforts</b> with <b>75,896</b> television <b>broadcasts</b> in 2023.`
    },
    es:{
        termi:'',
        links:{
            l1:'comunicacion'
        },
        mov:{
            t1:`Fundación Televisa:`,
            t2:`Comunicación <br class="d-md-block d-none"/> con impacto.`,
            t3:`Utilizamos los <b>medios de comunicación  de TelevisaUnivision y nuestras plataformas digitales</b> para difundir <b>mensajes</b> que influyen positivamente en la vida de millones de personas.`,
            t4:`millones de personas recibieron nuestros mensajes`,
            t5:`por medio de nuestras plataformas digitales y la televisión en 2023.`,
        },
        desk:{
            t1:`Fundación Televisa:`,
            t2:`COMUNICACIÓN <br class='d-md-block d-none'/> CON IMPACTO.`,
            t3:`Utilizamos los <b>medios de comunicación  de TelevisaUnivision y nuestras plataformas digitales</b> para difundir <b>mensajes</b> que influyen positivamente en la vida de millones de personas.`,
            t4:`millones de personas recibieron nuestros mensajes`,
            t5:`por medio de nuestras plataformas digitales y la televisión en 2023.`,
        },
        generico:`Generamos <b>esfuerzos</b> con <b>75,896 transmisiones</b> en televisión este año.`
    }
  }

  const txt_v = english ? textos.en : textos.es;
    
  return (
    <div className='Comunicacion'>
        
            {/* mobile */}
            <div className='mobile-device m-0 p-0'>
                <div className='container-fluid m-0 p-0'>

                    <div className='m-0 pt-4'>
                        <div className='d-flex justify-content-center'>
                            <img src={megafono} alt="imagen megafono" className='d-block megafono' />
                        </div>
                        <div className='row m-0 px-0 '>
                            <div className='col-12'>
                                <h3 className='com-futel'> {txt_v.mov.t1} </h3>
                            </div>
                        </div>

                        <div className='row px-4 mt-md-4'>
                            <h2 className='com-title' dangerouslySetInnerHTML={{ __html: txt_v.mov.t2}}></h2>
                        </div>
                        <div>
                            <p className='com-par mt-2 px-5' dangerouslySetInnerHTML={{ __html: txt_v.mov.t3}}></p>
                        </div>
                    </div>

                    <div className='m-0 p-0 mt-4'>
                        {/* <motion.section
                            whileInView ={{ translateY: '0vh' }} // Start offscreen at the bottom
                            animate={{ translateY: '30vh' }} // Animate to the top
                            transition={{ duration: 0.5 }} // Set animation duration
                        > */}
                            <LazyLoadImage
                                src={imgcomMobile}
                                
                                
                                alt="Image Alt"
                                className='img-fluid'
                            />
                            {/* <img src={imgcomMobile} alt="ima" className='img-fluid'/> */}
                        {/* </motion.section> */}
                    </div>
                    
                    {/* <motion.section
                        whileInView ={{ translateY: '0vh' }} // Start offscreen at the bottom
                        animate={{ translateY: '20vh' }} // Animate to the top
                        transition={{ duration: 0.5 }} // Set animation duration
                    > */}
                        <div className='row mt-4 px-4'>
                            <div className='com-title2'>
                                <CountUp
                                    enableScrollSpy={true}
                                    start={'10'}
                                    end={61.5}
                                    duration={3}
                                    decimal='.'
                                    decimals={1}
                                /> {txt_v.mov.t4}
                            </div>
                            <div className='com-tele pt-md-2'>
                                {txt_v.mov.t5}
                            </div>
                        </div>

                        <div className='mt-4 mb-4 mx-2'>
                            <Button 
                                link={txt_v.links.l1}
                                btnClass='conoce-mas-purple'
                                english={english}
                            />
                            <div className='pt-0 p-md-2'>&nbsp;</div>
                        </div>
                    {/* </motion.section> */}
                    
                </div>
            </div>

            {/* web */}
            <div className='web-device'>
                <div className='container-fluid com'>
                    <div className='row py-4'>
                        <div className='col-md-6 px-0 py-5' ref={ref}>
                            <motion.div
                                // whileInView ={{ translateY: '0vh' }} // Start offscreen at the bottom
                                // animate={{ translateY: '50vh' }} // Animate to the top
                                // transition={{ duration: 1 }} // Set animation duration
                                variants={variants}
                                initial="hidden"
                                animate={inView ? 'visible' : 'hidden'}
                            >
                                <img src={imgcom} alt="ima" className='w-100'/>
                            </motion.div>
                        </div>

                        <div className='ps-4 col-md-6'>
                            <div className='com-w-txt text-start px-4 py-3'>
                                <img src={megafono} alt="imagen megafono" className='d-block' style={{'paddingTop': '2rem'}} />
                                <h3 className='com-futel'> {txt_v.desk.t1} </h3>
                                <h2 className='com-title' dangerouslySetInnerHTML={{ __html: txt_v.desk.t2}}></h2>
                                <p className='com-par' dangerouslySetInnerHTML={{ __html: txt_v.desk.t3}}></p>
                                <h3 className='com-title2 pt-4 mb-0'>
                                    <CountUp
                                        enableScrollSpy={true}
                                        start={'10'}
                                        end={61.5}
                                        duration={3}
                                        decimal='.'
                                        decimals={1}
                                    /> {txt_v.desk.t4} 
                                </h3>
                                <div className='com-tele'>
                                    {txt_v.desk.t5}
                                </div>
                                {/* <h2 className='com-title-num'>
                                <CountUp
                                    enableScrollSpy={true}
                                    start={'75099'}
                                    end={75896}
                                    duration={4}
                                    /> impactos
                                </h2>
                                <h4 className='com-tele mt-0'>en televisión en 2023.</h4> */}
                                <div className='mt-4'>
                                    <Button 
                                        link={txt_v.links.l1}
                                        btnClass='conoce-mas-purple'
                                        english={english}
                                    />
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                
            </div>

            <div className='d-flex justify-content-center m-0 p-0 bg-purple'>
                <div className='w-75 purple-title py-4 py-lg-4' dangerouslySetInnerHTML={{ __html: txt_v.generico}}></div>
            </div>
        
    </div>
  )
}
