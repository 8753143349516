import React, { useState } from 'react'
import './Telenovelas.css'
// import bottomBorder from '../../../assets/images/comunicacion/bottom-border.svg'
import vencerLaCulpa from '../../../assets/images/comunicacion/vencer-la-culpa.png'
import CountUp from 'react-countup';
import VideoPlayer from '../../../componentes/VideoPlayer/VideoPlayer';


export default function Telenovelas({english=false}) {

  const [showVideoMobile, setShowVideoMobile] = useState(false);
  const [showVideo, setShowVideo] = useState(false);

  const textos = {
    en: {
      t1:`OUR MESSAGES THROUGH SOAP OPERAS WITH A CAUSE`,
      t2:`This year we integrated <b>social messages</b> into the soap operas <b>Eternamente Amándonos, Vencer la Culpa, Mi Secreto</b> and <b>Ellas soy yo</b>, addressing healthy relationships, mental health, early stimulation, and violence prevention.`,
      t3:`Our <b>websites</b> registered <b>more than</b>`,
      t4:`<b>million visits</b>, offering <b>calls for proposals, courses, and valuable content</b>.`,
      t5:`<b>people interacted</b> on our <b>digital platforms</b>.`
    },
    es: {
      t1:`Nuestros mensajes a través de Telenovelas con causa.`,
      t2:`Este año integramos <b>mensajes sociales</b> en las telenovelas: <b>Eternamente Amándonos, Vencer la Culpa, Mi Secreto</b> y <b>Ellas soy yo,</b> abordando las relaciones sanas, la salud mental, la estimulación temprana y la prevención de la violencia.`,
      t3:`Nuestros <b>sitios</b> web registraron <b>más de</b>`,
      t4:`<b>millones de visitas,</b> ofreciendo <b>convocatorias, cursos y contenido de valor.</b>`,
      t5:`<b>personas interactuaron</b> en nuestras <b>plataformas digitales.</b>`
    },
  }

  const txt_v = english ? textos.en : textos.es;

  return (
    <div className='Telenovelas'>
      
      {/* mobile */}
      <div className='mobile-device'>
        <div className='d-flex justify-content-center p-4 pb-0 p-lg-4 pb-lg-4'>
          <div className='title px-4 py-4 py-md-5'>
            {txt_v.t1}
          </div>
        </div>

        <div className='col-12 d-flex align-items-center image-content p-4 pt-0 p-lg-4 pt-lg-4'>
          <div>
            {
              !showVideoMobile &&
              <>
                <img src={vencerLaCulpa} alt='vencer la culpa' onClick={() => {setShowVideoMobile(true)}}/>
              </>
            }
            {
              showVideoMobile &&
              <div className='w-100 h-auto d-flex justify-content-center'>
                <VideoPlayer 
                  src="https://www.youtube.com/watch?v=CIrI23-LYNE&feature=youtu.be"
                  playing={true}
                />
              </div>
            }
            
          </div>
        </div>

        <div className='px-4 pt-2 pb-5'>
          <div className='row m-0 p-0 pb-md-4'>
            <div className='col-12'>
              <div className='paragraph' dangerouslySetInnerHTML={{ __html: txt_v.t2 }}></div>
            </div>
          </div>
        </div>

      </div>

      {/* web */}
      <div className='web-device'>
        <div className='main-content'>
          <div className='row m-0 p-0'>
            <div className='col-7 px-5'>
              <div className='d-flex justify-content-center align-items-center h-100'>
                <div>
                  <div className='d-flex justify-content-start px-5 px-lg-0'>
                    <div className='title'>
                      {txt_v.t1}
                    </div>
                  </div>
                  <div className='paragraph px-4 px-lg-0 pt-0 pt-lg-4 pt-0 pb-5' dangerouslySetInnerHTML={{ __html: txt_v.t2 }}></div>
                </div>
              </div>
            </div>
            <div className='col-5 py-5 pe-5'>
              <div className='d-flex justify-content-center align-items-center h-100 pe-5'>
                {
                  !showVideo &&
                  <>
                    <img src={vencerLaCulpa} alt='vencer la culpa' className='img-fluid' onClick={() => {setShowVideo(true)}}/>
                  </>
                }
                {
                  showVideo &&
                  <div className='w-100 h-auto d-flex justify-content-center'>
                    <VideoPlayer 
                      src="https://www.youtube.com/watch?v=CIrI23-LYNE&feature=youtu.be"
                      playing={true}
                    />
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className='row m-0 p-0 bg-gray'>
        <div className='col-12 col-lg-6 m-0 p-0'>
          <div className='bg-teacher m-0 p-0'>&nbsp;</div>
        </div>
        <div className='col-11 offset-0 col-lg-6 offset-lg-0 second-content m-auto'>
          <div className='pt-0 pt-lg-4'>&nbsp;</div>
          <div className='d-flex align-items-center h-100'>

            <div>
              <div className='second-paragraph d-flex'>
                <div className='bullet'>&nbsp;</div>
                <div className='ps-2'>
                  <span dangerouslySetInnerHTML={{ __html: txt_v.t3 }}></span> <b><CountUp
                    enableScrollSpy={true}
                    start={'0.5'}
                    end={1.8}
                    duration={2}
                    decimals={1}
                    decimal="."
                  /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t4 }}></span>
                </div>
              </div>
              <div className='second-paragraph d-flex pt-4 pt-md-5'>
                <div className='bullet'>&nbsp;</div>
                <div className='ps-2'>
                  <b><CountUp
                        enableScrollSpy={true}
                        start={'5562000'}
                        end={5562917}
                        duration={3}
                      /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t5 }}></span>
                </div>
              </div>
            </div>

          </div>

          <div className='pt-0 pt-lg-4'>&nbsp;</div>
        </div>
      </div>
    </div>
  )
}
