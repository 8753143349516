import React from 'react'
import './TestimoniosPosible.css'
import Carousel from '../../../../Carousel/Carousel';
import testimoniosIcon from '../../../../../assets/images/home/testimonios-icon-gold.png'
import thumbnailPosible1 from '../../../../../assets/images/programas/subsections/posible/thumbnails/posible1.jpg'
import thumbnailPosible2 from '../../../../../assets/images/home/testimonios/thumbnails/retoposible.jpg'

export default function TestimoniosPosible({english=false}) {

  const items = [
    {
        type: 'video',
        image: thumbnailPosible1,
        videoUrl: 'https://www.youtube.com/shorts/7AOrHK44dfw'
    },
    {
        type: 'video',
        image: thumbnailPosible2,
        videoUrl: 'https://www.youtube.com/watch?v=VaC1fV-5yFY'
    }
  ];

  const textos = {
    en: {
        t1:`READ THEIR TESTIMONIALS`
    },
    es:{
        t1:`CONOCE SUS TESTIMONIOS`
    }
  }

  const txt_v = english ? textos.en : textos.es;

  return (
    <div className='TestimoniosPosible'>
      <div className="container-fluid pt-4 pl-5 pb-5 pb-0 pr-0 m-0">
        <div className='d-flex justify-content-center'>
          <div className='text-center'>
            <img src={testimoniosIcon} alt='testimonios icon' className='testimonios-icon' />
            <div className='d-flex justify-content-center mt-4'>
              <div className='title-posible'>
                {txt_v.t1}
              </div>
            </div>
          </div>
        </div>

        <div className='d-flex justify-content-center mt-5'>
          <div className='w-75'>
            <Carousel
              items={items}
            />
          </div>
        </div>

      </div>
    </div>
  )
}
