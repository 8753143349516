import React, { useEffect, useState } from 'react'
import './ItemCardSlider.css'
import VideoPlayer from '../../VideoPlayer/VideoPlayer';

export default function ItemCardSlider(
  {item, stopVideo}
) {

  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    if (stopVideo) {
      setPlaying(false);
    } else {
      if (item.playing) {
        setPlaying(true);
      } else {
        setPlaying(false);
      }
    }
  }, [item.playing, stopVideo]);

  return (
    <div className='ItemCardSlider'>
      {
        !item.external && !playing &&
        <img src={ item.image } alt={ `item` } className='item-img' onClick={() => setPlaying(true)}/>
      }
      {
        !item.external && playing &&
        <div className='p-md-4'>
          <VideoPlayer src={item.videoUrl} playing={playing} />
        </div>
      }
      {
        item.external &&
        <a href={item.videoUrl} target='_Blank' rel='noreferrer'>
          <img src={ item.image } alt={ `item` } className='item-img'/>
        </a>
      }
    </div>
  )
}
