import React, {useState} from 'react'
import './CardProgram.css'
import play from '../../../../assets/images/home/video/play.png'
import VideoPlayer from '../../../VideoPlayer/VideoPlayer';

export default function CardProgram(
  {image, title, description,url, type = 'image'}
) {

  const [showVideo, setShowVideo] = useState(false);

  return (
    <div className='CardProgram'>
      <div className='card-container'>
        <div className="position-relative">
          <div className='d-flex justify-content-start'>
            {
              type === 'image' &&
              <img src={image} alt={title} className='image'/>
            }
            {
              type === 'video' && !showVideo &&
              <img src={image} alt={title} className='image' onClick={() => setShowVideo(true)}/>
            }
            {
              type === 'video' && showVideo &&
              <VideoPlayer
                src={url}
                className="w-100"
                playing={true}
              />
            }
          </div>
          {
            url !== undefined && !showVideo &&
            <img src={play} alt="video" className='play-overlay' onClick={() => setShowVideo(true)}/>
            
          }
        </div>
        <div className='title'>
          {title}
        </div>
        <div className='description'>
          {description}
        </div>
      </div>
    </div>
  )
}
