import React, {useState} from 'react'
import './Impulsando.css'
import imghead from '../../../../assets/images/programas/temprano/10 2.png'
import VideoPlayer from '../../../../componentes/VideoPlayer/VideoPlayer'
import CountUp from 'react-countup'

export default function Impulsando({english=false}) {

    const [showVideo, setShowVideo] = useState(false);

    const textos = {
        en: {
            t1:`EMPIEZA TEMPRANO`,
            t2:`Promoting holistic early childhood development.`,
            t3:`parents and early childhood professionals <b>benefited this year</b>.`,
            t4:`Through <b>educational tools</b>, we support the <b>development of children</b> from 0 to 5 years of age so that parents and professionals involved in their care recognize the <b>importance of early childhood</b>.`,
            t5:`Since our beginnings, we have added value to the <b>early education</b> of <b>211,242 people</b>.`,
        },
        es:{
            t1:`EMPIEZA TEMPRANO`,
            t2:`Promoviendo el desarrollo integral de la primera infancia.`,
            t3:`<b>padres, madres y profesionales de la primera infancia</b> se beneficiaron este año.`,
            t4:`A través de <b>herramientas educativas</b> apoyamos el <b>desarrollo infantil</b> de niños y niñas de 0 a 5 años para que los padres de familia y profesionales vinculados al cuidado, reconozcan la <b>importancia de la primera infancia.</b>`,
            t5:`Desde nuestros inicios, hemos sumado valor a la <b>educación temprana</b> de <b>211,242 personas.</b>`,
        }
    }
    
    const txt_v = english ? textos.en : textos.es;

  return (
    <div>
        <div className="container-fluid">
            <div className="row bg-suave">
                <div className="col-lg-5  m-auto inner-box order-lg-first order-last p-xxl-4 p-lg-4 p-md-5 px-4 py-3">
                    <h2 className="main-title-programas mb-md-2 mb-lg-2">{txt_v.t1}</h2>
                    <p className="main-subtitle-programas mb-md-4" dangerouslySetInnerHTML={{ __html: txt_v.t2 }}></p>
                    <p className="main-paragraph-programas mb-md-4">
                        <b><CountUp
                            enableScrollSpy={true}
                            start={60000}
                            end={69486}
                            duration={3}/></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t3 }}></span>
                            <div className='pt-4 pt-lg-2' dangerouslySetInnerHTML={{ __html: txt_v.t4 }}></div>
                    </p>
                    {/* <p className="main-paragraph-programas">
                    <b>Empieza Temprano</b> promueve el <b>desarrollo integral</b> de todas las niñas y niños de 0 a 6 años. 
                    Brindamos herramientas educativas a los padres de familia, cuidadores y profesionales vinculados al cuidado de la infancia.
                    </p> */}
                </div>
                <div className="col-lg-6 p-0">
                    {
                        !showVideo &&
                        <img src={imghead} alt="fut" className="et-img object-fit-cover" onClick={() => setShowVideo(true)} />
                    }
                    {
                        showVideo &&
                        <div className='p-4'>
                            <VideoPlayer
                                src="https://www.youtube.com/watch?v=KWnWzuJDI6E"
                                className="w-100"
                                playing={true}
                            />
                        </div>
                    }
                </div>
            </div>
            <div className="row bg-et justify-content-center">
                <div className="col-lg-7 col-11 p-0 ">
                    <div className='d-flex justify-content-center'>
                        <div className='et-quote  py-4 py-lg-5' dangerouslySetInnerHTML={{ __html: txt_v.t5 }}></div>
                    </div>
                </div>
                {/* <div className="col-lg-6 p-0 bg-et-gris">
                    <div className="row mh-250 justify-content-center  px-3">
                        <div className="col-lg-7 m-md-0 m-auto">
                            <h3 className="et-quote px-lg-0 px-3">
                            <span className='bold'>18 mil niñas y niños </span> se vieron beneficiados gracias a la alianza con Un Kilo de Ayuda.
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 p-0 bg-et">
                    <div className="row mh-250 px-lg-5 px-3">
                        <div className="col-lg-8 m-md-0 m-auto">
                            <h3 className="et-quote px-4">
                            Las infancias son el futuro por el que debemos de velar.
                            </h3>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    </div>
  )
}