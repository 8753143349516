import React from 'react'
import './Comunidades.css'
import img1 from '../../../../assets/images/programas/tecnolochicas/prog/S-WEB-Goal-01 4.png'
import img2 from '../../../../assets/images/programas/tecnolochicas/prog/S-WEB-Goal-01 5.png'
import img3 from '../../../../assets/images/programas/tecnolochicas/prog/S_SDG_PRINT-10 1.png'
import CountUp from 'react-countup'


export default function Comunidades({english=false}) {

    const textos = {
        en: {
            t1:`DIGITAL <br />COMMUNITIES:`,
            t2:`<span class="highlight">people</span> make up our <span class="highlight">digital communities.</span>`,
            t3:`<span class="highlight">views</span> registered on the <span class="highlight">tecnolochicas.org</span> site.`,
            t4:`UN Sustainable Development Goals`,
            t5:`Tecnolochicas is aligned with the 2030 agenda, contributing directly to the goal of quality education, gender equality and reduction of inequalities.`,
        },
        es:{
            t1:`comunidades <br />digitales:`,
            t2:`<span class="highlight">personas</span> conforman nuestras <span class="highlight">comunidades digitales.</span>`,
            t3:`<span class="highlight">vistas</span> registradas en el sitio <span class="highlight">tecnolochicas.org</span>`,
            t4:`Objetivos de Desarrollo Sostenible de la ONU`,
            t5:`Tecnolochicas está alineado con la agenda 2030, contribuyendo directamente al objetivo de educación de calidad, igualdad de género y reducción de las desigualdades.`,
        }
      }
    
    const txt_v = english ? textos.en : textos.es;

  return (
    <div>
        <div className="container-fluid">
            <div className="row ">
                <div className="col-lg-6 m-auto px-md-5 px-3">
                    <h2 className="com-dig ps-md-4" dangerouslySetInnerHTML={{ __html: txt_v.t1 }}></h2>
                </div>
                <div className="col-lg-6 px-0 py-2 px-lg-0 py-lg-5 bg-nar-cl m-auto bg-gray-comunidades">
                    <div className="row com-h align-items-center">
                        <div className="col ">
                            <div className='d-flex'>
                                <div className='bullet ms-5'>&nbsp;</div>
                                <div className='m-0 p-0'>
                                    <p className="com-dig-par px-2">
                                        <span className="highlight"><CountUp
                                            enableScrollSpy={true}
                                            start={'91500'}
                                            end={91605}
                                            duration={3}/></span> <span dangerouslySetInnerHTML={{ __html: txt_v.t2 }}></span>
                                    </p>
                                </div>
                            </div>

                            <div className='d-flex'>
                                <div className='bullet ms-5'>&nbsp;</div>
                                <div className='m-0 p-0'>
                                    <p className="com-dig-par px-2">
                                        <span className="highlight"><CountUp
                                            enableScrollSpy={true}
                                            start={'22900'}
                                            end={23076}
                                            duration={3}/></span> <span dangerouslySetInnerHTML={{ __html: txt_v.t3 }}></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row py-md-5 bg-nar'>
                <div className='col-lg-6 ps-5 ps-lg-0 pe-5'>
                    <div className='d-flex justify-content-start ms-lg-5 pt-md-0 pt-5'>
                    <div className='title-onu'>
                        {txt_v.t4}
                        <div className='paragraph-onu pt-2'>
                            {txt_v.t5}
                        </div>
                    </div>
                    </div>
                </div>
                <div className='col-12 col-lg-5 ps-md-5 ps-lg-0 pe-md-5 py-4 py-md-0'>
                    <div className='row m-0 p-0'>
                        <div className='col-6 col-lg-4 '>
                            <img src={img1} alt='Fin de la pobreza' className='img-objetivos'/>
                        </div>
                        <div className='col-6 col-lg-4 '>
                            <img src={img2} alt='Fin de la pobreza' className='img-objetivos'/>
                        </div>
                        <div className='col-6 col-lg-4 '>
                            <img src={img3} alt='Fin de la pobreza' className='img-objetivos'/>
                        </div>
                        {/* <div className='col-5 col-lg-3 mx-2 mx-lg-0'>
                            <img src={img1} alt='Fin de la pobreza' className='w-100'/>
                        </div>
                        <div className='col-5 col-lg-3 mx-2 mx-lg-4'>
                            <img src={img2} alt='Fin de la pobreza' className='w-100' />
                        </div>
                        <div className='col-5 col-lg-3 mx-2 mx-lg-4'>
                            <img src={img3} alt='Fin de la pobreza' className='w-100'/>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
