import React from 'react'
import './Ninios.css'

export default function Ninios({english=false}) {

  const textos = {
    en: {
      t1:`AT TELEVISA FOUNDATION`,
      t2:`YOU are our motivation.`
    },
    es: {
      t1:`EN FUNDACIÓN TELEVISA`,
      t2:`TÚ eres nuestra motivación.`
    }
  }

  const txt_v = english ? textos.en : textos.es;

  return (
    <div className='Ninios'>
      <div className='d-flex justify-content-center pt-2 pt-lg-4'>
        <div className='title mt-2 mt-lg-2'>
          {txt_v.t1}
        </div>
      </div>
      <div className='d-flex justify-content-center'>
        <div className='paragraph'>
          {txt_v.t2}
        </div>
      </div>
      {/* <div className='d-flex justify-content-center'>
        <div className='bottom-border mt-2'>&nbsp;</div>
      </div> */}
    </div>
  )
}
