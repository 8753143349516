import React from 'react'
import './ReconocimientosFundacion.css'
import HeaderSection from '../../componentes/HeaderSection/HeaderSection'
import hero from '../../assets/images/reconocimientos-fundacion/hero.png'
import Awards from '../../componentes/ReconocimientosFundacion/Awards/Awards'
import Footer from '../../componentes/Footer/Footer'

import item1 from '../../assets/images/items-footer/nuestros-programas.png'
import item2 from '../../assets/images/items-footer/comunicacion-con-impacto.png'
import item3 from '../../assets/images/items-footer/nuestras-alas-del-club-america.png'
import mobile from '../../assets/images/items-footer/nuestros-programas-mobile.png'

const itemsReconocimientos = [
  {
    image: item1,
    title: {
      en:'OUR PROGRAMS',
      es:'NUESTROS PROGRAMAS',
    },
    link: '/programas'
  },
  {
    image: item2,
    title: {
      en:'COMMUNICATION EFFORTS WITH IMPACT',
      es:'ESFUERZOS DE COMUNICACIÓN CON IMPACTO',
    },
    link: '/comunicacion'
  },
  {
    image: item3,
    title: {
      en:'NUESTRAS ALAS DEL CLUB AMÉRICA',
      es:'NUESTRAS ALAS DEL CLUB AMÉRICA',
    },
    link: '/nuestras-alas'
  }
];
const itemReconocimientosMobile = [
  {
    image: mobile,
    title: {
      en:'OUR PROGRAMS',
      es:'NUESTROS PROGRAMAS',
    },
    link: '/programas'
  }
];


export default function ReconocimientosFundacion({version=false}) {
  const textos = {
    en: {
      t1:'ACKNOWLEDGEMENTS'
    },
    es: {
      t1:'RECONOCIMIENTOS'
    }
  }
  const txt_v = version ? textos.en : textos.es;
  return (
    <div className='ReconocimientosFundacion'>
      <HeaderSection
        image={hero}
        title={txt_v.t1}
        ajustemov='mov-35'
        english={version}
      />

      <Awards english={version}/>

      <Footer
        showLogo={false}
        items={itemsReconocimientos}
        itemMobile={itemReconocimientosMobile}
        english={version}
      />

    </div>
  )
}

