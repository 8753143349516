import React from 'react'
import './IniciativasPosible.css'
import CountUp from 'react-countup';

export default function IniciativasPosible({english=false}) {

  const textos = {
    en: {
        t1:`INITIATIVES THAT<br/> GENERATE IMPACT: `,
        t2:``,
        t3:`<b>business models completed</b> in 2023.`,
        t4:`<b>people</b> <br/> trained through our <b>MOOCs</b> this year.`,
        t5:`<b>We accelerated more than</b>`,
        t6:`<b>cooperatives</b> that generated opportunities for <b>more than 500 families</b> in collaboration with <b>8 states in Mexico</b>.`,
        t7:`We benefited`,
        t8:`<b>students</b><br class='d-xl-block d-none'/> and`,
        t9:`<b>teachers</b> from public universities in Mexico, <b>increasing their knowledge about entrepreneurship</b>.`,
        t10:`<b>Mujer POSIBLE</b> was implemented in Chiapas and was incorporated into the Foro Mujer Emprendedora in the State of Mexico, <b>training</b> more than`,
        t11:`<b>women</b>.`,
        t12:`In 2023, we achieved a <b>female participation</b> of <b>41%</b> in the <b>National Call</b>, <b>45% in the National Camp</b> and <b>40% in Posible COOP and Reto Posible</b>.`,
        t13:`<b>Posible Coop</b> in alliance with <b>Fomento Social Citibanamex</b>, accelerated more than `,
        t14:`<b>cooperatives and collectives</b> benefiting more than`,
        t15:`<b>families in 6 states in the <br class="d-block d-md-none" /> South-Southeast.</b>`
    },
    es: {
        t1:`Iniciativas que <br/>generan impacto:`,
        t2:`En 2023, se lograron`,
        t3:`<b>modelos de negocio completados</b>.`,
        t4:`<b>personas</b> <br/> capacitadas a través  de nuestros <b>MOOC´S</b> este año.`,
        t5:`<b>Aceleramos a más de</b>`,
        t6:`<b>cooperativas</b>  que a su vez generaron oportunidades para <b>más de 500 familias</b> en colaboración con <b>8 estados</b> de la República Mexicana.`,
        t7:`Beneficiamos a`,
        t8:`<b>estudiantes</b><br class='d-xl-block d-none'/> y`,
        t9:`<b>docentes</b> de las universidades públicas de México para <b>incrementar sus conocimientos sobre emprendimiento.</b>`,
        t10:`<b>Mujer POSIBLE</b> se implementó en Chiapas y se incorporó al Foro Mujer Emprendedora en el Estado de México, <b>capacitando</b> a más de `,
        t11:`<b>mujeres</b>`,
        t12:`En 2023, logramos una <b>participación femenina</b> del <b>41%</b> en la <b>Convocatoria Nacional</b>, del <b>45% en el Campamento Nacional</b> y del <b>40% en Posible COOP y Reto Posible.</b>`,
        t13:`<b>Posible Coop</b> en alianza con <b>Fomento Social Citibanamex</b>, aceleró a más de`,
        t14:`<b>cooperativas y colectivos</b> beneficiando de manera indirecta a más de`,
        t15:`<b>familias en 6 estados de <br class="d-block d-md-none" /> Sur-Sureste.</b>`
    }
}

const txt_v = english ? textos.en : textos.es;

  return (
    <div className='IniciativasPosible'>
      {/* mobile */}
      <div className='mobile-device'>
        <div className='d-flex justify-content-center'>
          <div className='title px-5 py-4' dangerouslySetInnerHTML={{ __html: txt_v.t1 }}></div>
        </div>
        
        <div className='bg-iniciativas-posible m-0 p-0'>
          <div className='container-iniciativa1'>
            <div className='iniciativas-text1 w-100'>
              <div className='paragraph-iniciativas p-2'>
                <span dangerouslySetInnerHTML={{ __html: txt_v.t2 }}></span> <b><CountUp
                    enableScrollSpy={true}
                    start={'13006'}
                    end={13286}
                    duration={3}
                  /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t3 }}></span>
              </div>
            </div>
          </div>

          <div className='container-iniciativa2'>
            <div className='iniciativas-text2 w-100'>
              <div className='paragraph-iniciativas p-2'>
                <b><CountUp
                    enableScrollSpy={true}
                    start={'3800'}
                    end={4000}
                    duration={3}
                  /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t4 }}></span>
              </div>
            </div>
          </div>
        </div>

        <div className='d-flex justify-content-center m-0 p-0'>
          <div className='paragraph-iniciativas3 px-md-5 px-4 pt-5 pb-4'>
            <span dangerouslySetInnerHTML={{ __html: txt_v.t5 }}></span>
            <b> <CountUp
                    enableScrollSpy={true}
                    start={0}
                    end={100}
                    duration={3}
                  /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t6 }}></span>
          </div>
        </div>
        <div className='m-0 p-0 w-100 bg-iniciativa3'>&nbsp;</div>

        <div className='d-flex justify-content-center m-0 p-0'>
          <div className='paragraph-iniciativas3 px-md-5 px-4 pt-5 pb-4'>
            <span dangerouslySetInnerHTML={{ __html: txt_v.t7 }}></span> <b><CountUp
                    enableScrollSpy={true}
                    start={100}
                    end={167}
                    duration={3}
                  /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t8 }}></span> <b><CountUp
                    enableScrollSpy={true}
                    start={100}
                    end={171}
                    duration={3}
                  /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t9 }}></span>
          </div>
        </div>
        <div className='m-0 p-0 w-100 bg-iniciativa4'>&nbsp;</div>

        <div className='d-flex justify-content-center m-0 p-0'>
          <div className='paragraph-iniciativas3 px-md-5 px-4 pt-5 pb-4'>
            <span dangerouslySetInnerHTML={{ __html: txt_v.t10 }}></span> <b><CountUp
                    enableScrollSpy={true}
                    start={'2000'}
                    end={2198}
                    duration={3}
                  /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t11 }}></span>
          </div>
        </div>
        <div className='m-0 p-0 w-100 bg-iniciativa5'>&nbsp;</div>

        <div className='d-flex justify-content-center m-0 p-0'>
          <div className='paragraph-iniciativas3 px-md-5 px-4 pt-5 pb-4' dangerouslySetInnerHTML={{ __html: txt_v.t12 }}></div>
        </div>
        <div className='m-0 p-0 w-100 bg-participacion-femenina'>&nbsp;</div>

        <div className='m-0 p-0 bg-iniciativa6'>
          <div className='text-container-p6'>
            <div className='bg-gradient-iniciativa6'>
              <div className='paragraph-iniciativas6 px-4 py-2'>
              <span dangerouslySetInnerHTML={{ __html: txt_v.t13 }}></span> <b><CountUp
                    enableScrollSpy={true}
                    start={0}
                    end={300}
                    duration={3}
                  /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t14 }}></span> <b><CountUp
                    enableScrollSpy={true}
                    start={0}
                    end={1000}
                    duration={3}
                  /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t15 }}></span>
              </div>
            </div>
          </div>
        </div>

      </div>

      {/* web */}
      <div className='web-device'>
        <div className='d-flex justify-content-center'>
          <div className='title px-5 py-5' dangerouslySetInnerHTML={{ __html: txt_v.t1 }}></div>
        </div>

        <div className='m-0 p-0 bg-iniciativas-posible'>
          <div className='container-iniciativa1'>
            <div className='iniciativas-text1'>
              <div className='paragraph-iniciativas p-2'>
              <span dangerouslySetInnerHTML={{ __html: txt_v.t2 }}></span> <b><br className='d-xl-block d-none'/><CountUp
                    enableScrollSpy={true}
                    start={'13006'}
                    end={13286}
                    duration={3}
                  /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t3 }}></span>
              </div>
            </div>
          </div>

          <div className='d-flex justify-content-end'>
            <div className='container-iniciativa2'>
              <div className='iniciativas-text2'>
                <div className='paragraph-iniciativas p-2'>
                  <b><CountUp
                    enableScrollSpy={true}
                    start={'3300'}
                    end={4000}
                    duration={3}
                  /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t4 }}></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='d-flex m-0 p-0'>
          <div className='w-50 m-0 p-0'>
            <div className='d-flex justify-content-center align-items-center h-100 m-0 p-0'>
              <div className='paragraph-iniciativas3 w-75'>
                <span dangerouslySetInnerHTML={{ __html: txt_v.t5 }}></span>
                <b> <CountUp
                    enableScrollSpy={true}
                    start={0}
                    end={100}
                    duration={3}
                  /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t6 }}></span>
              </div>
            </div>
          </div>
          <div className='w-50 m-0 p-0'>
            <div className='bg-iniciativa3'>&nbsp;</div>
          </div>
        </div>

        <div className='d-flex m-0 p-0'>
          <div className='w-50 m-0 p-0 bg-iniciativa4'>&nbsp;</div>
          <div className='w-50 m-0 p-0'>
            <div className='d-flex justify-content-center align-items-center h-100 m-0 p-0'>
              <div className='paragraph-iniciativas3 w-75'>
              <span dangerouslySetInnerHTML={{ __html: txt_v.t7 }}></span> <b><CountUp
                    enableScrollSpy={true}
                    start={100}
                    end={167}
                    duration={3}
                  /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t8 }}></span> <b><CountUp
                    enableScrollSpy={true}
                    start={0}
                    end={171}
                    duration={3}
                  /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t9 }}></span>
              </div>
            </div>
          </div>
        </div>

        <div className='d-flex m-0 p-0'>
          <div className='w-50 m-0 p-0'>
            <div className='d-flex justify-content-center align-items-center h-100 m-0 p-0'>
              <div className='paragraph-iniciativas3 w-75 '>
                <span dangerouslySetInnerHTML={{ __html: txt_v.t10 }}></span> <b><CountUp
                    enableScrollSpy={true}
                    start={'2000'}
                    end={2198}
                    duration={3}
                  /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t11 }}></span>
              </div>
            </div>
          </div>
          <div className='w-50 m-0 p-0 '>
            <div className='bg-iniciativa5'>&nbsp;</div>
          </div>
        </div>

        <div className='d-flex m-0 p-0'>
          <div className='w-50 m-0 p-0 bg-participacion-femenina'>&nbsp;</div>
          <div className='w-50 m-0 p-0'>
            <div className='d-flex justify-content-center align-items-center h-100 m-0 p-0'>
              <div className='paragraph-iniciativas3 w-75' dangerouslySetInnerHTML={{ __html: txt_v.t12 }}></div>
            </div>
          </div>
        </div>

        <div className='m-0 p-0 bg-iniciativa6'>
          <div className='text-container-p6'>
            <div className='d-flex justify-content-center'>
              <div className='bg-gradient-iniciativa6 w-75'>
                <div className='paragraph-iniciativas6 px-4 py-2'>
                  <span dangerouslySetInnerHTML={{ __html: txt_v.t13 }}></span> <b><CountUp
                    enableScrollSpy={true}
                    start={250}
                    end={300}
                    duration={3}
                  /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t14 }}></span> <b><CountUp
                    enableScrollSpy={true}
                    start={'900'}
                    end={1000}
                    duration={3}
                  /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t15 }}></span>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      
      
    </div>
  )
}
