import React, { useState } from 'react'
import './Iniciativas.css'
import VideoPlayer from '../../VideoPlayer/VideoPlayer'
import CardAcciones from '../../Contenidos/Acciones/CardAcciones/CardAcciones';
import tenisConAlas from '../../../assets/images/nuestrasAlas/iniciativas/tenis-con-alas.png'
import becasAguila from '../../../assets/images/nuestrasAlas/iniciativas/becas-aguila.png'
import aguilasTecnologicas from '../../../assets/images/nuestrasAlas/iniciativas/aguilas-tecnologicas.png'
import prevencionViolenciaGenero from '../../../assets/images/nuestrasAlas/iniciativas/prevencion-violencia-genero.png'
import equiposExtraordinarios from '../../../assets/images/nuestrasAlas/iniciativas/equipos-extraordinarios.png'
import vistaAguila from '../../../assets/images/nuestrasAlas/iniciativas/vista-aguila.png'
import alasVuelanGuerrero  from '../../../assets/images/nuestrasAlas/iniciativas/alas-vuelan-guerrero.png'
import despensasGuerrero from '../../../assets/images/nuestrasAlas/iniciativas/despensas-guerrero.png'
import ambulancia from '../../../assets/images/nuestrasAlas/iniciativas/ambulancia.png'
import apEducativo from '../../../assets/images/nuestrasAlas/iniciativas/large_alas_4_41e8c305e5.jpg'
import apJornadas from '../../../assets/images/nuestrasAlas/iniciativas/large_NINOS_JUEGA_MAS_FIN_DE_CURSO_18494_2c21301040.jpg'

export default function Iniciativas({english=false}) {

  const [showVideo, setShowVideo] = useState(false);

  const textos = {
    en: {
      t1:`INITIATIVES THAT GENERATE IMPACT`,
      t2:`<b>15 tons of clothing and sneakers</b> were collected for recycling, thanks to the use of <b>3 containers</b> located at the <b>Estadio Azteca</b> and <b>Club América</b>.`,
      t3:`<b>330 scholarships were awarded to young people</b> attending high school and college in public schools throughout the country.`,
      t4:`<b>600 young women benefited</b> from <b>courses and workshops focused on technology</b> that helped them to stand out in the STEM universe.`,
      t5:`<b>588 women benefited</b> from <b>workshops</b> on <b>gender violence and empowerment</b> in <b>Yucatán, Campeche, and Oaxaca</b>.`,
      t6:`<b>119 children and young people with disabilities</b> benefited with a <b>safe and inclusive space</b> to practice <b>soccer</b>.`,
      t7:`<b>2,412 children</b> benefited with <b>eye exams and glasses</b> for public school students.`,
      t8:`NUESTRAS ALAS FLY <br />TO GUERRERO`,
      t9:`<b>41,000 people</b> affected by <b>Hurricane Otis</b> were benefited with <b>food supplies</b> during the most critical days of the emergency.`,
      t10:`Club América and the Estadio Azteca <b>donated the entire box office</b> from two matches. Televisa Foundation, together with its allies, <b>doubled</b> the amount collected for <b>food supplies, reconstruction of housing and educational spaces, media classrooms, and an ambulance for the Cruz Roja in Guerrero</b>.`,
      t11:`TENIS CON ALAS.`,
      t12:`ÁGUILA SCHOLARSHIPS`,
      t13:`TECHNOLOGICAL ÁGUILAS`,
      t14:`GENDER VIOLENCE PREVENTION`,
      t15:`EXTRAORDINARY TEAMS`,
      t16:`VISTA DE ÁGUILA`,
      t17:`SPECIAL DAYS`,
      t18:`<b>282 children and women</b> were impacted with workshops to promote physical activities in schools, special visits to deliver free toys in hospitals and medical centers, as well as mammograms and ultrasounds studies.`,
      t19:`We were the first foundation to support education in Guerrero. We benefited <b>704 students and teachers</b> with <b>3 equipped media classrooms with internet connection and personal training</b> in Acapulco city and Coyuca de Benítez town.`
    },
    es: {
      t1:`Iniciativas que generan impacto:`,
      t2:`<b>15 toneladas recolectadas de ropa y tenis</b> para su reciclaje, gracias al uso de <b>3 contenedores</b> ubicados en el Estadio Azteca y el Club América.`,
      t3:`<b>330 becas otorgadas a jóvenes</b> que cursan media superior y superior en escuelas públicas del país.`,
      t4:`<b>600 mujeres jóvenes</b> beneficiadas con <b>cursos y talleres enfocados en tecnología</b> que las impulsó para destacar dentro del universo STEM.`,
      t5:`<b>588 mujeres beneficiadas</b> con <b>talleres</b> en temas de <b>violencia de género y empoderamiento en Yucatán, Campeche y Oaxaca.</b>`,
      t6:`<b>119 niños, niñas y jóvenes con discapacidad</b> beneficiados con un <b>espacio seguro e incluyente</b> para practicar <b>futbol.</b>`,
      t7:`<b>2,412 niñas y niños</b> beneficiados con <b>estudios de la vista y lentes</b> para estudiantes de escuelas públicas.`,
      t8:`Nuestras Alas Vuelan <br />a Guerrero`,
      t9:`<b>41 mil personas</b> afectadas por el Huracán Otis fueron beneficiadas con <b>despensas</b> durante los días más críticos de la emergencia.`,
      t10:`El Club América y el Estadio Azteca <b>donaron la totalidad de la taquilla</b> de dos partidos. Fundación Televisa junto con sus aliados, <b>duplicaron</b> lo recaudado para <b>víveres, reconstrucción de vivienda y espacios educativos, aulas de medios y una ambulancia para la Cruz Roja de Guerrero.</b>`,
      t11:`Tenis con Alas`,
      t12:`BECAS Águila`,
      t13:`Águilas Tecnológicas`,
      t14:`Prevención DE LA violencia de género`,
      t15:`Equipos extraordinarios`,
      t16:`Vista de Águila`,
      t17:`JORNADAS ESPECIALES​`,
      t18:`<b>282 niños, niñas y mujeres</b> impactados con  clínicas para fomentar la actividad física en las escuelas, entrega de juguetes en hospitales y centros médicos, así como mastografías y ultrasonidos.`,
      t19:`Fuimos los primeros en apoyar la educación en Guerrero, beneficiando a <b>704 alumnos y docentes</b> con la entrega de <b>3 aulas de medios con conexión a internet y capacitación</b> en Acapulco y Coyuca de Benítez.`
    },
  }

  const txt_v = english ? textos.en : textos.es;

  return (
    <div className='Iniciativas'>
      <div className='title'>{txt_v.t1}</div>

      <div className='d-flex justify-content-center'>
        <div className='px-2'>
          <div className='row ms-2 ms-md-0 cards-content'>
            <div className='col-12 col-lg-6'>
              <CardAcciones
                image={tenisConAlas}
                title={txt_v.t11}
                description={txt_v.t2}
                type='image'
                isTitlePurple={false}
              />
            </div>
            <div className='col-12 col-lg-6 mt-4 mt-md-5 mt-lg-0'>
              <CardAcciones
                image={becasAguila}
                title={txt_v.t12}
                description={txt_v.t3}
                type='image'
                isTitlePurple={false}
              />
            </div>
          </div>

          <div className='mt-0 mt-md-0 mt-lg-4'>&nbsp;</div>

          <div className='row ms-2 ms-md-0 cards-content'>
            <div className='col-12 col-lg-6 mt-4 mt-md-5 mt-lg-0'>
              <CardAcciones
                image={aguilasTecnologicas}
                title={txt_v.t13}
                description={txt_v.t4}
                type='image'
                isTitlePurple={false}
              />
            </div>

            <div className='col-12 col-lg-6  mt-4 mt-md-5 mt-lg-0'>
              <CardAcciones
                image={prevencionViolenciaGenero}
                title={txt_v.t14}
                description={txt_v.t5}
                type='image'
                isTitlePurple={false}
              />
            </div>
          </div>

          <div className='mt-0 mt-md-0 mt-lg-4'>&nbsp;</div>

          <div className='row ms-2 ms-md-0 cards-content'>
            <div className='col-12 col-lg-6 mt-4 mt-md-5 mt-lg-0'>
              <CardAcciones
                image={equiposExtraordinarios}
                title={txt_v.t15}
                description={txt_v.t6}
                type='image'
                isTitlePurple={false}
              />
            </div>

            <div className='col-12 col-lg-6  mt-4 mt-md-5 mt-lg-0'>
              <CardAcciones
                image={vistaAguila}
                title={txt_v.t16}
                description={txt_v.t7}
                type='video'
                url='https://youtu.be/XKssgRYuku0'
                isTitlePurple={false}
              />
            </div>
          </div>

          <div className='mt-0 mt-md-0 mt-lg-4'>&nbsp;</div>

          <div className='row ms-2 ms-md-0 cards-content'>
            <div className='col-12 col-lg-6 mt-4 mt-md-5 mt-lg-0'>
              <CardAcciones
                image={apJornadas}
                title={txt_v.t17}
                description={txt_v.t18}
                type='image'
                isTitlePurple={false}
              />
            </div>
          </div>

          <div className='d-flex justify-content-center pt-5'>
            <div className='title2 pt-0 pt-lg-5' dangerouslySetInnerHTML={{ __html: txt_v.t8 }}></div>
          </div>

          <div className='d-flex justify-content-center pt-4'>
            <div className='w-75'>
              {
                !showVideo &&
                <img src={alasVuelanGuerrero} alt='nuestras alas vuelan a guerrero' className='img-fluid' onClick={() => setShowVideo(true)} />
              }
              {
                showVideo &&
                <div className='p-md-4'>
                  <VideoPlayer
                      src="https://youtu.be/mJh_Mc6dcPo"
                      className="w-100"
                      playing={true}
                  />
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      

      {/* mobile */}
      <div className='mobile-device'>
        <div className='row m-0 pt-5'>
          <div className='col-12 m-0 p-0'>
            <img src={despensasGuerrero} alt='despensas guerrero' className='img-fluid' />
          </div>
        </div>
        <div className='row m-0 p-0'>
          <div className='col-12 paragraph2 px-4 pt-4 pb-5' dangerouslySetInnerHTML={{ __html: txt_v.t9 }}></div>
        </div>
        <div className='row m-0 p-0'>
          <div className='col-12 m-0 p-0'>
            <img src={ambulancia} alt='ambulancia guerrero' className='img-fluid' />
          </div>
        </div>
        <div className='row m-0 p-0'>
          <div className='col-12 paragraph2 px-4 pt-4 pb-5' dangerouslySetInnerHTML={{ __html: txt_v.t10 }}></div>
        </div>
        <div className='row m-0 pt-5'>
          <div className='col-12 m-0 p-0'>
            <img src={apEducativo} alt='despensas guerrero' className='img-fluid' />
          </div>
        </div>
        <div className='row m-0 p-0'>
          <div className='col-12 paragraph2 px-4 pt-4 pb-5' dangerouslySetInnerHTML={{ __html: txt_v.t19 }}></div>
        </div>
      </div>

      {/* web */}
      <div className='web-device'>
        <div className='row m-0 pt-5'>
          <div className='col-6 m-0 p-0'>
            <img src={despensasGuerrero} alt='despensas guerrero' className='img-fluid' />
          </div>
          <div className='col-6 m-0 p-0 paragraph2'>
            <div className='d-flex justify-content-center align-items-center h-100'>
              <div className='w-75' dangerouslySetInnerHTML={{ __html: txt_v.t9 }}></div>
            </div> 
          </div>
        </div>
        <div className='row m-0 p-0'>
          <div className='col-6 m-0 p-0 paragraph2'>
            <div className='d-flex justify-content-center align-items-center h-100'>
              <div className='w-75' dangerouslySetInnerHTML={{ __html: txt_v.t10 }}></div>
            </div>
          </div>
          <div className='col-6 m-0 p-0'>
            <img src={ambulancia} alt='ambulancia guerrero' className='img-fluid' />
          </div>
        </div>
        <div className='row m-0 p-0'>
          <div className='col-6 m-0 p-0'>
            <img src={apEducativo} alt='despensas guerrero' className='img-fluid' />
          </div>
          <div className='col-6 m-0 p-0 paragraph2'>
            <div className='d-flex justify-content-center align-items-center h-100'>
              <div className='w-75' dangerouslySetInnerHTML={{ __html: txt_v.t19 }}></div>
            </div> 
          </div>
        </div>
        <div className='pt-5'>&nbsp;</div>
      </div>
      
    </div>
  )
}
