import React from 'react'
import './ComunidadesDigitales.css'
import educacionCalidad from '../../../../assets/images/programas/subsections/fotograficamx/educacion-calidad.png'
import igualdadGenero from '../../../../assets/images/programas/subsections/fotograficamx/igualdad-genero.png'
import alianzasObjetivos from '../../../../assets/images/programas/subsections/fotograficamx/alianzas-objetivos.png'
import fb from '../../../../assets/images/iconos/fb.svg'
import ig from '../../../../assets/images/iconos/ig.svg'
import x from '../../../../assets/images/iconos/x.svg'
import Progredes from '../../Progredes/Progredes'
import CountUp from 'react-countup';

export default function ComunidadesDigitales({english=false}) {
  const social= [
    {
        link:'https://www.facebook.com/Fotografica.FundacionTelevisa',
        icon: fb,
        nombre:'Fotográfica.Mx'
    },
    {
        link:'https://www.instagram.com/fotograficamx ',
        icon: ig,
        nombre:'fotograficamx'
    },
    {
      link:'https://twitter.com/FotograficaMX ',
      icon: x,
      nombre:'FotograficaMx'
    }
  ]

  const textos = {
    en: {
        t1:`DIGITAL <br/>COMMUNITIES:`,
        t2:`<b>people</b> make up our community, sharing in favor of audiovisual culture.`,
        t3:`UN Sustainable Development Goals`,
        t4:`FotográficaMX is aligned with the 2030 agenda, contributing <br class='d-lg-block d-none'/> directly to quality education and gender equality.`
    },
    es:{
      t1:`comunidades <br/> digitales:`,
      t2:`<b>personas</b> conforman nuestra comunidad, compartiendo a favor de la cultura audiovisual.`,
      t3:`Objetivos de Desarrollo Sostenible de la ONU`,
      t4:`FotográficaMX está alineado con la agenda 2030, contribuyendo <br class='d-lg-block d-none'/> directamente a la educación de calidad y la igualdad de género.`
    }
  }

  const txt_v = english ? textos.en : textos.es;

  return (
    <div className='ComunidadesDigitales'>
      <div className='row m-0 p-0'>
        <div className='col-12 col-lg-6 m-0 p-0 bg-light'>
          <div className='d-flex justify-content-center justify-content-lg-start align-items-center h-100 p-4'>
            <div className='title' dangerouslySetInnerHTML={{ __html: txt_v.t1 }}></div>
          </div>
        </div>
        <div className='col-12 col-lg-6 m-0 p-0  bg-gray'>
          <div className='d-flex justify-content-center py-5 px-4 px-md-5'>
            <ul className='comunidades'>
                <li className=''>
                <div className='par-comunidad-digital px-0 px-md-5'>
              <b><CountUp
                enableScrollSpy={true}
                start={'162002'}
                end={162142}
                duration={3}
              /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t2 }}></span>
              </div>
            </li>
            </ul>
            
          </div>
        </div>
      </div>

      <div className='row m-0 p-0 bg-purple'>
        <div className='col-12 col-lg-8 m-lg-auto m-0 p-0 pt-lg-5'>
          <div className='title-onu pt-4 px-5'>
            {txt_v.t3}
          </div>
          <div className='p-0 m-0 px-md-5 px-lg-0'>
            <div className='paragraph-onu py-4 px-5 py-lg-2' dangerouslySetInnerHTML={{ __html: txt_v.t4 }}></div>
          </div>
        </div>
        <div className='col-12 col-lg-4 m-0 p-0'>
          <div className='d-flex justify-content-center'>
            {/* mobile */}
            <div className='mobile-device'>
              <div className='row m-0 px-5 pb-5'>
                <div className='grid grid-cols-2 gap-4 mt-4'>
                  <div className='d-flex justify-content-end'>
                    <img src={educacionCalidad} alt='Fin de la pobreza' className='img-objetivos'/>
                  </div>
                  <div className='d-flex justify-content-start'>
                    <img src={igualdadGenero} alt='Fin de la pobreza' className='img-objetivos'/>
                  </div>
                </div>
                <div className='grid grid-cols-2 gap-4 mt-4'>
                  <div className='d-flex justify-content-end'>
                    <img src={alianzasObjetivos} alt='Fin de la pobreza' className='img-objetivos'/>
                  </div>
                  <div className='d-flex justify-content-start'>&nbsp;</div>
                </div>
              </div>
            </div>
            {/* web */}
            <div className='web-device'>
              <div className='d-flex p-5'>
                <div className='d-flex pt-4'>
                  <div className='d-flex align-items-center h-100'>
                    <div className='d-flex'>
                      <div className='px-2 w-card-custom'>
                        <img src={educacionCalidad} alt='Fin de la pobreza' className='w-100'/>
                      </div>
                      <div className='px-2 w-card-custom'>
                        <img src={igualdadGenero} alt='Fin de la pobreza' className='w-100'/>
                      </div>
                      <div className='px-2 w-card-custom'>
                        <img src={alianzasObjetivos} alt='Fin de la pobreza' className='w-100'/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
        <div className='col-12 pt-4'>&nbsp;</div>
      </div>

      <div className='row m-0 p-0'>
        <Progredes 
          sitio = 'https://fotografica.mx/'
          redes = {social}
          english={english}
        />
      </div>
    </div>
  )
}
