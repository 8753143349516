import React, { useRef, useState } from 'react'
import './CardSlider.css'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import ItemCardSlider from './ItemCardSlider/ItemCardSlider';

export default function CardSlider(
  {items}
) {

  const [currentItems, setCurrentItems] = useState(items);
  const [stopVideo, setStopVideo] = useState(null);

  const thumbsRef = useRef();

  const options = {
    type: 'loop', // Enable loop playback
    perPage: 1, // Show 3 slides at a time
    arrows: true, // Show navigation arrows
    perMove   : 1,
    pagination: true,
    cloneStatus:false
  };

  const handleSlideChanged = (event) => {
    const theItems = currentItems;
    theItems.forEach(element => {
      element.playing = false;
    });
    setCurrentItems(theItems);
    setStopVideo(Math.random());
  };

  return (
    <div className='CardSlider'>
      <div>
        <Splide
          options={ options }
          ref={ thumbsRef }
          aria-label="The carousel with thumbnails. Selecting a thumbnail will change the main carousel"
          onMove={handleSlideChanged}
        >
          {
            currentItems.map((item, index) => (
              <SplideSlide key={ index }>
                <ItemCardSlider
                  item={item}
                  stopVideo={stopVideo}
                />
              </SplideSlide>
            ))
          }
        </Splide>
      </div>
    </div>
  )
}
