import React from 'react'
import'./Testimonios.css'
import Carousel from '../../../Carousel/Carousel'
import iconoTest from '../../../../assets/images/programas/cuantrix/testimonio.svg'
import thumbnailCuantrix1 from '../../../../assets/images/home/testimonios/thumbnails/cuantrix.jpg'
import thumbnailCuantrix2 from '../../../../assets/images/programas/cuantrix/thumbnails/cuantrix2.png'

const items = [
    {
        type: 'video',
        image: thumbnailCuantrix1,
        videoUrl: 'https://www.youtube.com/watch?v=qtGoyfYOxG4&feature=youtu.be'
    },
    {
        type: 'video',
        image: thumbnailCuantrix2,
        videoUrl: 'https://www.youtube.com/watch?v=-kax-YmzwLg&feature=youtu.be'
    }
];
export default function Testimonios({english=false}) {
    const textos = {
        en: {
            t1:`READ THEIR TESTIMONIALS`
        },
        es:{
            t1:`CONOCE SUS TESTIMONIOS`
        }
    }

    const txt_v = english ? textos.en : textos.es;
  return (
    <div className='test-bg'>
        <div className="container  pb-5">
            <div className="row text-center">
                <img src={iconoTest} alt="" className="img-logo" />
                <h2 className="testimonios-titulo">{txt_v.t1}</h2>
            </div>
            <div className="row">
                <Carousel 
                    items={items}
                    arrowsColor='#3F8EFF'
                />
            </div>
        </div>
    </div>
  )
}
