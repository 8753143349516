import React from 'react'
import './ProgramasComunidadesDigitales.css'
import CountUp from 'react-countup';

export default function ProgramasComunidadesDigitales({english=false}) {
  const textos = {
    en: {
        t1:`DIGITAL <br />COMMUNITIES:`,
        t2:`More than `,
        t3:`<b>followers</b> in our digital communities.`,
        t4:`<b>visits</b> on <b>posible.org</b> with`,
        t5:`active <b>users</b> in 2023.`,
    },
    es:{
        t1:`comunidades <br />digitales:`,
        t2:`Más de `,
        t3:`<b>seguidores</b> en nuestras comunidades digitales.`,
        t4:`<b>visitas</b> en <b>posible.org</b> con`,
        t5:`<b>usuarios</b> activos en 2023.`,
    }
  }

  const txt_v = english ? textos.en : textos.es;
  return (
    <div className='ProgramasComunidadesDigitales'>
      <div className='row m-0 p-0'>
        <div className='col-12 col-lg-6 m-0 p-0 bg-light-comunidades2'>
          <div className='d-flex justify-content-center align-items-center h-100'>
            <div className='comunidades-title p-4' dangerouslySetInnerHTML={{ __html: txt_v.t1 }}></div>
          </div>
        </div>
        <div className='col-12 col-lg-6  m-0 p-0 bg-light-comunidades'>
          <div className='d-flex justify-content-center align-items-center h-100'>
            <div className='par-comunidad-digital p-5'>
              <div className='d-flex'>
                <div className='bullet'>&nbsp;</div>
                <div className='ms-2'>
                  <span dangerouslySetInnerHTML={{ __html: txt_v.t2 }}></span> <b><CountUp
                      enableScrollSpy={true}
                      start={'635999'}
                      end={637000}
                      duration={3}
                    /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t3 }}></span>
                  {/* Más de <b><CountUp
                      enableScrollSpy={true}
                      start={'635999'}
                      end={637000}
                      duration={3}
                    /> seguidores</b> <br className="d-block d-lg-none" /> en nuestras comunidades digitales encuentran la <span>motivación</span> para dar vida a sus proyectos. <b><CountUp
                      enableScrollSpy={true}
                      start={390000}
                      end={390633}
                      duration={5}
                    /> visitas</b><br className="d-block d-md-none" /> en <b>posible.org</b><br className="d-block d-lg-none" /> con <span>
                      <CountUp
                      enableScrollSpy={true}
                      start={'38088'}
                      end={38688}
                      duration={3}
                    /> usuarios</span> activos en 2023. */}
                </div>
              </div>

              <div className='d-flex pt-4'>
                <div className='bullet'>&nbsp;</div>
                <div className='ms-2'>
                  <b><CountUp
                      enableScrollSpy={true}
                      start={'390600'}
                      end={390633}
                      duration={3}
                    /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t4 }}></span> <b><CountUp
                      enableScrollSpy={true}
                      start={'38088'}
                      end={38688}
                      duration={3}
                    /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t5 }}></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
