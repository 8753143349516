import React, { useCallback, useEffect, useRef, useState } from 'react'
import './Carousel.css'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import VideoPlayer from '../VideoPlayer/VideoPlayer';

export default function Carousel(
  {items = [], arrowsColor = '#00B9F1'}
) {

  const [fixedWidth, setFixedWidth] = useState(330);
  const [fixedHeight, setFixedHeight] = useState(169);
  const [first, setFirst] = useState(true);

  const [currentSlide, setCurrentSlide] = useState({});
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [playing, setPlaying] = useState(false);

  /**
   * The thumbnail Splide component.
   */
  const thumbsRef = useRef();

  const handleSlideChanged = (event) => {
    setTimeout(() => {
      setCurrentSlide(items[thumbsRef.current?.splide.index]);
      setCurrentSlideIndex(thumbsRef.current?.splide.index);
      setPlaying(true);
    }, 500);
  };

  const thumbsOptions = {
    type        : 'carousel',
    rewind      : true,
    gap         : '1rem',
    pagination  : false,
    fixedWidth  : fixedWidth,
    fixedHeight : fixedHeight,
    cover       : false,
    focus       : 'auto',
    isNavigation: true,
    style: {border: '1px solid red'}
  };

  const handleBack = useCallback(() => {
    const newIndex = currentSlideIndex - 1;
    setCurrentSlideIndex(newIndex);
    setCurrentSlide(items[newIndex]);
  }, [currentSlideIndex, items]);

  const handleNext = useCallback(() => {
    const newIndex = currentSlideIndex + 1;
    setCurrentSlideIndex(newIndex);
    setCurrentSlide(items[newIndex]);
  }, [currentSlideIndex, items]);

  useEffect(() => {
    if (first) {
      setFirst(false);
      setCurrentSlide(items[0]);
      if (window.innerWidth < 768) {
        setFixedWidth(100);
        setFixedHeight(60);
      } else {
        if (window.innerWidth < 1024) {
          setFixedWidth(150);
          setFixedHeight(100);
        }
      }
    }
  }, [first, items]);

  return (
    <div className='Carousel'>
      <div className='m-0 p-2 p-lg-0 w-100 h-auto position-relative z-1'>
        {
          currentSlide.type === 'image' &&
          <img src={ currentSlide.image } alt={ `item slide` } className='item-img'/>
        }
        {
          currentSlide.type === 'video' && !playing &&
          <img src={ currentSlide.image } alt={ `item slide` } className='item-img' onClick={() => setPlaying(true)}/>
        }
        {
          currentSlide.type === 'video' && playing &&
          <VideoPlayer src={currentSlide.videoUrl} playing={playing} />
        }
      </div>
      <div className='mx-0 w-100 h-auto d-flex justify-content-between position-relative z-3 arrows-position'>
        <div className='w-50'>
          {
            currentSlideIndex > 0 &&
            <div className='arrows-text text-start back pt-2 pb-4'>
              <label onClick={handleBack}>
                <b>&lt;&lt;</b> Anterior
              </label>
            </div>
          }
        </div>
        <div className='w-50'>
          {
            currentSlideIndex < (items.length - 1) &&
            <div className='arrows-text text-end next pt-2 pb-4'>
              <label onClick={handleNext}>
                Siguiente <b>&gt;&gt;</b>  
              </label>
            </div>
          }
        </div>
      </div>

      <div className='thumbnails-section'>
        <Splide
          options={ thumbsOptions }
          ref={ thumbsRef }
          aria-label="The carousel with thumbnails. Selecting a thumbnail will change the main carousel"
          onClick={handleSlideChanged}
        >
          {
            items.map((item, index) => (
              <SplideSlide key={ index } >
                <img src={ item.image } alt={ `item ${index}` } className='item-img'/>
              </SplideSlide>
            ))
          }
        </Splide>
      </div>
    </div>
  )
}
