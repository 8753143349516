import React from 'react'
import './EnAccion.css'

export default function EnAccion() {
  return (
    <div className='EnAccion'>
      {/* mobile */}
      <div className='mobile-device'>
        <div className='d-flex justify-content-center w-100 pt-2'>
          <div className='title bg-rectangle p-3'>
            Niñas y mujeres <br/>en acción
          </div>
        </div>
        <div className='paragraph1'>
          <b>Fundación Televisa</b> está alineada con la <b>Agenda 2030</b> de la <b>Organización de las Naciones Unidas</b> desde diferentes perspectivas,  entre ellas la Igualdad de Género.
        </div>
        <div className='paragraph1'>
          Procuramos facilitar a las <b>niñas y mujeres</b> igualdad en el acceso a la <b>educación, atención médica, oportunidades laborales dignas</b> y <b>representación</b> dentro de <b>todas</b> las esferas sociales.
        </div>
        <div className='mt-5'>&nbsp;</div>
      </div>

      {/* web */}
      <div className='web-device'>
        <div className='row'>
          <div className='col-5'>
            <div className='d-flex items-center'>
              <div className='title bg-rectangle'>
                Niñas y mujeres<br /> en acción
              </div>
            </div>
          </div>
          <div className='col-7'>
            <div className='paragraph1 pt-5'>
              <b>Fundación Televisa</b> está alineada con la <b>Agenda 2030</b> de la <b>Organización de las Naciones Unidas</b> desde diferentes perspectivas, entre ellas la Igualdad de Género.
            </div>
            <div className='paragraph1 pt-5'>
              Procuramos facilitar a las <b>niñas y mujeres</b> igualdad en el acceso a la <b>educación, atención médica, oportunidades laborales dignas</b> y <b>representación</b> dentro de <b>todas</b> las esferas sociales.
            </div>
          </div>
        </div>
        <div className='mt-5'>&nbsp;</div>
      </div>

      <div className='bg-purple d-flex align-items-center'>
        <div className='title2'>
          Desde nuestras iniciativas, fortalecemos y empoderamos a niñas y mujeres.
        </div>
      </div>

      {/* mobile */}
      <div className='mobile-device'>
        <div className='bg-gray d-flex align-items-center'>
          <div className='title3'>
            <b>Más de 22.4 millones de personas</b> alcanzadas por televisión, fueron impactadas con nuestros mensajes.
          </div>
        </div>
        <div className='bg-blue d-flex align-items-center'>
          <div className='title4'>
            Recordemos que la igualdad de género hace a las sociedades libres.
          </div>
        </div>
      </div>

      {/* web */}
      <div className='web-device'>
        <div className='row p-0 m-0'>
          <div className='col bg-gray d-flex align-items-center justify-content-center'>
            <div className='title3 w-75'>
              <b>Más de 22.4 millones de personas</b> alcanzadas por televisión, fueron impactadas con nuestros mensajes.
            </div>
          </div>
          <div className='col bg-blue d-flex justify-content-center align-items-center pt-2'>
            <div className='title4'>
              Recordemos que la igualdad <br/> de género hace a las <br/> sociedades libres.
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}
