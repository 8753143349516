  import React from 'react'
import '../Aliados.css'
import logo from '../../../assets/images/aliados/logos/logo_empieza 1.png'
import CountUp from 'react-countup'

export default function Temprano({english=false}) {
  const textos = {
    en: {
      t1:`Many thanks to`,
      t2:`UNICEF, Vroom, public and private organizations, and institutions.`,
      t3:`Our allies in the Empieza Temprano program in 2023 were:`,
      t4:`Bezos Family Foundation (VROOM), LEGO Foundation, UNICEF, IMSS Bienestar, Colectivo de la Primera Infancia, Fundación Compartamos, Fundación COPPEL, Fundación Carulla aeioTU, Televisa Univisión, Un Kilo de Ayuda, ProMazahua, Pro niñez, Fundación Hospital ABC, Yo Quiero Yo Puedo, Sanando Heridas, Tseinan.`,
    },
    es: {
      t1:`Muchas gracias a`,
      t2:`UNICEF, Vroom, organizaciones e instituciones públicas y privadas.`,
      t3:`Nuestros aliados del programa Empieza Temprano en 2023 fueron:`,
      t4:`Bezos Family Foundation (Vroom), LEGO Foundation, UNICEF, IMSS Bienestar, Colectivo de la Primera Infancia, Fundación Compartamos, Fundación COPPEL, Fundación Carulla aeioTU, Televisa Univisión, Un Kilo de Ayuda, Pro Mazahua, Pro niñez, Fundación Hospital ABC, Yo Quiero Yo Puedo, Sanando Heridas, Tseinan.`,
    },
  }
  const txt_v = english ? textos.en : textos.es;

  return (
    <div>
    <div className="container-fluid">
    <div className="row">
      <div className="col-6 m-auto  pb-lg-0 pb-4 hero-content text-center">
        <img src={logo} alt="Becalos" className="img-fluid pt-md-5 p-lg-5 pt-4  w-75" />
      </div>
      <div className="col-lg-6 bg-color text-start ">
        <div className='mt-lg-4'>
        <h2 className="h-prog t-mv pt-0 pt-lg-5">{txt_v.t1}</h2> 
          <p className="par">{txt_v.t2}</p>
        </div>
      </div>
    </div>
    <div className="row bg-aliados justify-content-center py-5">
        <h2 className="sub-title my-lg-5  mb-md-3">{txt_v.t3}</h2>
        <div className="col-lg-9 text-start col-11">
          <p className="par-aliados pe-xl-2">{txt_v.t4}</p>
    </div>
  </div>
</div>
</div>
  )
}
