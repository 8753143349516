import React from 'react'
import './Comunidades.css'
import img1 from '../../../../assets/images/programas/cuantrix/S-WEB-Goal-01 4 (1).png'
import img2 from '../../../../assets/images/programas/cuantrix/S_SDG_PRINT-08 2.png'
import img3 from '../../../../assets/images/programas/cuantrix/S_SDG_PRINT-10 1.png'
import CountUp from 'react-countup'

export default function Comunidades({english=false}) {
    const textos = {
        en: {
            t1:`DIGITAL<br />COMMUNITIES:`,
            t2:`<span class="highlight"> 4.8 million people </span>have been reached through our<span class="highlight"> social networks.</span>`,
            t3:`<span class="highlight">users </span> <br class="d-md-none d-block" />visited<span class="highlight"> cuantrix.mx</span>`,
            t4:`<span class="highlight">people </span><br class="d-md-none d-block" /> make up our<span class="highlight"> online communities.</span>`,
            t5:`UN Sustainable Development Goals`,
            t6:`Cuantrix is aligned with the 2030 Agenda, contributing directly to the goal of quality education, economic growth, and reduction of inequalities.`
        },
        es:{
            t1:`comunidades <br />digitales:`,
            t2:`<span class="highlight"> 4.8 millones de personas  </span>han sido alcanzadas a través de nuestras<span class="highlight"> redes sociales.</span>`,
            t3:`<span class="highlight">usuarios </span> <br class="d-md-none d-block" />visitaron<span class="highlight"> cuantrix.mx</span>`,
            t4:`<span class="highlight">personas </span><br class="d-md-none d-block" /> conforman nuestras<span class="highlight"> comunidades en línea.</span>`,
            t5:`Objetivos de Desarrollo Sostenible de la ONU`,
            t6:`Cuantrix está alineado con la Agenda 2030, contribuyendo directamente al objetivo de educación de calidad, crecimiento económico y reducción de las desigualdades.`
        }
    }

    const txt_v = english ? textos.en : textos.es;

  return (
    <div>
        <div className="container-fluid">
            {/* <div className="row ">
                <div className="col-lg-6 m-auto py-5">
                    <p className="ctx-com-quote"><span className='bold'>Cuantrix.mx</span> impacta a docentes y estudiantes, generando un rico intercambio de conocimiento.</p>
                </div>
            </div> */}
            <div className="row bg-com-dig-light">
                <div className="col-lg-6 m-auto px-md-5 px-3">
                    <h2 className="comunidades-digitales-titulo py-5 py-lg-0" dangerouslySetInnerHTML={{ __html: txt_v.t1 }}></h2>
                </div>
                <div className="col-lg-6 p-0 com-bg-gris m-auto">
                    <div className="row com-h align-items-center">
                        <div className="col ">
                            <div className='d-flex'>
                                <div className='bullet'>&nbsp;</div>
                                <div className='m-0 p-0'>
                                    <p className="comunidades-digitales-items px-2" dangerouslySetInnerHTML={{ __html: txt_v.t2 }}></p>
                                </div>
                            </div>
                            <div className='d-flex pt-0 pt-md-4'>
                                <div className='bullet'>&nbsp;</div>
                                <div className='m-0 p-0'>
                                    <p className="comunidades-digitales-items px-2">
                                        <span className="highlight"> <CountUp
                                            enableScrollSpy={true}
                                            start={'25244'}
                                            end={25344}
                                            duration={3}/></span> <span dangerouslySetInnerHTML={{ __html: txt_v.t3 }}></span>
                                    </p>
                                </div>
                            </div>
                            <div className='d-flex pt-0 pt-md-4'>
                                <div className='bullet'>&nbsp;</div>
                                <div className='m-0 p-0'>
                                    <p className="comunidades-digitales-items px-2">  
                                        <span className="highlight"> <CountUp
                                            enableScrollSpy={true}
                                            start={'42488'}
                                            end={42688}
                                            duration={3}/></span> <span dangerouslySetInnerHTML={{ __html: txt_v.t4 }}></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row bg-nar pb-5 pb-md-0">
                <div className="col-lg-7 mx-auto px-4 pt-5 pt-lg-5 pb-4  ">
                <h2 className="title-onu">{txt_v.t5}</h2>
                <p className="paragraph-onu pt-4">
                    {txt_v.t6}
                    {/* <span className='bold'>Cuantrix </span> está alineado con la Agenda 2030, contribuyendo directamente a tres Objetivos de Desarrollo Sostenible. */}
                </p>
                </div>
                <div className="col-lg-5 py-md-5 px-lg-5">
                    <div className='row m-0 p-0'>
                        <div className='col-6 col-lg-3 offset-lg-5 ps-2 pe-2 ps-lg-4 pe-lg-0'>
                            <img src={img1} alt="" className="img-objetivos" />
                        </div>
                        <div className='col-6 col-lg-3 px-2'>
                            <img src={img2} alt="" className="img-objetivos" />
                        </div>
                        <div className='col-6 col-lg-3 offset-lg-8 px-2'>
                            <img src={img3} alt="" className="img-objetivos" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
