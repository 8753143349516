import './App.css';
import { useEffect,useState } from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import Home from './pages/Home/Home';
import Aliados from './pages/Aliados/Aliados'
import React from 'react';
import Igualdad from './pages/Igualdad/Igualdad';
import Contenidos from './pages/Contenidos/Contenidos';
import Comunicacion from './pages/Comunicacion/Comunicacion';
import Foundation from './pages/Foundation/Foundation';
import NuestrasAlas from './pages/NuestrasAlas/NuestrasAlas';
import Desastres from './pages/Desastres/Desastres';
import Programas from './pages/Programas/Programas';
import FotograficaMx from './pages/Programas/FotograficaMx/FotograficaMx';
import ProgramasPosible from './pages/Programas/ProgramasPosible/ProgramasPosible'
import ReconocimientosFundacion from './pages/ReconocimientosFundacion/ReconocimientosFundacion';



function App() {
  const [showButton, setShowButton] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smoothly scrolling
    });
  };
  return (
    <Router basename="/informe2023">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/nuestros-aliados" element={<Aliados />} />
          <Route path="/igualdad" element={<Igualdad />} />
          <Route path="/contenidos" element={<Contenidos />} />
          <Route path="/comunicacion" element={<Comunicacion />} />
          <Route path="/televisa-foundation" element={<Foundation />} />
          <Route path="/nuestras-alas" element={<NuestrasAlas />} />
          <Route path="/desastres-naturales" element={<Desastres />} />
          <Route path="/programas" element={<Programas />} />
          <Route path="/reconocimientos" element={<ReconocimientosFundacion />} />

          {/* versionEn */}
          <Route path="/en/" element={<Home version={true}/>} />
          <Route path="/en/nuestros-aliados" element={<Aliados version={true}/>} />
          <Route path="/en/comunicacion" element={<Comunicacion version={true}/>} />
          <Route path="/en/televisa-foundation" element={<Foundation version={true}/>} />
          <Route path="/en/nuestras-alas" element={<NuestrasAlas version={true}/>} />
          <Route path="/en/desastres-naturales" element={<Desastres version={true}/>} />
          <Route path="/en/programas" element={<Programas version={true}/>} />
          <Route path="/en/reconocimientos" element={<ReconocimientosFundacion version={true}/>} />

        </Routes>
        {showButton && (
        <button onClick={scrollToTop} className="back-to-top">
          <span className='arrow'>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path fill="#ffffff" d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"/></svg>
            </span>
        </button>
      )}
    </Router>
  );
}

export default App;
