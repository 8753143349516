import React from 'react'
import './Comunicacion.css'
// import HeaderComunicacion from '../../componentes/Comunicacion/HeaderComunicacion/HeaderComunicacion'
import Impactamos from '../../componentes/Comunicacion/Impactamos/Impactamos'
import Impacto from '../../componentes/Comunicacion/Impacto/Impacto'
import Telenovelas from '../../componentes/Comunicacion/Telenovelas/Telenovelas'
import Footer from '../../componentes/Footer/Footer'
// import NuestrasAcciones from '../../componentes/Comunicacion/NuestrasAcciones/NuestrasAcciones'
import HeaderSection from '../../componentes/HeaderSection/HeaderSection'
import hero from '../../assets/images/comunicacion/hero.png'

import item1 from '../../assets/images/items-footer/nuestras-alas-del-club-america.png'
import item2 from '../../assets/images/items-footer/televisa-foundation.png'
import item3 from '../../assets/images/items-footer/desastres-naturales.png'

import mobile from '../../assets/images/items-footer/nuestras-alas-del-club-america-mobile.png'

const itemsComunicacion = [
  {
    image: item1,
    title: {
      en:'NUESTRAS ALAS DEL CLUB AMÉRICA',
      es:'NUESTRAS ALAS DEL CLUB AMÉRICA',
    },
    link: '/nuestras-alas'
  },
  {
    image: item2,
    title: {
      en:'TELEVISA FOUNDATION',
      es:'TELEVISA FOUNDATION',
    },
    link: '/televisa-foundation'
  },
  {
    image: item3,
    title: {
      en:'SUPPORT IN NATURAL DISASTERS',
      es:'Apoyo en Desastres NATURALES',
    },
    link: '/desastres-naturales'
  }
];

const itemComunicacionMobile = [
  {
    image: mobile,
    title: {
      en:'NUESTRAS ALAS DEL CLUB AMÉRICA',
      es:'NUESTRAS ALAS DEL CLUB AMÉRICA',
    },
    link: '/nuestras-alas'
  }
]

export default function Comunicacion({version=false}) {
  const textos = {
    en: {
      t1:`COMMUNICATION EFFORTS <br/>WITH IMPACT `
    },
    es: {
      t1:`Esfuerzos de <br/>COMUNICACIÓN CON IMPACTO`
    }
  }
  const txt_v = version ? textos.en : textos.es;
  return (
    <div className='Comunicacion'>
      {/* <HeaderComunicacion /> */}

      <HeaderSection
        image={hero}
        title={txt_v.t1}
        ajustemov='mov-38'
        english={version}
      />

      <Impactamos english={version}/>

      <Impacto english={version}/>

      {/* <NuestrasAcciones /> */}
      <Telenovelas english={version}/>

      <Footer 
        showLogo={false}
        items={itemsComunicacion}
        itemMobile={itemComunicacionMobile}
        english={version}
      />
    </div>
  )
}
