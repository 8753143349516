import React from 'react'
import nino from '../../../assets/images/home/Nino-1-2.png'
import hat from '../../../assets/images/home/icono-graduado.svg'
import { Button } from '../../Button/Button'
import './Programas.css'
import CountUp from 'react-countup';
import { motion } from 'framer-motion';
import ninioMobile  from '../../../assets/images/home/Nino-1-2-mobile.png'
import { useInView } from 'react-intersection-observer';
import { LazyLoadImage } from 'react-lazy-load-image-component'

export function Programas({english=false}){

  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const variants = {
    hidden: { translateY: '20vh' },
    visible: { translateY: '0vh', transition: { duration: 1 } },
  };

  const [ref2, inView2] = useInView({
    triggerOnce: true,
  });

  const variants2 = {
    hidden: { translateX: '30vw' },
    visible: { translateX: '0vh', transition: { duration: 1 } },
  };

  const textos = {
    en: {
      termi:'/en',
      btns:{
        t1:'EDUCATION',
        t2:'ENTREPRENEURSHIP',
        t3:'NUTRITION, HEALTH AND HOUSING',
        t4:'PHOTOGRAPHIC AND AUDIOVISUAL CULTURE',
      },
      links:{
        l1:'programas'
      },
      mov:{
        t1:`OUR PROGRAMS`,
        t2:`Through our <b>programs and initiatives</b>, we generated <b>opportunities to improve people's quality of life.</b>`,
        t3:`In 2023 we supported`,
        t4:`children, young people, and adults.`,
      },
      desk:{
        t1:`OUR <br/>PROGRAMS`,
        t2:`Through our <b>programs and initiatives</b>, we generated <b>opportunities to improve people's quality of life.</b>`,
        t3:`In 2023 we supported`,
        t4:`children, young people, and adults.`
      }
    },
    es:{
      termi:'',
      btns:{
        t1:'Educación',
        t2:'Emprendimiento',
        t3:'Nutrición, salud y vivienda',
        t4:'Cultura fotográfica y audiovisual',
      },
      links:{
        l1:'programas'
      },
      mov:{
        t1:`NUESTROS PROGRAMAS`,
        t2:`A través de nuestros <b>programas e iniciativas</b> generamos <b>oportunidades para mejorar la calidad de vida</b> de las personas.`,
        t3:`En el 2023 apoyamos a`,
        t4:`niños, jóvenes y adultos.`,
      },
      desk:{
        t1:`NUESTROS <br/> PROGRAMAS`,
        t2:`A través de nuestros <b>programas e iniciativas</b> generamos <b>oportunidades para mejorar la calidad de vida</b> de las personas.`,
        t3:`En el 2023 apoyamos a`,
        t4:`niños, jóvenes y adultos.`
      }
    }
  }

  const txt_v = english ? textos.en : textos.es;

  return (
    <div>
      <div className='container-fluid m-0 p-0'>
        {/* mobile */}
        <div className='mobile-device'>
          <div className='row'>
            <div className='col-12 bg-gray-text'>
              {/* <motion.section
                whileInView ={{ translateX: '0vw' }} // Start offscreen at the rigth
                animate={{ translateX: '20vw' }} // Animate to the left
                transition={{ duration: 0.5 }} // Set animation duration
              > */}
                <div className='d-flex justify-content-center pt-5'>
                <LazyLoadImage
                  src={hat}
                  alt="Image Alt"
                  className='pr-hat'
                />
                  {/* <img src={hat} alt="graduated" className='pr-hat'/> */}
                </div>
                <h2 className='pr-title pt-2'>
                    {txt_v.mov.t1}
                </h2>
                <div className='row px-4'>
                  <div className='pr-parrafo' dangerouslySetInnerHTML={{ __html: txt_v.mov.t2}}></div>
                </div>
                <div className='mt-4'>
                  <div className='pr-title-2'>{txt_v.mov.t3} <br /><b>
                      <CountUp
                        enableScrollSpy={true}
                        start={'785000'}
                        end={785583}
                        duration={4}
                      />
                    </b><br /> {txt_v.mov.t4}
                  </div>
                </div>
                <div className='mt-2'>&nbsp;</div>
              {/* </motion.section> */}
            </div>
          </div>

          <div className='m-0 p-0'>
            {/* <motion.div
              // whileInView ={{ translateY: '0vh' }} // Start offscreen at the bottom
              // animate={{ translateY: '50vh' }} // Animate to the top
              // transition={{ duration: 1 }} // Set animation duration
              variants={variants}
              initial="hidden"
              animate={inView ? 'visible' : 'hidden'}
            > */}
              <LazyLoadImage
                src={ninioMobile}
                width={361}
                height={322}
                alt="Image Alt"
                className='pr-img-nino w-100'
              />
              {/* <img src={ninioMobile} alt="child on position saying hi" className='pr-img-nino w-100'/> */}
            {/* </motion.div> */}
          </div>

          <div className='pt-4 pb-4 pt-md-4 pb-md-5 bg-gray-text'>
            <Button 
              link={txt_v.links.l1}
              btnClass='conoce-mas-blue'
              english={english}
            />
            <div className='pt-0 p-md-2'>&nbsp;</div>
          </div>
        </div>

        {/* web */}
        <div className='web-device'>
          <div className='row m-0 p-0'>
            <div className='col-1-custom px-5 py-3 bg-gray-text text-start pe-5' ref={ref2}>
              <motion.div
                variants={variants2}
                initial="hidden"
                animate={inView2 ? 'visible' : 'hidden'}
              >
                <div className='px-4 py-4'>
                  <img src={hat} alt="graduated" className='pr-hat-align'/>
                  <h2 className='pr-title' dangerouslySetInnerHTML={{ __html: txt_v.desk.t1}}></h2>
                  <p className='pr-parrafo' dangerouslySetInnerHTML={{ __html: txt_v.desk.t2}}></p>
                  <div className='pr-title-2'>{txt_v.desk.t3}
                    <div className='py-4'>
                      <b>
                        <CountUp
                          enableScrollSpy={true}
                          start={'785000'}
                          end={785583}
                          duration={4}
                        />
                      </b>
                    </div>
                    {txt_v.desk.t4}
                  </div>
                  <div className='mt-5'>
                    <Button 
                      link={txt_v.links.l1}
                      btnClass='conoce-mas-blue'
                      english={english}
                    />
                  </div>
                </div>
              </motion.div>
            </div>

            <div className='col-2-custom px-0 bg-gray-text' ref={ref}>
              <motion.div
                variants={variants}
                initial="hidden"
                animate={inView ? 'visible' : 'hidden'}
              >
                <img src={nino} alt="child on position saying hi" className='pr-img-nino'/>
              </motion.div>
            </div>
          </div>
        </div>

        <div className='row m-0 p-0'>
            <div className='col-12 col-lg-3 programa-bg-1'>
              <div className='d-flex justify-content-center align-items-center h-100'>
                <div className='programa-bg-text py-4 py-md-5'>
                  <a href={process.env.PUBLIC_URL +txt_v.termi+"/programas?group=educacion"}>
                    {txt_v.btns.t1} 
                  </a>
                </div>
              </div>
            </div>
            <div className='col-12 col-lg-3 programa-bg-2'>
              <div className='d-flex justify-content-center align-items-center h-100'>
                <div className='programa-bg-text py-4  py-md-5'>
                  <a href={process.env.PUBLIC_URL +txt_v.termi+'/programas?group=emprendimiento'}>
                    {txt_v.btns.t2} 
                  </a>
                </div>
              </div>
            </div>
            <div className='col-12 col-lg-3 programa-bg-3'>
              <div className='d-flex justify-content-center align-items-center h-100'>
                <div className='programa-bg-text py-4  py-md-5'>
                  <a href={process.env.PUBLIC_URL +txt_v.termi+"/programas?group=salud"}>
                    {txt_v.btns.t3} 
                  </a>
                </div>
              </div>
            </div>
            <div className='col-12 col-lg-3 programa-bg-4'>
              <div className='d-flex justify-content-center align-items-center h-100'>
                <div className='programa-bg-text py-4  py-md-5'>
                  <a href={process.env.PUBLIC_URL +txt_v.termi+'/programas?group=cultura'}>
                    {txt_v.btns.t4} 
                  </a>
                </div>  
              </div>
            </div>
        </div>
        
      </div>
    </div>
  )
}
