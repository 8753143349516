import React, { useState } from 'react'
import './Impulsando.css'
import imghead from '../../../../assets/images/programas/tecnolochicas/tecnolochicas.jpg'
import CountUp from 'react-countup'
import VideoPlayer from '../../../VideoPlayer/VideoPlayer';

export default function Impulsando({english=false}) {
    const [showVideo, setShowVideo] = useState(false);
    const textos = {
        en: {
            t1:`Tecnolochicas`,
            t2:`Empowering girls and women to achieve their goals in STEM.`,
            t3:`<span class='highlight'>graduates </span> and`,
            t4:`<span class='highlight'>courses</span> taught in 2023. <br /><br /> We want <b>girls and young women</b> to discover their <b>potential</b> in the world of <b>programming and computer science</b>, expanding their professional aspirations and acquiring skills for their future <b>employability</b>.`,
            t5:`Tecnolochicas trained <span class='bold'>113% more students</span> than the previous year.`
        },
        es: {
            t1:`Tecnolochicas`,
            t2:`Impulsando a niñas y mujeres para que logren alcanzar sus metas en STEM.`,
            t3:`<span class='highlight'>graduadas</span> y`,
            t4:`<span class='highlight'>cursos</span> impartidos en 2023. <br /><br /> Queremos que las <b>niñas y jóvenes</b> descubran su <b>potencial</b> en el universo de la <b> programación y las ciencias computacionales,</b> logrando ampliar sus aspiraciones profesionales, adquiriendo habilidades para su futura <b>empleabilidad.</b>`,
            t5:`Tecnolochicas capacitó a <span class='bold'>113% más de alumnas</span> que el año anterior.`
        }
    }
    
    const txt_v = english ? textos.en : textos.es;

  return (
    <div>
        <div className="container-fluid">
            <div className="row bg-suave">
                <div className="col-lg-5  m-auto inner-box order-lg-first order-last pt-4 p-md-5">
                    <h2 className="main-title-programas">{txt_v.t1}</h2>
                    <p className="main-subtitle-programas">
                        {txt_v.t2}
                    </p>
                    <p className="main-paragraph-programas">
                        <span className='highlight'><CountUp
                            enableScrollSpy={true}
                            start={10000}
                            end={16157}
                            duration={4}/></span> <span dangerouslySetInnerHTML={{ __html: txt_v.t3 }}></span> <span className='highlight'><CountUp
                            enableScrollSpy={true}
                            start={10000}
                            end={32980}
                            duration={4}/></span> <span dangerouslySetInnerHTML={{ __html: txt_v.t4 }}></span>
                    </p>

                </div>
                <div className="col-lg-6 p-0">
                    {
                        !showVideo &&
                        <div className="img-vid">
                        <img src={imghead} alt="fut" className="ctx-img object-fit-cover" onClick={() => setShowVideo(true)} />
                        {/*<img src={play} alt=">" className="overlay-icon" />*/}
                        </div>
                    }
                    {
                        showVideo &&
                        <div className='p-4'>
                        <VideoPlayer
                            src="https://www.youtube.com/watch?v=vJlmEITt9JA"
                            className="w-100"
                            playing={true}
                        />
                        </div>
                    }
                </div>
            </div>
            <div className="row bg-tch justify-content-center">
                <div className="col-md-9 p-0 ">
                    <div className="row mh-250 align-items-center">
                        <div className="col-12 offset-0 col-lg-8 offset-lg-2">
                            <h3 className="tch-quote" dangerouslySetInnerHTML={{ __html: txt_v.t5 }}></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}