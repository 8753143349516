import React, { useCallback } from 'react'
import './Footer.css'
import facebook from '../../assets/images/home/footer/facebook.png'
import instagram from '../../assets/images/home/footer/instagram.png'
import x from '../../assets/images/home/footer/x.png'
import youtube from '../../assets/images/home/footer/youtube.png'
import { motion } from 'framer-motion';
import logoFooter from '../../assets/images/logo-footer.png'
import { useNavigate } from 'react-router-dom'

export default function Footer(
  {
    items = [],
    itemMobile = [],
    showLogo = true,
    english=false
  }
) {

  const navigate = useNavigate();

  const handleClick = useCallback((item) => {
    window.scrollTo({ top:50, left:0, behavior: "instant"});
    setTimeout(() => {
      let ruta = english ? '/en' : '';
      navigate(ruta+item.link);  
    }, 500);
  }, [navigate]);

  const textos = {
    en: {
      t1:`OUR PREVIOUS REPORTS`,
      t2:`LEARN MORE `,
      cr:`ALL RIGHTS RESERVED © TELEVISA FOUNDATION 2024.`
    },
    es: {
      t1:`Nuestros Informes Anteriores`,
      t2:`CONOCE MÁS`,
      cr:`Todos los derechos reservados © Fundación Televisa 2024.`
    }
  }

  const txt_v = english ? textos.en : textos.es;

  return (
    <div className='Footer'>

      {/* mobile */}
      <div className='mobile-device'>

        <div className='row pt-0 pt-md-4'>
          <div className='col-12'>
            <div className='d-flex justify-content-center justify-content-lg-start pt-5 conoce-mas'>
              {/* <img src={logoFooter} alt='logo Televisa' /> */}
                {txt_v.t2}
            </div>
            <div className='d-flex justify-content-center m-0 pt-2'>
              <div className='w-25 conoce-mas-border m-0 p-0'>&nbsp;</div>
            </div>
          </div>
        </div>

        {
          itemMobile.length > 0 &&
          <div
            className='w-100 px-4'
            onClick={() => handleClick(itemMobile[0])}
          >
            <img src={itemMobile[0].image} alt={itemMobile[0].title} className='img-fluid' />
            <div className='conoce-mas-title d-flex justify-content-center'>
              <div className='footer-title-card-width'>
                {english ? itemMobile[0].title.en : itemMobile[0].title.es}
              </div>
            </div>
          </div>
        }

        <div className='row mt-5 p-0'>
          <div className='d-flex justify-content-center col-12'>
            <div className='px-4 pt-4 pt-md-5'>
              <div className='title-footer'>
                {txt_v.t1}
              </div>
              <div className='d-flex justify-content-center'>
                <div className='past-years mt-2 p-2 w-75'>
                  <div className='d-flex justify-content-between'>
                    <div className='nav-link'>
                      <a href='https://informe.fundaciontelevisa.org/informe2022/' target='_blank' rel='noreferrer'>
                        2022
                      </a>
                    </div>
                    <div className='nav-link'>
                      <a href='https://informe.fundaciontelevisa.org/informe2021/' target='_blank' rel='noreferrer'>
                        2021
                      </a>
                    </div>
                    <div className='nav-link'>
                      <a href='https://informe.fundaciontelevisa.org/informe2020/' target='_blank' rel='noreferrer'>
                        2020
                      </a>
                    </div>
                    <div className='nav-link'>
                      <a href='https://informe.fundaciontelevisa.org/informe2019/' target='_blank' rel='noreferrer'>
                        2019
                      </a>
                    </div>
                  </div>
                  <div className='d-flex justify-content-between pt-2'>
                    <div className='nav-link'>
                      <a href='https://informe.fundaciontelevisa.org/informe2018/' target='_blank' rel='noreferrer'>
                        2018
                      </a>
                    </div>
                    <div className='nav-link'>
                      <a href='https://informe.fundaciontelevisa.org/informe2017/' target='_blank' rel='noreferrer'>
                        2017
                      </a>
                    </div>
                    <div className='nav-link'>
                      <a href='https://informe.fundaciontelevisa.org/informe2016/' target='_blank' rel='noreferrer'>
                        2016
                      </a>
                    </div>
                    <div className='nav-link'>
                      <a href='https://informe.fundaciontelevisa.org/informe2015/' target='_blank' rel='noreferrer'>
                        2015
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='d-flex justify-content-center pt-2 pt-md-5'>
            <div className='w-75 the-paragraph'>
              <div className='pt-4 second-paragraph'>
                Todos los derechos reservados © Fundación Televisa 2024.
              </div>
              <div className='derechos-reservados-border mt-2'>&nbsp;</div>
            </div>
          </div>

          <div className='d-flex justify-content-center col-12 pt-0 pt-md-0'>
            <div className='social-icons-container'>
              <motion.section
                whileInView ={{ translateY: '0vh' }} // Start offscreen at the bottom
                animate={{ translateY: '5vh' }} // Animate to the top
                transition={{ duration: 1 }} // Set animation duration
              >
                <div className='social-icons mt-0'>
                  <div className='social-icon'>
                    <a href='https://www.facebook.com/FundacionTelevisa' alt='facebook' target='_blank' rel='noreferrer'>
                      <img src={facebook} alt='Facebook' className='facebook-img' />
                    </a>
                  </div>
                  <div className='social-icon'>
                    <a href='https://www.instagram.com/fundaciontelevisa?igsh=cjRmZWt3NGFsZmZh' alt='Instagram' target='_blank' rel='noreferrer'>
                      <img src={instagram} alt='Instagram' className='others-img' />
                    </a>
                    
                  </div>
                  <div className='social-icon'>
                    <a href='https://x.com/fundtelevisa?s=21' alt='X' target='_blank' rel='noreferrer'>
                      <img src={x} alt='X' className='others-img' />
                    </a>
                  </div>
                  <div className='social-icon'>
                    <a href='https://www.youtube.com/@fundaciontelevisa' alt='Youtube' target='_blank' rel='noreferrer'>
                      <img src={youtube} alt='Youtube' className='youtube-img' />
                    </a>
                  </div>
                </div>
              </motion.section>
            </div>
          </div>
        </div>

        

      </div>

      {/* web */}
      <div className='web-device'>

        <div className='d-flex justify-content-center'>
          <div className='footer-container pt-5'>

            {
              showLogo &&
              <div className='d-flex justify-content-between'>
                <div className='py-4'>
                  <img src={logoFooter} alt='logo Televisa' />
                </div>

                <div className='py-4'>
                  <div className='title-footer'>
                    {txt_v.t1}
                  </div>
                  <div className='d-flex justify-content-center'>
                    <div className='px-0 w-75'>
                      <div className='past-years mt-4'>
                        <div className='d-flex justify-content-between'>
                          <div className='nav-link'>
                            <a href='https://informe.fundaciontelevisa.org/informe2022/' target='_blank' rel='noreferrer'>
                              2022
                            </a>
                          </div>
                          <div className='nav-link'>
                            <a href='https://informe.fundaciontelevisa.org/informe2021/' target='_blank' rel='noreferrer'>
                              2021
                            </a>
                          </div>
                          <div className='nav-link'>
                            <a href='https://informe.fundaciontelevisa.org/informe2020/' target='_blank' rel='noreferrer'>
                              2020
                            </a>
                          </div>
                          <div className='nav-link'>
                            <a href='https://informe.fundaciontelevisa.org/informe2019/' target='_blank' rel='noreferrer'>
                              2019
                            </a>
                          </div>
                        </div>
                        <div className='d-flex justify-content-between pt-2'>
                          <div className='nav-link'>
                            <a href='https://informe.fundaciontelevisa.org/informe2018/' target='_blank' rel='noreferrer'>
                              2018
                            </a>
                          </div>
                          <div className='nav-link'>
                            <a href='https://informe.fundaciontelevisa.org/informe2017/' target='_blank' rel='noreferrer'>
                              2017
                            </a>
                          </div>
                          <div className='nav-link'>
                            <a href='https://informe.fundaciontelevisa.org/informe2016/' target='_blank' rel='noreferrer'>
                              2016
                            </a>
                          </div>
                          <div className='nav-link'>
                            <a href='https://informe.fundaciontelevisa.org/informe2015/' target='_blank' rel='noreferrer'>
                              2015
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }

            <div className='row pt-2'>
              <div className='col-12'>
                <div className='d-flex justify-content-center pt-2 conoce-mas'>
                  {txt_v.t2}
                </div>
                <div className='d-flex justify-content-center m-0 pt-2'>
                  <div className='conoce-mas-border m-0 p-0'>&nbsp;</div>
                </div>
              </div>
            </div>

            {
              items.length > 0 &&
              (
                <div className='d-flex justify-content-between py-4'>
                  {
                    items.map((item, index) => (
                      <div
                        className='w-100 px-4 cursor-pointer'
                        onClick={() => handleClick(item)}
                        key={index}
                      >
                        <img src={item.image} alt={item.title} className='img-fluid w-100' />
                        <div className='conoce-mas-title d-flex justify-content-center'>
                          <div className='footer-title-card-width'>
                            {english ? item.title.en : item.title.es}
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
              )
            }

            <div className='d-flex justify-content-center pt-4'>
              <div className='w-75 the-paragraph'>
                <div className='pt-4 second-paragraph'>
                  {txt_v.cr}
                </div>
                <div className='derechos-reservados-border mt-3'>&nbsp;</div>
              </div>
            </div>

            <div className='d-flex justify-content-center'>
              <div className='w-50'>
                <motion.section
                  whileInView ={{ translateY: '0vh' }} // Start offscreen at the bottom
                  animate={{ translateY: '4vh' }} // Animate to the top
                  transition={{ duration: 1 }} // Set animation duration
                  className='m-0 p-0'
                >
                  <div className='social-icons mt-0'>
                    <div className='social-icon'>
                      <a href='https://www.facebook.com/FundacionTelevisa' alt='facebook' target='_blank' rel='noreferrer'>
                        <img src={facebook} alt='Facebook' className='facebook-img' />
                      </a>
                    </div>
                    <div className='social-icon'>
                      <a href='https://www.instagram.com/fundaciontelevisa?igsh=cjRmZWt3NGFsZmZh' alt='Instagram' target='_blank' rel='noreferrer'>
                        <img src={instagram} alt='Instagram' className='others-img' />
                      </a>
                      
                    </div>
                    <div className='social-icon'>
                      <a href='https://x.com/fundtelevisa?s=21' alt='X' target='_blank' rel='noreferrer'>
                        <img src={x} alt='X' className='others-img' />
                      </a>
                    </div>
                    <div className='social-icon'>
                      <a href='https://www.youtube.com/@fundaciontelevisa' alt='Youtube' target='_blank' rel='noreferrer'>
                        <img src={youtube} alt='Youtube' className='youtube-img' />
                      </a>
                    </div>
                  </div>
                </motion.section>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}
