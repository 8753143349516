import React from 'react'
import './ProgramasValores.css'
import ProgramasValoresHeader from '../../../componentes/Programas/subsections/ProgramasValores/ProgramasValoresHeader/ProgramasValoresHeader'
import ProgramasIniciativasValores from '../../../componentes/Programas/subsections/ProgramasValores/ProgramasIniciativasValores/ProgramasIniciativasValores'
import ProgramasComunidadesValores from '../../../componentes/Programas/subsections/ProgramasValores/ProgramasComunidadesValores/ProgramasComunidadesValores'
// import Footer from '../../../componentes/Footer/Footer'
import FooterProgramas from '../../../componentes/Programas/FooterProgramas/FooterProgramas'
import arrowsTecnolochicas from '../../../assets/images/programas/footer/arrows-tecnolochicas.png'

export default function ProgramasValores(
  {handleSectionSelected,englishp=false}
) {
  return (
    <div className='ProgramasValores'>
      <ProgramasValoresHeader english={englishp}/>

      <ProgramasIniciativasValores english={englishp}/>

      <ProgramasComunidadesValores english={englishp}/>

      {/* <Footer /> */}
      <FooterProgramas
        background='bg-programa-tecnolochicas'
        arrows={arrowsTecnolochicas}
        title='TECNOLOCHICAS'
        color='#8780BD'
        bgImage='bg-program-image-tecnolochicas'
        handleSectionSelected={handleSectionSelected}
        programSection='tecnolochicas'
        english={englishp}
      />
    </div>
  )
}
