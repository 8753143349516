import React, { useState } from 'react'
import './ProgramasValoresHeader.css'
import CountUp from 'react-countup';
import celeb from '../../../../../assets/images/programas/subsections/valores/valores.jpg'
import VideoPlayer from '../../../../VideoPlayer/VideoPlayer';

export default function ProgramasValoresHeader({english=false}) {
  
  const [showVideoP, setShowVideoP] = useState(false);

  const textos = {
    en: {
        t1:`VALORES`,
        t2:`We promote fairer, more peaceful societies.`,
        t3:`<b>22 years</b> bringing <b>Values</b> closer and promoting activities so that <b>girls, boys, young people, and adults</b> reach their <b>wellbeing</b> and improve their <b>social-emotional skills</b> through <b>television</b> and our <b>digital media</b>.`,
        t4:`<b>million people</b> were reached on <b> television </b> and `,
        t5:`<b>million</b> through our <b>social networks</b>.`
    },
    es:{
        t1:`VALORES`,
        t2:`Promovemos sociedades más justas y pacíficas.`,
        t3:`<b>22 años</b> acercando los <b>Valores</b> e impulsando actividades para que <b>niñas, niños, jóvenes y adultos</b> alcancen su <b>bienestar</b> y mejoren sus <b>habilidades socioemocionales</b> a partir de la <b>televisión</b> y nuestros <b>medios digitales</b>.`,
        t4:`<b>millones de personas</b> alcanzadas por <b> televisión </b> y `,
        t5:`<b>millones</b> a través de nuestras <b>redes sociales</b>.`
    }
}

const txt_v = english ? textos.en : textos.es;

  return (
    <div className='ProgramasValoresHeader'>
      {/* mobile */}
      <div className='mobile-device'>
        <div className='m-0 p-0 bg-header'>&nbsp;</div>

        <div className='m-0 p-0 bg-rectangle'>
          <div className='d-flex justify-content-center pt-5'>
            <div className='main-title-programas'>
              {txt_v.t1}
            </div>
          </div>
          <div className='d-flex justify-content-center mt-2'>
            <div className='main-subtitle-programas px-4 px-md-0'>
            {txt_v.t2}
            </div>
          </div>
          <div className='d-flex justify-content-center mt-4'>
            <div className='main-paragraph-programas px-4 pb-5 px-md-5' dangerouslySetInnerHTML={{ __html: txt_v.t3 }}></div>
          </div>
        </div>

        {/* <div className='bg-gray-valores px-4 py-5'>
          <div className='paragraph-valores-2'>
            <b><CountUp
                    enableScrollSpy={true}
                    start={0}
                    end={8}
                    duration={2}
                  /> millones de personas</b> alcanzadas a través de nuestras <b>redes sociales y <CountUp
                    enableScrollSpy={true}
                    start={0}
                    end={29.6}
                    duration={2}
                    decimals={1}
                    decimal='.'
                  /> millones por televisión</b>.
          </div>
        </div> */}

        <div className='bg-blue-valores px-4 py-4 py-md-5'>
          <div className='paragraph-valores-2'>
            <b>29.6</b> <span dangerouslySetInnerHTML={{ __html: txt_v.t4 }}></span> <b>8</b> <span dangerouslySetInnerHTML={{ __html: txt_v.t5 }}></span>
          </div>
        </div>

      </div>

      {/* web */}
      <div className='web-device'>
        <div className='row m-0 p-0 bg-rectangle'>
          {/* <div className='col-6 m-0 p-0'>
            <div className='m-0 p-0 bg-rectangle p-5'>
              <div className='w-75'>
                <div className='main-title-programas'>
                  VALORES
                </div>
                <div className='main-subtitle-programas pt-2'>
                  Promovemos sociedades más justas y pacíficas.
                </div>
                <div className='main-paragraph-programas pt-4'>
                <b>22 años</b> acercando los <b>Valores</b> e impulsando actividades para que <b>niñas, niños, jóvenes y adultos</b> alcancen su <b>bienestar</b> y mejoren sus <b>habilidades socio emocionales</b> a partir de la <b>televisión</b> y nuestros <b>medios digitales</b>.                </div>
              </div>
            </div>
          </div> */}
          <div className="col-lg-5  m-auto inner-box order-lg-first order-last p-xxl-4 p-lg-4 p-md-5 px-4 py-3">
              <h2 className="main-title-programas mb-md-2 mb-lg-2">
              {txt_v.t1}
              </h2>
              <p className="main-subtitle-programas mb-md-4">
              {txt_v.t2}
              </p>
              <p className="main-paragraph-programas mb-md-4" dangerouslySetInnerHTML={{ __html: txt_v.t3 }}></p>
              {/* <p className="main-paragraph-programas">
              <b>Empieza Temprano</b> promueve el <b>desarrollo integral</b> de todas las niñas y niños de 0 a 6 años. 
              Brindamos herramientas educativas a los padres de familia, cuidadores y profesionales vinculados al cuidado de la infancia.
              </p> */}
          </div>
          <div className="col-lg-6 p-0">
            {
                !showVideoP &&
                <img src={celeb} alt="fut" className="et-img object-fit-cover" onClick={() => setShowVideoP(true)} />
            }
            {
                showVideoP &&
                <div className='p-4'>
                    <VideoPlayer
                        src="https://www.youtube.com/watch?v=2OxgVHsyfn8"
                        className="w-100"
                        playing={true}
                    />
                </div>
            }
          </div>
        </div>

        <div className='row bg-blue-valores justify-content-center'>
          <div className='col-lg-12 m-auto px-3'>
              <div className='d-flex justify-content-center'>
                <div className='paragraph-valores-2  p-5 w-75'>
                  <b><CountUp
                      enableScrollSpy={true}
                      start={0}
                      end={29.6}
                      duration={2}
                      decimals={1}
                      decimal='.'
                    /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t4 }}></span> <b>
                      <CountUp
                      enableScrollSpy={true}
                      start={0}
                      end={8}
                      duration={2}
                    /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t5 }}></span>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  )
}
