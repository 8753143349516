import React from 'react'
import CountUp from 'react-countup'
import './Programas.css'
import DSN from '../../../assets/images/desastres/DSC_0675 1.png'
import gxm from '../../../assets/images/desastres/golxme.png'
import becalos from '../../../assets/images/desastres/DSC_0327.png'
import playcity from '../../../assets/images/desastres/play city 2.png'

export default function Programas({english=false}) {
    const textos = {
        en: {
            t1:`All the goals scored in the <span class='hyper'>Gol por Mexico</span> program during November and December were transformed into`,
            t2:`<span class='hyper'>food pantries</span> benefiting`,
            t3:`<span class='hyper'>people</span> affected by Hurricane Otis.`,
            t4:`The <span class='hyper'>Bécalos program</span> allocated resources to ensure continuity of studies and prevent school dropouts through <span class='hyper'>scholarships and educational support</span> for young people in <span class='hyper'>Guerrero</span>.`,
            t5:`Two raffles were held through <span class='hyper'>Play City: "Play City por Guerrero"</span>. Televisa Foundation <span class='hyper'>doubled the proceeds</span>, which were used for the <span class='hyper'>construction, reconstruction and equipping of 2 classrooms in Guerrero</span>, benefiting`,
            t6:`<span class="hyper">students and teachers.</span>`
        },
        es: {
            t1:`Todos los goles anotados del programa <span class='hyper'>Gol por México </span> durante noviembre y diciembre se transformaron en`,
            t2:`<span class='hyper'>despensas</span> beneficiando a`,
            t3:`<span class='hyper'>personas</span> afectadas por el Huracán Otis.`,
            t4:`El programa <span class="hyper">Bécalos</span> destinó recursos para asegurar la continuidad de estudios y evitar el abandono escolar a través de <span class="hyper"> becas y apoyos educativos </span> para los jóvenes en <span class="hyper">Guerrero.</span>`,
            t5:`A través de <span class="hyper">Play City</span> se realizaron 2 sorteos: "Play City por Guerrero". Fundación Televisa duplicó <span class="hyper"> lo recaudado</span> y se destinó para la <span class="hyper">construcción, reconstrucción y equipamiento de 2 aulas en Guerrero</span> beneficiando a`,
            t6:`<span class="hyper">alumnos y docentes.</span>`
        },
    }
    
    const txt_v = english ? textos.en : textos.es;

  return (
    <div className='DesastresNaturalesProgramas'>
        <div className="container-fluid prog-bg">
            <div className="row ">
                <div className="col-12 p-0">
                    <img src={DSN} alt="" className='img-fluid'/>
                </div>
            </div>
        </div>
        <div className="container-fluid">
            <div className="row cdn-bg-blue">
                <div className="col-lg-6 m-auto cr-text-box ">
                <h3 className="cr-title">
                    <span dangerouslySetInnerHTML={{ __html: txt_v.t1 }}></span> <span className='hyper'>
                    <CountUp
                        enableScrollSpy={true}
                        start={3600}
                        end={3800}
                        duration={4}/></span> <span dangerouslySetInnerHTML={{ __html: txt_v.t2 }}></span>
                    <span className='hyper'>
                    <CountUp
                        enableScrollSpy={true}
                        start={18000}
                        end={19000}
                        duration={4}/></span> <span dangerouslySetInnerHTML={{ __html: txt_v.t3 }}></span>
                    </h3>
                </div>
                <div className="col-lg-6 p-0">
                    <img src={gxm} alt="" className='img-fluid'/>
                    
                </div>
            </div>
            <div className="row cdn-bg-blue">
                <div className="col-lg-6 p-0 order-lg-first order-last">
                    <img src={becalos} alt="" className='img-fluid'/>
                    
                </div>
                <div className="col-lg-6 m-auto cr-text-box order-lg-last order-first">
                <h3 className="cr-title" dangerouslySetInnerHTML={{ __html: txt_v.t4 }}></h3>
                </div>
            </div>
            <div className="row cdn-bg-blue">
                <div className="col-lg-6 m-auto cr-text-box">
                    <h3 className="cr-title">
                    <span dangerouslySetInnerHTML={{ __html: txt_v.t5 }}></span> <span className="hyper"> 
                        <CountUp
                        enableScrollSpy={true}
                        start={100}
                        end={688}
                        duration={4}/></span> <span dangerouslySetInnerHTML={{ __html: txt_v.t6 }}></span>
                    </h3>
                </div>
                <div className="col-lg-6 p-0">
                    <img src={playcity} alt="" className='img-fluid'/>
                    
                </div>
            </div>
        </div>
    </div>
  )
}
