import React, { useState } from 'react'
import './Escolar.css'
import imghead from '../../../../assets/images/programas/cuantrix/TestimonialesCuantrix_2 1.png'
import VideoPlayer from '../../../../componentes/VideoPlayer/VideoPlayer'
import CountUp from 'react-countup'

export default function Escolar({english=false}) {

  const [showVideo, setShowVideo] = useState(false);

  const textos = {
    en: {
      t1:'CUANTRIX',
      t2:`Betting on education focused on programming.`,
      t3:`During this school year,`,
      t4:`<span class='highlight'>students</span> benefited from the courses offered by the program.<br /><br />Through <b>Cuantrix</b>, we promote the teaching of <b>Computer Science</b> so that <b>girls, boys and young people</b> between the ages of 6 and 18 <b>learn to program</b> and can have <b>better employability opportunities</b>.`,
      t5:`Since the program's beginning in 2017,`,
      t6:`<b>students</b> and`,
      t7:`<b>teachers</b> have benefited.`
    },
    es: {
      t1:'CUANTRIX',
      t2:`Apostamos por la educación enfocada a la programación.`,
      t3:`Durante este ciclo escolar`,
      t4:`<span class='highlight'>estudiantes</span> fueron beneficiados por los cursos impartidos por el programa.<br /><br />A través de <b>Cuantrix</b>, impulsamos la enseñanza de las <b>Ciencias de la Computación</b> para que <b>niñas, niños y jóvenes</b> entre 6 y 18 años <b>aprendan a programar</b> y puedan contar con  <b>mejores oportunidades de empleabilidad.</b>`,
      t5:`Desde el inicio del programa en 2017,`,
      t6:`<b>alumnos</b> y `,
      t7:`<b>docentes</b> se han visto beneficiados.`,
    }
  }
  const txt_v = english ? textos.en : textos.es;

  return (
    <div>
        <div className="container-fluid m-0 p-0">
            <div className="row bg-suave m-0 p-0">
                <div className="col-lg-6  m-auto inner-box order-lg-first order-last p-4 p-md-5">
                    <h2 className="main-title-programas">{txt_v.t1}</h2>
                    <p className="main-subtitle-programas">{txt_v.t2}</p>
                    <p className="main-paragraph-programas">
                      <span dangerouslySetInnerHTML={{ __html: txt_v.t3 }}></span> <span className='highlight'><CountUp
                        enableScrollSpy={true}
                        start={'101499'}
                        end={101670}
                        duration={4}/></span> <span dangerouslySetInnerHTML={{ __html: txt_v.t4 }}></span>
                    </p>
                </div>
                <div className="col-lg-6 p-0 ">
                  {
                    !showVideo &&
                    <div className="img-vid">
                    <img src={imghead} alt="fut" className="ctx-img object-fit-cover" onClick={() => setShowVideo(true)} />
                    {/*<img src={play} alt=">" className="overlay-icon" />*/}
                    </div>
                  }
                  {
                    showVideo &&
                    <div className='p-4'>
                      <VideoPlayer
                        src="https://www.youtube.com/watch?v=n_cnKniYa2Y"
                        className="w-100"
                        playing={true}
                      />
                    </div>
                  }
                </div>
            </div>

            <div className='d-flex justify-content-center bg-ctx m-0 p-0'>
              {/* <div className='w-100'>
                <div className='ctx-quote py-4 py-lg-5'>
                  Desde el inicio del programa en 2017, <b><CountUp
                    enableScrollSpy={true}
                    start={'671000'}
                    end={671600}
                    duration={4}/> alumnos</b> y <b><CountUp
                    enableScrollSpy={true}
                    start={'31699'}
                    end={31795}
                    duration={4}/> docentes</b> se han visto beneficiados.
                </div>
              </div> */}
              <div className="col-lg-7 col-11 p-0 ">
                  <div className='d-flex justify-content-center'>
                      <div className='ctx-quote py-4 py-lg-5'>
                        <span dangerouslySetInnerHTML={{ __html: txt_v.t5 }}></span> <b><CountUp
                        enableScrollSpy={true}
                        start={'671000'}
                        end={671600}
                        duration={4}/></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t6 }}></span> <b><CountUp
                        enableScrollSpy={true}
                        start={'31699'}
                        end={31795}
                        duration={4}/></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t7 }}></span>
                      </div>
                  </div>
              </div>
            </div>
        </div>
    </div>
  )
}