import React from 'react'
import '../Aliados.css'
import logo from '../../../assets/images/aliados/logos/golxmex.png'
import CountUp from 'react-countup'
export default function Golxmex({english=false}) {
  const textos = {
    en: {
      t1:`Many thanks to`,
      t2:`GNP Seguros <br/>and all their team.`,
      t3:`<span class='fw-light'>Thanks to</span> TUDN for their constant support in every goal scored.`
    },
    es: {
      t1:`Muchas gracias a`,
      t2:`GNP Seguros <br/>y a todo su equipo.`,
      t3:`<span class='fw-light'> Gracias a</span> TUDN por su apoyo constante en cada gol anotado.`,
    },
  }

  const txt_v = english ? textos.en : textos.es;
  return (
    <div>
        <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 col-7 m-auto py-4 text-center">
            <img src={logo} alt="Becalos" className="img-fluid w-75" />
          </div>
          <div className="col-lg-6 bg-color text-start order-lg-first">
            <h3 className="h-prog">{txt_v.t1}</h3>
              <p className="par" dangerouslySetInnerHTML={{ __html: txt_v.t2 }}></p>
          </div>
        </div>
        <div className="row bg-aliados justify-content-center py-5">
          <div className='col-lg-6 col-10'>  
            <h2 className="sub-title" dangerouslySetInnerHTML={{ __html: txt_v.t3 }}></h2> 
          </div> 
        </div>
      </div>
    </div>
  )
}
