import React from 'react'
import './NuestrosContenidos.css'
import CountUp from 'react-countup';

export default function NuestrosContenidos() {
  return (
    <div className='NuestrosContenidos'>
      {/* web */}
      <div className=''>
        <div className='main-container'>
          <div className='row justify-content-around'>
            <div className='col-lg-4 m-auto'>
              <div className='nct-title'>
                A través de  <br className=" d-block" />nuestros  contenidos, 
              </div>
              <div className='nct-subtitle'>
                generamos y ampliamos la participación <br className="d-lg-block d-none" /> y conversación.
              </div>
            </div>
            <div className='col-lg-6 m-auto'>
              <div className='nct-paragraph'>
                <span className='bold'>Más de <CountUp
                    enableScrollSpy={true}
                    start={0}
                    end={61.5}
                    duration={3}
                    decimals={1}
                    decimal="."
                  /> millones de personas</span> han conectado con nuestra comunicación gracias a los canales digitales y más de <span className='bold'><CountUp
                    enableScrollSpy={true}
                    start={0}
                    end={45.8}
                    duration={3}
                    decimals={1}
                    decimal="."
                  /> millones de personas</span> a través de la televisión.
              </div>
            </div>
          </div>
        </div>
        <div className='nct-bg-gray'>
          <div className='nct-title2'>
            La <span className='bold'>comunicación</span> es el puente que <span className='bold'>nos acerca</span>.
          </div>
        </div>
      </div>
    </div>
  )
}
