import React, { Suspense } from 'react'
import './Home.css'
import Header from '../../componentes/Header/Header'
import Futel from '../../componentes/Home/Futel/Futel'
import { Programas } from '../../componentes/Home/Programas/Programas'
import Footer from '../../componentes/Footer/Footer'
// import Agenda2023 from '../../componentes/Home/Agenda2023/Agenda2023'
// import Reconocimientos from '../../componentes/Home/Reconocimientos/Reconocimientos'
import { Camp } from '../../componentes/Home/Campanias/Camp'
import { Comunicacion } from '../../componentes/Home/Comunicacion/Comunicacion'
import { Dnatural } from '../../componentes/Home/Dnatural/Dnatural'
// import { Alas } from '../../componentes/Home/Alas/Alas'
import { Aliados } from '../../componentes/Home/Aliados/Aliados'
import { Testimonios } from '../../componentes/Home/Testimonios/Testimonios'
// import Numbers from '../../componentes/Home/Numbers/Numbers'
import Ninios from '../../componentes/Home/Ninios/Ninios'

import mobile from '../../assets/images/items-footer/nuestros-programas-mobile.png'
import item1 from '../../assets/images/items-footer/nuestros-programas.png'
import item2 from '../../assets/images/items-footer/comunicacion-con-impacto.png'
import item3 from '../../assets/images/items-footer/nuestras-alas-del-club-america.png'
import Agradecimientos from '../../componentes/Home/Agradecimientos/Agradecimientos'

const itemsHome = [
  {
    image: item1,
    title: {
      en:'OUR PROGRAMS',
      es:'NUESTROS PROGRAMAS',
    },
    link: '/programas'
  },
  {
    image: item2,
    title: {
      en:'COMMUNICATION EFFORTS WITH IMPACT ',
      es:'ESFUERZOS DE COMUNICACIÓN CON IMPACTO',
    },
    link: '/comunicacion'
  },
  {
    image: item3,
    title: {
      en:'NUESTRAS ALAS DEL CLUB AMÉRICA',
      es:'NUESTRAS ALAS DEL CLUB AMÉRICA',
    },
    link: '/nuestras-alas'
  }
];

const itemMobile = [
  {
    image: mobile,
    title: {
      en:'OUR PROGRAMS',
      es:'NUESTROS PROGRAMAS',
    },
    link: '/programas'
  }
]

export default function Home({version=false}) {
  return (
    <div className='Home'>
      <section>
        <Suspense fallback={<>Cargando...</>}>
          <Header english={version}/>
        </Suspense>
        

        <Suspense fallback={<>Cargando...</>}>
          <Futel english={version}/>
        </Suspense>

        {/* <Suspense fallback={<>Cargando...</>}>
          <Numbers />
        </Suspense> */}
        
        <Suspense fallback={<>Cargando...</>}>
          <Programas english={version}/>
        </Suspense>
        
        <Suspense fallback={<>Cargando...</>}>
          <Comunicacion english={version}/>
        </Suspense>

        <Suspense fallback={<>Cargando...</>}>
          <Camp english={version}/>
        </Suspense>

        {/* <Suspense fallback={<>Cargando...</>}>
          <Conversacion />
        </Suspense>
        
        <Suspense fallback={<>Cargando...</>}>
          <Mujeres />
        </Suspense> */}

        <Suspense fallback={<>Cargando...</>}>
          <Dnatural english={version}/>
        </Suspense>
        
        {/* <Suspense fallback={<>Cargando...</>}>
          <Alas />
        </Suspense> */}
        
        <Suspense fallback={<>Cargando...</>}>
          <Aliados english={version}/>
        </Suspense>
        
        <Suspense fallback={<>Cargando...</>}>
          <Testimonios english={version}/>
        </Suspense>

        <Suspense fallback={<>Cargando...</>}>
          <Ninios english={version}/>
        </Suspense>

        {/* <Suspense fallback={<>Cargando...</>}>
          <Reconocimientos />
        </Suspense> */}
        
        {/* <Suspense fallback={<>Cargando...</>}>
          <Agenda2023 />
        </Suspense> */}

        <Suspense fallback={<>Cargando...</>}>
          <Agradecimientos english={version}/>
        </Suspense>
          
        <Footer
          items={itemsHome}
          itemMobile={itemMobile}
          english={version}
        />
        
      </section>
    </div>
  )
}
