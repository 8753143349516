import React from 'react'
import './FotograficaMx.css'
import HeaderFotograficaMx from '../../../componentes/Programas/subsections/FotograficaMx/HeaderFotograficaMx/HeaderFotograficaMx'
import IniciativasFotograficaMx from '../../../componentes/Programas/subsections/FotograficaMx/IniciativasFotograficaMx/IniciativasFotograficaMx'
import Exposicion1 from '../../../componentes/Programas/subsections/FotograficaMx/Exposicion1/Exposicion1'
import ComunidadesDigitales from '../../../componentes/Programas/subsections/ComunidadesDigitales/ComunidadesDigitales'
// import Footer from '../../../componentes/Footer/Footer'
import FooterProgramas from '../../../componentes/Programas/FooterProgramas/FooterProgramas'
import arrowsPosible from '../../../assets/images/programas/footer/arrows-posible.png'

export default function FotograficaMx(
  {handleSectionSelected,englishp=false}
) {
  return (
    <div className='FotograficaMx'>
      <HeaderFotograficaMx english={englishp}/>

      <IniciativasFotograficaMx english={englishp}/>

      <Exposicion1 english={englishp}/>

      <ComunidadesDigitales english={englishp}/>

      {/* <Footer /> */}
      <FooterProgramas 
        background='bg-programa-posible'
        arrows={arrowsPosible}
        title='POSIBLE'
        color='#E8BA4A'
        bgImage='bg-program-image-posible'
        handleSectionSelected={handleSectionSelected}
        programSection='posible'
        english={englishp}
      />
    </div>
  )
}
