import React from 'react'
import './Agradecimientos.css'
import gradientAgradecimiento from '../../../assets/images/home/gradient-agradecimiento.png'

export default function Agradecimientos({english=false}) {
  const textos = {
    en: {
      t1:`THANKS TO EACH AND EVERY ONE OF YOU, WHOSE SUPPORT MAKES THESE RESULTS POSSIBLE.`
    },
    es: {
      t1:`Gracias a todos y a cada uno de ustedes, que con su apoyo hacen posible estos resultados.`
    }
  }

  const txt_v = english ? textos.en : textos.es;

  return (
    <div className='Agradecimientos'>
      <div className='d-flex justify-content-center'>
        <div className='custom-bg m-4 m-lg-5 pt-lg-5'>
          <div className='agradecimiento-title px-4 py-4 px-lg-5 py-lg-5 '>
            {txt_v.t1}
          </div>
          {/* <div className='w-100'>
            <img src={gradientAgradecimiento} alt='gradiente' className='w-100' />
          </div> */}
        </div>
      </div>
    </div>
  )
}
