import React, {useState} from 'react'
import './CardAcciones.css'
import play from '../../../../assets/images/home/video/play.png'
import VideoPlayer from '../../../../componentes/VideoPlayer/VideoPlayer'
import CardSlider from '../../../CardSlider/CardSlider';

export default function CardAcciones(
  {image, title, description,url, isTitlePurple = true, type = 'image', videoItems = null}
) {

  const [showVideo, setShowVideo] = useState(false);

  return (
    <div className='CardAcciones'>
      <div className=''> {/** revisar porqueestaba el p-4 */}

        <div className='card-container'>
          <div className="position-relative">
            {
              type === 'image' &&
              <img src={image} alt={title} className='image'/>
            }
            {
              type === 'video' && !showVideo &&
              <>
                <img src={image} alt={title} className='image' onClick={() => setShowVideo(true)}/>
                {/* <img src={play} alt="video" className='play-overlay' onClick={() => setShowVideo(true)}/> */}
              </>
            }
            {
              type === 'video' && showVideo &&
              <VideoPlayer
                src={url}
                className="w-100"
                playing={true}
              />
            }
            {
              type === 'link' &&
              <>
                <img src={image} alt={title} className='image'/>
                <a href={url} target='_blank' rel='noreferrer'>
                  <img src={play} alt="video" className='play-overlay'/>
                </a>
              </>
            }
            {
              type === 'videoSlider' &&
              <CardSlider items={videoItems} />
            }
          </div>
          <div className={isTitlePurple ? 'title-card pt-2 pt-md-4' : 'title-card-aqua pt-2 pt-md-4'}>
            {title}
          </div>
          <div className='description pb-4' dangerouslySetInnerHTML={{ __html: description }}></div>
        </div>

      </div>
    </div>
  )
}
