import React from 'react'
import'./Testimonios.css'
import Carousel from '../../../Carousel/Carousel'
import iconoTest from '../../../../assets/images/programas/becalos/testimonios.svg'
import CountUp from 'react-countup';
import thumbnailBecalos from '../../../../assets/images/home/testimonios/thumbnails/becalos.jpg'
import thumbnailBecalos2 from '../../../../assets/images/programas/becalos/thumbnails/becalos2.png'
import thumbnailBecalos3 from '../../../../assets/images/programas/becalos/thumbnails/becalos3.png'
import thumbnailBecalos4 from '../../../../assets/images/programas/becalos/thumbnails/becalos4.png'

const items = [
    {
        type: 'video',
        image: thumbnailBecalos,
        videoUrl: 'https://www.youtube.com/watch?utm_source=informe2023&utm_medium=reginajuarez&utm_id=rise2023&utm_content=carmenmunoz&v=QUtZJJpplvo+&feature=youtu.be'
    },
    {
        type: 'video',
        image: thumbnailBecalos2,
        videoUrl: 'https://www.youtube.com/watch?utm_source=informe2023&utm_medium=edithvillafana&utm_id=becalas2023&utm_content=carmenmunoz&v=em1_IVT6XbA+&feature=youtu.be'
    },
    {
        type: 'video',
        image: thumbnailBecalos3,
        videoUrl: 'https://www.youtube.com/watch?utm_source=informe2023&utm_medium=joanaamador&utm_id=tufuturoconbecalos&utm_content=carmenmunoz&v=5v90d_5sSAk+&feature=youtu.be'
    },
    {
        type: 'video',
        image: thumbnailBecalos4,
        videoUrl: 'https://www.youtube.com/watch?utm_source=informe2023&utm_medium=juditharias&utm_id=becalas&v=U5kaf0NLO4k&feature=youtu.be'
    }
];
export default function Testimonios({english=false}) {
    const textos = {
        en: {
            t1:`READ THEIR TESTIMONIALS`,
            t2:`We reached more than`,
            t3:`<span class='bold'>million</span> people through our digital platforms.`
        },
        es:{
            t1:`CONOCE SUS TESTIMONIOS`,
            t2:`A través de nuestras plataformas digitales alcanzamos a más de`,
            t3:`<span class='bold'>millones</span> de personas.`
        }
    }

    const txt_v = english ? textos.en : textos.es;

  return (
    <div>
        <div className="container">
            <div className="row text-center">
                <img src={iconoTest} alt="" className="img-logo" />
                <h2 className="testimonios-title">{txt_v.t1}</h2>
            </div>
            <div className="row">
                <Carousel 
                items={items}/>
            </div>
        </div>
        <div className="container-fluid">
            <div className="row justify-content-center">
                <div className="col-lg-8 my-5">
                    <h3 className="cdig-tit">
                        <span dangerouslySetInnerHTML={{ __html: txt_v.t2 }}></span> <span className='bold'><CountUp
                        enableScrollSpy={true}
                        start={0}
                        end={3.8}
                        duration={2}
                        decimals={1}
                        decimal="."
                    /></span><span dangerouslySetInnerHTML={{ __html: txt_v.t3 }}></span></h3>
                </div>
            </div>
        </div>
    </div>
  )
}
