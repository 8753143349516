import React from 'react'
import '../Aliados.css'
import logo from '../../../assets/images/aliados/logos/logo_fotografica 2.png'
import CountUp from 'react-countup'

export default function Fotografica({english=false}) {
    const textos = {
        en: {
          t1:`Many thanks to the`,
          t2:`Museo del Palacio de Bellas Artes and 59 cultural institutions.`,
          t3:`Our allies in the Artes Visuales and FotográficaMx program in 2023 were:`,
          t4:`Museo del Palacio de Bellas Artes, Morelia International Film Festival, Hotel Xcaret Arte, Cataluyna Film Library (Barcelona, España), The Film Foundation, Brazil Embassy in Mexico, The United States Embassy in Mexico, Conferencia Interamericana de Seguridad Social, Fundación MAPFRE (Barcelona, España), Museo del Estanquillo, Museo Kaluz, Museo de las Artes de la Universidad de Guadalajara, Museo Casa Estudio Diego Rivera y Frida Kahlo, Museo de Arte Contemporáneo de Monterrey, Museo del Barrio (Nueva York, the United States), Google Arts & Culture, Bestel.`,
          t5:`Participation in national and international film festivals: Block Museum of Art (the United States), Hong Kong International Film Festival Society (China), Ministry of Foreign Affairs, Seattle International Film Festival (the United States), Festival Internazionale del film Locarno (Switzerland), Centro Sperimentale di Cinematografia Cineteca Nazionale (Italy), Heights Theater (the United States), Cineteca di Bologna (Italy), AFI Silver Theatre and Cultural Center (the United States), Main Plaza Conservancy (the United States), Museo Nacional de San Carlos, The UNAM Film Archive, SHORTS MÉXICO, Cinémathèque Suisse (Switzerland), Ars Luminaria (Italy), Hollywood Theater (the United States), UC Berkeley Art Museum and Pacific Film Archive (the United States), Fondation des Cinémas du Grütli (Italy), Celebrate Mexico Now Festival (the United States), La Cinémathèque Française (France), Museo Nazionale del Cinema / Cinema Massimo (Italy), Filmpodium der Stadt Zürich (Switzerland), Museo Nacional de San Carlos / INBAL, Milwaukee Film (the United States), Kino REX Bern / Cinéville (Switzerland), Guadalajara Film Festival (The United States), Loyola Marymount University (the United States), National Film Archives of México, Centro Cultural de Tolcayuca (Hidalgo), Complejo Cultural “Los Pinos”, Espacio Cultural Metropolitano de Tamaulipas (Tamaulipas), Museo Frida Kahlo, Instituto Mexicano del Seguro Social, Tijuana Film Archive (Baja California), Palacio Cultural de Tlaxcala (Tlaxcala), Mexican Embassy in Sacramento (the United States), Ministry of Culture in Coahuila (Coahuila), Ministry of Culture del Estado de Durango (Durango), Foro Hydra de Tulum (Quintana Roo), Instituto Estatal de Cultura de Tabasco (Tabasco), UAM Xochimilco, Bergen Film Library (Norway), Mexican Embassy in the United Kingdom, Festival de las Almas in Valle de Bravo.`
        },
        es: {
          t1:`Muchas gracias al`,
          t2:`Museo del Palacio de Bellas Artes y 59 instituciones culturales.`,
          t3:`Nuestros aliados del programa de Artes Visuales y FotográficaMx en 2023 fueron:`,
          t4:`Museo del Palacio de Bellas Artes, Festival Internacional de Cine de Morelia, Hotel Xcaret Arte, Filmoteca de Cataluyna (Barcelona, España), The Film Foundation, Embajada de Brasil en México, Embajada de los Estados Unidos en México, Conferencia Interamericana de Seguridad Social, Fundación MAPFRE (Barcelona, España), Museo del Estanquillo, Museo Kaluz, Museo de las Artes de la Universidad de Guadalajara, Museo Casa Estudio Diego Rivera y Frida Kahlo, Museo de Arte Contemporáneo de Monterrey, Museo del Barrio (Nueva York, Estados Unidos), Google Arts & Culture, Bestel.`,
          t5:`Participación en festivales de cine, nacionales e internacionales: Block Museum of Art (Estados Unidos), Hong Kong International Film Festival Society (China), Secretaría de Relaciones Exteriores, Seattle International Film Festival (Estados Unidos), Festival Internazionale del film Locarno (Suiza), Centro Sperimentale di Cinematografia Cineteca Nazionale (Italia), Heights Theater (Estados Unidos), Cineteca di Bologna (Italia), AFI Silver Theatre and Cultural Center (Estados Unidos), Main Plaza Conservancy (Estados Unidos), Museo Nacional de San Carlos, Filmoteca UNAM, SHORTS MÉXICO, Cinémathèque Suisse (Suiza), Ars Luminaria (Italia), Hollywood Theater (Estados Unidos), UC Berkeley Art Museum and Pacific Film Archive (Estados Unidos), Fondation des Cinémas du Grütli (Italia), Celebrate Mexico Now Festival (Estados Unidos), La Cinémathèque Française (Francia), Museo Nazionale del Cinema / Cinema Massimo (Italia), Filmpodium der Stadt Zürich (Suiza), Museo Nacional de San Carlos / INBAL, Milwaukee Film (Estados Unidos), Kino REX Bern / Cinéville (Suiza), Guadalajara Film Festival (Estados Unidos), Loyola Marymount University (Estados Unidos), Cineteca Nacional, Centro Cultural de Tolcayuca (Hidalgo), Complejo Cultural “Los Pinos”, Espacio Cultural Metropolitano de Tamaulipas (Tamaulipas), Museo Frida Kahlo, Instituto Mexicano del Seguro Social, Cineteca Tijuana (Baja California), Palacio Cultural de Tlaxcala (Tlaxcala), Consulado de México en Sacramento (Estados Unidos), Secretaría de Cultura de Coahuila (Coahuila), Instituto de Cultura del Estado de Durango (Durango), Foro Hydra de Tulum (Quintana Roo), Instituto Estatal de Cultura de Tabasco (Tabasco), UAM Xochimilco, Cinemateca de Bergen (Noruega), Embajada de México en Reino Unido, Festival de las Almas en Valle de Bravo.`
        },
      }
    const txt_v = english ? textos.en : textos.es;
  return (
    <div>
        <div className="container-fluid">
        <div className="row">
        <div className="col-6 m-auto py-lg-4 py-5 pb-md-4 py-xl-3 text-center">
            <img src={logo} alt="Becalos" className="img-fluid" />
        </div>
        <div className="col-lg-6 bg-color text-start ">
            <h2 className="h-prog t-mv">{txt_v.t1}</h2> 
            <p className="par">{txt_v.t2}</p>
        </div>
        </div>
        <div className="row bg-aliados gradient justify-content-center py-5">
            <div className="col-lg-8 col-11 text-center">
                <h2 className="sub-title my-lg-5  mb-md-3">{txt_v.t3}</h2>
            </div>
            <div className="col-lg-9 col-11 text-start">
            <p className="par-aliados">{txt_v.t4}</p>
            <p className="par-aliados">{txt_v.t5}</p>
        </div>
    </div>
    </div>
    </div>
    )
}
