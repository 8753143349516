import React from 'react'
import './Futel.css'
import ninas from '../../../assets/images/home/BDS_5692_BYN 1.png'
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import niniasMobile from '../../../assets/images/home/ninias-mobile.png'

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function Futel({english=false}) {

  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const variants = {
    hidden: { translateY: '20vh' },
    visible: { translateY: '0vh', transition: { duration: 1 } },
  };


  const textos = {
    en: {
      mov:{
        titulo:"WE GENERATED OPPORTUNITIES FOR AS MANY PEOPLE AS POSSIBLE.",
        subtitulo:"We focused on <b>actions</b> that boost <b>education, values, health, entrepreneurship, gender equity, the environment, culture, and the arts.</b>"
      },
      desk:{
        titulo:"WE GENERATED OPPORTUNITIES <br/> FOR AS MANY PEOPLE<br/> AS POSSIBLE. ",
        subtitulo:"We focused on <b>actions</b> that boost <b>education, values, health, entrepreneurship, gender equity, the environment, culture, and the arts.</b>"
      }
    },
    es:{
      mov:{
        titulo:"Generamos oportunidades para el mayor número de  personas POSIBLE.",
        subtitulo:`Nos enfocamos en <b>acciones</b> que impulsan <b>la educación, los valores, la salud, el emprendimiento, la equidad de género, el medioambiente, la cultura y el arte.</b>`
      },
      desk:{
        titulo:`Generamos oportunidades <br/> para el mayor <br /> número de personas.`,
        subtitulo:`Nos enfocamos en <b>acciones</b> que impulsan <b>la educación, los valores, la salud, el emprendimiento, la equidad de género, el medioambiente, la cultura y el arte.</b>`
      }
    }
  };

  // Selecciona el texto basado en la prop 'version'
  const txt_v = english ? textos.en : textos.es;

  return (
    <div id='part-two'>
      {/* mobile */}
      <div className='mobile-device'>
        <div className='container-fluid m-0 p-0'>
          <div className='row'>
            <div className='col-1'>&nbsp;</div>
            <div className='col-10'>
              <div className='futel-oportunidades mt-4' dangerouslySetInnerHTML={{ __html: txt_v.mov.titulo }}></div>
              <div className='futel-enfoque pt-2' dangerouslySetInnerHTML={{ __html: txt_v.mov.subtitulo }}></div>
            </div>
            <div className='col-1'>&nbsp;</div>
          </div>
          
          <div className='d-flex justify-content-center justify-content-md-end m-0 p-0'>
            {/* <motion.section
              whileInView ={{ translateY: '0vh' }} // Start offscreen at the bottom
              animate={{ translateY: '20vh' }} // Animate to the top
              transition={{ duration: 0.5 }} // Set animation duration
            > */}
            <LazyLoadImage
              src={niniasMobile}
              alt="Image Alt"
              className='futel-img-ninas'
              effect="blur"
            />
              {/* <img src={niniasMobile} alt="two girls saying hi" className='futel-img-ninas' /> */}
            {/* </motion.section> */}
          </div>
        </div>
      </div>

      {/* web */}
      <div className='web-device'>
        <div className='container-fluid'>
          <div className='row ps-md-0'>
            <div className='px-0 col-lg-6 col-sm-12 z-index-img' ref={ref}>
              <motion.div
                // whileInView ={{ translateY: '0vh' }} // Start offscreen at the bottom
                // animate={{ translateY: '50vh' }} // Animate to the top
                // transition={{ duration: 1 }} // Set animation duration
                variants={variants}
                initial="hidden"
                animate={inView ? 'visible' : 'hidden'}
              >
                <img src={ninas} alt="two girls saying hi" className='futel-img-ninas' />
              </motion.div>
            </div>
            <div className='px-5 py-3 col-lg-6 col-sm-12 mb-0 py-lg-5 mt-4'>
              {/* <h2 className='futel-title'>EN FUNDACIÓN TELEVISA</h2> */}
              <p className='futel-oportunidades' dangerouslySetInnerHTML={{ __html: txt_v.desk.titulo }}></p>
              <div className='futel-enfoque pt-2' dangerouslySetInnerHTML={{ __html: txt_v.desk.subtitulo }}></div>
              {/* <div className='m-0 futel-enfoque-historias'>Juntos cambiamos historias. </div> */}
            </div>
          </div>
        </div>
      </div>

      {/* <div className='d-flex justify-content-center bg-blue'>
        <div className='title py-5'>
          Juntos cambiamos historias.
        </div>
      </div> */}

    </div>
  )
}
