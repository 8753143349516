import React from 'react'
import '../Aliados.css'
import logo from '../../../assets/images/aliados/logos/logo_cuantrix 1.png'
import CountUp from 'react-countup'

export default function Cuantrix({english=false}) {
  const textos = {
    en: {
      t1:`Many thanks to`,
      t2:`state education authorities, strategic partners, and teachers.`,
      t3:`Our allies in the Cuantrix program in 2023 were:`,
      t4:`Sura, Accenture, Fondo Unido, AFFECI, Universidad Anáhuac, Centro Meneses, AEFCM (Autoridad Educativa Federal de la Ciudad de México), Ministry of Education in Nuevo Leon, Ministry of Education in Campeche, Ministry of Education in Yucatán, Fundación Destina, Scratch, CODE.org, Microsoft, UNETE.`,
    },
    es: {
      t1:`Muchas gracias a las`,
      t2:`autoridades educativas estatales, aliados estratégicos y docentes.`,
      t3:`Nuestros aliados del programa Cuantrix en 2023 fueron:`,
      t4:`Sura, Accenture, Fondo Unido, AFFECI, Universidad Anáhuac, Centro Meneses, AEFCM (Autoridad Educativa Federal de la Ciudad de México), Secretaría de Educación de Nuevo León, Secretaría de Educación de Campeche, Secretaría de Educación de Yucatán, Fundación Destina, Scratch, CODE.org, Microsoft, UNETE.`,
    },
  }
  const txt_v = english ? textos.en : textos.es;
  return (
    <div>
    <div className="container-fluid">
    <div className="row">
      <div className="col-lg-6 col-6 m-auto pb-lg-5 pt-md-5 pt-5 px-0 px-md-5 text-center">
        <img src={logo} alt="Becalos" className="img-fluid w-75 px-5 px-md-0" />
      </div>
      <div className="col-lg-6 bg-color text-start order-lg-first  pt-4 pt-lg-5">
        <h3 className="h-prog pt-0 pt-lg-4">{txt_v.t1}</h3>
          <p className="par">{txt_v.t2}</p>
      </div>
    </div>
    <div className="row bg-aliados justify-content-center py-5">
        <h2 className="sub-title my-lg-5  mb-md-3">{txt_v.t3}</h2>
        <div className="col-lg-9 col-11 text-start">
          <p className="par-aliados">{txt_v.t4}</p>
    </div>
  </div>
</div>
</div>
  )
}
