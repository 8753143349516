import React from 'react'
import './Resultados.css'
import subrayado from '../../../assets/images/nuestrasAlas/subrayado.png'
import subrayado2 from '../../../assets/images/nuestrasAlas/subrayado2.png'

export default function Resultados({english=false}) {
  const textos = {
    en: {
      t1:`See the results`,
      t2:`IN COMMUNICATION AND CONTENT OF NUESTRAS ALAS`,
      t3:`Communication`,
      t4:`<b>2.9 million people reached</b> with the <b>campaign to call</b> for attendance to the matches <b>for those affected by the hurricane</b>.`,
      t5:`<b>+172 thousand people reached</b> with the <b>Vista de Águila</b> eyeglasses delivery video.`,
      t6:`<b>+3 million people reached</b> with the <b>Tennis con Alas clothing donation</b> campaign.`,
      t7:`Content`,
      t8:`<b>+15 million users</b> were impacted through <b>Club América's social networks</b> in favor of the different causes that <b>Nuestras Alas</b> supports. `,
    },
    es: {
      t1:`Conoce los resultados`,
      t2:`en la Comunicación y Contenidos de Nuestras Alas`,
      t3:`Comunicación`,
      t4:`<b>2.9 millones de personas alcanzadas</b> con la <b>campaña de convocatoria</b> para la asistencia a los partidos <b>donados para los afectados por el huracán.</b>`,
      t5:`<b>+172 mil personas alcanzadas</b> con el video de entrega de lentes de <b>Vista de Águila</b>.`,
      t6:`<b>+3 millones de personas alcanzadas</b> con la campaña <b>Donación de ropa</b> de <b>Tenis con Alas</b>.`,
      t7:`Contenidos`,
      t8:`<b>+15 millones</b> de usuarios fueron impactados a través de las redes sociales de <b>Club América</b> a favor de las diferentes causas que <b>Nuestras Alas</b> apoya.`,
    },
  }

  const txt_v = english ? textos.en : textos.es;
  return (
    <div className='Resultados'>
      <div className='title-content'>
        <div className='title'>
          {txt_v.t1}
        </div>
        <div className='title-big'>
        {txt_v.t2}
        </div>
        <div className='d-flex justify-content-center'>
          <div className='subtitle'>
            {txt_v.t3}
            <div className='subrayado'>
              <img src={subrayado} alt='subrayado' />
            </div>
          </div>
        </div>
      </div>

      {/* mobile */}
      <div className='mobile-device'>
        <div className='bg-rectangle-1'>
          <div className='paragraph px-4 py-4 px-md-5 py-md-4' dangerouslySetInnerHTML={{ __html: txt_v.t4 }}></div>
        </div>
        <div className='bg-rectangle-1 mt-4'>
          <div className='paragraph px-4 py-4 px-md-5 py-md-4' dangerouslySetInnerHTML={{ __html: txt_v.t5 }}></div>
        </div>
        <div className='bg-rectangle-1 mt-4'>
          <div className='paragraph px-4 py-4 px-md-5 py-md-4' dangerouslySetInnerHTML={{ __html: txt_v.t6 }}></div>
        </div>

        <div className='d-flex justify-content-center pt-0 pt-4'>
          <div className='subtitle'>
            {txt_v.t7}
            <div className='subrayado'>
              <img src={subrayado2} alt='subrayado' />
            </div>
          </div>
        </div>

        <div className='bg-rectangle-2 mt-2 mt-md-0'>
          <div className='paragraph px-4 py-4 px-md-5 py-md-4' dangerouslySetInnerHTML={{ __html: txt_v.t8 }}></div>
        </div>
        {/* <div className='bg-rectangle-2 mt-4'>
          <div className='paragraph px-5 py-4 px-md-5 py-md-5'>
            <b>+16 millones de personas</b> conforman la <b>comunidad digital</b> Club América.
          </div>
        </div> */}

      </div>

      {/* web */}
      <div className='web-device'>
        <div className='grid grid-cols-3 gap-4 px-5 pt-0'>
          <div>
            <div className='bg-rectangle-1 d-flex align-items-center h-100'>
              <div className='paragraph p-4' dangerouslySetInnerHTML={{ __html: txt_v.t4 }}></div>
            </div>
          </div>
          <div>
            <div className='bg-rectangle-1 d-flex align-items-center h-100'>
              <div className='paragraph p-4' dangerouslySetInnerHTML={{ __html: txt_v.t5 }}></div>
              <div className='mt-4'>&nbsp;</div>
            </div>
          </div>
          <div>
            <div className='bg-rectangle-1 d-flex align-items-center h-100'>
              <div className='paragraph p-4' dangerouslySetInnerHTML={{ __html: txt_v.t6 }}></div>
              <div className='mt-4'>&nbsp;</div>
            </div>
          </div>
        </div>

        <div className='d-flex justify-content-center mt-5 mb-0'>
          <div className='subtitle'>
            {txt_v.t7}
            <div className='subrayado'>
              <img src={subrayado2} alt='subrayado' />
            </div>
          </div>
        </div>

        <div className='row p-0 m-0'>
          <div className='col-10 offset-1'>
            <div className='d-flex justify-content-center mt-0'>
              <div className='w-50 pl-5'>
                <div className='bg-rectangle-2 d-flex align-items-center h-100'>
                  <div className='paragraph p-4' dangerouslySetInnerHTML={{ __html: txt_v.t8 }}></div>
                </div>
              </div>

              {/* <div className='w-100 pr-5 bg-rectangle-2'>
                <div className='mt-4'>
                  <div className='paragraph px-5 py-4 px-md-5 py-md-5'>
                    <b>+16 millones de personas</b> conforman la <b>comunidad digital</b> Club América.
                  </div>
                  <div className='mt-4'>&nbsp;</div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      
      <div className='mt-lg-5 d-md-block d-none'>&nbsp;</div>
    </div>
  )
}
