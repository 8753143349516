import React from 'react'
import './Progredes.css'
export default function Progredes(
    {redes,sitio, isEmpiezaTemprano = false, english=false}
) {
    // let datos = Object.values(redes);
    let arrayRedes  = redes.map((red,index) =>{ 
      return (
        <div key={index} className="col my-md-0 my-lg-3 my-md-4 my-3 ">
          <a href={red.link} target="_blank" rel='noreferrer' className='social-link'>
            <img src={red.icon} alt="" height="25"/> <strong className='ps-2'> {red.nombre}</strong>
          </a>
        </div>
      )
    });

    const textos = {
      en: {
          t1:`VISIT SITE`,
      },
      es: {
          t1:`VISITAR SITIO`,
      }
    }
    const txt_v = english ? textos.en : textos.es;

    return (
    <div className='container-fluid'>
        <div className="row ">
          <div className="col-lg-8 px-md-2 px-4 bg-amcl justify-content-center align-items-center">
            <div className='d-flex flex-lg-row flex-column flex-lg-nowrap flex-wrap justify-content-center text-center mt-2'>
                {arrayRedes}
            </div>
          </div>
          <div className="col-lg-4 bg-rjo px-md-5 px-4 py-3 text-center align-items-center h-100">
            <a href={sitio} target="_blank" rel="noreferrer" className="">
              <h3 className="txt-body-bold-pr btn-visita text-light mt-2">
                {txt_v.t1}
              </h3>
            </a>
          </div>
        </div>
    </div>
  )
}
