import React from 'react'
import './NuestrasAlas.css'
// import HeaderNuestrasAlas from '../../componentes/NuestrasAlas/HeaderNuestrasAlas/HeaderNuestrasAlas'
import Content from '../../componentes/NuestrasAlas/Content/Content'
// import AreasDeAccion from '../../componentes/NuestrasAlas/AreasDeAccion/AreasDeAccion'
import Iniciativas from '../../componentes/NuestrasAlas/Iniciativas/Iniciativas'
import Resultados from '../../componentes/NuestrasAlas/Resultados/Resultados'
import VistaAguila from '../../componentes/NuestrasAlas/VistaAguila/VistaAguila'
import ObjetivosOnu from '../../componentes/NuestrasAlas/ObjetivosOnu/ObjetivosOnu'
import Footer from '../../componentes/Footer/Footer'

import HeaderSection from '../../componentes/HeaderSection/HeaderSection'
import hero from '../../assets/images/nuestrasAlas/hero.png'

import item1 from '../../assets/images/items-footer/televisa-foundation.png'
import item2 from '../../assets/images/items-footer/desastres-naturales.png'
import item3 from '../../assets/images/items-footer/nuestros-aliados.png'
import mobile from '../../assets/images/items-footer/televisa-foundation-mobile.png'

const itemsAlas = [
  {
    image: item1,
    title: {
      en:'TELEVISA FOUNDATION',
      es:'TELEVISA FOUNDATION',
    },
    link: '/televisa-foundation'
  },
  {
    image: item2,
    title: {
      en:'SUPPORT IN NATURAL DISASTERS',
      es:'Apoyo en DesastreS NATURALES',
    },
    link: '/desastres-naturales'
  },
  {
    image: item3,
    title: {
      en:'OUR ALLIES',
      es:'Nuestros Aliados',
    },
    link: '/nuestros-aliados'
  }
];

const itemAlasMobile = [
  {
    image: mobile,
    title: {
      en:'TELEVISA FOUNDATION',
      es:'TELEVISA FOUNDATION',
    },
    link: '/televisa-foundation'
  }
]

export default function NuestrasAlas({version=false}) {
  const textos = {
    en: {
      t1:`NUESTRAS ALAS DEL <br class='d-none d-lg-block'/> CLUB AMÉRICA`
    },
    es: {
      t1:`NUESTRAS ALAS DEL <br class='d-none d-lg-block'/> CLUB AMÉRICA`
    }
  }
  const txt_v = version ? textos.en : textos.es;

  return (
    <div className='NuestrasAlas'>

      <HeaderSection 
        image={hero}
        title={txt_v.t1}
        english={version}
      />

      <Content english={version}/>

      {/* <AreasDeAccion /> */}

      <Iniciativas english={version}/>

      <Resultados english={version}/>

      <VistaAguila english={version}/>

      <ObjetivosOnu english={version}/>

      <Footer 
        showLogo={false}
        items={itemsAlas}
        itemMobile={itemAlasMobile}
        english={version}
      />
    </div>
  )
}
