import React from 'react'
import '../Aliados.css'
import logo from '../../../assets/images/aliados/logos/logo_posible 1.png'
import CountUp from 'react-countup'

export default function Posible({english=false}) {
  const textos = {
    en: {
      t1:`Many thanks to`,
      t2:`Nacional Monte de Piedad, Fomento Social Citibanamex and 13 strategic allies.`,
      t3:`Our allies in the Posible program in 2023 were:`,
      t4:`Nacional Monte de Piedad, Fomento Social Citibanamex, City Express Hotels, BID, Instituto Mexiquense del Estado de México, INJUVE Durango, SEDECO Durango, Instituto Oaxaqueño del Emprendedor y la Competitividad, Ministry of Economic Development in Oaxaca, Ministry of Economy and Labor in Chiapas, Ministry of Economic Development in Quintana Roo, Instituto Yucateco del Emprendedor, Instituto Campechano del Emprendedor, Ministry for the Economic Development and the Competitiveness in Tabasco, SURGES.`,
    },
    es: {
      t1:`Muchas gracias a`,
      t2:`Nacional Monte de Piedad, Fomento Social Citibanamex y 13 aliados estratégicos.`,
      t3:`Nuestros aliados del programa Posible en 2023 fueron:`,
      t4:`Nacional Monte de Piedad, Fomento Social Citibanamex, Hoteles City Express, BID, Instituto Mexiquense del Estado de México, INJUVE Durango, SEDECO Durango, Instituto Oaxaqueño del Emprendedor y la Competitividad, Secretaría de Desarrollo Económico del Estado de Oaxaca, Secretaría de Economía y del Trabajo de Chiapas, Secretaría de Desarrollo Económico de Quintana Roo, Instituto Yucateco del Emprendedor, Instituto Campechano del Emprendedor, Secretaría para el Desarrollo Económico y la Competitividad de Tabasco, SURGES.`,
    },
  }
  const txt_v = english ? textos.en : textos.es;

  return (
    <div>
    <div className="container-fluid">
    <div className="row">
      <div className="col-6 m-auto pt-4 text-center">
        <img src={logo} alt="Becalos" className="img-fluid" />
      </div>
      <div className="col-lg-6 bg-color text-start order-lg-first">
        
        <h2 className="h-prog t-mv">{txt_v.t1}</h2> 
          <p className="par">{txt_v.t2}</p>
      </div>
    </div>
    <div className="row bg-aliados  justify-content-center py-5">
        <h2 className="sub-title my-lg-5 mb-md-3">{txt_v.t3}</h2>
        <div className="col-lg-9 col-11 text-start">
          <p className="par-aliados">{txt_v.t4}</p>
        </div>
  </div>
</div>
</div>
  )
}
