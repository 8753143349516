import React from 'react'
import './Temprano.css'
import Impulsando from './Impulsando/Impulsando'
import Iniciativas from './Iniciativas/Iniciativas'
import Impacto from './Impacto/Impacto'
import Testimonios from './Testimonios/Testimonios'
import Comunidades from './Comunidades/Comunidades'
import Progredes from '../Progredes/Progredes'

import fb from '../../../assets/images/iconos/fb.svg'
import ig from '../../../assets/images/iconos/ig.svg'
import tiktok from '../../../assets/images/iconos/tiktok.svg'
import x from '../../../assets/images/iconos/x.svg'
import yt from '../../../assets/images/iconos/yt.svg'
// import Footer from '../../Footer/Footer'
import FooterProgramas from '../FooterProgramas/FooterProgramas'
import arrowsCuantrix from '../../../assets/images/programas/footer/arrows-cuantrix.png'

const social= [
  {
      link:'https://www.facebook.com/EmpiezaTemprano',
      icon:fb,
      nombre:'EmpiezaTemprano'
  },
  {
      link:'https://www.facebook.com/EmpiezaTemprano',
      icon:ig,
      nombre:'empiezatemprano'
  },
  {
    link:'https://twitter.com/empiezatemprano',
    icon:x,
    nombre:'empiezatemprano'
  },
  {
    link:'https://www.youtube.com/c/EmpiezaTempranoCadaMomentoCuenta',
    icon:yt,
    nombre:'empiezatemprano'
  },
  {
    link:'https://www.tiktok.com/@empiezatempranomx',
    icon:tiktok,
    nombre:'empiezatempranomx'
  }
]
export default function Temprano(
  {handleSectionSelected,englishp=false}
) {
  return (
    <div className='temprano'>
      <Impulsando english={englishp}/>
      <Iniciativas english={englishp}/>
      <Impacto english={englishp}/>
      <Testimonios english={englishp}/>
      <Comunidades english={englishp}/>
     <Progredes 
          sitio = 'https://empiezatemprano.org/'
          redes = {social}
          english={englishp}
        /> 

      {/*<div className='row m-0 p-0'>
        <div className='col-12 col-lg-9 m-0 p0 bg-redes'>
          <div className='d-flex justify-content-center align-items-center h-100 custom-prog-redes'>
            <div className='px-3'>
              <a href='https://www.facebook.com/EmpiezaTemprano' target="_blank" rel='noreferrer' className='social-link'>
                <img src={fb} alt="" height="25"/> <strong>EmpiezaTemprano</strong>
              </a>
            </div>
            <div className='px-3'>
              <a href='https://www.facebook.com/EmpiezaTemprano' target="_blank" rel='noreferrer' className='social-link'>
                <img src={ig} alt="" height="25"/> <strong>empiezatemprano</strong>
              </a>
            </div>
            <div className='px-3'>
              <a href='https://twitter.com/empiezatemprano' target="_blank" rel='noreferrer' className='social-link'>
                <img src={x} alt="" height="25"/> <strong>empiezatemprano</strong>
              </a>
            </div>
            <div className='px-3'>
              <a href='https://www.youtube.com/c/EmpiezaTempranoCadaMomentoCuenta' target="_blank" rel='noreferrer' className='social-link'>
                <img src={yt} alt="" height="25"/> <strong>@empiezatemprano5116</strong>
              </a>
            </div>
            <div className='px-3'>
              <a href='https://www.tiktok.com/@empiezatempranomx' target="_blank" rel='noreferrer' className='social-link'>
                <img src={tiktok} alt="" height="25"/> <strong>empiezatempranomx</strong>
              </a>
            </div>
          </div>
        </div>
        <div className='col-12 col-lg-3 bg-visita-sitio'>
          <div className='d-flex justify-content-center align-items-center h-100'>
            <div>
              <a href='https://empiezatemprano.org/' target="_blank" rel="noreferrer" className="">
                <h3 className="txt-body-bold-pr btn-visita text-light mt-2">
                  VISITAR SITIO
                </h3>
              </a>
            </div>
          </div>
        </div>
      </div>
  */}
      {/* <Footer /> */}

      <FooterProgramas
          background='bg-programa-cuantrix'
          arrows={arrowsCuantrix}
          title='CUANTRIX'
          color='#3F8EFF'
          bgImage='bg-program-image-cuantrix'
          handleSectionSelected={handleSectionSelected}
          programSection='cuantrix'
          english={englishp}
        />
    </div>
  )
}
