import React from 'react'
import './Iniciativas.css'
import imgpr from '../../../../assets/images/programas/tecnolochicas/309A5089png 1.png'
import CountUp from 'react-countup'

export default function Iniciativas({english=false}) {
    const textos = {
        en: {
            t1:`INITIATIVES THAT <br />GENERATE IMPACT: `,
            t2:`<span class="highlight">female ambassadors</span><br class='d-lg-block d-none'/> trained in <span class="highlight">STEM.</span>`,
            t3:`<span class="highlight">instructors </span> <br class='d-lg-block d-none'/> on average taught three courses.`,
        },
        es: {
            t1:`Iniciativas que <br /> generan impacto:`,
            t2:`<span class="highlight">embajadoras capacitadas </span><br class='d-lg-block d-none'/> en <span class="highlight">STEM.</span>`,
            t3:`<span class="highlight">instructoras </span> <br class='d-lg-block d-none'/>en promedio impartieron tres cursos.`,
        }
    }
    
    const txt_v = english ? textos.en : textos.es;
  return (
    <div>
        <div className="container-fluid ini-bg">
            <div className="row justify content-center">
                <div className="col-lg-6 m-auto pt-4  pb-3">
                    <h2 className="gxm-in-title" dangerouslySetInnerHTML={{ __html: txt_v.t1 }}></h2>
                </div>
            </div>
            <div className="row justify-content-around">
                <div className="col-lg-4 col-11 col-md-7">
                    <div className="text-box-numbs">
                        <p className="numbers">
                        <span className="highlight">+<CountUp
                            enableScrollSpy={true}
                            start={0}
                            end={900}
                            duration={4}/></span> <span dangerouslySetInnerHTML={{ __html: txt_v.t2 }}></span>
                        </p>
                    </div>
                </div>
                <div className="col-lg-4 col-11 col-md-7">
                    <div className="text-box-numbs">
                        <p className="numbers">
                        <span className="highlight">+<CountUp
                            enableScrollSpy={true}
                            start={0}
                            end={600}
                            duration={4}/></span> <span dangerouslySetInnerHTML={{ __html: txt_v.t3 }}></span>
                        </p>
                    </div>
                </div>
                <div className="col-12 p-0">
                    <img src={imgpr} alt="" className="d-lg-none d-block img-fluid" />
                </div>
            </div>
        </div>
    </div>
  )
}
