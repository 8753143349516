import React from 'react'
import './ObjetivosOnu.css'
import finPobreza from '../../../assets/images/nuestrasAlas/objetivosOnu/fin-pobreza.png'
import hambreCero from '../../../assets/images/nuestrasAlas/objetivosOnu/hambre-cero.png'
import saludBienestar from '../../../assets/images/nuestrasAlas/objetivosOnu/salud-bienestar.png'
import educacionCalidad from '../../../assets/images/nuestrasAlas/objetivosOnu/educacion-calidad.png'
import igualdadGenero from '../../../assets/images/nuestrasAlas/objetivosOnu/igualdad-genero.png'
import aguaSaneamiento from '../../../assets/images/nuestrasAlas/objetivosOnu/agualimpia-saneamiento.png'
import energiaNoContaminante from '../../../assets/images/nuestrasAlas/objetivosOnu/energia-nocontaminante.png'
import trabajoCrecimiento from '../../../assets/images/nuestrasAlas/objetivosOnu/trabajo-crecimiento.png'
import industriaInnovacion from '../../../assets/images/nuestrasAlas/objetivosOnu/industria-innovacion.png'

export default function ObjetivosOnu({english=false}) {
  const textos = {
    en: {
      t1:`UN Sustainable Development Goals`,
      t2:`Nuestras Alas is aligned with the 2030 Agenda.`
    },
    es: {
      t1:`Objetivos de Desarrollo Sostenible de la ONU`,
      t2:`Nuestras Alas está alineado a la Agenda 2030.`
    },
  }

  const txt_v = english ? textos.en : textos.es;
  return (
    <div className='ObjetivosOnu'>
      {/*  */}
      <div className='mobile-device'>
        <div className='px-4 py-5'>
          <div className='d-flex justify-content-center'>
            <div className='title'>
              {txt_v.t1}
            </div>
          </div>
          <div className='d-flex justify-content-center mt-4'>
            <div className='paragraph text-start'>
              {txt_v.t2}
            </div>
          </div>
          <div className='grid grid-cols-2 gap-4 mt-4 mt-md-5'>
            <div className='d-flex justify-content-end'>
              <img src={finPobreza} alt='Fin de la pobreza' className='w-100'/>
            </div>
            <div className='d-flex justify-content-start'>
              <img src={hambreCero} alt='Fin de la pobreza' className='w-100'/>
            </div>
          </div>

          <div className='grid grid-cols-2 gap-4 mt-4'>
            <div className='d-flex justify-content-end'>
              <img src={saludBienestar} alt='Fin de la pobreza' className='w-100'/>
            </div>
            <div className='d-flex justify-content-start'>
              <img src={educacionCalidad} alt='Fin de la pobreza' className='w-100'/>
            </div>
          </div>

          <div className='grid grid-cols-2 gap-4 mt-4'>
            <div className='d-flex justify-content-end'>
              <img src={igualdadGenero} alt='Fin de la pobreza' className='w-100'/>
            </div>
            <div className='d-flex justify-content-start'>
              <img src={aguaSaneamiento} alt='Fin de la pobreza' className='w-100'/>
            </div>
          </div>

          <div className='grid grid-cols-2 gap-4 mt-4'>
            <div className='d-flex justify-content-end'>
              <img src={energiaNoContaminante} alt='Fin de la pobreza' className='w-100'/>
            </div>
            <div className='d-flex justify-content-start'>
              <img src={trabajoCrecimiento} alt='Fin de la pobreza' className='w-100'/>
            </div>
          </div>

          <div className='grid grid-cols-2 gap-4 mt-4'>
            <div className='d-flex justify-content-end'>
              <img src={industriaInnovacion} alt='Fin de la pobreza' className='w-100'/>
            </div>
            <div className='d-flex justify-content-start'>
              &nbsp;
            </div>
          </div>
        </div>
      </div>

      {/* web */}
      <div className='web-device'>
        <div className='row py-5'>
          <div className='col-lg-7 pr-4'>
            <div className='d-flex justify-content-start ms-lg-5 pt-md-0 pt-5'>
              <div className='title'>
                {txt_v.t1}
                <div className='paragraph text-start'>
                  {txt_v.t2}
                </div>
              </div>
            </div>
          </div>
          <div className='col-5 pe-5'>
            <div className='d-flex'>
              <div className='d-flex justify-content-center card-width me-4'>
                <img src={finPobreza} alt='Fin de la pobreza' className='w-100'/>
              </div>
              <div className='d-flex justify-content-center card-width me-4'>
                <img src={hambreCero} alt='Fin de la pobreza' className='w-100'/>
              </div>
              <div className='d-flex justify-content-center card-width me-4'>
                <img src={saludBienestar} alt='Fin de la pobreza' className='w-100'/>
              </div>
              <div className='d-flex justify-content-start card-width me-2'>
                <img src={educacionCalidad} alt='Fin de la pobreza' className='w-100'/>
              </div>
              <div className='d-flex justify-content-center card-width'>
                <img src={igualdadGenero} alt='Fin de la pobreza' className='w-100'/>
              </div>
            </div>
            <div className='d-flex mt-4'>
              <div className='d-flex justify-content-center card-width  me-4'>
                &nbsp;
              </div>
              <div className='d-flex justify-content-center card-width me-4'>
                <img src={aguaSaneamiento} alt='Fin de la pobreza' className='w-100'/>
              </div>
              <div className='d-flex justify-content-center card-width me-4'>
                <img src={energiaNoContaminante} alt='Fin de la pobreza' className='w-100'/>
              </div>
              <div className='d-flex justify-content-start card-width me-2'>
                <img src={trabajoCrecimiento} alt='Fin de la pobreza' className='w-100'/>
              </div>
              <div className='d-flex justify-content-center card-width'>
                <img src={industriaInnovacion} alt='Fin de la pobreza' className='w-100'/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
