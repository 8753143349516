import React, { useState } from 'react'
import './ProgramasIniciativasValores.css'
import VideoPlayer from '../../../../../componentes/VideoPlayer/VideoPlayer'
import CountUp from 'react-countup';
import playIcon from '../../../../../assets/images/iconos/play.png'


export default function ProgramasIniciativasValores({english=false}) {

  const [showVideoMobile1, setShowVideoMobile1] = useState(false);
  const [showVideo1, setShowVideo1] = useState(false);

  const [showVideoMobile2, setShowVideoMobile2] = useState(false);
  const [showVideo2, setShowVideo2] = useState(false);

  const textos = {
    en: {
        t1:`INITIATIVES THAT <br/>GENERATE IMPACT:`,
        t2:`The campaign <b>"Tienes el valor o te vale" and the site valores.mx</b> help teachers and parents to communicate values and social-emotional skills <b>impacting positively in our society.</b>`,
        t3:`More than`,
        t4:`<b>thousand views</b> on our <b>Children's Day and Teacher's Day Facebook Live</b> with the support of Fundación en Movimiento, Papá Alfa, Aserrín Aserrán, Educación Millennial and Generation México.`,
        t5:`This year's campaign, <b>“Tienes el valor o te vale”, focused on promoting the value of respect</b>, which highlighted respect for women.`,
        t6:`We achieved more than`,
        t7:`<b>television broadcasts</b> and`,
        t8:`<b>reproductions</b> on Facebook.`
    },
    es:{
        t1:`Iniciativas que <br/> generan impacto:`,
        t2:`La campaña <b>"Tienes el valor o te vale" y el sitio valores.mx</b> ayudan a docentes y padres de familia a comunicar los valores y habilidades socioemocionales <b>impactando positivamente en nuestra sociedad.</b>`,
        t3:`Más de`,
        t4:`<b>mil reproducciones</b> en nuestros <b>Facebook Live</b> del <b>Día del niño y Día del maestro </b> con el apoyo de Fundación en Movimiento, Papá Alfa, Aserrín Aserrán, Educación Millennial y Generation México.`,
        t5:`La campaña de <b>¿Tienes el valor o te vale?</b>. En este año, estuvo <b>enfocada en promover el valor del respeto,</b> que resaltó el respeto hacia las mujeres.`,
        t6:`Logramos más de`,
        t7:`<b>transmisiones</b> en televisión y`,
        t8:`<b>reproducciones</b> en Facebook.`
    }
  }
  const txt_v = english ? textos.en : textos.es;

  return (
    <div className='ProgramasIniciativasValores'>
      <div className='bg-rectangle-iniciativas'>
      <div className='d-flex justify-content-center pt-3 pb-xl-5'>
          <div className='title-iniciativas' dangerouslySetInnerHTML={{ __html: txt_v.t1 }}></div>
        </div>
        <div className='row justify-content-center pt-4'>
          <div className='col-lg-9 px-4 px-md-5'>
            <div className='paragraph-iniciativa'>
              <span dangerouslySetInnerHTML={{ __html: txt_v.t2 }}></span>              
              <div className='mt-4 mt-lg-5'></div>
            </div>
          </div>
          
        </div>



        <div className='m-0 p-0 bg-iniciativas-ninios'>&nbsp;</div>
      </div>

      {/* mobile */}
      <div className='mobile-device'>
        <div className='d-flex justify-content-center m-0 p-0'>
          <div className='paragraph-iniciativas-valores1 px-3 pt-5 pb-4'>
            <span dangerouslySetInnerHTML={{ __html: txt_v.t3 }}></span> <b><CountUp
                    enableScrollSpy={true}
                    start={'599'}
                    end={462}
                    duration={3}
                  /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t4 }}></span>
              
          <div className='mt-4 px-4'>
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-center m-0 p-0'>
          {
            !showVideoMobile1 &&
            <div className='m-0 p-0 w-100 bg-iniciativa-valores1' onClick={() => setShowVideoMobile1(true)}>
              <div className='play-icon'>
                
              </div>
            </div>
          }
          {
            showVideoMobile1 &&
            <div className='p-4'>
              <VideoPlayer
                src="https://www.youtube.com/watch?v=f5aLzELTHtA"
                playing={true}
              />
            </div>
          }
        </div>

        <div className='d-flex justify-content-center m-0 p-0'>
          <div className='paragraph-iniciativas-valores1 px-5 pt-5 pb-4'>
          <p><span dangerouslySetInnerHTML={{ __html: txt_v.t5 }}></span></p> <br/>
                <p>
                <span dangerouslySetInnerHTML={{ __html: txt_v.t6 }}></span> <b><CountUp
                  enableScrollSpy={true}
                  start={'10000'}
                  end={15000}
                  duration={2}
                /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t7 }}></span> <b><CountUp
                  enableScrollSpy={true}
                  start={'60000'}
                  end={65000}
                  duration={2}
                /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t8 }}></span>
                </p>
          </div>
        </div>
          {
            !showVideoMobile2 &&
            <div className='m-0 p-0 w-100 bg-iniciativa-valores2' onClick={() => setShowVideoMobile2(true)}>
              <div className='play-icon'>
                
              </div>
            </div>
          }
          {
            showVideoMobile2 &&
            <div className='p-4'>
              <VideoPlayer
                src="https://www.youtube.com/watch?v=0HGdpGXgZGY"
                playing={true}
              />
            </div>
          }
      </div>

      {/* web */}
      <div className='web-device'>
        
        <div className='d-flex m-0 p-0'>
          <div className='w-50 m-0 p-0'>
            <div className='d-flex justify-content-center align-items-center h-100 m-0 p-0'>
              <div className='paragraph-iniciativas-valores1 w-75'>
                <span dangerouslySetInnerHTML={{ __html: txt_v.t3 }}></span> <b><CountUp
                    enableScrollSpy={true}
                    start={'599'}
                    end={462}
                    duration={3}
                  /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t4 }}></span>
              
              </div>
            </div>
          </div>
          <div className='w-50 m-0 p-0'>
            {
              !showVideo1 &&
              <div className='bg-iniciativa-valores1' onClick={() => setShowVideo1(true)}>
                <div className='play-icon'>
                  
                </div>
              </div>
            }
            {
              showVideo1 &&
              <div className='p-4'>
                <VideoPlayer
                  src="https://www.youtube.com/watch?v=f5aLzELTHtA"
                  playing={true}
                />
              </div>
            }
            {/* https://www.youtube.com/watch?v=0HGdpGXgZGY */}
          </div>
        </div>

        <div className='d-flex m-0 p-0'>
        
          <div className='w-50 m-0 p-0'>
            {
              !showVideo2 &&
              <div className='bg-iniciativa-valores2' onClick={() => setShowVideo2(true)}>
                <div className='play-icon'>
                  
                </div>
              </div>
            }
            {
              showVideo2 &&
              <div className='p-4'>
                <VideoPlayer
                  src="https://www.youtube.com/watch?v=0HGdpGXgZGY"
                  playing={true}
                />
              </div>
            }
          </div>
          <div className='w-50 m-0 p-0'>
            <div className='d-flex justify-content-center align-items-center h-100 m-0 p-0'>
              <div className='paragraph-iniciativas-valores1 w-75'>
                <p>
                  <span dangerouslySetInnerHTML={{ __html: txt_v.t5 }}></span>
                </p>
                <br/>
                <p><span dangerouslySetInnerHTML={{ __html: txt_v.t6 }}></span> <b><CountUp
                  enableScrollSpy={true}
                  start={'10000'}
                  end={15000}
                  duration={2}
                /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t7 }}></span>  <b><CountUp
                  enableScrollSpy={true}
                  start={'65000'}
                  end={65000}
                  duration={2}
                /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t8 }}></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
