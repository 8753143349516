import React, { useState } from 'react'
import './Exposicion1.css'
import CountUp from 'react-countup';
import VideoPlayer from '../../../../VideoPlayer/VideoPlayer';

export default function Exposicion1({english=false}) {

  const [showVideoMobile, setShowVideoMobile] = useState(false);
  const [showVideoMobile2, setShowVideoMobile2] = useState(false);
  const [showVideoMobile3, setShowVideoMobile3] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [showVideo2, setShowVideo2] = useState(false);
  const [showVideo3, setShowVideo3] = useState(false);

  const textos = {
    en: {
      t1:`In collaboration with the <b>Filmoteca de Catalunya</b> in Barcelona, Spain, we presented the exhibition <em>El sueño de la liebre. El cine de Luis Buñuel y Gabriel Figueroa.</em>`,
      t2:`<em>El rancho que forjó una industria, la gesta de Fernando de Fuentes</em> was the exhibition that participated in the <b>21st edition of the Morelia International Film Festival</b> with`,
      t3:`<b>attendees</b>,<br class="d-md-none d-block" /> In addition, the national premiere of the restored version of <em>Macario</em>`,
      t4:`In collaboration with the <b>Museo del Palacio de Bellas Artes</b>, we launched <em>Mexichrome. Fotografía y color en México</em>, with more than`,
      t5:`<b>people</b> attending the exhibition.`,
      t6:`In alliance with the <b>Morelia International Film Festival, the Cineteca Nacional and the Filmoteca UNAM,</b> the Museum of Modern Art <b>(MoMA)</b> presented a fascinating film series <em>Buñuel in Mexico,</em> 7 of the 20 films presented are part of the legacy of <b>TelevisaUnivision.</b>`,
      t7:`In the context of <b>International Women's Day,</b> the program held the exhibition <b>Luminarias del cine mexicano,</b> on the <b>Google Arts and Culture</b> platform, showing <b>the contribution that women have made to the Mexican cinematography.</b>`,
      t8:`We disseminated`,
      t9:`<b>virtual exhibitions</b> through the <b>Google Arts & Culture</b><br class="d-md-none d-block" /> platform with`,
      t10:`<b>views</b> and held <b>6 new virtual exhibitions</b>`
    },
    es:{
        t1:`En colaboración con la <b>Filmoteca de Catalunya</b> en Barcelona, España. realizamos la exposición <em> El sueño de la liebre. El cine de Luis Buñuel y Gabriel Figueroa.</em>`,
        t2:`<em>El rancho que forjó una industria, la gesta de Fernando de Fuentes</em> fue la exposición con la que se participó en la <b>21° edición del Festival Internacional de Cine de Morelia </b> con`,
        t3:`<b>asistentes</b>,<br class="d-md-none d-block" /> así mismo, se realizó el estreno nacional de la versión restaurada de <em>Macario.</em>`,
        t4:`En colaboración con el <b>Museo del Palacio de Bellas Artes</b>, lanzamos <em>Mexichrome. Fotografía y color en México</em> con la asistencia de más de`,
        t5:`<b>personas</b> a la exposición.`,
        t6:`En alianza con el <b>Festival Internacional de Cine de Morelia, la Cineteca Nacional y la Filmoteca UNAM, </b> el Museo de Arte Moderno <b>(MoMA)</b> presentó un fascinante ciclo de cine <em>Buñuel in Mexico,</em> 7 de las 20 películas presentadas, forman parte del legado de <b>TelevisaUnivision.</b>`,
        t7:`En el contexto del <b>Día Internacional de la Mujer,</b> el programa realizó la exposición <b>Luminarias del cine mexicano, </b> en la plataforma de <b>Google Arts and Culture,</b> mostrando <b>la contribución que las mujeres han hecho a la cinematografía mexicana.</b>`,
        t8:`Difundimos`,
        t9:`<b>exposiciones virtuales</b> a través de la plataforma <b>Google Arts & Culture</b><br class="d-md-none d-block" /> con`,
        t10:`<b>vistas</b> y realizamos <b>6 nuevas exposiciones virtuales</b>.`
    }
  }

  const txt_v = english ? textos.en : textos.es;

  return (
    <div className='Exposicion1'>
      <div className='bg1'>
        <div className='text-container-exposicion1'>
          <div className='gradient1'>
            <div className='paragraph p-4' dangerouslySetInnerHTML={{ __html: txt_v.t1 }}></div>
          </div>
        </div>
      </div>

      <div className='mobile-device'>
        <div className='paragraph-inif px-4 mt-4 py-4'>
          <span dangerouslySetInnerHTML={{ __html: txt_v.t2 }}></span>
           <b><CountUp
                enableScrollSpy={true}
                start={'64900'}
                end={65000}
                duration={5}
              /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t3 }}></span>
        </div>

        <div className='m-0 p-0 w-100'>
          {
            !showVideoMobile &&
            <div className='bg2' onClick={() => setShowVideoMobile(true)}>&nbsp;</div>
          }
          {
            showVideoMobile &&
            <VideoPlayer
              src="https://youtu.be/aO_s9jh1FCI"
              className="w-100"
              playing={true}
            />
          }
        </div>
        {/* <div className='bg2'>&nbsp;</div> */}

        <div className='paragraph-inif px-4 mt-4 py-4'>
          <span dangerouslySetInnerHTML={{ __html: txt_v.t4 }}></span>
          <b><CountUp
                enableScrollSpy={true}
                start={140000}
                end={150000}
                duration={3}
              /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t5 }}></span>
        </div>

        {
          !showVideoMobile2 &&
          <div className='bg3' onClick={() => setShowVideoMobile2(true)}>&nbsp;</div>
        }
        {
          showVideoMobile2 &&
          <VideoPlayer
            src="https://youtu.be/aO_s9jh1FCI"
            className="w-100"
            playing={true}
          />
        }

        <div className='paragraph-inif px-4 mt-4 py-4' dangerouslySetInnerHTML={{ __html: txt_v.t6 }}></div>

        <div className='bg4'>&nbsp;</div>

        <div className='paragraph-inif px-4 mt-4 py-4' dangerouslySetInnerHTML={{ __html: txt_v.t7 }}></div>

        {
          !showVideoMobile3 &&
          <div className='bg-fotografica' onClick={() => setShowVideoMobile3(true)}>&nbsp;</div>
        }
        {
          showVideoMobile3 &&
          <VideoPlayer
            src="https://www.youtube.com/watch?v=si2-azAD5S8"
            className="w-100"
            playing={true}
          />
        }

        <div className='bg5'>
          <div className='text-container-exposicion5'>
            <div className='gradient1'>
              <div className='paragraph p-4'>
                <span dangerouslySetInnerHTML={{ __html: txt_v.t8 }}></span> <b><CountUp
                enableScrollSpy={true}
                start={20}
                end={27}
                duration={4}
              /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t9 }}></span> <b><CountUp
                enableScrollSpy={true}
                start={'35150'}
                end={35950}
                duration={3}
              /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t10 }}></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* web */}
      <div className='web-device'>
        <div className='row m-0 p-0'>
          <div className='col-12 col-lg-6 m-0 p-0'>
            {
              !showVideo &&
              <div className='bg2' onClick={() => setShowVideo(true)}>&nbsp;</div>
            }
            {
              showVideo &&
              <div className='p-4'>
                <VideoPlayer
                  src="https://youtu.be/aO_s9jh1FCI"
                  className="w-100"
                  playing={true}
                />
              </div>
            }
          </div>
          <div className='col-12 col-lg-6 m-0 p-0'>
            <div className='d-flex justify-content-center align-items-center h-100 w-100 px-5'>
              <div className='paragraph-inif px-4 mt-4 py-4'>
                <span dangerouslySetInnerHTML={{ __html: txt_v.t2 }}></span> <b><CountUp
                      enableScrollSpy={true}
                      start={'64900'}
                      end={65000}
                      duration={5}
                    /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t3 }}></span>
              </div>
            </div>
          </div>
        </div>

        <div className='row m-0 p-0'>
          <div className='col-12 col-lg-6 m-0 p-0'>
            <div className='d-flex justify-content-center align-items-center h-100 w-100 px-5'>
              <div className='paragraph-inif px-4 mt-4 py-4'>
              <span dangerouslySetInnerHTML={{ __html: txt_v.t4 }}></span> <b><CountUp
                enableScrollSpy={true}
                start={140000}
                end={150000}
                duration={3}
              /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t5 }}></span>
              </div>
            </div>
          </div>
          <div className='col-12 col-lg-6 m-0 p-0'>
            {
              !showVideo2 &&
              <div className='bg3' onClick={() => setShowVideo2(true)}>&nbsp;</div>
            }
            {
              showVideo2 &&
              <div className='p-4'>
                <VideoPlayer
                  src="https://www.youtube.com/watch?v=FkieduLnl_M"
                  className="w-100"
                  playing={true}
                />
              </div>
            }
          </div>
        </div>

        <div className='row m-0 p-0'>
          <div className='col-12 col-lg-6 m-0 p-0'>
            <div className='bg4'>&nbsp;</div>
          </div>
          <div className='col-12 col-lg-6 m-0 p-0'>
            <div className='d-flex justify-content-center align-items-center h-100 w-100 px-5'>
              <div className='paragraph-inif px-4 mt-4 py-4' dangerouslySetInnerHTML={{ __html: txt_v.t6 }}></div>
            </div>
          </div>
        </div>

        <div className='row m-0 p-0'>
          <div className='col-12 col-lg-6 m-0 p-0'>
            <div className='d-flex justify-content-center align-items-center h-100 w-100 px-4'>
              <div className='paragraph-inif px-4 mt-4 py-4' dangerouslySetInnerHTML={{ __html: txt_v.t7 }}></div>
            </div>
          </div>
          <div className='col-12 col-lg-6 m-0 p-0'>
            {
              !showVideo3 &&
              <div className='bg-fotografica' onClick={() => setShowVideo3(true)}>&nbsp;</div>
            }
            {
              showVideo3 &&
              <div className='p-4'>
                <VideoPlayer
                  src="https://www.youtube.com/watch?v=si2-azAD5S8"
                  className="w-100"
                  playing={true}
                />
              </div>
            }
          </div>
        </div>

        <div className='row m-0 p-0'>
          <div className='bg5'>
            <div className='text-container-exposicion5'>
              <div className='gradient1'>
                <div className='paragraph p-4'>
                <span dangerouslySetInnerHTML={{ __html: txt_v.t8 }}></span> <b><CountUp
                enableScrollSpy={true}
                start={20}
                end={27}
                duration={4}
              /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t9 }}></span> <b><CountUp
                enableScrollSpy={true}
                start={'35150'}
                end={35950}
                duration={3}
              /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t10 }}></span>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    
    </div>
  )
}
