import React from 'react'
import './HeaderSection.css'
import Nav from '../Nav/Nav'

export default function HeaderSection(
  {image, title, ajustemov,english=false}
) {
  return (
    <div className='HeaderSection'>
      <Nav ver={english}/>
      <img src={image} alt="Hero" className={`hero-img movement ${ajustemov}`} />
      <div className="hero-content">
        <h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
      </div>
    </div>
  )
}
