import React, { useCallback } from 'react'
import './Button.css'
import { useNavigate } from 'react-router-dom';
export function Button(
  {link = '/', btnClass = 'conoce-mas-blue', onClick, english=false}
){

  const navigate = useNavigate();

  const handleButtonClicked = useCallback(() => {
    setTimeout(() => {
      if (link !== '#') {
        window.scrollTo({ top:50, left:0, behavior: "instant"});
        navigate(link);
      } else {
        onClick();
      }
    }, 500);
  }, [link, navigate, onClick]);

  const textos = {
    en: {
      t1:'LEARN ABOUT OUR RESULTS'
    },
    es: {
      t1:'CONOCE NUESTROS RESULTADOS'
    }
  }

  const txt_v = english ? textos.en : textos.es;

  return (
    <>
      <div className='Button'>
        <div className='mobile-device m-0 p-0'>
          <button
            onClick={handleButtonClicked}
            className={`conoce-mas ${btnClass}`}
          >
            {txt_v.t1}
          </button>
        </div>

        <div className='web-device m-0 p-0'>
          <button
            className={`btn rounded-pill conoce-mas ${btnClass}`}
            onClick={handleButtonClicked}
          >
            {txt_v.t1}
          </button>
        </div>
      </div>
    </>

    
        
  )
}
