import React from 'react'
import'./Testimonios.css'
import Carousel from '../../../Carousel/Carousel'
import iconoTest from '../../../../assets/images/programas/temprano/testimonios.svg'
import temprano1 from '../../../../assets/images/home/testimonios/thumbnails/e-temprano.png'
import temprano2 from '../../../../assets/images/programas/temprano/thumbnails/temprano2.png'

const items = [
    {
        type: 'video',
        image: temprano1,
        videoUrl: 'https://www.youtube.com/watch?v=A13akf8d9EY'
    },
    {
        type: 'video',
        image: temprano2,
        videoUrl: 'https://www.youtube.com/watch?v=fMprlKGuMMA&feature=youtu.be'
    }
];
export default function Testimonios({english=false}) {

    const textos = {
        en: {
            t1:`READ THEIR TESTIMONIALS`,
        },
        es:{
            t1:`CONOCE SUS TESTIMONIOS`,
        }
    }

    const txt_v = english ? textos.en : textos.es;

  return (
    <div className='testim-bg'>
        <div className="container  pb-5">
            <div className="row text-center">
                <img src={iconoTest} alt="" className="img-logo" />
                <h2 className="testimonios-titulo pb-0 pb-lg-4">
                {txt_v.t1}
                </h2>
            </div>
            <div className="row">
                <Carousel 
                    items={items}
                    arrowsColor='#D4569B'
                />
            </div>
        </div>
    </div>
  )
}
