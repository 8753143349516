import React from 'react'
import './ProgramasComunidadesValores.css'
import pazJusticia from '../../../../../assets/images/programas/subsections/valores/paz-justicia.png'
import alianzasObjetivos from '../../../../../assets/images/programas/subsections/valores/alianzas-objetivos.png'
import Progredes from '../../../Progredes/Progredes'
import fb from '../../../../../assets/images/iconos/fb.svg'
import ig from '../../../../../assets/images/iconos/ig.svg'
import site from '../../../../../assets/images/iconos/site.svg'
import CountUp from 'react-countup';

export default function ProgramasComunidadesValores({english=false}) {

  const social= [
    {
        link:'https://www.facebook.com/valores.fundaciontelevisa',
        icon: fb,
        nombre:'valores.fundaciontelevisa'
    },
    {
        link:'https://www.instagram.com/tieneselvalor_ ',
        icon: ig,
        nombre:'tieneselvalor_'
    },
    {
      link:'https://twitter.com/PosibleMex',
      icon: site,
      nombre:'semillero.valores.mx'
    }
  ]

  const textos = {
    en: {
        t1:`DIGITAL <br/> COMMUNITIES:`,
        t2:`<b class='highlight'>million</b> people reached on <b class='highlight'>social networks</b>.`,
        t3:`<b class='highlight'>thousand teachers and parents</b> make up our <b class='highlight'>Facebook</b> community.`,
        t4:`<b class='highlight'>million views</b> of our <b class='highlight'>videos</b> on <b class='highlight'>Facebook</b>.`,
        t5:`<b class='highlight'>million people</b> reached on <b class='highlight'>Semillero de Valores</b>.`,
        t6:`UN Sustainable Development Goals`,
        t7:`Our <b>Valores</b> program is aligned with the 2030 agenda, contributing directly to the goal of peace, justice and building strong institutions.`,
    },
    es:{
        t1:`comunidades <br/> digitales:`,
        t2:`<b class='highlight'>millones</b> de personas alcanzadas en <b class='highlight'>redes sociales</b>.`,
        t3:`<b class='highlight'>mil maestros y padres de familia</b> conforman nuestra comunidad en <b class='highlight'>Facebook</b>.`,
        t4:`<b class='highlight'>millones de reproducciones</b> de nuestros <b class='highlight'>videos</b> en <b class='highlight'>Facebook</b>.`,
        t5:`<b class='highlight'>millones de personas</b> alcanzadas en <b class='highlight'>Semillero de Valores</b>.`,
        t6:`Objetivos de Desarrollo Sostenible de la ONU`,
        t7:`Nuestro programa <b>Valores</b> está alineado con la agenda 2030, contribuyendo directamente al objetivo de paz, justicia y construcción de instituciones sólidas.`,
    }
}

const txt_v = english ? textos.en : textos.es;

  return (
    <div className='ProgramasComunidadesValores'>
      <div className='row m-0 p-0'>
        <div className='col-12 col-lg-6 m-0 p-0'>
          <div className='d-flex justify-content-center justify-content-lg-start align-items-center h-100'>
            <div className='comunidades-digitales-titulo pt-5 pt-l-0 pb-5 ps-0 ps-lg-5' dangerouslySetInnerHTML={{ __html: txt_v.t1 }}></div>
          </div>
        </div>
        <div className='col-12 col-lg-6 m-0 p-0'>
          <div className='m-0 p-0 bg-bluelight'>
            <div className='d-flex justify-content-start ps-4 ps-lg-5'>
              <div className='pt-3 pt-md-5 pt-lg-5 pb-3 px-md-4 custom-width-valores'>
                <ul className=''>
                  <li className=''>
                <div className='comunidades-digitales-items pb-md-4 pb-lg-0 ps-2 pe-4'>
                  <b className='highlight'><CountUp
                    enableScrollSpy={true}
                    start={0}
                    end={8}
                    duration={2}
                  /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t2 }}></span>
                </div>
                </li>
                  <li className=''>
                <div className='pt-4'>
                  <div className='comunidades-digitales-items pb-md-4 ps-2 pe-4'>
                    <b className='highlight'><CountUp
                    enableScrollSpy={true}
                    start={500}
                    end={607}
                    duration={2}
                  /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t3 }}></span>
                  </div>
                </div>
                </li>
                  <li className=''>
                <div className='pt-4'>
                  <div className='comunidades-digitales-items pb-md-4 ps-2 pe-4'>
                    <b className='highlight'><CountUp
                    enableScrollSpy={true}
                    start={0}
                    end={1.1}
                    duration={2}
                    decimal='.'
                    decimals={1}
                  /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t4 }}></span>
                  </div>
                </div>
                </li>
                  <li className=''>
                <div className='pt-4'>
                  <div className='comunidades-digitales-items pb-md-4 ps-2 pe-4'>
                    <b className='highlight'><CountUp
                    enableScrollSpy={true}
                    start={0}
                    end={1.5}
                    duration={2}
                    decimal='.'
                    decimals={1}
                  /></b> <span dangerouslySetInnerHTML={{ __html: txt_v.t5 }}></span>
                  </div>
                </div>
                </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row m-0 p-0 bg-bluedark'>
        <div className='col-12 col-lg-7 m-0 p-0'>
          <div className='d-flex justify-content-center'>
            <div className='p-5'>
              <div className='title-onu'>
                {txt_v.t6}
              </div>
              <div className='paragraph-onu pt-4' dangerouslySetInnerHTML={{ __html: txt_v.t7 }}></div>
            </div>
          </div>
        </div>
        <div className='col-12 col-lg-5 m-0 p-0'>
          <div className='d-flex justify-content-center pb-5'>
            <div className='d-flex pt-0 pt-lg-5'>
              <div className='px-2'>
                <img src={pazJusticia} alt='Paz, justicia e instituciones sólidas' className='width-icon-objetivos' />
              </div>
              <div className='px-2'>
                <img src={alianzasObjetivos} alt='Alianzas para lograr objetivos' className='width-icon-objetivos' />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row m-0 p-0'>
        <Progredes
          sitio = 'https://valores.mx/'
          redes = {social}
          english={english}
        />
      </div>
    </div>
  )
}
