import React from 'react'
import './Aliados.css'
// import { Header } from '../../componentes/Aliados/Header/Header'
import Union from '../../componentes/Aliados/Union/Union'
import Becalos from '../../componentes/Aliados/Becalos/Becalos'
import Golxmex from '../../componentes/Aliados/Golxmex/Golxmex'
import Tecnolochicas from '../../componentes/Aliados/Tecnolochicas/Tecnolochicas'
import Temprano from '../../componentes/Aliados/Temprano/Temprano'
import Cuantrix from '../../componentes/Aliados/Cuantrix/Cuantrix'
import Posible from '../../componentes/Aliados/Posible/Posible'
import Fotografica from '../../componentes/Aliados/Fotograficamx/Fotografica'
import Valores from '../../componentes/Aliados/Valores/Valores'
import Footer from '../../componentes/Footer/Footer'
import HeaderSection from '../../componentes/HeaderSection/HeaderSection'
import hero from '../../assets/images/aliados/header/DSC_0559.png'

import item1 from '../../assets/images/items-footer/reconocimientos.png'
import item2 from '../../assets/images/items-footer/nuestros-programas.png'
import item3 from '../../assets/images/items-footer/comunicacion-con-impacto.png'
import mobile from '../../assets/images/items-footer/reconocimientos-mobile.png'

const itemsAliados = [
  {
    image: item1,
    title: {
      en:'ACKNOWLEDGEMENTS',
      es:'RECONOCIMIENTOS',
    },
    link: '/reconocimientos'
  },
  {
    image: item2,
    title: {
      en:'OUR PROGRAMS',
      es:'NUESTROS PROGRAMAS',
    },
    link: '/programas'
  },
  {
    image: item3,
    title: {
      en:'COMMUNICATION EFFORTS WITH IMPACT',
      es:'ESFUERZOS DE COMUNICACIÓN CON IMPACTO',
    },
    link: '/comunicacion'
  }
];

const itemAliadosMobile = [
  {
    image: mobile,
    title: {
      en:'ACKNOWLEDGEMENTS',
      es:'RECONOCIMIENTOS',
    },
    link: '/reconocimientos'
  }
]

export default function Aliados({version=false}) {
  const textos = {
    en: {
      t1:'OUR ALLIES'
    },
    es: {
      t1:'Nuestros Aliados'
    }
  }
  const txt_v = version ? textos.en : textos.es;
  return (
    <>
    <div className='Aliados'>
        {/* <Header /> */}
        <HeaderSection
          image={hero}
          title={txt_v.t1}
          english={version}
        />

        <Union english={version}/>
        <Becalos english={version}/>
        <Golxmex english={version}/>
        <Tecnolochicas english={version}/>
        <Cuantrix english={version}/>
        <Temprano english={version}/>
        <Posible english={version}/>
        <Fotografica english={version}/>
        <Valores english={version}/>
    </div>
        <Footer 
          showLogo={false}
          items={itemsAliados}
          itemMobile={itemAliadosMobile}
          english={version}
        />
    </>
  )
}
