import React from 'react'
import './Comunidades.css'
import img1 from '../../../../assets/images/programas/golxmex/obj/S-WEB-Goal-01.png'
import img2 from '../../../../assets/images/programas/golxmex/obj/S-WEB-Goal-02.png'
import img3 from '../../../../assets/images/programas/golxmex/obj/S-WEB-Goal-03.png'
import img4 from '../../../../assets/images/programas/golxmex/obj/S-WEB-Goal-04.png'
import img5 from '../../../../assets/images/programas/golxmex/obj/S-WEB-Goal-05.png'
import img6 from '../../../../assets/images/programas/golxmex/obj/S-WEB-Goal-08.png'
import img7 from '../../../../assets/images/programas/golxmex/obj/S_SDG_PRINT-10.png'
import img8 from '../../../../assets/images/programas/golxmex/obj/S_SDG_PRINT-13.png'
import img9 from '../../../../assets/images/programas/golxmex/obj/S-WEB-Goal-17.png'
import CountUp from 'react-countup'

export default function Comunidades({english=false}) {
    const textos = {
        en: {
            t1:`DIGITAL<br />COMMUNITIES:`,
            t2:`<span class="highlight">visits</span> and`,
            t3:`<span class="highlight">users </span> on the <span class="highlight">golpormexico.org</span> site`,
            t4:`UN Sustainable Development Goals`,
            t5:`<span class='bold'>Gol por México</span> is aligned with the 2030 agenda contributing to 9 of the 17 SDGs.`
        },
        es:{
            t1:`comunidades <br />digitales:`,
            t2:`<span class="highlight">visitas </span> y`,
            t3:`<span class="highlight">usuarios </span>  en el sitio <span class="highlight">golpormexico.org</span>`,
            t4:`Objetivos de Desarrollo Sostenible de la ONU`,
            t5:`<span class='bold'>Gol por México</span>  está alineado con la agenda 2030 contribuyendo con 9 de los 17 ODS.`
        }
    }

    const txt_v = english ? textos.en : textos.es;

  return (
    <div>
        <div className="container-fluid">
            <div className="row ">
                <div className="col-lg-6 m-auto px-md-5 px-3">
                    <h2 className="com-dig" dangerouslySetInnerHTML={{ __html: txt_v.t1 }}></h2>
                </div>
                <div className="col-lg-6 p-0 bg-nar-cl m-auto">
                    <div className="row com-h align-items-center justify-content-center">
                        <div className="col-10 col-md-12 col-lg-12 ">
                        <ul className='comunidades px-md-5'>
                            <li className=''>
                            <p className="par-comunidad-digital ">
                                <span className="highlight"><CountUp
                                    enableScrollSpy={true}
                                    start={222000}
                                    end={222152}
                                    duration={4}/></span> <span dangerouslySetInnerHTML={{ __html: txt_v.t2 }}></span>
                                <span className="highlight"> <CountUp
                                    enableScrollSpy={true}
                                    start={214000}
                                    end={214872}
                                    duration={4}/></span> <span dangerouslySetInnerHTML={{ __html: txt_v.t3 }}></span>
                            </p>
                            </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row bg-nar ">
                <div className="col-lg-6 col-11 m-auto px-md-5 pt-4 pt-md-0">
                <h2 className="title-onu">{txt_v.t4}</h2>
                <p className="paragraph-onu pt-3" dangerouslySetInnerHTML={{ __html: txt_v.t5 }}></p>
                </div>
                <div className="col-lg-6 py-md-5 text-lg-end text-center">
                    <img src={img1} alt="" className="img-objetivos" />
                    <img src={img2} alt="" className="img-objetivos" />
                    <img src={img3} alt="" className="img-objetivos" />
                    <img src={img4} alt="" className="img-objetivos" />
                    <img src={img5} alt="" className="img-objetivos" />
                    
                    <img src={img6} alt="" className="img-objetivos" />
                    <img src={img7} alt="" className="img-objetivos" />
                    <img src={img8} alt="" className="img-objetivos" />
                    <img src={img9} alt="" className="img-objetivos" />
                </div>
            </div>
        </div>
    </div>
  )
}
