import React, { useState } from 'react'
import './Futbol.css'
import imghead from '../../../../assets/images/programas/golxmex/gxm.jpg'
import CountUp from 'react-countup'
import VideoPlayer from '../../../VideoPlayer/VideoPlayer';


export default function Futbol({english=false}) {
    const [showVideo, setShowVideo] = useState(false);
    const textos = {
        en: {
            t1:`GOL POR MÉXICO`,
            t2:`We transform the passion for soccer <b>into a passion</b> for helping since 2001.`,
            t3:`We favor the <b>living conditions</b> of low-income populations and focus on causes in favor of <b>education, nutrition, health, housing, environment</b> and since 2018 we have evolved to favor causes focused on <b>women</b>.`,
            t4:`<span class='bold'>More than </span>`,
            t5:`<span class='bold'>million beneficiaries,</span>  thanks to the goals scored in <span class='bold'>Liga MX</span> throughout the program's history.`,
        },
        es:{
            t1:`GOL POR MÉXICO`,
            t2:`Transformamos la pasión por el futbol en <b>pasión por ayudar desde 2001.</b>`,
            t3:`Favorecemos las <b>condiciones de vida </b>de poblaciones de escasos recursos y nos enfocamos en causas a favor de la <b>educación, nutrición, salud, vivienda, medioambiente </b>y desde 2018 evolucionamos a favorecer causas enfocadas a las<b> mujeres.</b>`,
            t4:`<span class='bold'>Más de </span>`,
            t5:`<span class='bold'>millones de beneficiados</span> gracias a los goles anotados en la <span class='bold'>Liga MX </span> a largo de la historia del programa.`,
        }
    }
    
    const txt_v = english ? textos.en : textos.es;
  return (
    <div>
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-6 bg-suave p-lg-5">
                    <h2 className="main-title-programas pt-lg-4">{txt_v.t1}</h2>
                    <p className="main-paragraph-programas" dangerouslySetInnerHTML={{ __html: txt_v.t2 }}></p>
                    <p className="main-paragraph-programas" dangerouslySetInnerHTML={{ __html: txt_v.t3 }}></p>
                </div>
                <div className="col-lg-6 p-0">
                    {
                        !showVideo &&
                        <div className="img-vid">
                        <img src={imghead} alt="fut" className="ctx-img object-fit-cover" onClick={() => setShowVideo(true)} />
                        {/*<img src={play} alt=">" className="overlay-icon" />*/}
                        </div>
                    }
                    {
                        showVideo &&
                        <div className='p-4'>
                        <VideoPlayer
                            src="https://www.youtube.com/watch?v=LCEyMVcHFB0"
                            className="w-100"
                            playing={true}
                        />
                        </div>
                    }
                </div>
            </div>
            <div className="row  bg-gxm justify-content-center">
                <div className="col-lg-8 px-md-4">
                    <div className="row mh-250 align-items-center">
                        <div className="col">
                            <h3 className="gxm-quote">
                                <span dangerouslySetInnerHTML={{ __html: txt_v.t4 }}></span>
                                <span className='bold'><CountUp
                                    enableScrollSpy={true}
                                    start={0}
                                    end={1.4}
                                    duration={3}
                                    decimal='.'
                                    decimals={1}
                                /></span> <span dangerouslySetInnerHTML={{ __html: txt_v.t5 }}></span>
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}