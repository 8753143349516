import React from 'react'
import './Awards.css'
import awardIcon  from '../../../assets/images/reconocimientos-fundacion/laurel.png'

export default function Awards({english=false}) {
  const textos = {
    en: {
      t1:`At <b>Televisa Foundation</b> we work tirelessly for a fairer, more prosperous, and sustainable country, our efforts in 2023 were rewarded through:`,
      t2:`POSIBLE`,
      t3:`<b>Posible</b>, winner of the <b>"Alebrijes"</b> award given by the <b>Asociación Méxicana de Capital Privado</b> and the "Generator of Impact in the Innovation and Entrepreneurship Ecosystem in the South-Southeast" award given by the <b>Inter-American Development Bank</b>.`,
      t4:`BÉCALOS`,
      t5:`The program obtained the renewal of the <b>Acreditación de Institucionalidad y Transparencia of CEMEFI</b>.`,
      t6:`CUANTRIX`,
      t7:`Selected among 60 organizations to collaborate with <b>Scratch Education Collaborative for two years</b>.`,
      t8:`POSIBLE`,
      t9:`Winner of the <b>Fomento Social Citibanamex</b> call for <b>Social Entrepreneurship 2023</b>.`
    },
    es: {
      t1:`En <b>Fundación Televisa</b> trabajamos incansablemente por un país más <b>justo, próspero y sostenible,</b> nuestros esfuerzos en 2023 se vieron recompensados a través de:`,
      t2:`POSIBLE`,
      t3:`Posible, acreedor al premio <b>“Alebrijes”</b> entregado por la <b>Asociación Mexicana de Capital Privado y el Banco Interamericano de Desarrollo</b> a “Generador de Impacto en el Ecosistema de Innovación y Emprendimiento en el Sur-Sureste”.`,
      t4:`BÉCALOS`,
      t5:`El programa obtuvo la renovación de <b>Acreditación de Institucionalidad y Transparencia de CEMEFI</b>.`,
      t6:`CUANTRIX`,
      t7:`Seleccionados entre 60 organizaciones para colaborar con <b>Scratch Education Collaborative</b> durante dos años.`,
      t8:`POSIBLE`,
      t9:`Ganador de la convocatoria <b>Emprendimiento Social 2023</b> de <b>Fomento Social Citibanamex.</b>`
    },
  }

  const txt_v = english ? textos.en : textos.es;

  return (
    <div className='Awards'>
      <div className='d-flex justify-content-center p-0 p-md-5'>
        <div className='award-description px-4 px-md-5 py-4 py-lg-5'>
          <div className='paragraph px-0 px-lg-5' dangerouslySetInnerHTML={{ __html: txt_v.t1 }}></div>
        </div>
      </div>

      <div className='mobile-device'>
          <div className='px-5 py-4'>
            <div className='award-card-container px-5 py-4'>
              <div className='d-flex justify-content-center'>
                <img src={awardIcon} alt='award icon' className='award-card-icon'/>
              </div>
              <div className='award-card-title pt-4'>
                {txt_v.t2}
              </div>
              <div className='award-card-text pt-2' dangerouslySetInnerHTML={{ __html: txt_v.t3 }}></div>
            </div>
          </div>

          <div className='pt-2'>&nbsp;</div>

          <div className='px-5 py-4'>
            <div className='award-card-container px-5 py-4'>
              <div className='d-flex justify-content-center'>
                <img src={awardIcon} alt='award icon' className='award-card-icon'/>
              </div>
              <div className='award-card-title pt-4'>
                {txt_v.t4}
              </div>
              <div className='award-card-text pt-2' dangerouslySetInnerHTML={{ __html: txt_v.t5 }}></div>
            </div>
          </div>

          <div className='pt-2'>&nbsp;</div>

          <div className='px-5 py-4'>
            <div className='award-card-container px-5 py-4'>
              <div className='d-flex justify-content-center'>
                <img src={awardIcon} alt='award icon' className='award-card-icon'/>
              </div>
              <div className='award-card-title pt-4'>
                {txt_v.t6}
              </div>
              <div className='award-card-text pt-2' dangerouslySetInnerHTML={{ __html: txt_v.t7 }}></div>
            </div>
          </div>

          <div className='pt-2'>&nbsp;</div>

          <div className='px-5 py-4'>
            <div className='award-card-container px-5 py-4'>
              <div className='d-flex justify-content-center'>
                <img src={awardIcon} alt='award icon' className='award-card-icon'/>
              </div>
              <div className='award-card-title pt-4'>
                {txt_v.t8}
              </div>
              <div className='award-card-text pt-2' dangerouslySetInnerHTML={{ __html: txt_v.t9 }}></div>
            </div>
          </div>
          

        </div>

        <div className='web-device'>
          <div className='row m-0 pb-4'>
            <div className='col-10 offset-1'>
              <div className='grid grid-cols-3 gap-4'>
                <div className='d-flex justify-content-center align-items-center h-100'>
                  <div className='p-4'>
                    <div className='award-card-container p-4'>
                      <div className='d-flex justify-content-center'>
                        <img src={awardIcon} alt='award icon' className='award-card-icon'/>
                      </div>
                      <div className='award-card-title pt-4'>
                        {txt_v.t2}
                      </div>
                      <div className='award-card-text pt-4' dangerouslySetInnerHTML={{ __html: txt_v.t3 }}></div>
                    </div>
                  </div>
                </div>
                <div className='d-flex flex-column justify-content-center align-items-center h-100'>
                  <div className='p-4'>
                    <div className='award-card-container p-4'>
                      <div className='d-flex justify-content-center'>
                        <img src={awardIcon} alt='award icon' className='award-card-icon'/>
                      </div>
                      <div className='award-card-title pt-4'>
                        {txt_v.t4}
                      </div>
                      <div className='award-card-text pt-4' dangerouslySetInnerHTML={{ __html: txt_v.t5 }}></div>
                    </div>
                  </div>

                  <div className='pt-2'>&nbsp;</div>

                  <div className='p-4'>
                    <div className='award-card-container p-4'>
                      <div className='d-flex justify-content-center'>
                        <img src={awardIcon} alt='award icon' className='award-card-icon'/>
                      </div>
                      <div className='award-card-title pt-4'>
                        {txt_v.t8}
                      </div>
                      <div className='award-card-text pt-4' dangerouslySetInnerHTML={{ __html: txt_v.t9 }}></div>
                    </div>
                  </div>

                </div>
                <div className='d-flex justify-content-center align-items-center h-100'>
                  <div className='p-4'>
                    <div className='award-card-container p-4'>
                      <div className='d-flex justify-content-center'>
                        <img src={awardIcon} alt='award icon' className='award-card-icon'/>
                      </div>
                      <div className='award-card-title pt-4'>
                        {txt_v.t6}
                      </div>
                      <div className='award-card-text pt-4' dangerouslySetInnerHTML={{ __html: txt_v.t7 }}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

    </div>
  )
}
