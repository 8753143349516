import React from 'react'
import './Union.css'
import CountUp from 'react-countup'

export default function Union({english=false}) {

  const textos = {
    en: {
      t1:`We are deeply grateful
      <span class=''>to all our allies, essential</span> pieces 
      <span class=''>in our achievements.</span>`
    },
    es: {
      t1:`Agradecemos profundamente   
      <span class=''> a todos nuestros aliados,</span> piezas
      <span class=''> fundamentales en nuestros logros.</span>`
    },
  }

  const txt_v = english ? textos.en : textos.es;

  return (
    <div>
        <div className="container-fluid bg-union">
          <div className='row justify-content-center '>
            <div className='col-xxl-7 col-lg-8 col-10'>
              <h2 className='u-title' dangerouslySetInnerHTML={{ __html: txt_v.t1 }}></h2>
            </div>
          </div>
          
        </div>
    </div>
  )
}
