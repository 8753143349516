import React from 'react'
import './Impacto.css'
import CardAcciones from '../../Contenidos/Acciones/CardAcciones/CardAcciones'
import becalos from '../../../assets/images/comunicacion/acciones/becalos.png'
import becalos2 from '../../../assets/images/acciones/becalos.jpg'
import tecnolochicas from '../../../assets/images/comunicacion/acciones/tecnolochicas.png'
import tecnolochicas2 from '../../../assets/images/acciones/tecnolochicas.jpg'
import cuantrix from '../../../assets/images/comunicacion/acciones/cuantrix.png'
import cuantrix2 from '../../../assets/images/acciones/cuantrix.jpg'
import posible from '../../../assets/images/comunicacion/acciones/posible.png'
import posible2 from '../../../assets/images/acciones/posible.jpg'
import valores from '../../../assets/images/comunicacion/acciones/valores.png'
import valores2 from '../../../assets/images/acciones/valores.jpg'
import empiezaTemprano from '../../../assets/images/comunicacion/acciones/empieza-temprano.png'
import empiezaTemprano2 from '../../../assets/images/acciones/empieza-temprano.jpg'
import fotograficamx from '../../../assets/images/comunicacion/acciones/fotograficamx.png'
import fotograficamx2 from '../../../assets/images/acciones/fotograficamx.jpg'
import golxmex from '../../../assets/images/acciones/gol_por_mexico.jpg'
import prevencionViolenciaGenero from '../../../assets/images/comunicacion/acciones/prevencion-violencia-genero.png'
import atteTuAmiga from '../../../assets/images/acciones/atte_tu_amiga.png'

export default function Impacto({english=false}) {

  const becalosItems = [
    {
      image: becalos,
      videoUrl: 'https://www.youtube.com/watch?v=ff-vIKwfxqk&feature=youtu.be',
      playing: false,
      external: false
    },
    {
      image: becalos2,
      videoUrl: 'https://www.facebook.com/becalos/posts/pfbid04DQsZprNnhBCXoCC8ovYpPkMZQYRAtHnW2z4WN5zdWz3uRRxTFhZaPf9TLJUDWWtl',
      playing: false,
      external: true
    }
  ];

  const eTempranoItems = [
    {
      image: empiezaTemprano,
      videoUrl: 'https://www.youtube.com/watch?v=hfgsd54ytUI&feature=youtu.be',
      playing: false,
      external: false
    },
    {
      image: empiezaTemprano2,
      videoUrl: 'https://www.youtube.com/watch?v=hRC20SQMKe0',
      playing: false,
      external: false
    }
  ];

  const tecnolochicasItems = [
    {
      image: tecnolochicas,
      videoUrl: 'https://www.youtube.com/watch?v=8ncdIUrL5Ak',
      playing: false,
      external: false
    },
    {
      image: tecnolochicas2,
      videoUrl: 'https://youtu.be/wD8prh2LX5A',
      playing: false,
      external: false
    }
  ];

  const posibleItems = [
    {
      image: posible,
      videoUrl: 'https://www.youtube.com/watch?v=69G7T0uBq2U',
      playing: false,
      external: false
    },
    {
      image: posible2,
      videoUrl: 'https://youtu.be/R_Q6rkubTSw',
      playing: false,
      external: false
    }
  ];

  const cuantrixItems = [
    {
      image: cuantrix,
      videoUrl: 'https://www.youtube.com/watch?v=-q26c4aJOwo',
      playing: false,
      external: false
    },
    {
      image: cuantrix2,
      videoUrl: 'https://www.youtube.com/watch?v=Q9jy6rWuIWM&feature=youtu.be',
      playing: false,
      external: false
    }
  ];

  const valoresItems = [
    {
      image: valores,
      videoUrl: 'https://www.youtube.com/watch?v=0mhdOn5vWwc',
      playing: false,
      external: false
    },
    {
      image: valores2,
      videoUrl: 'https://www.facebook.com/valores.fundaciontelevisa/videos/791351622707357/',
      playing: false,
      external: true
    }
  ];

  const fotograficaItems = [
    {
      image: fotograficamx,
      videoUrl: 'https://www.youtube.com/watch?v=k0XK0fiHDQs',
      playing: false,
      external: false
    },
    {
      image: fotograficamx2,
      videoUrl: 'https://www.youtube.com/watch?v=bOENgaGuOtQ&feature=youtu.be',
      playing: false,
      external: false
    }
  ];

  const violenciaGeneroItems = [
    {
      image: prevencionViolenciaGenero,
      videoUrl: 'https://youtu.be/im3gsFcGgUY',
      playing: false,
      external: false
    },
    {
      image: atteTuAmiga,
      videoUrl: 'https://youtu.be/OldZjMdJixc',
      playing: false,
      external: false
    }
  ];

  const textos = {
    en: {
      t1:`COMMUNICATION AND <br /> CONTENT WITH IMPACT `,
      t2:`Our campaign <b>¿Tienes el valor o te vale?</b> has maintained a constant presence on television, reaching <b>more than 29.6 million people</b>.<br/><br/>In our <b>Facebook Live events for Children's Day and Teachers' Day</b>, we obtained <b>more than 462 thousand reproductions.</b>`,
      t3:`Our <b>"Cada que juegas cuenta"</b> campaign reached <b>more than 19.7 million people on television and digital platforms</b>.<br/><br/>During Early Childhood Week, <b>"Cada momento cuenta"</b> had an average daily audience of <b>2.2 million</b>, featuring <b>five exclusive episodes on "Como dice el dicho"</b>.`,
      t4:`We had an impact on <b>more than 4.8 million people</b> through our social networks and created digital communities on TikTok for Cuantrix and Compu Cuantrix, adding <b>more than 14,062 followers</b>.<br/><br/>Thanks to our <b>content strategy on Facebook and TikTok</b>, we achieved <b>6,112 successful registrations</b> on Compu Cuantrix. `,
      t5:`Our television campaign reached <b>more than 29.2 million people</b>, promoting the participation of <b>girls and women in STEM careers</b>.<br/><br/>In collaboration with BEDU, we launched the <b>Data Science</b> course, with the participation of <b>300 university women</b>.`,
      t6:`Our campaign reached <b>more than 29.4 million people</b> on <b>television</b> and has been integrated into the lives of many Mexicans who support Bécalos.<br/><br/>Testimonials and content from <b>Mobility Canada</b> scholarship recipients reached <b>more than 1.1 million people through our social networks</b>.`,
      t7:`Our call for entrepreneurship reached <b>more than 32.5 million people</b> on television.<br/><br/>On TikTok, our content reached <b>more than 8.3 million views</b> and more than 390 thousand "Likes". `,
      t8:`Gol por México Femenil exceeds <b>30 thousand beneficiaries</b> (since the beginning of its support in 2018) and in 2023, this was communicated with the support of the participants of <b>"La casa de los famosos"</b>, reaching <b>more than 5 million users</b> on <b>social networks</b>.`,
      t9:`The campaign about the exhibition <b>Mexichrome. Fotografía y color en México</b> had an impact on <b>more than 23.1 million people</b> on television and social networks, and was amplified through <b>mexichrome.mx</b>, generating <b>60,000 visits and more than 3 million video views</b>.`,
      t10:`The campaign reached <b>more than 16.6 million people</b> on <b>television</b> and <b>social networks</b>, encouraging <b>11,348 people</b> to interact with the <b>Hola Violetta chatbot</b> to promote healthy relationships.<br /><br />The <b>Atte.tuamiga podcast</b> addressed tips to <b>promote a full life free of violence</b>, achieving <b>more than 986 thousand reproductions</b> of its video fragments.`
    },
    es: {
      t1:`COMUNICACIÓN Y <br /> CONTENIDOS CON IMPACTO`,
      t2:`Nuestra campaña <b>¿Tienes el valor o te vale?</b> ha mantenido una presencia constante en televisión, llegando a <b>más de 29.6 millones de personas.</b><br /><br />En nuestros eventos de <b>Facebook Live del Día del niño y del Día del maestro</b>, obtuvimos <b>más de 462 mil reproducciones.</b>`,
      t3:`Nuestra campaña <b>"Cada que juegas cuenta"</b> llegó a <b>más de 19.7 millones de personas en televisión y plataformas digitales.</b><br /><br />Durante la Semana de la Primera Infancia, <b>"Cada momento cuenta"</b> tuvo una audiencia promedio diaria de <b>2.2 millones,</b> presentando cinco capítulos exclusivos en <b>“Como dice el dicho”.</b>`,
      t4:`Impactamos a <b>más de 4.8 millones de personas</b> a través de nuestras redes sociales y creamos las comunidades digitales en TikTok para Cuantrix y Compu Cuantrix, sumando <b>más de 14,062 seguidores.</b><br /><br />Gracias a nuestra estrategia de <b>contenido en Facebook y TikTok,</b> conseguimos <b>6,112 registros exitosos</b> en Compu Cuantrix.`,
      t5:`Nuestra campaña de televisión llegó a <b>más de 29.2 millones de personas,</b> promoviendo la participación de <b>niñas y mujeres en carreras STEM.</b><br /><br />En colaboración con BEDU, lanzamos el curso de <b>Data Science,</b> con la participación de <b>300 mujeres universitarias.</b>`,
      t6:`Nuestra campaña alcanzó a <b>más de 29.4 millones de personas</b> en <b>televisión</b> y se ha integrado en la vida de muchos mexicanos que respaldan a Bécalos.<br /><br />Los testimonios y el contenido de los becarios de <b>Movilidad Canadá</b> llegaron a <b>más de 1.1 millones de personas a través de nuestras redes sociales.</b>`,
      t7:`Nuestra convocatoria a favor del emprendimiento alcanzó a <b>más de 32.5 millones de personas</b> en televisión.<br /><br />En TikTok, nuestros contenidos alcanzaron a <b>más de 8.3 millones de vistas</b> y más de 390 mil “Me Gusta”.`,
      t8:`Gol por México Femenil supera las <b>30 mil beneficiadas</b> (desde el inicio de sus apoyos en 2018) y en 2023, lo comunicó con el apoyo de los participantes de <b>“La casa de los famosos”,</b> llegando a <b>más de 5 millones de usuarios en redes sociales.</b>`,
      t9:`La campaña sobre la exposición <b>Mexichrome. Fotografía y color en México</b> impactó a <b>más de 22.1 millones de personas</b> en televisión y redes sociales, además se amplifico a través de <b>mexichrome.mx</b>, generando <b>60,000 visitas y más de 3 millones de reproducciones</b> de video.`,
      t10:`La campaña alcanzó a <b>más de 16.6 millones de personas</b> en <b>televisión</b> y <b>redes sociales,</b> impulsando a que <b>11,348 personas</b> interactuaran con el <b>chatbot de Hola Violetta</b> para fomentar relaciones saludables.<br /><br />El <b>podcast Atte.tuamiga</b> abordó consejos para <b>promover una vida plena y libre de violencia</b> logrando <b>más de 986 mil reproducciones</b> de sus fragmentos en video.`
    },
  }

  const txt_v = english ? textos.en : textos.es;

  return (
    <div className='Impacto'>
      <div className='d-flex justify-content-center pt-4 pt-md-5'>
        <div className='title pt-2 pt-md-5' dangerouslySetInnerHTML={{ __html: txt_v.t1 }}></div>
      </div>
      <div className='mt-4 mt-md-5 mt-lg-4'>&nbsp;</div>

      <div className='d-flex justify-content-center'>

        <div className='row ms-2 ms-md-0 cards-content'>
          <div className='col-12 col-lg-6'>
            <CardAcciones
              image={valores}
              title='valores'
              description={txt_v.t2}
              url="https://www.youtube.com/watch?v=0mhdOn5vWwc"
              type='videoSlider'
              isTitlePurple={false}
              videoItems={valoresItems}
            />
          </div>
          <div className='col-12 col-lg-6 mt-4 mt-md-5 mt-lg-0'>
            <CardAcciones
              image={empiezaTemprano}
              title='empieza temprano'
              description={txt_v.t3}
              url="https://www.youtube.com/watch?v=hfgsd54ytUI&feature=youtu.be"
              type='videoSlider'
              isTitlePurple={false}
              videoItems={eTempranoItems}
            />
          </div>

          <div className='d-none d-lg-block mt-0 mt-md-0 mt-lg-5'>&nbsp;</div>

          <div className='col-12 col-lg-6 mt-4 mt-md-5 mt-lg-0'>
            <CardAcciones
              image={cuantrix}
              title='cuantrix'
              description={txt_v.t4}
              url="https://www.youtube.com/watch?v=-q26c4aJOwo"
              type='videoSlider'
              isTitlePurple={false}
              videoItems={cuantrixItems}
            />
          </div>

          <div className='col-12 col-lg-6  mt-4 mt-md-5 mt-lg-0'>
            <CardAcciones
              image={tecnolochicas}
              title='Tecnolochicas'
              description={txt_v.t5}
              url="https://www.youtube.com/watch?v=8ncdIUrL5Ak"
              type='videoSlider'
              isTitlePurple={false}
              videoItems={tecnolochicasItems}
            />
          </div>

          <div className='d-none d-lg-block mt-0 mt-md-0 mt-lg-5'>&nbsp;</div>

          <div className='col-12 col-lg-6 mt-4 mt-md-5 mt-lg-0'>
            <CardAcciones
              image={becalos}
              title='Bécalos'
              description={txt_v.t6}
              url="https://www.youtube.com/watch?v=ff-vIKwfxqk&feature=youtu.be"
              type='videoSlider'
              isTitlePurple={false}
              videoItems={becalosItems}
            />
          </div>
          
          <div className='col-12 col-lg-6 mt-4 mt-md-5 mt-lg-0'>
            <CardAcciones
              image={posible}
              title='posible'
              description={txt_v.t7}
              url="https://www.youtube.com/watch?v=69G7T0uBq2U"
              type='videoSlider'
              isTitlePurple={false}
              videoItems={posibleItems}
            />
          </div>

          <div className='d-none d-lg-block mt-0 mt-md-0 mt-lg-5'>&nbsp;</div>

          <div className='col-12 col-lg-6  mt-4 mt-md-5 mt-lg-0 card-custom-mb'>
            <CardAcciones
              image={golxmex}
              title='GOL POR MÉXICO'
              description={txt_v.t8}
              url="https://www.youtube.com/watch?v=pIY0PbVoALk&feature=youtu.be"
              type='video'
              isTitlePurple={false}
            />
          </div>

          <div className='col-12 col-lg-6 mt-4 mt-md-5 mt-lg-0'>
            <CardAcciones
              image={fotograficamx}
              title='FOTOGRÁFICAMX'
              description={txt_v.t9}
              url="https://www.youtube.com/watch?v=k0XK0fiHDQs"
              type='videoSlider'
              isTitlePurple={false}
              videoItems={fotograficaItems}
            />
          </div>

          <div className='d-none d-lg-block mt-0 mt-md-5 mt-lg-5'>&nbsp;</div>

          <div className='col-12 col-lg-6  mt-4 mt-md-5 mt-lg-0 card-custom-mb'>
            <CardAcciones
              image={prevencionViolenciaGenero}
              title='A FAVOR DE LA PREVENCIÓN DE LA VIOLENCIA DE GÉNERO'
              description={txt_v.t10}
              url="https://www.youtube.com/watch?v=pIY0PbVoALk&feature=youtu.be"
              type='videoSlider'
              isTitlePurple={false}
              videoItems={violenciaGeneroItems}
            />
          </div>

          <div className='mt-4 mt-md-5'>&nbsp;</div>

        </div>

      </div>

    </div>
  )
}
