import React from 'react'
import './IniciativasFotograficaMx.css'
import macario from '../../../../../assets/images/programas/subsections/fotograficamx/macario.png'
import cinemaztlan from '../../../../../assets/images/programas/subsections/fotograficamx/cinemaztlan.png'
import CountUp from 'react-countup';

export default function IniciativasFotograficaMx({english=false}) {
  const textos = {
    en: {
        t1:`INITIATIVES THAT <br />GENERATE IMPACT: :`,
        t2:`In alliance with <em>The Film Foundation's World Cinema Project,</em> <b>Filmoteca UNAM</b> and with funding from the <b>Hollywood Foreign Press Association, we restored the film</b> <em>Macario</em> (Roberto Gavaldón, 1959). The premiere screening was at the <b>Festival II Cinema Ritrovato</b> <br class='d-xl-block d-none'/> in Bologna, Italy.`,
        t3:`In collaboration with <b>Hotel Xcaret Arte,</b> we presented the exhibition: <em>Cinemaztlán. La tierra prometida with</em>`,
        t4:`<em>attendees.</em> The exhibition was accompanied by a <b>film series of 11 films.</b>`
    },
    es:{
        t1:`Iniciativas que <br /> generan impacto:`,
        t2:`En alianza con <em>The Film Foundation’s World Cinema Project,</em> <b>Filmoteca UNAM</b> y con financiamiento de <b> Hollywood Foreign Press Association, restauramos la película</b><em> Macario</em> (Roberto Gavaldón, 1959). La proyección de la premier fue en el <b>Festival II Cinema Ritrovato</b> <br class='d-xl-block d-none'/> en Bolonia, Italia.`,
        t3:`En colaboración con <b>Hotel Xcaret Arte,</b> presentamos la exposición: <em>Cinemaztlán. La tierra prometida con</em>`,
        t4:`<em>asistentes.</em> La muestra se acompañó de un <b>ciclo de cine de 11 películas.</b>`
    }
  }

  const txt_v = english ? textos.en : textos.es;

  return (
    <div className='IniciativasFotograficaMx'>
      {/* mobile */}
      <div className='mobile-device'>
        <div className='d-flex justify-content-center py-4 px-md-5'>
          <div className='title' dangerouslySetInnerHTML={{ __html: txt_v.t1 }}></div>
        </div>
        <div className='d-flex justify-content-center px-5 pb-4'>
          <div className='paragraph-inif' dangerouslySetInnerHTML={{ __html: txt_v.t2 }}></div>
        </div>

        <div className='bg-purple py-4 px-5'>
          <div className='d-flex justify-content-center px-4'>
            <img src={macario} alt='macario' className='w-100' />
          </div>
        </div>

        <div className='d-flex justify-content-center mt-5 px-5 pb-4'>
          <div className='paragraph-inif'>
            <span dangerouslySetInnerHTML={{ __html: txt_v.t3 }}></span>
                  <em> <CountUp
                    enableScrollSpy={true}
                    start={'9400'}
                    end={9500}
                    duration={2}
                  /></em> <span dangerouslySetInnerHTML={{ __html: txt_v.t4 }}></span>
          </div>
        </div>

        <div className='p-0 m-0'>
          <div className='d-flex justify-content-center'>
            <img src={cinemaztlan} alt='macario' className='w-100' />
          </div>
        </div>
      </div>

      {/* web */}
      <div className='web-device'>
        <div className='d-flex justify-content-center py-5 px-5'>
          <div className='title' dangerouslySetInnerHTML={{ __html: txt_v.t1 }}></div>
        </div>

        <div className='row m-0 p-0'>
          <div className='col-6 m-0 p-0'>
            <div className='bg-purple py-4 py-lg-0 m-0 px-5'>
              <div className='d-flex justify-content-center px-5 py-4 py-lg-0'>
                <img src={macario} alt='macario' className='w-75' />
              </div>
            </div>
          </div>
          <div className='col-6'>
            <div className='d-flex justify-content-center align-items-center h-100 w-100 p-5'>
              <div className='paragraph-inif' dangerouslySetInnerHTML={{ __html: txt_v.t2 }}></div>
            </div>
          </div>
        </div>

        <div className='row p-0 m-0'>
          <div className='col-6 py-0 p-5'>
            <div className='d-flex justify-content-center align-items-center h-100 w-100 p-5'>
              <div className='paragraph-inif'>
                <span dangerouslySetInnerHTML={{ __html: txt_v.t3 }}></span>
                  <em> <CountUp
                    enableScrollSpy={true}
                    start={'9400'}
                    end={9500}
                    duration={2}
                  /></em> <span dangerouslySetInnerHTML={{ __html: txt_v.t4 }}></span>
              </div>
            </div>
          </div>
          <div className='col-6 m-0 p-0'>
            <div className='p-0 m-0'>
              <div className='d-flex justify-content-center'>
                <img src={cinemaztlan} alt='macario' className='w-100' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
